import { client } from 'common/graphql/client';
import { BffCountry, BffVatResponse } from 'common/graphql/sdk';
import { reportError } from 'common/hooks/useError';
import { BlankButton } from 'common/primitives/buttons';
import { FakeInput, MaskedInput, TextInput } from 'common/primitives/forms';
import globalStyle from 'common/primitives/forms/styles';
import { compileTemplate, useTranslations } from 'common/primitives/translations';
import { colors, linkColor } from 'common/styles';
import type { CheckoutConfig } from 'components/checkout-v2/config';
import { css } from 'linaria';
import debounce from 'lodash/debounce';
import defaults from 'lodash/defaults';
import React, { Fragment, useState } from 'react';

const vissCheckableCountries = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'EL',
    'ES',
    'FI',
    'FR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK'
];

const styles = defaults(
    {
        hintLink: css`
            ${linkColor(colors.black, colors.primary)};
        `,
        inputVatWrapper: css`
            position: relative;
        `,
        spinner: css`
            position: absolute;
            right: 16px;
            top: 22px;
            z-index: 1;
            height: 16px;
            width: 16px;
            color: ${colors.inputBorderColor};
            display: inline-block;
            border: 2px solid;
            border-radius: 50%;
            border-top-color: transparent;
            animation: rotate 1s linear infinite; }

            @keyframes rotate {
                0% {
                transform: rotate(0);
            }
                100% {
                transform: rotate(360deg);
                }
            }
        `
    },
    globalStyle
);

interface VitraVatCompanyFormProps {
    /**
     * The Checkout Config is used to arrange the address fields
     */
    config: CheckoutConfig;

    /**
     * Register ref function must be passed to each field
     */
    register: any;

    /**
     * Required for the masked input
     */
    control: any;

    /**
     * Errors must be passed to feilds to trigger error display
     */
    errors: any;

    /**
     * The defaultValues object passed from react-hook-form
     */
    defaultValues?: any;

    /**
     * The component sets the field error if the VAT Service says its an inavlida VAT Number
     */
    setError?: any;

    /**
     * We must clear errors which we set manually
     */
    clearError?: any;

    /**
     * Tell the form that we have updated values.
     * Needed if ths user accepts the corrected company name by the VATresponse
     */
    setValue: any;

    /**
     * Watch/Observe values which might be changed somewhere else
     */
    watch: (fieldName: string) => string;

    /**
     * Triggers the validation for the updated values. Needed for addressInput auto completion
     */
    triggerValidation: any;

    /**
     * Prefixes the fields if required. Used in nested formsStates e.g. billingAddress.company
     * MUST END WITH A DOT: eg. 'billingAddress.'
     */
    fieldNamePrefix?: string;

    /**
     * Company field name eg, company or companyName
     * Defaults to company
     */
    fieldNameCompany?: string;

    /**
     * Tax field name eg, tax or taxNumber
     * Defaults to company
     */
    fieldNameTax?: string;
}

const VitraVatCompanyForm: React.FunctionComponent<VitraVatCompanyFormProps> = ({
    config,
    register,
    control,
    errors,
    defaultValues,
    setValue,
    setError,
    clearError,
    watch,
    triggerValidation,
    fieldNamePrefix = '',
    fieldNameCompany = 'company',
    fieldNameTax = 'taxNumber'
}) => {
    const t = {
        errRequired: useTranslations('address.fields.errors.required', 'Please fill in the mandatory field'),

        company: useTranslations('address.fields.company', 'Company name'),
        country: useTranslations('address.fields.country', 'Country'),
        tax_number: useTranslations('checkout.personal.anonymous_customer.new_account.tax.number.number', 'Tax number'),
        tax_validaion_failed: useTranslations(
            'checkout.personal.anonymous_customer.new_account.tax.validation_failed',
            'VAT Validation via Service failed'
        ),
        tax_pattern_failed: useTranslations(
            'checkout.personal.anonymous_customer.new_account.tax.pattern_failed',
            'Please make sure your Tax Number Starts with {country}'
        ),
        tax_fix: useTranslations(
            'checkout.personal.anonymous_customer.new_account.tax.number.fix',
            'Tax number and company name do not match. Click here for an automatic correction.'
        )
    };

    const { vatNumberRegexIsValid, vatNumberFormat, countryCode } = config;

    const vatNumberValidationServiceRequest = async (vat: string): Promise<BffVatResponse | null | undefined> => {
        // We must remove the country iso from the taxNumber
        if (vat.startsWith(config.countryCode)) {
            vat = vat.slice(config.countryCode.length);
        }
        const { checkVatNumber: checkVatNumberResult } = await client.checkVatNumber({
            vat,
            country: config.countryCode as BffCountry
        });

        if (checkVatNumberResult?.__typename === 'BffVatResponse') {
            return checkVatNumberResult;
        }

        return null;
    };

    const companyNameMatches = (companyName: string, vatResponse: BffVatResponse): boolean => {
        if (!vatResponse || !vatResponse.name) {
            return true;
        }

        // sanitize
        const cn = companyName.replace(/\s+/g, ' ').trim();
        const vn = vatResponse.name.replace(/\s+/g, ' ').trim();

        // We only allow a max length of 70 chars in the input so we have to consider that
        return vn.slice(0, 70) === cn;
    };

    // Tax Number Vaidation
    const [isLoading, setIsLoading] = useState(false);
    const validateTaxNumber = async (taxNumber: string): Promise<boolean | React.ReactElement | string> => {
        // Check by regex
        if (vatNumberRegexIsValid && !vatNumberRegexIsValid(taxNumber)) {
            return t.tax_validaion_failed;
        }

        // Check if viess api supports the country for validation
        if (!vissCheckableCountries.includes(countryCode.toUpperCase())) {
            return true;
        }

        const companyName = watch(`${fieldNamePrefix}${fieldNameCompany}`);

        if (companyName === '') {
            // We wait till the user entered a company name.
            // As long as that we dont cant validate and though we shouldnt mark the field
            // as invalid
            return true;
        }

        const taxNumberTrimmed = taxNumber.trim().toLowerCase();

        if (!taxNumberTrimmed.startsWith(countryCode.toLowerCase())) {
            return compileTemplate(t.tax_pattern_failed, { country: countryCode });
        }

        try {
            setIsLoading(true);
            const vatReponse = await vatNumberValidationServiceRequest(taxNumberTrimmed.toUpperCase());
            if (
                vatReponse &&
                vatReponse.isValid &&
                (companyNameMatches(companyName, vatReponse) || vatReponse.name === '---')
            ) {
                setIsLoading(false);
                if (clearError) {
                    clearError(`${fieldNamePrefix}${fieldNameTax}`);
                }
                return true;
            }
            if (vatReponse && vatReponse.isValid && vatReponse.name !== '---') {
                setIsLoading(false);
                const setName = (e: any) => {
                    e.preventDefault();
                    setValue(`${fieldNamePrefix}${fieldNameCompany}`, (vatReponse.name ?? '').slice(0, 70));
                    setTimeout(async () => {
                        await triggerValidation([
                            `${fieldNamePrefix}${fieldNameCompany}`,
                            `${fieldNamePrefix}${fieldNameTax}`
                        ]);
                    }, 20);
                };

                return (
                    <BlankButton className={styles.hintLink} testId="fixVatNumber" onClick={setName}>
                        {t.tax_fix}
                    </BlankButton>
                );
            }
            if (setError && vatReponse && !vatReponse.isValid) {
                setIsLoading(false);
                setTimeout(() => {
                    setError(`${fieldNamePrefix}${fieldNameTax}`, 'manual', t.tax_validaion_failed);
                }, 100);
            }
        } catch (err) {
            setIsLoading(false);
            reportError(err, 'vatNumberValidationServiceRequest');
            // If the api fails we whould return true to keep the order process going
            // because its not the users fault
            return true;
        }
        return false;
    };
    return (
        <Fragment>
            <TextInput
                defaultValues={defaultValues}
                name={`${fieldNamePrefix}${fieldNameCompany}`}
                label={t.company}
                showErrorMessage={true}
                maxLength={70}
                errors={errors}
                inputRef={register({
                    required: t.errRequired
                })}
            />
            <FakeInput value={config.countryName} label={t.country} />
            <div className={styles.inputVatWrapper}>
                {isLoading && <div className={styles.spinner} />}
                {(vatNumberFormat && (
                    <MaskedInput
                        mask={vatNumberFormat}
                        control={control}
                        defaultValues={defaultValues}
                        name={`${fieldNamePrefix}${fieldNameTax}`}
                        label={t.tax_number}
                        showErrorMessage={true}
                        errors={errors}
                        rules={{
                            required: t.errRequired,
                            validate: debounce(validateTaxNumber, 600, {
                                leading: true
                            })
                        }}
                        inputRef={register}
                    />
                )) || (
                    <TextInput
                        defaultValues={defaultValues}
                        name={`${fieldNamePrefix}${fieldNameTax}`}
                        label={t.tax_number}
                        showErrorMessage={true}
                        errors={errors}
                        inputRef={register({
                            required: t.errRequired,
                            validate: debounce(validateTaxNumber, 600, {
                                leading: true
                            })
                        })}
                    />
                )}
            </div>
        </Fragment>
    );
};
export default VitraVatCompanyForm;
