import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { useRef } from 'react';
import { colors } from 'common/styles';
import throttle from 'lodash/throttle';

export interface VitraPinchZoomSliderProps {
    /**
     * images must be passed as an array
     */
    images: Image[]
    /**
     * Current Active
     */
    currentIdx: number;
    /**
     * handler
     */
    onChange: (arg0: number) => void;
}


const styles = {
    pinchZoomSlider: css`
        position: absolute;
        bottom: 0;
        z-index: 1;
        height: 60px;
        width: 100%;
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        background: rgba(51,51,51,0.8);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    `,
    pinchZoomSliderSlide: css`
        flex: 0 0 59px;
        height: 100%;
        border-right: 1px solid ${colors.black};
        background-size: cover;
        opacity: 0.5;
        scroll-snap-align: start;
        transition:opacity 500ms ease-out;
    `,
    pinchZoomSliderSlideActive: css`
        flex: 1 0 60px;
        height: 100%;
        border-right: 1px solid ${colors.black};
        background-size: cover;
        opacity: 1;
    `,
    pinchZoomSliderSlideSpacer: css`
        scroll-snap-align: start;
    `
};

const VitraPinchZoomSlider: React.FunctionComponent<VitraPinchZoomSliderProps> = props => {

    // Only works in DOM
    if (!canUseDOM) {
        return null;
    }

    const leaftMeasureRef = useRef(null);

    const handleOnClick = (idx: number) => () => {
        props.onChange(idx);
    };

    const handleOnScroll = () => {
        if (leaftMeasureRef == null) {
            return;
        }
        const position = (leaftMeasureRef.current as any).getBoundingClientRect()
        const newIdx = Math.abs(Math.floor(position.x / 60));
        if (newIdx < props.images.length) {
            props.onChange(newIdx);
        }
    };

    return (
        <div
            className={styles.pinchZoomSlider}
            onScroll={throttle(handleOnScroll, 100)}
        >
            <div
                ref={leaftMeasureRef}
                className={styles.pinchZoomSliderSlideSpacer}
            />

            {props.images.map((i, idx) => {
                return (
                    <div
                        className={cx(
                            styles.pinchZoomSliderSlide,
                            props.currentIdx == idx && styles.pinchZoomSliderSlideActive
                        )}
                        style={{backgroundImage: `url(${i.src})`}}
                        onClick={handleOnClick(idx)}
                    />

                );
            })}

            <div
                className={styles.pinchZoomSliderSlideSpacer}
                style={{paddingRight: `${(window.innerWidth) - 60}px`}}
            />
        </div>
    );
};

export default VitraPinchZoomSlider;
