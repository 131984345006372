/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import 'cross-fetch/polyfill';

export const get = <T>(url: string, options: any = {}): Promise<T> => {
    return fetch(url, {
        // @todo: validate this for production
        method: 'GET',
        ...options
    }).then((response) => {
        if (!response.ok) {
            const e: any = new Error(response.statusText);
            e.status = response.status;
            throw e;
        }
        return response.json() as Promise<T>;
    });
};

export const del = <T>(url: string, params: any, options: any = {}): Promise<T> => {
    options.headers = options.headers || {
        'Content-Type': 'application/json'
    };
    return fetch(url, {
        method: 'DELETE',
        // @todo: validate this for production
        body: JSON.stringify(params),
        ...options
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
    });
};

export const patch = <T>(url: string, params: any, options: any = {}): Promise<T> => {
    options.headers = options.headers || {
        'Content-Type': 'application/json'
    };
    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(params),
        ...options
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
    });
};

export const post = <T>(url: string, params: any, options: any = {}): Promise<T> => {
    options.headers = options.headers || {
        'Content-Type': 'application/json'
    };
    const roptions = {
        method: 'POST',
        body: JSON.stringify(params),
        ...options
    };

    return fetch(url, roptions).then(async (response) => {
        let data = undefined;
        try {
            data = await response.json();
        } catch (err) {
            console.log(err);
        }

        if (!response.ok) {
            const err: any = new Error(response.statusText);
            err.data = data;
            err.status = response.status;
            throw err;
        }

        return data as Promise<T>;
    });
};

export const postForm = <T>(url: string, params: any): Promise<T> => {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
    });
    return fetch(url, {
        method: 'POST',
        body: formData
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
    });
};
