import { visuallyHidden } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

const screenreaderText = css`
    ${visuallyHidden}
`;

/**
 * This component can be used to render text that is not visible on the screen,
 * but will be recognized and read by screen reader software.
 *
 * This is helpful in cases where your component does not accept aria attributes such as
 * aria-label or aria-labelledby
 */
const ScreenreaderOnlyText: React.FC<React.ComponentPropsWithoutRef<'span'>> = ({ children, ...props }) => {
    return (
        <span {...props} className={cx(props.className, screenreaderText)}>
            {children}
        </span>
    );
};

export default ScreenreaderOnlyText;
