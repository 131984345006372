import { colors, linkColor, rh } from 'common/styles';

import { css } from 'linaria';

/**
 * GENERAL INPUT STYLES
 */
const styles = {
    input: css`
        appearance: none;
        &::placeholder {
            color: ${colors.textGrey};
        }
    `,
    wrapper: css`
        margin-bottom: ${rh(0.65)};
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    `,
    label: css`
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        color: ${colors.textGrey};
        font-weight: 500;
        transition: all 100ms ease-in-out;
        pointer-events: none;
        padding: ${rh(0.45)} ${rh(0.5)} ${rh(0.2)};
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
            ${linkColor(colors.textGrey, colors.primary)};
        }
    `,
    required: css`
        position: absolute;
        top: 4px;
        right: 5px;
        color: ${colors.lightgrey};
    `,
    errorMessage: css`
        color: ${colors.error};
        font-weight: 400;
        font-size: 14px;
        line-height: ${rh(1)};
    `,
    errorLabel: css`
        color: ${colors.error};
    `,
    formMessage: css`
        background: ${colors.silverDark};
        padding: ${rh(0.5)} 20px;
        margin-bottom: ${rh(0.5)};
        margin-top: ${rh(0.75)};
        font-weight: 100;
        display: flex;
        flex-direction: row;
    `,
    formMessageIcon: css`
        flex: 0 0 20px;
        margin-right: 15px;
        position: relative;
        top: 4px;
    `,
    formMessageCopy: css``,
    // Hint beneath an input field
    hint: css`
        font-size: 14px;
        margin-top: -${rh(0.5)};
        margin-bottom: ${rh(0.65)};
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `,
    hintHidden: css`
        display: none;
    `,
    // A separator vertically between two inputs
    divider: css`
        border-top: 2px solid ${colors.checkoutBorder};
        margin: ${rh(1)} 0;
    `
};

/**
 * FORMFIELD ALIGNMENT STYLES
 */
const formRowGutterPx = 16;
export const formRowWrapperStyles = css`
    display: flex;
    margin-left: -${formRowGutterPx}px;
    align-items: flex-start;
    flex-wrap: wrap;
    > * {
        margin-left: ${formRowGutterPx}px;
        flex: 1 0 calc(100% - ${formRowGutterPx}px);
        max-width: calc(100% - ${formRowGutterPx}px);
        width: calc(100% - ${formRowGutterPx}px);
        flex-wrap: wrap;
    }
`;

export const formRowStyles = {
    p50: css`
        margin-left: ${formRowGutterPx}px;
        flex: 1 0 calc(50% - ${formRowGutterPx}px);
        max-width: calc(50% - ${formRowGutterPx}px);
        width: calc(50% - ${formRowGutterPx}px);
    `,
    p30: css`
        margin-left: ${formRowGutterPx}px;
        flex: 1 0 calc(30% - ${formRowGutterPx}px);
        max-width: calc(30% - ${formRowGutterPx}px);
        width: calc(30% - ${formRowGutterPx}px);
    `,
    p70: css`
        margin-left: ${formRowGutterPx}px;
        flex: 1 0 calc(70% - ${formRowGutterPx}px);
        max-width: calc(70% - ${formRowGutterPx}px);
        width: calc(70% - ${formRowGutterPx}px);
    `,
    p50p50: css`
        display: flex;
        margin-left: -${formRowGutterPx}px;
        align-items: flex-start;
        .form__field {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(50% - ${formRowGutterPx}px);
            max-width: calc(50% - ${formRowGutterPx}px);
            width: calc(50% - ${formRowGutterPx}px);
        }
    `,
    p60p40: css`
        display: flex;
        margin-left: -${formRowGutterPx}px;
        align-items: flex-start;
        .form__field:first-child {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(60% - ${formRowGutterPx}px);
            max-width: calc(60% - ${formRowGutterPx}px);
            width: calc(60% - ${formRowGutterPx}px);
        }
        .form__field + .form__field {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(40% - ${formRowGutterPx}px);
            max-width: calc(40% - ${formRowGutterPx}px);
            width: calc(40% - ${formRowGutterPx}px);
        }
    `,
    p40p60: css`
        display: flex;
        margin-left: -${formRowGutterPx}px;
        align-items: flex-start;
        .form__field:first-child {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(40% - ${formRowGutterPx}px);
            max-width: calc(40% - ${formRowGutterPx}px);
            width: calc(40% - ${formRowGutterPx}px);
        }
        .form__field + .form__field {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(60% - ${formRowGutterPx}px);
            max-width: calc(60% - ${formRowGutterPx}px);
            width: calc(60% - ${formRowGutterPx}px);
        }
    `,
    p30p70: css`
        display: flex;
        margin-left: -${formRowGutterPx}px;
        align-items: flex-start;
        .form__field:first-child {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(30% - ${formRowGutterPx}px);
            max-width: calc(30% - ${formRowGutterPx}px);
            width: calc(30% - ${formRowGutterPx}px);
        }
        .form__field + .form__field {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(70% - ${formRowGutterPx}px);
            max-width: calc(70% - ${formRowGutterPx}px);
            width: calc(70% - ${formRowGutterPx}px);
        }
    `,
    p70p30: css`
        display: flex;
        margin-left: -${formRowGutterPx}px;
        align-items: flex-start;
        .form__field:first-child {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(70% - ${formRowGutterPx}px);
            max-width: calc(70% - ${formRowGutterPx}px);
            width: calc(70% - ${formRowGutterPx}px);
        }
        .form__field + .form__field {
            margin-left: ${formRowGutterPx}px;
            flex: 1 0 calc(30% - ${formRowGutterPx}px);
            max-width: calc(30% - ${formRowGutterPx}px);
            width: calc(30% - ${formRowGutterPx}px);
        }
    `
};

export default styles;
