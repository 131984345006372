import * as React from 'react';

const SvgV2Replay = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 300 300" {...props}>
        <path d="M150 45.5c-51.6 0-94.5 37.5-103 86.7l-20-20-11.3 11.3L42.2 150l11.3 11.3L64.8 150l26.5-26.5L80 112.2l-15.7 15.7c9.8-38.1 44.5-66.4 85.7-66.4 48.8 0 88.5 39.7 88.5 88.5s-39.7 88.5-88.5 88.5c-4.6 0-9.2-.4-13.8-1.1l-2.5 15.8c5.3.8 10.8 1.3 16.2 1.3 57.6 0 104.5-46.9 104.5-104.5.1-57.6-46.8-104.5-104.4-104.5z" />
        <path d="M94.8 238.7c4.9 3 10.1 5.7 15.4 7.9l6.1-14.8c-4.5-1.9-8.9-4.1-13-6.7l-8.5 13.6zM79.1 203l-12.8 9.6c3.4 4.6 7.3 9 11.5 12.9l11.1-11.6c-3.6-3.3-6.9-7-9.8-10.9zm-14.4-29.5l-15.4 4.2c1.5 5.6 3.5 11 6 16.3l14.5-6.7c-2.2-4.5-3.9-9.1-5.1-13.8z" />
    </svg>
);

export default SvgV2Replay;
