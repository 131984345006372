import * as React from 'react';

const SvgCart = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={props.width || '1em'} height={props.height || '1em'} viewBox="0 0 21.5 17" {...props}>
        <path d="M0 .5a.5.5 0 0 0 .5.5h2.109l.902 3.611.006.021 1.998 7.989A.5.5 0 0 0 6 13h13c.229 0 .43-.156.484-.378l2-7.984A.497.497 0 0 0 21 4.016L4.391 4 3.484.378A.498.498 0 0 0 3 0H.5a.5.5 0 0 0-.5.5zM4.641 5l15.719.015L18.609 12H6.391l-1.75-7z" />
        <circle cx={16.707} cy={15.206} r={1.794} />
        <circle cx={8.292} cy={15.206} r={1.794} />
    </svg>
);

export default SvgCart;
