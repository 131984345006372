import { isNotProduction, useConfig } from 'common/hooks/useBoostrap';
import { FeatureFlags, useFeature } from 'common/hooks/useFeature';
import { getConfig } from 'components/checkout-v2/config';
import { GraphQLClient } from 'graphql-request';
import type { RequestMiddleware } from 'graphql-request/dist/types';
import { BffCountry, BffLanguage, BffMarket, getSdk } from './sdk';

const requestMiddleware: RequestMiddleware = async (request) => {
    // needed to mock this our in storbybook
    request.url = useConfig('bff', '');
    return request;
};
const responseMiddleware = async (response: any) => {
    if (response?.extensions?.trace && (isNotProduction || useFeature(FeatureFlags.log_traces))) {
        const styles = ['color: white', 'background: green'].join(';');
        const message = `${response?.extensions?.trace.operationName} - TraceId: ${response?.extensions?.trace.traceId} - Duration: ${response?.extensions?.trace.duration}}`;
        console.log('%c%s', styles, message);
        response.data.trace = response?.extensions?.trace;
    }
};

export const gqlClient = new GraphQLClient(useConfig('bff', ''), {
    requestMiddleware,
    responseMiddleware,
    credentials: 'include'
});

export const client = getSdk(gqlClient);
export const getBffLanguageAndCountry = (): {
    language: BffLanguage;
    country: BffCountry;
    market: BffMarket;
    code: string;
    showPrices: boolean;
} => {
    const code = useConfig('code', 'de-de');
    const countryConfigFromUrl = useConfig('country', 'DE');
    const codeParts = code.split('-');
    const showPrices = useConfig('showPrices', false);
    const shopConfig = getConfig(codeParts[1] as BffCountry);

    // This overwrites the config with the shop country so we can have multiple fr-fr countries but always show France
    const country = shopConfig.hasCart ? shopConfig.countryCode : countryConfigFromUrl;

    return {
        language: `${codeParts[0]}`.toLowerCase() as BffLanguage,
        country: country as BffCountry,
        market: `${codeParts[1]}`.toUpperCase() as BffMarket,
        code,
        showPrices
    };
};
