import { getAnayltics } from 'common/primitives/analytics';
import { useTranslations } from 'common/primitives/translations';
import { NavigationSection } from '../desktop/desktopNavigationSubmenu';
import { RowItemGalleryProps } from '../desktop/rowItemGallery';
import { RowItemImageProps } from '../desktop/rowItemImage';
import { RowItemTextProps } from '../desktop/rowItemText';
import {
    NavigationDisplayV2,
    NavigationFlyout,
    NavigationFlyoutItemV2,
    NavigationItemRowElementV2
} from '../main-navigation-types';

/**
 * transform flyout items to props for the respective component to render them
 * based on the flyout type
 */
export const transformFlyoutDataToComponentProps = (flyout: NavigationFlyout): NavigationSection => {
    switch (flyout.type) {
        case 'text': {
            return {
                type: 'text',
                data: (flyout.items ?? []).map(({ footer, headline, items }) => ({
                    footer,
                    headline,
                    links: (items ?? []).map(({ link }) => link)
                })) as RowItemTextProps[]
            };
        }
        case 'gallery': {
            const item = (flyout.items ?? [])[0] ?? {};
            return {
                type: 'gallery',
                data: {
                    items: item.items,
                    headline: item.headline,
                    link: item.footer
                } as RowItemGalleryProps
            };
        }
        case 'two-images-horizontal': {
            const item = (flyout.items ?? [])[0] ?? {};
            return {
                type: 'gallery',
                data: {
                    items: item.items
                } as RowItemGalleryProps
            };
        }
        case 'image':
        case 'two-images-vertical': {
            const item = (flyout.items ?? [])[0] ?? {};
            return {
                type: 'image',
                data: item.items as RowItemImageProps[]
            };
        }
    }
};

/**
 * deletes all items from the navigation data that should only be visible for a
 * specific platform (desktop or mobile)
 */
export const removePlatformSpecificContent = (platformToDiscard: NavigationDisplayV2) => (
    flyout: NavigationFlyout
): NavigationFlyout => {
    const discardPlatform = (item: NavigationFlyoutItemV2 | NavigationItemRowElementV2) =>
        item.display !== platformToDiscard;

    return {
        ...flyout,
        items: flyout.items
            ?.filter(discardPlatform)
            .map((flyoutItem) => ({ ...flyoutItem, items: flyoutItem.items?.filter(discardPlatform) }))
    };
};

/**
 * Gets the correctly pluralized accessibility label for the wishlist badge.
 * Must be used within a React component.
 */
export const useWishlistBadgeLabel = (wishlistCount: number | undefined): string => {
    if (wishlistCount === undefined) return '';
    if (wishlistCount < 1) return useTranslations('wishlist.status.empty', 'No items in your wishlist.');
    if (wishlistCount === 1) useTranslations('wishlist.status.single_item', 'Your wishlist contains one item.');
    return useTranslations('wishlist.status.multiple_items', 'Your wishlist contains {count} items.', {
        count: wishlistCount.toString()
    });
};

/**
 * Gets the correctly pluralized accessibility label for the shopping cart badge.
 * Must be used within a React component.
 */
export const useCartBadgeLabel = (cartCount: number | undefined): string => {
    if (cartCount === undefined) return '';
    if (cartCount < 1) return useTranslations('cart.status.empty', 'Your shopping cart is empty');
    if (cartCount === 1) useTranslations('cart.status.single_item', 'One item in your shopping cart');
    return useTranslations('cart.status.multiple_items', '{count} items in your shopping cart.', {
        count: cartCount.toString()
    });
};

/**
 * Generates link attributes for navigation
 * wrapper bc of the category
 * @param link Link
 * @returns htlm Attibutes
 */
export const generateNavigationAnayltics = (link: Link): Record<string, string> => {
    // this requires a creative to be set
    if (link?.analytics?.creative === '') {
        return {};
    }
    return getAnayltics({
        enabled: Boolean(link?.analytics),
        type: 'LinkGa4',
        data: {
            category: 'navigation_interaction',
            action: 'navigation_interaction',
            label: link?.analytics?.creative
        }
    });
};
