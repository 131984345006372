import * as React from 'react';

const SvgV2Check = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={13.435} height={13.435} viewBox="0 0 13.435 13.435" {...props}>
        <path
            d="M3.535 12.022L0 8.486l1.414-1.414 3.536 3.535 7.07-7.07 1.415 1.413-8.485 8.486z"
            data-name="icon/single/check"
        />
    </svg>
);

export default SvgV2Check;
