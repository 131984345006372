import React from 'react';

import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';


interface VitraPaginationBulletsProps {
    className?: string;
    onClick: (index: number) => void;
    paginationTotal: number;
    paginationCurrent: number;
}


const styles = {
    wrapper: css`
        width: 100%;
        text-align: center;
        height: ${rh(1)};
    `,
    bullet: css`
        display: inline-block;
        background: ${colors.silverDark};
        width: 15px;
        height: 15px;
        margin: 5px 10px;
        border-radius: 100%;
        :hover {
            cursor: pointer;
            background: ${colors.primary};
        }
    `,
    bulletActive: css`
        background: ${colors.primary};
        pointer-events: none !important;
    `
};

const VitraPaginationBullets: React.FunctionComponent<VitraPaginationBulletsProps> = ({
    onClick,
    paginationTotal,
    paginationCurrent,
    className
}) => {

    function renderBullets() {
        const bullets: JSX.Element[] = [];
        for (let i = 0; i < paginationTotal; i++) {
            bullets.push(
                <div
                    className={cx(
                        'bullets__item',
                        styles.bullet,
                        i === paginationCurrent ? styles.bulletActive : null
                    )}
                    onClick={() => onClick(i)}
                >
                </div>
            );
        }
        return bullets;
    }

    return (
        <div className={cx('pagination__bullets', styles.wrapper, className)}>
            {renderBullets()}
        </div>
    );
};


export default VitraPaginationBullets;
