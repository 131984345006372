import VitraSpinner from 'common/primitives/spinners/vitraSpinner';
import React, { useEffect, useRef, useState } from 'react';

interface VitraImagePreloadProps {
    /*
        The src to load
    */
    src: string;
    /*
        A Component to display during loading
    */
    loadingIndicator?: React.ReactChild;
    /*
        Sometimes its nice to who the loading indicator at least some milliseconds
    */
    delayMs?: number;
    /*
        The child components which will be renderer as soon as we are finished
    */
    children: any;
}

const loadImage = (url: string) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = url;
    });
};

const VitraImagePreload: React.FunctionComponent<VitraImagePreloadProps> = ({
    delayMs,
    src,
    loadingIndicator,
    children
}) => {
    const [preloadStatus, setPreloadStatus] = useState<'success' | 'loading' | 'error'>('loading');
    const [minDelayMsExpired, setMinDelayMsExpired] = useState(delayMs && delayMs > 0 ? false : true);
    const timerRef = useRef<any>(null);

    const preloadImages = async () => {
        setPreloadStatus('loading');
        if (timerRef.current) {
            timerRef.current.clearTimeout();
        }
        if (delayMs) {
            timerRef.current = window.setTimeout(() => {
                setMinDelayMsExpired(true);
            }, delayMs);
        }
        try {
            await loadImage(src);
            setPreloadStatus('success');
        } catch (err) {
            console.log(err);
            setPreloadStatus('error');
        }
    };

    useEffect(() => {
        preloadImages();
    }, [src]);

    if (preloadStatus === 'loading' || !minDelayMsExpired) {
        if (loadingIndicator) {
            return loadingIndicator;
        } else {
            return <VitraSpinner />;
        }
    }

    return children;
};

export default VitraImagePreload;
