import VitraLink from 'common/primitives/links/vitraLink';
import { colors, fontSizeAndLineHeight, linkColor, rh } from 'common/styles';
import { css } from 'linaria';
import React from 'react';

const styles = {
    wrapper: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto ${rh(2)};
    `,
    link: css`
        ${linkColor(colors.black, colors.primary)};
        text-decoration: none;
        ${fontSizeAndLineHeight('14px')}
        padding: 0 20px;
        white-space: nowrap;
    `
};

interface CheckoutHeader {
    links: Link[];
}

const VitraCheckoutFooter: React.FunctionComponent<CheckoutHeader> = ({ links }) => {
    return (
        <div className={styles.wrapper}>
            {links.map((link) => (
                <VitraLink {...link} className={styles.link} />
            ))}
        </div>
    );
};
export default VitraCheckoutFooter;
