import Translate from 'common/primitives/translations';
import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import { MapMarker } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';

export const styles = {
    resultMapLegend: css`
        width: calc(50% - 10px);
        height: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        column-gap: 20px;
    `,
    resultMapLegendItem: css`
        align-items: center;
        display: inline-flex;
    `,
    resultMapLegendItemColor: css`
        position: relative;
        top: 1px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-left: 10px;
        background: ${colors.findVitraColorBlack};
    `,
    resultMapLegendItemColorOrange: css`
        background: ${colors.findVitraColorOrange};
    `,
    resultMapLegendItemColorPremiumWithSpace: css`
        background: ${colors.findVitraColorPremiumPartnerWithSpace};
    `,
    resultMapLegendItemColorPremiumPartner: css`
        background: ${colors.findVitraColorPremiumPartner};
    `
};

interface CommonSearchMapLegendProps {}

const CommonSearchMapLegend: React.FunctionComponent<CommonSearchMapLegendProps> = (props) => {
    const [state, _]: any = useStateValue();
    const markerTypeIsVisible = (markerType: string) =>
        (state.ux.visibleMarkers || []).some((m: MapMarker) => m.type === markerType);
    return (
        <div className={styles.resultMapLegend}>
            <div className={styles.resultMapLegendItem}>
                <div className="item__copy">
                    <Translate id="community_map_marker_label_retailer" />
                </div>
                <div className={styles.resultMapLegendItemColor} />
            </div>
            {markerTypeIsVisible('address.community_map.publicSpaces.') && (
                <div className={styles.resultMapLegendItem}>
                    <div className="item__copy">
                        <Translate id="community_map_marker_label_public" />
                    </div>
                    <div className={cx(styles.resultMapLegendItemColor, styles.resultMapLegendItemColorOrange)} />
                </div>
            )}
            {markerTypeIsVisible('address.community_map.dealer_POI.premiumPartner.') && (
                <div className={styles.resultMapLegendItem}>
                    <div className="item__copy">
                        <Translate id="community_map_marker_label_premium_partner" />
                    </div>
                    <div
                        className={cx(styles.resultMapLegendItemColor, styles.resultMapLegendItemColorPremiumPartner)}
                    />
                </div>
            )}
            {markerTypeIsVisible('address.community_map.dealer_POI.premiumPartnerOffice.') && (
                <div className={styles.resultMapLegendItem}>
                    <div className="item__copy">
                        <Translate id="community_map_marker_label_premium_partner_office" />
                    </div>
                    <div
                        className={cx(styles.resultMapLegendItemColor, styles.resultMapLegendItemColorPremiumPartner)}
                    />
                </div>
            )}
            {markerTypeIsVisible('address.community_map.dealer_POI.premiumPartnerVitraSpace.') && (
                <div className={styles.resultMapLegendItem}>
                    <div className="item__copy">
                        <Translate id="community_map_marker_label_premium_partner_vitra_space" />
                    </div>
                    <div
                        className={cx(styles.resultMapLegendItemColor, styles.resultMapLegendItemColorPremiumWithSpace)}
                    />
                </div>
            )}
        </div>
    );
};

export default CommonSearchMapLegend;
