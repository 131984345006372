import { colors, fontSizeAndLineHeight, linkColor, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';
import VitraPicture from '../images/vitraPicture';
import VitraLink from '../links/vitraLink';

const styles = {
    wrapper: css`
        position: relative;
        text-align: center;
        letter-spacing: 0.2px;
        font-weight: 100;
        ${fontSizeAndLineHeight('15px', 0.75)}
        margin-bottom: ${rh(1)};
    `,
    materialLink: css`
        display: block;
        ${linkColor(colors.black, colors.primary)};
        text-decoration: none;
    `,
    image: css`
        display: block;
        // margin-bottom: ${rh(0.25)};
        // padding-bottom: ${rh(0.25)};
    `,
    name: css`
        font-weight: 600;
    `
};

export type VitraMaterialType = 'material' | 'color';

export interface VitraMaterialProps {
    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * The Material
     */
    material: Material;

    /**
     * Render Variant
     */
    display?: 'small' | 'medium' | 'big';

    /**
     * Lazy Load the material image, defaults to false
     */
    lazy?: boolean;

    /**
     * The material type,
     */
    type?: VitraMaterialType;
}

const VitraMaterial: React.FunctionComponent<VitraMaterialProps> = ({
    className,
    material,
    display = 'small',
    lazy = false,
    type = 'material'
}) => {
    const renderMaterialBody = () => (
        <Fragment>
            <div className={styles.name}>{material.name}</div>
            {material.attributes &&
                display! == 'medium' &&
                material.attributes.map((a) => (
                    <div>
                        {`${a.name}: ${a.value}`}
                        <br />
                    </div>
                ))}
        </Fragment>
    );

    const renderColorBody = () => (
        <Fragment>
            <div className={cx('name', styles.name)}>{material.name}</div>
            <div className="description">{material.description}</div>

            {material.attributes &&
                display! == 'medium' &&
                material.attributes.map((a) => (
                    <div>
                        {`${a.name}: ${a.value}`}
                        <br />
                    </div>
                ))}
        </Fragment>
    );

    return (
        <div className={cx('vitra__material', className, styles.wrapper)}>
            <VitraLink {...material.link!} className={styles.materialLink}>
                <VitraPicture alt={''} className={styles.image} image={material.image!} lazy={lazy} />
                {type === 'material' ? renderMaterialBody() : renderColorBody()}
            </VitraLink>
        </div>
    );
};
export default VitraMaterial;
