import { colors, fontSizeAndLineHeight, fontStyles, linkColor, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import VitraPicture from '../images/vitraPicture';
import VitraLink from '../links/vitraLink';
import VitraProductWishlistButtons from './vitraProductWishlistButtons';

export interface VitraProductProps {
    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * The Product
     */
    product: Product;

    /**
     * Should we load the product image lazy, defaults to false
     */
    lazy?: boolean;

    /**
     * Show Product Attibutes
     */
    showAttributes?: boolean;

    /**
     * the wihlist button Bar
     */
    wishlistButtons?: 'all' | 'cart';

    /**
     * Override thwe on click event
     */
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const styles = {
    wrapper: css`
        position: relative;
        text-align: center;
        letter-spacing: 0.2px;
        font-weight: 100;
        ${fontSizeAndLineHeight('17px', 0.75)}
    `,
    productLink: css`
        display: block;
        transition: color 0.2s ease-in;
        ${linkColor(colors.black, colors.primary)};
        text-decoration: none;
    `,
    productLinkshowAttributes: css`
        margin-bottom: ${rh(0.5)};
    `,
    image: css`
        display: block;
        margin-bottom: ${rh(0.25)};
        padding-bottom: ${rh(0.25)};
        position: relative;
        .image__placeholder {
            background: transparent !important;
        }
    `,
    imageNameOnly: css`
        padding-bottom: 0;
    `,
    name: css`
        font-weight: 600;
    `,
    nameOnly: css`
        margin-bottom: ${rh(0.75)};
    `,
    price: css`
        margin-top: ${rh(0.25)};
    `,
    info: css`
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        display: block;
    `,
    attibutes: css`
        list-style: none;
        strong {
            display: block;
        }
        li {
            margin-bottom: ${rh(0.5)};
        }
    `,
    // THe vitra fint has problems with font-weight 100 and ≤ Symbols
    // So we use a system font here
    attributesValue: css`
        font-weight: 100;
        font-family: sans-serif;
    `
};

const VitraProduct: React.FunctionComponent<VitraProductProps> = ({
    className,
    product,
    showAttributes,
    wishlistButtons,
    onClick,
    lazy = false
}) => {
    const attributes = product.attributes || [];
    const onlyName = (!product.price || product.price.formatted === '') && !product.description;

    return (
        <div className={cx('vitra__product', className, styles.wrapper)}>
            {wishlistButtons && (
                <div className={styles.info}>
                    <VitraProductWishlistButtons type={wishlistButtons} product={product} />
                </div>
            )}
            <VitraLink
                {...product.link}
                onClick={onClick}
                className={cx(
                    'product__link',
                    product.link && styles.productLink,
                    showAttributes && styles.productLinkshowAttributes
                )}
            >
                <VitraPicture
                    className={cx('link__image', styles.image, onlyName && styles.imageNameOnly)}
                    image={product.image}
                    lazy={lazy}
                />
                <div
                    className={cx(
                        'product__name',
                        styles.name,
                        showAttributes && fontStyles.p,
                        onlyName && styles.nameOnly
                    )}
                >
                    {product.name}
                </div>
                {product.description && <div>{product.description}</div>}
                {product.designer && product.designer.name && (
                    <div className="product__designer">{product.designer.name}</div>
                )}
                {product.price && <div className={styles.price}>{product.price.formatted}</div>}
            </VitraLink>
            {showAttributes && attributes.length > 0 && (
                <ul className={styles.attibutes}>
                    {attributes.map((attr) => {
                        return (
                            <li>
                                <strong>{attr.name}</strong>
                                <div className={styles.attributesValue}>{attr.value}</div>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
export default VitraProduct;
