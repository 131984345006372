/* eslint-disable @typescript-eslint/no-var-requires */
import VitraLink from 'common/primitives/links/vitraLink';
import { colors, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

export interface SocialIcon {
    name: 'youtube' | 'twitter' | 'facebook' | 'pinterest' | 'instagram' | 'linkedin';
    title: string;
    href: string;
}

interface SocialIconsProps {
    icons: SocialIcon[];
    className?: string;
}

const socialIcons = {
    youtube: require('common/primitives/icons/components/V1Youtube').default,
    twitter: require('common/primitives/icons/components/V1Twitter').default,
    facebook: require('common/primitives/icons/components/V1Facebook').default,
    pinterest: require('common/primitives/icons/components/V1Pinterest').default,
    instagram: require('common/primitives/icons/components/V1Instagram').default,
    linkedin: require('common/primitives/icons/components/V1Linkedin').default
};

const styles = {
    socialIconWrapper: css`
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
    `,
    socialIcon: css`
        padding-left: ${rh(0.5)};
        padding-right: ${rh(0.5)};
        margin-bottom: ${rh(1)};
        flex: 0 1 calc(100% / 4);
        ${media.tablet} {
            flex: 0 1 auto;
        }
        font-size: 0;
        svg {
            height: ${rh(0.75)};
        }
        &:hover {
            svg path {
                fill: ${colors.primary};
                color: ${colors.primary};
            }
        }
    `
};

export default class VitraSocialIcons extends React.Component<SocialIconsProps> {
    public render(): JSX.Element {
        const { icons, className } = this.props;

        return (
            <div className={cx(styles.socialIconWrapper, className)}>
                {icons.map((link) => {
                    return (
                        <VitraLink
                            title={link.title}
                            className={styles.socialIcon}
                            target="_blank"
                            rel="noopener"
                            href={link.href}
                        >
                            {React.createElement((socialIcons as any)[link.name as string])}
                        </VitraLink>
                    );
                })}
            </div>
        );
    }
}
