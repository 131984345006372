/* eslint-disable */
class TinyGesture {
    constructor(element, options) {
        options = Object.assign({}, TinyGesture.defaults, options);
        this.element = element;
        this.opts = options;
        this.touchStartX = null;
        this.touchStartY = null;
        this.touchEndX = null;
        this.touchEndY = null;
        this.velocityX = null;
        this.velocityY = null;
        this.longPressTimer = null;
        this.doubleTapWaiting = false;
        this.handlers = {
            panstart: [],
            panmove: [],
            panend: [],
            swipeleft: [],
            swiperight: [],
            swipeup: [],
            swipedown: [],
            tap: [],
            doubletap: [],
            longpress: []
        };

        this._onTouchStart = this.onTouchStart.bind(this);
        this._onTouchMove = this.onTouchMove.bind(this);
        this._onTouchEnd = this.onTouchEnd.bind(this);

        this.element.addEventListener('touchstart', this._onTouchStart, passiveIfSupported);
        this.element.addEventListener('touchmove', this._onTouchMove, passiveIfSupported);
        this.element.addEventListener('touchend', this._onTouchEnd, passiveIfSupported);

        if (this.opts.mouseSupport && !('ontouchstart' in window)) {
            this.element.addEventListener('mousedown', this._onTouchStart, passiveIfSupported);
            document.addEventListener('mousemove', this._onTouchMove, passiveIfSupported);
            document.addEventListener('mouseup', this._onTouchEnd, passiveIfSupported);
        }
    }

    destroy() {
        this.element.removeEventListener('touchstart', this._onTouchStart);
        this.element.removeEventListener('touchmove', this._onTouchMove);
        this.element.removeEventListener('touchend', this._onTouchEnd);
        this.element.removeEventListener('mousedown', this._onTouchStart);
        document.removeEventListener('mousemove', this._onTouchMove);
        document.removeEventListener('mouseup', this._onTouchEnd);
        clearTimeout(this.longPressTimer);
        clearTimeout(this.doubleTapTimer);
    }

    on(type, fn) {
        if (this.handlers[type]) {
            this.handlers[type].push(fn);
            return {
                type,
                fn,
                cancel: () => this.off(type, fn)
            };
        }
    }

    off(type, fn) {
        if (this.handlers[type]) {
            const idx = this.handlers[type].indexOf(fn);
            if (idx !== -1) {
                this.handlers[type].splice(idx, 1);
            }
        }
    }

    fire(type, event) {
        for (let i = 0; i < this.handlers[type].length; i++) {
            this.handlers[type][i](event);
        }
    }

    onTouchStart(event) {
        this.thresholdX = this.opts.threshold('x', this);
        this.thresholdY = this.opts.threshold('y', this);
        this.disregardVelocityThresholdX = this.opts.disregardVelocityThreshold('x', this);
        this.disregardVelocityThresholdY = this.opts.disregardVelocityThreshold('y', this);
        this.touchStartX = event.type === 'mousedown' ? event.screenX : event.changedTouches[0].screenX;
        this.touchStartY = event.type === 'mousedown' ? event.screenY : event.changedTouches[0].screenY;
        this.touchMoveX = null;
        this.touchMoveY = null;
        this.touchEndX = null;
        this.touchEndY = null;
        // Long press.
        this.longPressTimer = setTimeout(() => this.fire('longpress', event), this.opts.longPressTime);
        this.fire('panstart', event);
    }

    onTouchMove(event) {
        if (event.type === 'mousemove' && (!this.touchStartX || this.touchEndX !== null)) {
            return;
        }
        const touchMoveX =
            (event.type === 'mousemove' ? event.screenX : event.changedTouches[0].screenX) - this.touchStartX;
        this.velocityX = touchMoveX - this.touchMoveX;
        this.touchMoveX = touchMoveX;
        const touchMoveY =
            (event.type === 'mousemove' ? event.screenY : event.changedTouches[0].screenY) - this.touchStartY;
        this.velocityY = touchMoveY - this.touchMoveY;
        this.touchMoveY = touchMoveY;
        const absTouchMoveX = Math.abs(this.touchMoveX);
        const absTouchMoveY = Math.abs(this.touchMoveY);
        this.swipingHorizontal = absTouchMoveX > this.thresholdX;
        this.swipingVertical = absTouchMoveY > this.thresholdY;
        this.swipingDirection =
            absTouchMoveX > absTouchMoveY
                ? this.swipingHorizontal
                    ? 'horizontal'
                    : 'pre-horizontal'
                : this.swipingVertical
                ? 'vertical'
                : 'pre-vertical';
        if (Math.max(absTouchMoveX, absTouchMoveY) > this.opts.pressThreshold) {
            clearTimeout(this.longPressTimer);
        }
        this.fire('panmove', event);
    }

    onTouchEnd(event) {
        if (event.type === 'mouseup' && (!this.touchStartX || this.touchEndX !== null)) {
            return;
        }
        this.touchEndX = event.type === 'mouseup' ? event.screenX : event.changedTouches[0].screenX;
        this.touchEndY = event.type === 'mouseup' ? event.screenY : event.changedTouches[0].screenY;
        this.fire('panend', event);
        clearTimeout(this.longPressTimer);

        const x = this.touchEndX - this.touchStartX;
        const absX = Math.abs(x);
        const y = this.touchEndY - this.touchStartY;
        const absY = Math.abs(y);

        if (absX > this.thresholdX || absY > this.thresholdY) {
            this.swipedHorizontal = this.opts.diagonalSwipes
                ? Math.abs(x / y) <= this.opts.diagonalLimit
                : absX >= absY && absX > this.thresholdX;
            this.swipedVertical = this.opts.diagonalSwipes
                ? Math.abs(y / x) <= this.opts.diagonalLimit
                : absY > absX && absY > this.thresholdY;
            if (this.swipedHorizontal) {
                if (x < 0) {
                    // Left swipe.
                    if (this.velocityX < -this.opts.velocityThreshold || x < -this.disregardVelocityThresholdX) {
                        this.fire('swipeleft', event);
                    }
                } else {
                    // Right swipe.
                    if (this.velocityX > this.opts.velocityThreshold || x > this.disregardVelocityThresholdX) {
                        this.fire('swiperight', event);
                    }
                }
            }
            if (this.swipedVertical) {
                if (y < 0) {
                    // Upward swipe.
                    if (this.velocityY < -this.opts.velocityThreshold || y < -this.disregardVelocityThresholdY) {
                        this.fire('swipeup', event);
                    }
                } else {
                    // Downward swipe.
                    if (this.velocityY > this.opts.velocityThreshold || y > this.disregardVelocityThresholdY) {
                        this.fire('swipedown', event);
                    }
                }
            }
        } else if (absX < this.opts.pressThreshold && absY < this.opts.pressThreshold) {
            // Tap.
            if (this.doubleTapWaiting) {
                this.doubleTapWaiting = false;
                clearTimeout(this.doubleTapTimer);
                this.fire('doubletap', event);
            } else {
                this.doubleTapWaiting = true;
                this.doubleTapTimer = setTimeout(() => (this.doubleTapWaiting = false), this.opts.doubleTapTime);
                this.fire('tap', event);
            }
        }
    }
}

TinyGesture.defaults = {
    threshold: (type, self) =>
        Math.max(
            25,
            Math.floor(
                0.15 *
                    (type === 'x'
                        ? window.innerWidth || document.body.clientWidth
                        : window.innerHeight || document.body.clientHeight)
            )
        ),
    velocityThreshold: 10,
    disregardVelocityThreshold: (type, self) =>
        Math.floor(0.5 * (type === 'x' ? self.element.clientWidth : self.element.clientHeight)),
    pressThreshold: 8,
    diagonalSwipes: false,
    diagonalLimit: Math.tan(((45 * 1.5) / 180) * Math.PI),
    longPressTime: 500,
    doubleTapTime: 300,
    mouseSupport: true
};

// Passive feature detection.
let passiveIfSupported = false;

try {
    window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
            get: function () {
                passiveIfSupported = { passive: true };
            }
        })
    );
} catch (err) {}

export default TinyGesture;
