import { client, getBffLanguageAndCountry } from 'common/graphql/client';
import { BffDataSource } from 'common/graphql/sdk';
import { hasConfigurator } from 'common/hooks/useConfigurator-v3';
import { reportError } from 'common/hooks/useError';
import { FeatureFlags, useFeature } from 'common/hooks/useFeature';
import { WishlistButton } from 'common/hooks/useWishlist';
import { colors, fontSizeAndLineHeight, generateProductImageUrl, linkColor, rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { useEffect, useState } from 'react';
import { addGaEvent } from '../analytics';
import Button from '../buttons';
import VitraPicture from '../images/vitraPicture';
import VitraLink from '../links/vitraLink';
import VitraSpinner from '../spinners/vitraSpinner';
import Translate, { getLocalizedPrice } from '../translations';

export interface VitraProductInfoProps {
    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * The Product
     */
    product: Product;

    /**
     * How to display the info
     * full is always on, hover only shows the info on hover
     */
    display: 'hover' | 'full';

    /**
     * Shall we render the description
     * defaults to true
     */
    showDescription?: boolean;

    /**
     * Shows the Wihlist button
     * defaults to true
     */
    showWishlist?: boolean;

    /**
     * Triggered when loading a configuration
     */
    onLoadConfiguration?: (productId: string) => void;
}

const styles = {
    wrapper: css`
        position: relative;
        /* padding: 10px 16% 0;
        display: flex;
        flex-direction: column;
        height: 100%; */
    `,
    productLink: css`
        display: block;
        ${linkColor(colors.black, colors.primary)};
        text-decoration: none;
        text-align: center;
        letter-spacing: 0.5px;
        ${fontSizeAndLineHeight('14px', 0.75)}
        margin-bottom: ${rh(0.75)};
    `,
    productLinkDisabled: css`
        pointer-events: none;
    `,
    name: css`
        font-weight: 700;
    `,
    price: css`
        display: block;
        min-height: 25px;
    `,
    wishlistButton: css`
        position: absolute;
        top: 10px;
        right: 10px;
    `,
    wrapperFull: css``,
    button: css`
        margin-top: auto;
    `,
    wrapperHover: css`
        .info__wishlist,
        .info__button {
            visibility: hidden;
        }
        &:hover {
            .info__wishlist,
            .info__button {
                visibility: visible;
            }
        }
    `,
    squareImage: css`
        width: 250px;
        height: 250px;
        display: inline-block;
    `,
    restrictImage: css`
        width: 250px;
    `
};

const VitraProductInfo: React.FunctionComponent<VitraProductInfoProps> = ({
    onLoadConfiguration,
    className,
    product,
    display,
    showWishlist = true,
    showDescription = true
}) => {
    const { showPrices, language, country, market } = getBffLanguageAndCountry();
    const rewritePrices = useFeature(FeatureFlags.prices_productInfo) && product.id && showPrices;
    if (!canUseDOM) {
        return <div />;
    }

    const useImageServer = product.id !== '';
    const [productData, setProductData] = useState<Product>({
        ...product,
        price: { formatted: '' } as Price,
        image: { src: generateProductImageUrl(product.id) } as Image
    });

    const fetchAdditionalData = async () => {
        try {
            const { getProducts } = await client.getProductPrices({
                dataSource: BffDataSource.Commercetools,
                products: [
                    {
                        sku: product.id
                    }
                ],
                language,
                market
            });

            if (getProducts.length === 1) {
                const { price, image, url, name, designer } = getProducts[0];
                const grossPrice = price?.grossPrice;
                const currencyCode = grossPrice?.currencyCode;
                setProductData({
                    ...productData,
                    ...(image && { image }),
                    ...(grossPrice && {
                        price: {
                            raw: grossPrice?.raw,
                            currencyCode,
                            formatted: grossPrice ? getLocalizedPrice(grossPrice) : ''
                        } as Price
                    }),
                    link: productData.link.href
                        ? productData.link
                        : {
                              href: getProducts[0].url
                                  ? `/${language.toLowerCase()}-${country.toLowerCase()}${url}`
                                  : ''
                          },
                    name: name ?? productData.name,
                    designer: {
                        id: productData.designer?.id ?? '',
                        name: designer ?? productData.designer?.name ?? ''
                    }
                });
            }
        } catch (err) {
            reportError(err, 'fetchAdditionalData');
        }
    };

    useEffect(() => {
        if (rewritePrices) {
            fetchAdditionalData();
        } else {
            setProductData({
                ...product,
                image: { src: generateProductImageUrl(productData.id) } as Image
            });
        }
    }, [product]);

    const currentlyAtProductPage = () => canUseDOM && window.location.pathname === productData.link.href;

    const onClick = (e: any) => {
        if (`${window.location.pathname}` === productData.link.href) {
            e.preventDefault();
            if (onLoadConfiguration) {
                onLoadConfiguration(productData.id);
            }
            addGaEvent({
                eventCategory: 'Product Interactions',
                eventAction: 'PIO Click Configure',
                eventLabel: productData.id
            });
        } else {
            addGaEvent({
                eventCategory: 'Product Interactions',
                eventAction: 'PIO Click to Product',
                eventLabel: productData.id
            });
        }
    };

    const renderButton = () => {
        if (!canUseDOM) {
            return <div />;
        }
        let translateId = 'media_to_product';
        if (currentlyAtProductPage()) {
            if (!hasConfigurator()) {
                return <div />;
            }
            translateId = 'media_configure';
        }
        let link = productData.link;
        if (`${window.location.pathname}` !== productData.link.href) {
            link = { ...link, href: link.href + `?productId=${productData.id}` };
        }

        return (
            <Button
                size="small"
                className={cx(styles.button, 'info__button')}
                testId="configure"
                type="blackBorder"
                onClick={onClick}
                link={link}
            >
                <Translate id={translateId} defaultMessage={translateId} />
            </Button>
        );
    };

    return (
        <div
            className={cx(
                'vitra__product vitra__product--info',
                className,
                styles.wrapper,
                display === 'full' && styles.wrapperFull,
                display === 'hover' && styles.wrapperHover
            )}
        >
            {showWishlist && (
                <div className={cx(styles.wishlistButton, 'info__wishlist')}>
                    <WishlistButton showModal={false} sku={productData.id} type="product" />
                </div>
            )}
            <VitraLink
                {...productData.link}
                onClick={onClick}
                className={cx(
                    'product__link',
                    styles.productLink,
                    currentlyAtProductPage() && styles.productLinkDisabled
                )}
            >
                <div className={cx(useImageServer && styles.squareImage, !useImageServer && styles.restrictImage)}>
                    <div>
                        {productData?.image?.src && <VitraPicture lazy={true} image={productData.image} />}
                        {!productData?.image?.src && <VitraSpinner className={styles.squareImage} />}
                    </div>
                </div>
                <div className={styles.name}>{productData.name}</div>
                {showDescription && productData.description && <div>{productData.description}</div>}
                {productData.designer && productData.designer.name && <div>{productData.designer.name}</div>}
                {!productData.designer && <div>&nbsp;</div>}
                <div className={styles.price}>{productData.price?.formatted ?? ''}</div>
            </VitraLink>
            {renderButton()}
        </div>
    );
};
export default VitraProductInfo;
