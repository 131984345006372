import useToggle from 'common/hooks/useToggle';
import IconVideoPlay from 'common/primitives/icons/components/V1VideoPlay';
import VitraModal from 'common/primitives/modal/vitraModal';
import { colors, injectBackgroundPicture } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import {VideoPlayerProps, VitraVideoPlayer} from './vitraVideoPlayer';


const styles = {
    wrapper: css`
        position: relative;
        height: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    `,
    wrapperCover: css`
        height: 100%;
    `,
    poster: css`
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
    `,
    playButton: css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 20;
        a {
            svg {
                width: 50px;
                height: 50px;
                path {
                    fill: ${colors.black};
                }
            }
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
        }
        &:hover a {
            background: rgba(255, 255, 255, 1);
        }
    `
};

const vitraVideoModal: React.FunctionComponent<VideoPlayerProps> = props => {

    const { video, cover, className } = props;

    const [modalActive, toggleModalActive] = useToggle(false);
    const {backgroundImage, backgroundClass} = injectBackgroundPicture(props.video.poster);
    const paddingTop = !cover ? `${(video.height / video.width) * 100}%` : 0;

    const handlePlayButtonClick = (evt: React.MouseEvent) => {
        if (evt) {
            evt.preventDefault();
        }
        toggleModalActive();
    };

    return (
        <div
            className={cx(className, styles.wrapper, cover && styles.wrapperCover)}
            style={{paddingTop}}
        >
            {backgroundImage}
            <div className={cx(backgroundClass, styles.poster)}>
                <div
                    onClick={handlePlayButtonClick}
                    className={styles.playButton}
                >
                    <a href="#">
                        <IconVideoPlay />
                    </a>
                </div>
            </div>
            {modalActive && (
                <VitraModal type="video" onClose={toggleModalActive}>
                    <div style={{maxWidth: '80vw' }}>
                        <VitraVideoPlayer {...props} cover={false}/>
                    </div>
                </VitraModal>
            )}
        </div>
    );
};

export default vitraVideoModal;
