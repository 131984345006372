import * as React from 'react';

const SvgGridBig = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="25px" height="25px" viewBox="0 0 50 50" {...props}>
        <path
            fill="#B2B2B2"
            d="M22.629 22.91H0V.28h22.629v22.63zM1.963 20.947h18.703V2.243H1.963v18.704zM49.674 22.91H27.043V.28h22.631v22.63zm-20.667-1.963H47.71V2.243H29.007v18.704zM22.629 49.721H0V27.09h22.629v22.631zM1.963 47.758h18.703V29.053H1.963v18.705zM50 49.721H27.369V27.09H50v22.631zm-20.668-1.963h18.705V29.053H29.332v18.705z"
        />
    </svg>
);

export default SvgGridBig;
