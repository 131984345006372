import * as React from 'react';

const SvgV2PinchZoom = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} viewBox="0 0 100 100" {...props}>
        <path d="M77.631 48.932a6.028 6.028 0 00-3.621 1.223 6.093 6.093 0 00-6.084-6.076A6.029 6.029 0 0064.3 45.3a6.093 6.093 0 00-6.085-6.076 6.029 6.029 0 00-3.615 1.223v-12.12a6.086 6.086 0 10-12.172 0v32.9c-3.251-5.885-7.055-7.559-9.315-8-2.2-.426-4.268.052-5.269 1.217-3.109 3.617.723 8.413 1.982 9.99 3.323 4.162 4.7 8.193 6.024 12.092 1.681 4.938 3.42 10.045 9.027 15.177A23.4 23.4 0 0060.3 97.5c14.228 0 23.42-12.325 23.42-31.4V55.018a6.092 6.092 0 00-6.089-6.086zM81.25 66.1c0 17.847-8.029 28.934-20.954 28.934a20.918 20.918 0 01-13.773-5.164c-5.122-4.688-6.684-9.277-8.337-14.135-1.4-4.1-2.839-8.336-6.433-12.836-3.815-4.776-2.477-6.331-2.039-6.842a3.519 3.519 0 012.929-.4c1.872.363 6.58 2.249 9.85 11.891 0 .016.018.026.023.041a.979.979 0 00.431.542 1.226 1.226 0 00.2.138 1.277 1.277 0 00.2.054 1.151 1.151 0 00.267.054c.015 0 .028.009.043.009a2.612 2.612 0 01.4-.065c.015 0 .025-.017.04-.023a1.193 1.193 0 00.221-.129 1.218 1.218 0 00.187-.125 1.237 1.237 0 00.134-.178 1.161 1.161 0 00.138-.2 3.52 3.52 0 00.108-.467c0-.015.009-.028.009-.043V28.327a3.619 3.619 0 117.238 0v29.117a1.234 1.234 0 102.467 0V45.312a3.619 3.619 0 017.238 0v12.132a1.234 1.234 0 102.467 0v-7.278a3.62 3.62 0 117.24 0v9.739a1.233 1.233 0 102.466 0v-4.887a3.62 3.62 0 017.239 0z" />
        <path d="M39.041 36.541a1.233 1.233 0 00.991-1.967 10.441 10.441 0 01-2.069-6.247 10.551 10.551 0 1121.1 0A10.45 10.45 0 0157 34.574a1.234 1.234 0 101.985 1.466 13.018 13.018 0 10-20.934 0 1.234 1.234 0 00.99.501zM29.871 69.009a10.549 10.549 0 012.293-20.846 10.47 10.47 0 016.154 1.994 1.234 1.234 0 101.443-2 13.005 13.005 0 00-20.615 10.558 13.093 13.093 0 0010.189 12.7 1.233 1.233 0 10.536-2.408zM55.85 14.692a1.23 1.23 0 001.67-.5l3.9-7.249.563 1.875a1.235 1.235 0 001.182.879 1.256 1.256 0 00.355-.052 1.235 1.235 0 00.827-1.537l-1.42-4.728c0-.01-.011-.017-.014-.026a1.226 1.226 0 00-1.525-.8l-4.725 1.42a1.234 1.234 0 10.711 2.363l1.874-.563-3.9 7.248a1.232 1.232 0 00.502 1.67zM24.835 72.338a1.235 1.235 0 00-1.671.5l-3.9 7.25-.564-1.872a1.234 1.234 0 00-2.364.711l1.42 4.726a1.239 1.239 0 00.206.391c.025.032.057.055.085.085a1.193 1.193 0 00.254.211c.02.012.032.033.053.044s.043.006.063.016a1.232 1.232 0 00.517.131h.014a1.236 1.236 0 00.326-.048h.016l4.726-1.42a1.234 1.234 0 10-.711-2.363l-1.874.563 3.9-7.25a1.233 1.233 0 00-.496-1.675z" />
    </svg>
);

export default SvgV2PinchZoom;
