import * as React from 'react';

const SvgV2Info = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
        <g fillRule="nonzero">
            <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 1.5a8.5 8.5 0 110 17 8.5 8.5 0 010-17z" />
            <path d="M11.356 6c0 .746-.61 1.356-1.356 1.356-.746 0-1.356-.61-1.356-1.356 0-.746.61-1.356 1.371-1.356.747 0 1.34.61 1.34 1.356zM11 16H9V9h2v7z" />
        </g>
    </svg>
);

export default SvgV2Info;
