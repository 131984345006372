import { useConfig } from 'common/hooks/useBoostrap';
import useToggle from 'common/hooks/useToggle';
import { canUseDOM } from 'exenv';
import cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Locale } from '../main-navigation-types';
import NavigationLocaleSwitcher from './navigationLocaleSwitcher';

/**
 * Gets the current page alternate
 */
interface PageAlternate {
    lang: string;
    href: string;
}
const getPageAlternate = (): PageAlternate[] => {
    if (!canUseDOM || !(window as any).bootstrap.hreflangs) {
        return [];
    }
    const hrefLang = (window as any).bootstrap.hreflangs;

    return hrefLang.map((item: { language: string; url: string }) => {
        return {
            lang: item.language,
            href: item.url
        };
    });
};

/**
 * Gets the ISO Tuple Language-Country of the current page
 * @returns string (de-de, en-it, ...)
 */
export const getCurrentPageLanguage = (): string => {
    return useConfig('code', 'de-de');
};

/**
 * Tries to guess the locale
 * of the user
 */
const getRawLocale = (): string => {
    let locale;

    if (
        (window as any).chrome &&
        (window as any).chrome.runtime &&
        typeof (window as any).chrome.runtime.getManifest === 'function'
    ) {
        locale = (window as any).chrome.runtime.getManifest();
        if (locale && locale.current_locale) {
            return locale.current_locale;
        }
    }

    locale =
        (window as any).navigator &&
        (((window as any).navigator.languages && (window as any).navigator.languages[0]) ||
            (window as any).navigator.language ||
            (window as any).navigator.userLanguage);

    if (!locale && (window as any).navigator && (window as any).navigator.userAgent) {
        locale = (window as any).navigator.userAgent.match(/;.(\w+-\w+)/i);
        if (locale) {
            return locale[1];
        }
    }

    if (!locale) {
        locale = ((window as any).clientInformation || Object.create(null)).language;
    }

    if (!locale) {
        if ((window as any).Intl && typeof (window as any).Intl.DateTimeFormat === 'function') {
            locale =
                (window as any).Intl.DateTimeFormat().resolvedOptions &&
                (window as any).Intl.DateTimeFormat().resolvedOptions().locale;
        }
        if (!locale && ['LANG', 'LANGUAGE'].some(Object.hasOwnProperty, process.env)) {
            return (process.env.LANG || process.env.LANGUAGE || String()).replace(/[.:].*/, '').replace('_', '-');
        }
    }

    return locale;
};

const getCookieDomain = (): string => {
    const hostnames = window.location.hostname.split('.');
    hostnames.shift();
    return hostnames.join('.');
};

export const setLanguageCookiesAndRedirect = (code: string, iso: string): void => {
    const cookieDomain = getCookieDomain();
    cookie.set('selected_country', iso, {
        domain: cookieDomain,
        path: '/',
        expires: 365
    });
    cookie.set('selected_locale', code, {
        domain: cookieDomain,
        path: '/',
        expires: 365
    });

    // Redirect to page
    const alternatives = getPageAlternate();
    const page = alternatives.find((a) => a.lang === code);
    if (page) {
        (window as any).location = page.href;
    } else {
        // We can not find the locale, try redirecting to the page manually
        const pArray = window.location.pathname.split('/');
        pArray.shift();
        pArray.shift();
        pArray.unshift(`${code}`);
        window.location.href = `/${pArray.join('/')}`;
    }
};

interface NavigationLocaleProps {
    locales: Locale[];
}

const NavigationLocale: React.FunctionComponent<NavigationLocaleProps> = ({ locales }) => {
    const [show, toggleShow] = useToggle(false);
    const [proposedLocale, setProposedLocale] = useState<any>();
    const [userLocale, setUserLocale] = useState<string>('');

    const setLocaleSwitcherDone = (locale: any) => {
        const cookieDomain = getCookieDomain();
        cookie.set('locale_switcher_done', locale, {
            domain: cookieDomain,
            path: '/',
            expires: 365
        });
        toggleShow(false);
    };

    const onSubmitLocale = (locale: any) => {
        const countryIso = locale.iso;
        const langPath = locale.value;

        // Set switcher done
        setLocaleSwitcherDone(langPath);

        setLanguageCookiesAndRedirect(langPath, countryIso);
    };

    const onClose = () => {
        const currentLocale = getCurrentPageLanguage(); // en-ch
        setLocaleSwitcherDone(currentLocale);
    };

    const activate = (switcherLocale: string, proposed: string) => {
        setProposedLocale(proposed);
        setUserLocale(switcherLocale);
        toggleShow(true);
    };

    const checkActivation = async () => {
        // First we chec the cookie and see if we need to force the dialog
        const localeDone = cookie.get('locale_switcher_done') || cookie.get('selected_locale');
        const forceLocale = window.location.search.includes('locale_switcher_force');
        const rawLocale = `${getRawLocale()}`.substring(0, 2).toLowerCase();

        if (!rawLocale) {
            return;
        }

        if (forceLocale) {
            activate(rawLocale, rawLocale);
            return;
        }
        if (localeDone) {
            return;
        }

        // Next we get determen the user locale
        const currentLocale = getCurrentPageLanguage(); // en-ch
        if (!currentLocale) {
            return;
        }
        const currentCountry = currentLocale.split('-')[1];
        const userCountry = useConfig('CFCountry', '');

        if (!userCountry) {
            return;
        }

        const localeCountry = `${rawLocale}-${userCountry}`.toLowerCase();

        if (localeCountry === currentLocale) {
            // do nothing, we are fine
            return;
        }

        // Check if we have a alternative
        // that match exactly
        const alternatives = getPageAlternate();
        const fullLocaleMatch = alternatives.find((l) => l.lang === localeCountry);

        if (fullLocaleMatch) {
            activate(fullLocaleMatch.lang, fullLocaleMatch.lang);
            return;
        }

        // Next we check if we have a partial match for the country
        // because we mostly care about th market for the prices
        if (currentCountry === userCountry) {
            return;
        }

        // Next we traverse the altneratives for the users country
        const countryMatch = alternatives.find((l) => l.lang.split('-')[1] === userCountry);

        if (countryMatch) {
            activate(countryMatch.lang, rawLocale);
        }

        // If nothing is found we do not display anything
    };

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        checkActivation();
    }, []);

    if (!canUseDOM || !show) {
        return null;
    }

    return (
        <NavigationLocaleSwitcher
            onSubmit={onSubmitLocale}
            onClose={onClose}
            locales={locales}
            proposedLocale={proposedLocale}
            userLocale={userLocale}
        />
    );
};

export default NavigationLocale;
