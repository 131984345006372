import globalStyle from 'common/primitives/forms/styles';
import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import React, { useState } from 'react';

interface RadioInputProps extends InputProps {
    /**
     * The radio options
     */
    options: NameValue[];

    /**
     * If True none of the radios is selected when the radio input is mounted
     */
    initallyUnselected?: boolean;

    /**
     * DefaultValue for the radio input
     */
    defaultValue?: string;

    appearance?: 'regular' | 'large';

    readOnly?: boolean;
}

const styles = defaults(
    {
        radioFieldOptions: css`
            flex-direction: row;
            div:last-child label {
                padding-right: 0 !important;
            }
        `,
        radioFieldLabel: css`
            color: ${colors.textGrey};
            font-weight: 100;
        `,
        radioFieldLabelError: css`
            color: ${colors.error};
        `,
        radioItem: css`
            display: inline-block;
            input[type='radio'] {
                opacity: 0;
                position: absolute;

                &:focus {
                    + label {
                        &:before {
                            outline-style: solid;
                        }
                    }
                }

                &:checked {
                    + label {
                        &:after {
                            background-color: ${colors.black};
                        }
                    }
                }
            }
        `,
        radioItemLarge: css`
            display: block;
            width: 100%;
            margin-bottom: ${rh(0.5)};
        `,
        radioItemLabelLarge: css`
            padding-right: 0px !important;
            width: 100%;
        `,
        radioItemLabel: css`
            user-select: none;
            display: inline-block;
            position: relative;
            padding-left: 34px;
            padding-right: 50px;

            &:hover {
                cursor: pointer;
            }

            &:before {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 10px;
                position: absolute;
                left: 0;
                top: 4px;
                background-color: white;
                border: 1px solid ${colors.inputBorderColorDarker};
                border-radius: 50%;
            }

            &:after {
                content: '';
                text-align: center;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                position: absolute;
                left: 7px;
                top: 11px;
                background-color: white;
            }
        `,
        radioItemLabelError: css`
            color: ${colors.error};
            &:before {
                border: 1px solid ${colors.error};
            }
        `
    },
    globalStyle
);

const RadioInput: React.FunctionComponent<RadioInputProps> = ({
    inputRef,
    name,
    label,
    errors,
    appearance,
    className,
    onChange,
    options,
    initallyUnselected,
    defaultValue
}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue ?? '');
    const hasError = errors?.[name];

    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
        setSelectedValue(e.currentTarget.value);
    };

    const renderOptions = (optionsArray: NameValue[]) => {
        return optionsArray.map((element, idx) => {
            let additionalProps;
            if (!initallyUnselected) {
                additionalProps = {
                    defaultChecked: selectedValue ? element.value === selectedValue : idx === 0
                };
            }
            return (
                <div
                    key={`${name}_${idx}`}
                    data-testid={`${name}_${idx}`}
                    className={cx('radioItem', styles.radioItem, appearance === 'large' && styles.radioItemLarge)}
                >
                    <input
                        id={`${name}_${idx}`}
                        name={name}
                        type="radio"
                        value={element.value}
                        ref={inputRef}
                        onChange={handleOnChange}
                        {...additionalProps}
                    />

                    {typeof element.name !== 'object' && (
                        <label
                            htmlFor={`${name}_${idx}`}
                            className={cx(styles.radioItemLabel, hasError && styles.radioItemLabelError)}
                            dangerouslySetInnerHTML={{ __html: element.name }}
                        />
                    )}
                    {typeof element.name === 'object' && (
                        <label
                            htmlFor={`${name}_${idx}`}
                            className={cx(
                                styles.radioItemLabelLarge,
                                styles.radioItemLabel,
                                hasError && styles.radioItemLabelError
                            )}
                        >
                            {element.name}
                        </label>
                    )}
                </div>
            );
        });
    };

    return (
        <div className={cx('form__field', 'form__field--radio', className, styles.wrapper)}>
            <fieldset>
                {label && (
                    <legend className={cx(styles.radioFieldLabel, hasError && styles.radioFieldLabelError)}>
                        {label}
                    </legend>
                )}
                <div className={styles.radioFieldOptions}>{renderOptions(options)}</div>
            </fieldset>
        </div>
    );
};

export default RadioInput;
