import VitraAccordion from 'common/primitives/accordion/vitraAccordion';
import { compileTemplate, useTranslations } from 'common/primitives/translations';
import { colors, linkColor, rh } from 'common/styles';
import VitraCartTotal from 'components/cart-v2/components/cartTotal';
import { CheckoutState } from 'components/checkout-v2/state/checkoutState';
import { css, cx } from 'linaria';
import React from 'react';
import { CHECKOUT_STATUS_TYPE } from '../../state/checkoutProvider.d';
import VitraCheckoutCommonSidebarCart from './checkoutCommonSidebarCart';

const styles = {
    wrapper: css`
        width: 100%;
    `,
    section: css`
        padding: 0 29px;
        border-bottom: 2px solid ${colors.silverDark};
    `,
    sectionTotals: css`
        padding-bottom: 29px;
    `,
    // sectionExpress: css`
    //     padding: 29px 26px !important;
    //     text-align: center;
    //     ${media.tablet} {
    //         text-align: left;
    //     }
    // `,
    checkoutViewTrailingAccordion: css`
        .accordionTitle {
            padding: ${rh(0.5)} 0px;
        }
    `,
    checkoutViewTrailingAccordionCopy: css`
        margin-right: 13px;
        display: inline-block;
    `,
    checkoutViewTrailingAccordionLink: css`
        ${linkColor(colors.textGrey, colors.primary)};
        font-size: 14px;
        text-decoration: underline;
        padding-right: 8px;
    `
};

interface CheckoutSidebar {
    checkoutActions: any;
    checkoutState: CheckoutState;
}

const VitraCheckoutCommonSidebar: React.FunctionComponent<CheckoutSidebar> = ({ checkoutState, checkoutActions }) => {
    const t = {
        articles_in_cart: useTranslations(
            'checkout.sidebar.cart.articles_in_cart',
            '{count} product(s) in your shopping cart'
        ),
        edit: useTranslations('checkout.sidebar.cart.edit', 'Edit')
    };

    const checkoutEdit = (e: any) => {
        e.preventDefault();
        window.location.href = checkoutState.config!.shoppingCartUrl;
    };

    if (
        checkoutState.status.type === CHECKOUT_STATUS_TYPE.ERROR ||
        checkoutState.status.type === CHECKOUT_STATUS_TYPE.EMPTY
    ) {
        return null;
    }

    if (!checkoutState.cart) {
        return null;
    }

    const lineItems = checkoutActions.cartGetLineItems();

    return (
        <div className={styles.wrapper}>
            <section className={cx(styles.section, styles.sectionTotals)}>
                <VitraCartTotal totals={checkoutState.cart.totals!} />
            </section>
            <section className={styles.section}>
                <VitraAccordion
                    isOpen={true}
                    className={styles.checkoutViewTrailingAccordion}
                    title={
                        <div>
                            <div className={styles.checkoutViewTrailingAccordionCopy}>
                                {compileTemplate(t.articles_in_cart, { count: lineItems.length })}
                            </div>
                            <a
                                role="button"
                                href={checkoutState.config!.shoppingCartUrl}
                                onClick={checkoutEdit}
                                className={styles.checkoutViewTrailingAccordionLink}
                            >
                                {t.edit}
                            </a>
                        </div>
                    }
                >
                    <VitraCheckoutCommonSidebarCart
                        lineItems={lineItems}
                        toggleBlockUi={checkoutActions.checkoutToggleStatus}
                    />
                </VitraAccordion>
            </section>
        </div>
    );
};
export default VitraCheckoutCommonSidebar;
