/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { media } from 'common/styles';
import { canUseDOM } from 'exenv';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import { bind } from 'size-sensor';
import { useConfig } from './useBoostrap';

export const matchMediaToScreenWidth = (): string => {
    const m: { [index: string]: any } = pickBy(media, (value, key) => {
        return ['mobile', 'mobileX', 'tablet', 'desktop', 'desktopX'].includes(key);
    });

    let match = 'desktopX';
    if (!canUseDOM) {
        return match;
    }
    for (const property in m) {
        if (Object.prototype.hasOwnProperty.call(m, property)) {
            const query = m[property].replace('@media only screen and ', '');
            const matches = (window.matchMedia(query) || {}).matches;
            if (matches) {
                match = property;
            }
        }
    }
    return match;
};

export const isMobile = (): boolean => {
    if (!canUseDOM) {
        return false;
    }
    const breakpointName = matchMediaToScreenWidth();

    return ['mobile', 'mobileX', 'tablet'].includes(breakpointName);
};

export const isVitra = (): boolean => {
    if (!canUseDOM) {
        return true;
    }
    return useConfig('site', 'vitra').includes('vitra');
};

// @todo: make a global window resize event
export const useBreakpoint = (initalValue = 'mobile', callback?: (size: string) => void): string => {
    const [breakpoint, setBreakpoint] = useState(canUseDOM ? matchMediaToScreenWidth() : initalValue);

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        const supportsOrientationChange = 'onorientationchange' in window;
        const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';
        const onChange = debounce(() => {
            const breakpointName = matchMediaToScreenWidth();
            setBreakpoint(breakpointName);
            if (callback) {
                callback(breakpointName);
            }
        }, 50);

        window.addEventListener(orientationEvent, onChange);

        // Set inital value
        setBreakpoint(matchMediaToScreenWidth());

        return () => {
            window.removeEventListener(orientationEvent, onChange);
        };
    }, []);

    return breakpoint;
};

export const useOrientation = (): 'portrait' | 'landscape' => {
    const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait');
    const isPortrait = () => window.innerHeight > window.innerWidth;

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        const supportsOrientationChange = 'onorientationchange' in window;
        const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';
        const onChange = debounce(() => {
            setOrientation(isPortrait() ? 'portrait' : 'landscape');
        }, 50);

        window.addEventListener(orientationEvent, onChange);

        // init
        setOrientation(isPortrait() ? 'portrait' : 'landscape');

        return () => {
            window.removeEventListener(orientationEvent, onChange);
        };
    }, []);

    return orientation;
};

type Size = {
    width: number;
    height: number;
};

export const useSizeSensor = (ref: any, inital = { width: 0, height: 0 }): Size => {
    const [size, setSize] = useState<Size>(inital);

    useEffect(() => {
        let unbind: any;
        if (canUseDOM && ref.current) {
            unbind = bind(ref.current, (element) => {
                if (element) {
                    const csize = getComputedStyle(element);
                    const width = parseInt(csize.width || '0', 10);
                    const height = parseInt(csize.height || '0', 10);
                    setSize({ width, height });
                }
            });
        }
        return () => {
            if (unbind) {
                unbind();
            }
        };
    }, [ref]);

    return size;
};
