import globalStyle from 'common/primitives/forms/styles';
import V2Alert from 'common/primitives/icons/components/V2Alert';
import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';

import React from 'react';

const styles =  defaults({
    formMessageIconError: css`
        svg {
            fill: ${colors.error}
        }
    `
}, globalStyle);

interface FormMessageProps {
    messages: [string];
    className?: string;
}

const FormMessage: React.FunctionComponent<FormMessageProps> = (props) => {

    const {
        className,
        messages
    } = props;

    return (
        <div className={cx(
            'form__message',
            'form__message--error',
            styles.formMessage,
            className)
        }>
            <div className={cx(
                styles.formMessageIcon,
                styles.formMessageIconError
            )}>
                <V2Alert/>
            </div>
            <div className={styles.formMessageCopy}>
                {messages.map(m => <p>{m}</p>)}
            </div>
        </div>
    );
};


export default FormMessage;
