import * as React from 'react';

const SvgArrowSmallRight = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 11.248 19">
        <path fill="#333333" d="M11.248,9.498L0.836,18.871c-0.205,0.186-0.521,0.168-0.707-0.037s-0.168-0.521,0.037-0.705L9.754,9.5
        L0.166,0.871c-0.203-0.186-0.223-0.5-0.037-0.705C0.315-0.04,0.631-0.057,0.836,0.128L11.248,9.498z"/>
    </svg>
);

export default SvgArrowSmallRight;
