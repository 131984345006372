import { css, cx } from 'linaria';

import IconLocate from 'common/primitives/icons/components/V1Locate2';
import { colors } from 'common/styles';
import React from 'react';

interface CommonMapMarkerProps {
    /**
     * An optional onCLick handler callback
     */
    onClick?: (id: string) => void;

    /**
     * an identifier which will be added to the classname
     */
    type: 'address.community_map.airbnb.' | 'address.community_map.publicSpaces.' | 'address.community_map.dealer_POI.';

    /**
     * A boolean which triggers the animation to highlight a marker on the map
     */
    isHighlighted?: boolean;

    /**
     * Latitude which will be picked up by google-maps-react
     */
    lat: number;

    /**
     * Longitude which will be picked up by google-maps-react
     */
    lng: number;

    /**
     * the ID
     */
    id: string;

    /**
     * Number of points if this is a cluster marker
     */
    numPoints?: number;

    /**
     * Show Number of clustered points
     */
    showNumPoints?: boolean;
}

export const styles = {
    marker: css`
        position: absolute;
        width: 25px;
        height: 32px;
        left: -12px;
        top: -32px;
        z-index: 2;
        svg {
            width: 25px;
            height: 32px;
            stroke: white;
            stroke-width: 0.5px;
            stroke-opacity: 0.5;
            path {
                fill: ${colors.findVitraColorBlack};
            }
        }
    `,
    markerWithClusterIndicator: css`
        z-index: 3;
    `,
    addresscommunity_mapairbnb: css`
        z-index: 2;
        svg path {
            fill: ${colors.findVitraColorGreen};
        }
    `,
    addresscommunity_mappublicSpaces: css`
        z-index: 2;
        svg path {
            fill: ${colors.findVitraColorOrange};
        }
    `,
    addresscommunity_mapdealer_POIpremiumPartner: css`
        z-index: 2;
        svg path {
            fill: ${colors.findVitraColorPremiumPartner};
        }
    `,
    addresscommunity_mapdealer_POIpremiumPartnerOffice: css`
        z-index: 2;
        svg path {
            fill: ${colors.findVitraColorPremiumPartner};
        }
    `,
    addresscommunity_mapdealer_POIpremiumPartnerVitraSpace: css`
        z-index: 2;
        svg path {
            fill: ${colors.findVitraColorPremiumPartnerWithSpace};
        }
    `,
    markerHighlightAnimation: css`
        z-index: 3;
        animation: pulse 0.5s infinite alternate;
        transform-origin: bottom;
        svg path {
            fill: ${colors.findVitraColorRed};
        }
        @keyframes pulse {
            to {
                transform: scale(0.7);
            }
        }
    `,
    clusterPoints: css`
        font-size: 10px;
        position: absolute;
        z-index: 4;
        background: ${colors.primary};
        color: white;
        right: -8px;
        top: -8px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
    `
};

const CommonMapMarker: React.FunctionComponent<CommonMapMarkerProps> = ({
    id,
    type,
    onClick,
    isHighlighted,
    numPoints,
    showNumPoints = true
}) => {
    const typeName = type && type.replace(/\./g, '');
    let typeStyle: any = false;
    if (
        typeName === 'addresscommunity_mapairbnb' ||
        typeName === 'addresscommunity_mappublicSpaces' ||
        typeName === 'addresscommunity_mapdealer_POIpremiumPartner' ||
        typeName === 'addresscommunity_mapdealer_POIpremiumPartnerOffice' ||
        typeName === 'addresscommunity_mapdealer_POIpremiumPartnerVitraSpace'
    ) {
        typeStyle = styles[typeName];
    }
    const handleMarkerClick = (markerId: string) => (evt: React.MouseEvent) => {
        if (onClick) {
            onClick(markerId);
        }
    };
    return (
        <div
            onClick={handleMarkerClick(id)}
            className={cx(
                styles.marker,
                typeStyle,
                isHighlighted && styles.markerHighlightAnimation,
                showNumPoints && numPoints && numPoints > 2 && styles.markerWithClusterIndicator
            )}
        >
            {showNumPoints && numPoints && numPoints > 1 && <span className={styles.clusterPoints}>{numPoints}</span>}
            <IconLocate />
        </div>
    );
};

export default CommonMapMarker;
