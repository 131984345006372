import { isIE } from 'common/styles';
import { canUseDOM } from 'exenv';
import Hls from 'hls.js';
import { css, cx } from 'linaria';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useRef, useState } from 'react';
import { Video } from './types';

interface VideoPlayerBackgroundProps {
    /**
     * Optional className
     */
    className?: string;

    /**
     * The video object
     */
    video: Video;

    /**
     * An optional poster image
     */
    poster?: Image;

    /**
     * We may weant to pause the background video at one moment
     * e.g. if we start another video like in the teaser widget
     */
    paused?: boolean;
}

const styles = {
    wrapper: css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    `,
    video: css`
        pointer-events: none;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    `,
    content: css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    `
};

const vitraVideoBackground: React.FunctionComponent<VideoPlayerBackgroundProps> = (props) => {
    const { children, video, paused, poster, className } = props;

    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoId, setVideoId] = useState<null | string>(null);

    // This is needed to trick hls to
    // create a new instance with this instance
    // we also attach the video events
    const resetVideoId = () => {
        setVideoId(uniqueId('play_'));
    };

    const onPlay = () => {
        const videoEl = videoRef!.current as any;
        if (videoEl && videoEl.play) {
            videoEl.play();
        }
    };

    const onEnded = () => {
        onPlay();
    };

    // On change of the video element
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        // Setup Event Handler for Video
        if (videoRef.current) {
            videoRef.current.addEventListener('ended', onEnded);
        }

        // Cleanup Handler
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('ended', onEnded);
            }
        };
    }, [videoRef.current]);

    // Start the video, this value is only set once
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        let hlsInstance: Hls;
        if (videoId) {
            if (!videoRef.current) {
                return;
            }

            // handle native ios
            if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                videoRef.current.src = video.src;
                videoRef.current.play();
            } else {
                // If we have hls Support
                import('hls.js').then((hls) => {
                    if (hls.default.isSupported()) {
                        hlsInstance = new hls.default({
                            // Use smaller buffer fragments to reduce size of preloaded the buffer
                            // A background video will be scrolled away anyway
                            maxBufferSize: 3 * 1000 * 1000, // 5MB
                            maxBufferLength: 2, // 2 seconds, maxBufferLength is dominant in regard to maxBufferSize
                            autoStartLoad: true,
                            startLevel: 4
                        });
                        if (videoRef.current) {
                            hlsInstance.loadSource(video.src);
                            hlsInstance.attachMedia(videoRef.current);
                            hlsInstance.on(hls.default.Events.MANIFEST_PARSED, () => {
                                if (videoRef.current) {
                                    videoRef.current.play();
                                }
                            });
                        }
                    }
                });
            }
        }

        // IE11 polyfill for css: object-fit: contain
        if (isIE()) {
            import('objectFitPolyfill');
        }

        // Cleanup
        return () => {
            if (hlsInstance) {
                hlsInstance.destroy();
            }
        };
    }, [videoId]);

    // On Mount
    useEffect(() => {
        resetVideoId();
    }, []);

    // Pause the video via props.paused from the outer component
    useEffect(() => {
        const videoEl = videoRef!.current as any;
        if (videoEl) {
            if (paused) {
                videoEl.pause();
            } else {
                videoEl.play();
            }
        }
    }, [paused]);

    return (
        <div className={cx(styles.wrapper, className)}>
            <video data-object-fit autoPlay muted className={styles.video} ref={videoRef} poster={poster?.src} />
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default vitraVideoBackground;
