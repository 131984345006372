import { useConfig } from 'common/hooks/useBoostrap';
import { useClickoutSide } from 'common/hooks/useClickOutside';
import { reportError } from 'common/hooks/useError';
import { useEvents } from 'common/hooks/useEvents';
import IconRotate from 'common/primitives/icons/components/V1Rotate';
import V2Minus from 'common/primitives/icons/components/V2Minus';
import V2Plus from 'common/primitives/icons/components/V2Plus';
import IconReplay from 'common/primitives/icons/components/V2Replay';
import { colors, rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import queryString from 'query-string';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { addGaEvent } from '../analytics';
import { BlankButton } from '../buttons';
import V2Ar from '../icons/components/V2Ar';
import VitraTooltip from '../tooltip/vitraTooltip';
import Translate from '../translations';

const styles = {
    wrapper: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    container: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    toolbar: css`
        z-index: 10;
        position: absolute;
        left: 30px;
        bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    toolTip: css`
        background: #fff;
    `,
    toolbarIcon: css`
        width: 50px;
        height: 50px;
        display: none;
        align-items: center;
        justify-content: center;
        svg {
            transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
            path,
            g {
                fill: ${colors.black};
            }
        }
        &:hover {
            svg path {
                fill: ${colors.primary};
                stroke: ${colors.primary};
            }
        }
    `,
    toolbarIconShown: css`
        display: flex;
    `,
    toolbarIconAr: css`
        padding: ${rh(0.5)};
    `,
    toolbarIconDisabled: css`
        svg {
            opacity: 0.1;
        }
        &:hover {
            svg path {
                fill: ${colors.black}!important;
            }
        }
    `,
    error: css`
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        flex-flow: column;
        text-align: center;
    `,
    errorMessage: css`
        color: ${colors.primary};
    `,
    errorCode: css`
        color: ${colors.lightgrey};
    `,
    toolbarIconReplay: css`
        svg {
            width: 30px;
        }
    `,
    isHidden: css`
        visibility: hidden;
    `,
    arTooltip: css`
        z-index: 10;
        position: absolute;
        right: 30px;
        bottom: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    `
};

// This is a Object.keys on the emersya object
interface EmersyaApi {
    id: any;
    name: any;
    customTextureIds: any;
    addEventListener: any;
    destroyViewer: any;
    getViewerState: any;
    startViewer: any;
    stopViewer: any;
    switchExperienceType: any;
    preloadViewerData: any;
    play: any;
    pause: any;
    resetCamera: any;
    startZoomIn: any;
    stopZoomIn: any;
    startZoomOut: any;
    stopZoomOut: any;
    resize: any;
    changeLanguage: any;
    disableARDefaultQRCodeStartingScreen: any;
    openAnnotationPin: any;
    closeAnnotationPin: any;
    toggleAnnotationPin: any;
    showAnnotationPin: any;
    hideAnnotationPin: any;
    showAnnotationsPin: any;
    hideAnnotationsPin: any;
    toggleAnnotationsPin: any;
    getARCompatibility: any;
    getARAvailability: any;
    startAr: any;
    stopAr: any;
    showArHelper: any;
    hideArHelper: any;
    showArMenu: any;
    hideArMenu: any;
    toggleARShareButton: any;
    collapseARShareButton: any;
    expandARShareButton: any;
    showHelp: any;
    hideHelp: any;
    toggleHelp: any;
    setPredefinedViewpoint: any;
    setCamera: any;
    resetMaterials: any;
    setHighlight: any;
    getScreenshot: any;
    getScreenshots: any;
    setZoomProportion: any;
    getCamera: any;
    rotateCameraX: any;
    rotateCameraY: any;
    rotateCameraZ: any;
    rotateCamera: any;
    moveCameraX: any;
    moveCameraY: any;
    moveCameraZ: any;
    moveCamera: any;
    translateCameraX: any;
    translateCameraY: any;
    translateCamera: any;
    setPlayMode: any;
    setSceneryBackgroundColor: any;
    setARControlMode: any;
    setCursor: any;
    toggleKeyboard: any;
    enableKeyboard: any;
    disableKeyboard: any;
    toggleMouseButtons: any;
    enableMouseButtons: any;
    disableMouseButtons: any;
    toggleMouseMove: any;
    enableMouseMove: any;
    disableMouseMove: any;
    toggleMouseWheel: any;
    enableMouseWheel: any;
    disableMouseWheel: any;
    toggleTouchInteractions: any;
    enableTouchInteractions: any;
    disableTouchInteractions: any;
    toggleHighFrequencyFeedback: any;
    enableHighFrequencyFeedback: any;
    disableHighFrequencyFeedback: any;
    toggleMouseTracking: any;
    enableMouseTracking: any;
    disableMouseTracking: any;
    toggleCameraLock: any;
    unlockCamera: any;
    lockCamera: any;
    toggleRenderingFreezing: any;
    freezeRendering: any;
    unfreezeRendering: any;
    setPreset: any;
    togglePresetsMenu: any;
    openPresetsMenu: any;
    closePresetsMenu: any;
    triggerAnimation: any;
    resetAnimations: any;
    setAnimationTriggerColor: any;
    showAnimationTriggers: any;
    hideAnimationTriggers: any;
    toggleAnimationTriggers: any;
    getCurrentAnimationStates: any;
    setMaterials: any;
    updateCustomTexture: any;
    updateCustomTextureWithPattern: any;
    updateCustomText: any;
    updateCustomTexts: any;
    updateCustomColor: any;
    setMaterialsGroup: any;
    setMaterialsGroups: any;
    getFallbackScreenshots: any;
    takeSaveScreenshotGetURL: any;
    takeSaveServerScreenshotGetURL: any;
    takeSaveServerScreenshotsGetURL: any;
    takeSaveCroppedScreenshotFromFallbackGetURL: any;
    takeSaveServerScreenshotGetURLCustomConfiguration: any;
    saveConfiguration: any;
    loadConfiguration: any;
    getCurrentMaterialsGroup: any;
    getCurrentMaterials: any;
    getCurrentCustomTexturesSettings: any;
    getCurrentCustomTextsSettings: any;
    updateTextureSettings: any;
    setTextureLayer: any;
    removeTextureLayer: any;
    exportTextureImage: any;
    exportTextureImageGetURL: any;
    createPromise: any;
    setOFMLConfiguration: any;
}

interface Vitra3dViewerToolbarProps {
    /**
     * The rotate level from 0-360deg
     * eg. `90`
     */
    rotate?: number;

    /**
     * The Zoom Level of the compoent
     * eg `0-100`
     */
    zoomLevel: number;

    /**
     * Show AR Button
     */
    hasAR: boolean;

    /**
     * On Reset
     */
    onReset: () => void;
    /**
     * On Zoom in
     */
    onZoomIn: () => void;
    /**
     * On Zoom Out
     */
    onZoomOut: () => void;
    /**
     * On Rotate
     */
    onRotate: () => void;
    /**
     * On Start AR
     */
    onStartAr: () => void;
}

interface Vitra3dViewerProps {
    /**
     * The Id of the Viewer
     * This is the prefix
     * for all events
     */
    id: string;

    /**
     * Inital Product ID
     * Loads a product via `setFurnitureConfiguration`
     */
    productId?: string;

    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * Show the toolbar of the configurator
     */
    showToolbar?: boolean;

    /**
     * furnitureCode overwrite
     */
    furnitureCode?: string;
}

interface Vitra3dViewerError {
    /**
     * Emersya Error Code
     */
    code: string;

    /**
     * Emersya Error Message
     */
    message: string;
}

/**
 * Prevents a click and calls the bound function
 * @param func callback function
 */
const onClickPreventDefault = (func: any) => (e: any) => {
    e.preventDefault();
    func();
};

/**
 * Gets the rotation and zoomLevel from the state
 * @param cameraState 3dViewer state
 */
const getAngleAndZoomLevel = (cameraState: any) => {
    const pos = [cameraState.position[0], 0, cameraState.position[2]];
    const len = Math.sqrt(pos[0] * pos[0] + pos[2] * pos[2]);
    pos[0] /= len;
    pos[2] /= len;
    const radAngle = pos[0] > 0 ? Math.acos(pos[2]) : -Math.acos(pos[2]);
    const rotation = Math.ceil((360 + (180 * radAngle) / Math.PI) % 360);

    const zoomLevel = Math.ceil(cameraState.zoomProportion * 100);
    return { rotation, zoomLevel };
};

const Vitra3dViewerToolbar: React.FunctionComponent<Vitra3dViewerToolbarProps> = ({
    hasAR,
    rotate,
    zoomLevel,
    onReset,
    onStartAr,
    onZoomIn,
    onZoomOut,
    onRotate
}) => {
    const [showButtons, setShowButtons] = useState(false);
    const targetRef = useClickoutSide(() => setShowButtons(false));

    return (
        <Fragment>
            <div ref={targetRef as any} className={styles.toolbar}>
                <BlankButton
                    testId="vitra3d-viewer-toolbar-reset"
                    className={cx(styles.toolbarIcon, styles.toolbarIconReplay, showButtons && styles.toolbarIconShown)}
                    onClick={onClickPreventDefault(onReset)}
                >
                    <IconReplay />
                </BlankButton>
                <BlankButton
                    testId="vitra3d-viewer-toolbar-zoom-out"
                    className={cx(
                        zoomLevel === 0 && styles.toolbarIconDisabled,
                        styles.toolbarIcon,
                        showButtons && styles.toolbarIconShown
                    )}
                    onClick={onClickPreventDefault(onZoomOut)}
                >
                    <V2Minus />
                </BlankButton>
                <BlankButton
                    testId="vitra3d-viewer-toolbar-zoom-in"
                    onMouseOver={() => setShowButtons(true)}
                    className={cx(
                        zoomLevel === 100 && styles.toolbarIconDisabled,
                        styles.toolbarIcon,
                        styles.toolbarIconShown
                    )}
                    onClick={onClickPreventDefault(onZoomIn)}
                >
                    <V2Plus />
                </BlankButton>
                {rotate && (
                    <BlankButton
                        testId="vitra3d-viewer-toolbar-rotate"
                        className={styles.toolbarIcon}
                        onClick={onClickPreventDefault(onRotate)}
                    >
                        <IconRotate
                            style={{
                                transform: `rotate(${rotate}deg)`
                            }}
                        />
                    </BlankButton>
                )}
            </div>
            {hasAR && (
                <div className={styles.arTooltip}>
                    <VitraTooltip
                        trigger="hover"
                        tooltip={
                            <div className={styles.toolTip}>
                                <Translate
                                    className={styles.toolbarIconAr}
                                    id="configurator_show_in_room"
                                    defaultMessage="show in room"
                                />
                            </div>
                        }
                    >
                        <BlankButton
                            onClick={onClickPreventDefault(onStartAr)}
                            testId="vitra3d-viewer-toolbar-ar"
                            className={cx(styles.toolbarIcon, styles.toolbarIconShown)}
                        >
                            <V2Ar />
                        </BlankButton>
                    </VitraTooltip>
                </div>
            )}
        </Fragment>
    );
};

const Vitra3dViewerError: React.FunctionComponent<Vitra3dViewerError> = ({ code, message }) => {
    return (
        <div className={styles.error}>
            <div className={styles.errorCode}>{code}</div>
            <div className={styles.errorMessage}>{message}</div>
        </div>
    );
};

/**
 *  The 3D Viewer
 * @param Vitra3dViewerProps
 */
const Vitra3dViewer: React.FunctionComponent<Vitra3dViewerProps> = ({
    className,
    furnitureCode,
    productId,
    showToolbar,
    id
}) => {
    const configuratorId = `conf-${id}`;
    const [hasInit, setHasInit] = useState(false);
    const [hasAR, setHasAR] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [zoomLevel, setZoomLevel] = useState<number>(55);
    const [currentProductId, setCurrentProductId] = useState<string>(productId || '');

    /**
     * Event Functions
     */
    const onFurnitureConfiguration = async (configuration: string) => {
        try {
            setCurrentProductId(configuration);
            const viewer = await getViewer();
            viewer.setOFMLConfiguration({ configuration });
            checkForAR();
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    /**
     * Toolbar Functions
     */
    const onReset = async () => {
        try {
            const viewer = await getViewer();
            viewer.resetCamera({ transitionTime: 500 });
        } catch (err) {
            reportError(err, 'emersya');
        }
    };
    const onResize = async () => {
        try {
            const viewer = await getViewer();
            await viewer.resize();
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    const onPause = async () => {
        try {
            const viewer = await getViewer();
            await viewer.pause();
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    const onPlay = async () => {
        try {
            const viewer = await getViewer();
            await viewer.play();
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    // is called on the emersyaViewerInitialized event
    const checkForAR = async () => {
        try {
            const viewer = await getViewer();
            const arAvailability = await viewer.getARAvailability();
            setHasAR(arAvailability);
            emit('onHasAr', arAvailability);
            if (arAvailability) {
                viewer.disableARDefaultQRCodeStartingScreen();
            }
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    const onHide = async () => {
        setIsHidden(true);
    };

    const onShow = async () => {
        setIsHidden(false);
    };

    const onZoomIn = () => {
        addGaEvent({
            eventCategory: 'Product Interactions',
            eventAction: 'ZoomIn',
            eventLabel: currentProductId
        });
        let newLevel = zoomLevel + 10;
        newLevel = newLevel <= 100 ? newLevel : 100;
        postZoomLevel(newLevel);
    };

    const onZoomOut = () => {
        addGaEvent({
            eventCategory: 'Product Interactions',
            eventAction: 'ZoomOut',
            eventLabel: currentProductId
        });
        let newLevel = zoomLevel - 10;
        newLevel = newLevel >= 0 ? newLevel : 0;
        postZoomLevel(newLevel);
    };

    const postZoomLevel = async (level: number) => {
        setZoomLevel(level);
        const value = level / 100;
        try {
            const viewer = await getViewer();
            viewer.setZoomProportion({ value, transitionTime: 500 });
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    const onRotate = async () => {
        try {
            addGaEvent({
                eventCategory: 'Product Interactions',
                eventAction: 'Configure Rotate',
                eventLabel: currentProductId
            });

            const viewer = await getViewer();
            viewer.rotateCameraY({ value: 45 });
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    const getViewer = (): Promise<EmersyaApi> => {
        return new Promise((resolve, reject) => {
            const viewers = (window as any).emViewers;
            if (!viewers) {
                reject(new Error(`Viewer ${configuratorId} not found`));
            }
            const viewer = viewers[configuratorId];
            if (viewer) {
                return resolve(viewer);
            } else {
                reject(new Error(`Viewer ${configuratorId} not found`));
            }
        });
    };

    const onStartAr = async () => {
        try {
            // Track event
            addGaEvent({
                eventCategory: 'Product Interactions',
                eventAction: 'AR Start',
                eventLabel: currentProductId
            });
            const viewer = await getViewer();
            await viewer.startAr();
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    useEvents('fullscreen', {
        enterFullScreen: () => {
            setTimeout(() => {
                onResize();
            }, 250);
        },
        exitFullScreen: () => {
            setTimeout(() => {
                onResize();
            }, 500);
        }
    });

    // We must call the viewer api on every resize to prevent some
    // unwanted stretching of the 3d display in some browsers
    useEffect(() => {
        const windowOnResize = () => {
            onResize();
        };
        window.addEventListener('resize', windowOnResize);
        setTimeout(windowOnResize, 50);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    /**
     * Register Event callbacks
     */
    const emit = useEvents(id, {
        onHide,
        onShow,
        onReset,
        onStartAr,
        onResize,
        onFurnitureConfiguration
    });

    const ARQRCodeStartingScreenExpected = (data: any) => {
        emit('arCode', data);
    };

    const onAngleOrZoomEvent = ({ camera }: any) => {
        if (camera) {
            const state = getAngleAndZoomLevel(camera);
            setZoomLevel(state.zoomLevel);
        }
    };

    // The hasLoaded or viewerstate.loaded event means
    // that the 3d Model is fully rendered for the first time
    // and ready for animation
    const [hasLoaded, setHasLoaded] = useState(false);
    const onStateChangeEvent = async (state: any) => {
        if (state.viewerState === 'loaded') {
            setHasLoaded(true);
            checkForAR();
        }
    };

    // We us an intersection observer to play the 3d animation
    // as soon as the 3dviewer reaches the viewport
    const viewerRef = useRef<HTMLDivElement>(null);
    const [isInView, setIsInView] = useState(false);
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        if ('IntersectionObserver' in window) {
            const playObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((element) => {
                        if (element.isIntersecting) {
                            setIsInView(true);
                        } else {
                            setIsInView(false);
                        }
                    });
                },
                {
                    rootMargin: '-50% 0px',
                    threshold: [0, 0.5]
                }
            );
            if (viewerRef && viewerRef.current) {
                playObserver.observe(viewerRef.current as any);
            }
            return () => playObserver.disconnect();
        }
    }, []);

    // We pause the player after its loaded to keep the whole
    // site smooth and play again as soon as it reaches the
    // viewport BUT ONLY IF THE USER DIDN'T INTERACT YET with
    // the viewer
    const [viewerTouched, setViewerTouched] = useState(false);
    useEffect(() => {
        if (viewerTouched) {
            return;
        }
        if (hasLoaded && !isInView) {
            onPause();
        }
        if (hasLoaded && isInView) {
            onPlay();
        }
    }, [hasLoaded, isInView, viewerTouched]);

    // Change product Id on props change
    useEffect(() => {
        if (productId) {
            onFurnitureConfiguration(productId);
        }
    }, [productId]);

    const onLoadFrame = async () => {
        try {
            const viewer = await getViewer();
            viewer.enableHighFrequencyFeedback();
            viewer.addEventListener('onStateChange', onStateChangeEvent, false);

            const AngleOrZoomEvents = [
                'onGetCamera',
                'onEndCameraInteraction',
                'onStartCameraInteraction',
                'onUpdateCameraInteraction'
            ];

            AngleOrZoomEvents.forEach((event) => {
                viewer.addEventListener(event, onAngleOrZoomEvent);
            });

            setHasInit(true);
            emit('isLoaded');

            // Listen to viewer AR Events
            viewer.addEventListener('ARQRCodeStartingScreenExpected', ARQRCodeStartingScreenExpected);
        } catch (err) {
            reportError(err, 'emersya');
        }
    };

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        const qs = queryString.parse(window.location.search);
        furnitureCode = qs.furnitureCode || furnitureCode;

        const env: any = useConfig('env', 'staging');
        document.addEventListener('emersyaViewerInitialized', onLoadFrame, false);

        const defaultCode = 'GOGC957E2K';
        const isProduction = env === 'production';
        const tag = document.createElement('script');

        let fc = defaultCode;
        if (!isProduction && furnitureCode) {
            fc = furnitureCode;
        }
        // Generate script
        tag.setAttribute('src', 'https://emergatev4.s3-eu-west-1.amazonaws.com/f/emersyaLoader.js');
        tag.setAttribute('furnitureCode', fc);
        if (!isProduction && defaultCode !== fc) {
            tag.setAttribute('devMode', 'true');
        }
        tag.setAttribute('containerId', configuratorId); //
        tag.setAttribute('furnitureConfiguratorArEnabled', 'true');

        if (productId) {
            tag.setAttribute('configurationCode', productId);
        }

        document.getElementsByTagName('head')[0].appendChild(tag);

        return () => {
            document.removeEventListener('emersyaViewerInitialized', onLoadFrame, false);
        };
    }, []);

    return (
        <div
            ref={viewerRef}
            onClick={() => !viewerTouched && setViewerTouched(true)}
            className={cx(styles.wrapper, isHidden && styles.isHidden, className)}
        >
            {showToolbar && hasInit && (
                <Vitra3dViewerToolbar
                    hasAR={hasAR}
                    onZoomOut={onZoomOut}
                    onRotate={onRotate}
                    onStartAr={onStartAr}
                    onZoomIn={onZoomIn}
                    onReset={onReset}
                    zoomLevel={zoomLevel}
                />
            )}

            <div className={styles.container} id={configuratorId} />
        </div>
    );
};

// We never re-render this component
export default memo(Vitra3dViewer);
