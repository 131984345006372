import { colors, containerCentered, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';

import IconGridBig from 'common/primitives/icons/components/V1GridBig';
import IconGridMedium from 'common/primitives/icons/components/V1GridMedium';
import IconGridSmall from 'common/primitives/icons/components/V1GridSmall';
import React from 'react';
import Button from '../buttons';
import Translate from '../translations';

export type DisplayType = 'small' | 'big' | 'medium';
interface ListingFilterSwitch {
    active: number;
    display: DisplayType;
    categories: string[];
    onSelectCategory: (id: number) => void;
    onSelectDisplay: (id: DisplayType) => void;
}

const styles = {
    wrapper: css`
        align-items: center;
        ${media.tablet} {
            border-bottom: 1px solid ${colors.lightgrey};
        }
        display: flex;
        width: 100%;
        justify-content: space-between;
    `,
    caption: css`
        display: none;
        ${media.tablet} {
            display: block;
        }
        padding: ${rh(0.25)} 0;
        color: ${colors.lightgrey};
        font-weight: 500;
    `,
    categories: css`
        display: none;
        ${media.tablet} {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `,
    categoriesMobile: css`
        display: flex;
        width: 100%;
        ${media.tablet} {
            display: none;
        }
    `,
    categoryItem: css`
        cursor: pointer;
        border: 1px solid transparent;
        border-bottom: 0;
        a {
            padding: ${rh(0.25)} ${rh(0.75)};
            display: flex;
            align-items: center;
            ${linkColor(colors.black, colors.textGrey)};
            font-weight: 500;
            text-decoration: none;
        }
    `,
    categoryItemActive: css`
        a {
            ${linkColor(colors.primary, colors.primary)};
        }
    `,

    captionRight: css`
        display: none;
        ${media.tablet} {
            display: flex;
        }
        margin-left: auto;
        position: relative;
    `,
    displayIcon: css`
        display: inline-flex;
        margin-left: 5px;
        &:hover {
            svg path {
                fill: ${colors.primary};
            }
        }
    `,
    displayIconActive: css`
        svg path {
            fill: ${colors.primary};
        }
    `
};
const displayOptions = [
    {
        id: 'small',
        icon: <IconGridSmall />
    },
    {
        id: 'medium',
        icon: <IconGridMedium />
    },
    {
        id: 'big',
        icon: <IconGridBig />
    }
];

const VitraListingFilterSwitch: React.FunctionComponent<ListingFilterSwitch> = ({
    categories,
    active,
    display,
    onSelectCategory,
    onSelectDisplay
}) => {
    const toggleCategory = (categoryIndex: number) => (e: any) => {
        e.preventDefault();
        onSelectCategory(categoryIndex);
    };

    const toggleDisplay = (id: DisplayType) => (e: any) => {
        e.preventDefault();
        onSelectDisplay(id);
    };

    return (
        <div className={containerCentered}>
            <div className={styles.wrapper}>
                <div className={styles.caption}>
                    <Translate id="listing.filter.switch" defaultMessage="display" />
                </div>
                <div className={cx('main__items--desktop', styles.categories)}>
                    {categories.map((cat, ix) => {
                        return (
                            <div
                                className={cx(
                                    'items__element',
                                    active === ix && styles.categoryItemActive,
                                    styles.categoryItem
                                )}
                            >
                                <a onClick={toggleCategory(ix)} href="#">
                                    {cat}
                                </a>
                            </div>
                        );
                    })}
                </div>
                <div className={cx('main__items--mobile', styles.categoriesMobile)}>
                    {categories.map((cat, ix) => {
                        return (
                            <Button
                                onClick={toggleCategory(ix)}
                                testId={`open-mobile-cat-${ix}`}
                                type={active === ix ? 'black' : 'blackBorder'}
                            >
                                {cat}
                            </Button>
                        );
                    })}
                </div>
                <div className={cx(styles.caption, styles.captionRight)}>
                    {displayOptions.map((d) => {
                        const isActive = display === d.id;
                        return (
                            <a
                                onClick={toggleDisplay(d.id as DisplayType)}
                                className={cx(styles.displayIcon, isActive && styles.displayIconActive)}
                                href="#"
                            >
                                {d.icon}
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default VitraListingFilterSwitch;
