import { client, getBffLanguageAndCountry } from 'common/graphql/client';
import Translate, { getLocalizedPrice } from 'common/primitives/translations';
import React from 'react';

export const loadFromPriceData = async (products: Product[]): Promise<Product[]> => {
    const { market } = getBffLanguageAndCountry();

    try {
        const censhareProductIds = products.map((el) => parseInt(el.id, 10));
        const dataWithPrices = await client
            .getFromPrices({ censhareProductIds: censhareProductIds, market })
            .then(({ getFromPrices }) => (getFromPrices || []).filter((el) => el.censhareId && el.price))
            .then((data) => {
                const pricesMap: any = {};
                data.forEach((el) => {
                    const { censhareId, isFromPrice } = el;
                    const localizedPrice = getLocalizedPrice(el.price!);
                    if (localizedPrice) {
                        let priceElement: any = localizedPrice;
                        if (isFromPrice) {
                            priceElement = (
                                // We need to use the Translate component here,
                                // because this assures that the translation context is present
                                // in the react rendering tree.
                                <Translate
                                    id="listing.price.from"
                                    defaultMessage="from {fromPrice}"
                                    data={{ fromPrice: localizedPrice }}
                                />
                            );
                        }
                        pricesMap[`${censhareId}`] = {
                            isFromPrice: isFromPrice,
                            formatted: priceElement,
                            raw: el.price?.raw
                        };
                    }
                });
                return pricesMap;
            })
            .then((pricesMap) => {
                return [...products].map((p: any) => {
                    if (pricesMap[`${p.id}`]) {
                        return {
                            ...p,
                            price: {
                                ...pricesMap[`${p.id}`]
                            }
                        };
                    }
                    return p;
                });
            });

        return dataWithPrices;
    } catch (err) {
        return products;
    }
};

export const loadFromPriceDataBySlugs = async (products: Product[]): Promise<Product[]> => {
    const { market } = getBffLanguageAndCountry();

    try {
        const censhareProductSlugs = products.map((el) => el.id);
        console.log(censhareProductSlugs);

        const dataWithPrices = await client
            .getFromPricesBySlugs({ slugs: censhareProductSlugs, market })
            .then(({ getFromPricesBySlugs }) =>
                (getFromPricesBySlugs ?? []).filter((el) => el.censhareSlug && el.price)
            )
            .then((data) => {
                const pricesMap: any = {};
                data.forEach((el) => {
                    const { censhareSlug, isFromPrice } = el;
                    const localizedPrice = getLocalizedPrice(el.price!);
                    if (localizedPrice) {
                        let priceElement: any = localizedPrice;
                        if (isFromPrice) {
                            priceElement = (
                                // We need to use the Translate component here,
                                // because this assures that the translation context is present
                                // in the react rendering tree.
                                <Translate
                                    id="listing.price.from"
                                    defaultMessage="from {fromPrice}"
                                    data={{ fromPrice: localizedPrice }}
                                />
                            );
                        }
                        pricesMap[`${censhareSlug}`] = {
                            isFromPrice: isFromPrice,
                            formatted: priceElement,
                            raw: el.price?.raw
                        };
                    }
                });
                return pricesMap;
            })
            .then((pricesMap) => {
                return [...products].map((p: any) => {
                    if (pricesMap[`${p.id}`]) {
                        return {
                            ...p,
                            price: {
                                ...pricesMap[`${p.id}`]
                            }
                        };
                    }
                    return p;
                });
            });

        return dataWithPrices;
    } catch (err) {
        return products;
    }
};
