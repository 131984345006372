import * as React from 'react';

const SvgInstagram = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  height="1em" viewBox="0 0 22 22" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#333"
            d="M2.538 22h16.924A2.54 2.54 0 0 0 22 19.461V2.538A2.538 2.538 0 0 0 19.462 0H2.538A2.538 2.538 0 0 0 0 2.538v16.923A2.538 2.538 0 0 0 2.538 22zM15.232 3.384c0-.467.378-.846.845-.846h2.538c.469 0 .848.379.848.846v2.539a.847.847 0 0 1-.848.846h-2.538a.845.845 0 0 1-.845-.846V3.384zM11 6.769a4.231 4.231 0 1 1 .001 8.463A4.231 4.231 0 0 1 11 6.769zM2.538 9.307h1.916A6.745 6.745 0 0 0 4.231 11a6.77 6.77 0 0 0 13.539 0c0-.586-.085-1.152-.222-1.693h1.914v9.309a.847.847 0 0 1-.848.846H3.385a.846.846 0 0 1-.847-.846V9.307z"
        />
    </svg>
);

export default SvgInstagram;
