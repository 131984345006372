import { useEffect, useRef } from 'react';

const usePreventBodyScroll = (isActive = false): void => {
    const initialBodyOverscrollBehaviorY = useRef<string | null>(null);
    const initialElementOverscrollBehaviorY = useRef<string | null>(null);

    useEffect(() => {
        if (isActive) {
            initialBodyOverscrollBehaviorY.current = document.body.style.overscrollBehaviorY;
            initialElementOverscrollBehaviorY.current = document.documentElement.style.overscrollBehaviorY;

            document.body.style.overscrollBehaviorY = 'none';
            document.documentElement.style.overscrollBehaviorY = 'none';

            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overscrollBehaviorY = initialBodyOverscrollBehaviorY.current ?? '';
            document.documentElement.style.overscrollBehaviorY = initialElementOverscrollBehaviorY.current ?? '';

            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overscrollBehaviorY = initialBodyOverscrollBehaviorY.current ?? '';
            document.documentElement.style.overscrollBehaviorY = initialElementOverscrollBehaviorY.current ?? '';

            document.body.style.overflow = '';
        };
    }, [isActive]);
};

export default usePreventBodyScroll;
