import * as React from 'react';

const SvgV2Ar = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={26.447} height={29.5} viewBox="0 0 26.447 29.5" {...props}>
        <g data-name="icon/single/ar">
            <path
                strokeWidth={0.5}
                d="M13.224 29.25a.76.76 0 0 1-.355-.087l-4.566-2.282a.763.763 0 0 1 .683-1.365l3.474 1.737V23.15a.763.763 0 0 1 1.526 0v4.1l3.475-1.737a.763.763 0 0 1 .682 1.365l-4.565 2.282a.767.767 0 0 1-.354.09zm7.632-3.815a.763.763 0 0 1-.342-1.446l3.214-1.607-3.214-1.607a.763.763 0 0 1 .343-1.446.756.756 0 0 1 .34.08l3.474 1.738v-4.108a.763.763 0 0 1 1.526 0v5.327a.762.762 0 0 1-.069.334.758.758 0 0 1-.359.367l-4.572 2.286a.758.758 0 0 1-.341.081zm-15.264 0a.757.757 0 0 1-.341-.081L.679 23.068a.76.76 0 0 1-.348-.345l-.005-.011-.005-.011-.021-.05a.762.762 0 0 1-.049-.283v-5.327a.763.763 0 0 1 1.526 0v4.108l3.474-1.738a.764.764 0 0 1 1.024.342.764.764 0 0 1-.341 1.023l-3.21 1.606 3.214 1.607a.763.763 0 0 1-.342 1.446zm19.843-12.211a.764.764 0 0 1-.763-.763V8.35l-3.474 1.737a.763.763 0 0 1-.683-1.365l3.214-1.606-3.214-1.607a.763.763 0 0 1 .683-1.365L25.77 6.43a.761.761 0 0 1 .348.345l.009.019.007.016.015.037a.766.766 0 0 1 .049.284v5.326a.764.764 0 0 1-.763.766zm-24.421 0a.764.764 0 0 1-.763-.763V7.135A.754.754 0 0 1 .32 6.8a.769.769 0 0 1 .359-.367l4.572-2.286a.763.763 0 0 1 .683 1.365l-3.21 1.607 3.214 1.606a.763.763 0 0 1-.683 1.365L1.781 8.353v4.107a.764.764 0 0 1-.767.763zm11.624-6.379a.764.764 0 0 1-.177-.488V2.25L8.987 3.987a.763.763 0 1 1-.683-1.365L12.87.34a.766.766 0 0 1 .71 0l4.565 2.282a.763.763 0 1 1-.682 1.365L13.987 2.25v4.108a.761.761 0 0 1-.11.4h-.126a6.621 6.621 0 0 0-1.112.094z"
                data-name="Differenzmenge 1"
            />
            <path
                d="M13.95 18.06l-3.61-7.81-3.59 7.81h1.2l.83-1.86h3.1l.83 1.86zm-2.48-2.85H9.21l1.12-2.55zm8.23 2.85l-2.17-3.3a2.056 2.056 0 0 0 1.56-2.06 2.058 2.058 0 0 0-.93-1.76c-.48-.34-.95-.42-2.36-.42h-1.1v7.54h1.1v-3.18h.52l2.05 3.18zm-1.74-5.34a1.087 1.087 0 0 1-.27.76 1.69 1.69 0 0 1-1.3.35h-.59v-2.24h.44a2.529 2.529 0 0 1 1.15.15 1.048 1.048 0 0 1 .57.98z"
                data-name="Pfad 7292"
            />
        </g>
    </svg>
);

export default SvgV2Ar;
