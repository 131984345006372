import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React from 'react';

const styles = {
    spinner: css`
        display: inline-flex !important;
        flex-direction: row;
    `,
    dot: css`
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-left: 2px;
        margin-right: 2px;
        mix-blend-mode: difference;
    `,
    dot_white: css`
        color: #fff;
        background-color: #fff;
        animation: dotWhiteFlashing 0.6s infinite linear alternate;
        @keyframes dotWhiteFlashing {
            0% {
                background-color: rgba(255, 255, 255, 1);
            }

            50%,
            100% {
                background-color: rgba(255, 255, 255, 0.15);
            }
        }
    `,
    dot_black: css`
        color: #fff;
        background-color: #000;
        animation: dotBlackFlashing 0.6s infinite linear alternate;
        @keyframes dotBlackFlashing {
            0% {
                background-color: rgba(0, 0, 0, 1);
            }

            50%,
            100% {
                background-color: rgba(0, 0, 0, 0.15);
            }
        }
    `,
    dotMiddle: css`
        animation-delay: 0.2s;
    `,
    dotRight: css`
        animation-delay: 0.6s;
    `
};

interface SpinnerDots {
    /**
     * The color
     */
    type: 'white' | 'black';

    /**
     * Force to disable mixblend
     */
    mixblendDisabled?: boolean;
}

const VitraSpinnerDots: React.FunctionComponent<SpinnerDots> = ({ type, mixblendDisabled = false }) => {
    let mixBlendCSSSupport = false;
    if (canUseDOM && ((window as any).CSS || {}).supports) {
        mixBlendCSSSupport = (window as any).CSS.supports('mix-blend-mode: difference');
    }
    const dotStyle = mixBlendCSSSupport && !mixblendDisabled ? styles.dot_white : (styles as any)[`dot_${type}`];
    return (
        <div className={styles.spinner}>
            <div className={cx(styles.dot, dotStyle)}></div>
            <div className={cx(dotStyle, styles.dot, styles.dotMiddle)}></div>
            <div className={cx(dotStyle, styles.dot, styles.dotRight)}></div>
        </div>
    );
};

export default VitraSpinnerDots;
