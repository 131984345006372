import * as React from 'react';

const SvgV2ContactPrivate = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
        <g transform="translate(-704 -2001.533)">
            <circle cx={25} cy={25} r={25} data-name="Ellipse 22847" transform="translate(704 2001.533)" />
            <path
                fill="#fff"
                d="M735.364 2035.395h-12.728a.909.909 0 01-.909-.909v-8.182H719l9.388-8.535a.909.909 0 011.223 0l9.389 8.535h-2.727v8.177a.909.909 0 01-.909.914zm-5.455-1.814h4.545v-8.952L729 2019.67l-5.455 4.959v8.952h4.545v-5.459h1.818z"
            />
        </g>
    </svg>
);

export default SvgV2ContactPrivate;
