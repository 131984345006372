import useId from 'common/hooks/useId';
import globalStyle from 'common/primitives/forms/styles';
import { adjustFontSizeTo, colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import React from 'react';

const styles = defaults(
    {
        textInput: css`
            width: 100%;
            ${fontSizeAndLineHeight('17px')}
            color: ${colors.black};
            border: 1px solid ${colors.inputBorderColor};
            border-radius: 0px;
            &:disabled {
                background: ${colors.inputDisabledBackground};
            }
            &:hover,
            &:active,
            &:focus {
                outline: none;
                &:not(:disabled) {
                    border-bottom-color: ${colors.black};
                }
            }
        `,

        // With a label we must position the input differeently
        // underneath the label..
        textInputWithLabel: css`
            padding: ${rh(0.65)} ${rh(0.5)} ${rh(0.15)};
        `,

        label: css`
            opacity: 1;
            position: absolute;
            top: 4px;
            left: calc(${rh(0.5)} - ${rh(0.15)});
            font-size: ${adjustFontSizeTo('12px')};
            width: auto;
            background: transparent;
            padding: 0 ${rh(0.15)};
        `
    },
    globalStyle
);

interface FakeTextInputProps {
    label: string;
    value: string;
}

const FakeTextInput: React.FunctionComponent<FakeTextInputProps> = (props) => {
    const { value, label } = props;

    // Input id
    const id = useId();

    return (
        <div className={cx('form__field', 'form__field--input', styles.wrapper)}>
            <input
                className={cx(styles.textInput, label && styles.textInputWithLabel)}
                id={id}
                disabled={true}
                type="text"
                value={value}
            />
            <label className={styles.label} htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

export default FakeTextInput;
