import { colors, linkColor, rh } from 'common/styles';
import { css, cx } from 'linaria';

import ArrowRotate from 'common/primitives/icons/components/V2ArrowRotate';
import React from 'react';
import V2Check from 'common/primitives/icons/components/V2Check';
import VitraLink from '../links/vitraLink';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import useToggle from 'common/hooks/useToggle';

export interface DropDownListItem {
    id: string;
    link?: Link;
    className?: string;
    isActive?: boolean;
    disabled: boolean;
    render?: (item: DropDownListItem, toggle: () => void) => JSX.Element;
    onClick?: (toggle: () => void, e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface DropDownListCategories {
    title: string;
    children?: DropDownListItem[];
    className?: string;
    render?: (toggle: () => void) => JSX.Element | undefined;
    onClick?: (toggle: () => void, e: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface DropDownList {
    className?: string;
    items: DropDownListCategories[];
}

const styles = {
    dropdownList: css`
        border-bottom: 1px solid ${colors.inputBorderColor};
    `,
    dropdownListRow: css`
        &:not(:first-child) {
            border-top: 1px solid ${colors.inputBorderColor};
        }
    `,
    dropdownListChildren: css`
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        background: ${colors.footerGreyLight};
    `,
    dropdownListRowCategory: css`
        ${linkColor(colors.black, colors.primary)};
        font-weight: 500;
        padding: ${rh(.5)} ${rh(.5)};
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    `,
    dropdownListRowCategoryHasChildren: css`
    `,
    dropdownListRowCategoryOpen: css`
        border-bottom: 1px solid ${colors.inputBorderColor};
    `,
    dropdownListRowLink: css`
        ${linkColor(colors.black, colors.primary)};
        font-weight: 500;
        width: 100%;
        text-decoration: none;
        padding: ${rh(.5)} 0 ${rh(.5)} 40px;
        border-bottom: 1px solid ${colors.inputBorderColor};
        position: relative;
        &:last-child {
            border-bottom: 0;
        }
        svg {
            right: 16px;
            position: absolute;
            top: 23px;
        }
    `,
    dropdownListRowLinkDisabled: css`
        ${linkColor("rgba(51,51,51,0.3)", "rgba(51,51,51,0.3)")};
    `,
    customElement: css`
        padding: ${rh(.25)} ${rh(.5)};
    `
};

export const VitraDropDownListRow: React.FunctionComponent<DropDownListCategories> = ({
    onClick,
    className,
    render,
    title,
    children
}) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    let onClickItem = onClick ? onClick.bind(null, toggleIsOpen) : null;
    const toggle = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (hasChildren) {
            e.preventDefault();
            toggleIsOpen();
        } else {
            if (onClickItem) {
                onClickItem(e);
            }
        }
    };

    const hasChildren = (children && children.length > 0) || !!render;

    return (
        <div className={cx(styles.dropdownListRow)}>
            <a href="#" onClick={toggle} className={cx(
                className,
                styles.dropdownListRowCategory,
                isOpen && styles.dropdownListRowCategoryOpen,
                hasChildren && styles.dropdownListRowCategoryHasChildren
            )}>
                {title} {hasChildren && <ArrowRotate up={isOpen} />}
            </a>
            {(isOpen && hasChildren) && <div className={styles.dropdownListChildren}>
                {!!render && render(toggleIsOpen)}
                {(!render && hasChildren) && children!.map(child => {
                    onClickItem = child.onClick ? child.onClick.bind(null, toggleIsOpen) : onClickItem;
                    if (child.render) {
                        return (
                            <div className={styles.customElement}>
                                {child.render(child, toggleIsOpen)}
                            </div>
                        )
                    }
                    if (child.link) {
                        return (
                            <VitraLink
                                {...child.link}
                                className={cx(
                                    styles.dropdownListRowLink,
                                    child.disabled && styles.dropdownListRowLinkDisabled,
                                    child.className
                                )}
                                href={child.link.href || '#'}
                                onClick={(!child.disabled && onClickItem as any)}
                            >
                                {child.link.name}
                                {child.isActive && <V2Check/>}
                            </VitraLink>
                        )
                    }
                })}
            </div>}
        </div>
    );
};


export const VitraDropDownList: React.FunctionComponent<DropDownList> = ({ className, items }) => {
    return (
        <div className={cx(styles.dropdownList, className)}>
            {items.map(item => <VitraDropDownListRow {...item} />)}
        </div>
    );
};
