import * as React from 'react';

const SvgV2FindVitra = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g>
            <path d="M11.745 11.746a2 2 0 112-2 2 2 0 01-2 2z" />
            <path d="M11.745 1a8.746 8.746 0 00-6.187 14.933l6.188 6.187 6.186-6.187A8.746 8.746 0 0011.745 1zm0 19l-5.127-5.127a7.25 7.25 0 1110.253 0z" />
        </g>
    </svg>
);

export default SvgV2FindVitra;
