import { canUseDOM } from 'exenv';
import noop from 'lodash/noop';
import mitt from 'mitt';
import { useEffect } from 'react';

type eventsFunc = Record<string, unknown>;

declare const window: Window &
    typeof globalThis & {
        __VITRA_EMITTER: any;
    };

export const emitter = mitt();
if (canUseDOM) {
    window.__VITRA_EMITTER = emitter;
}

/**
 * the useEvents hook injects a listener for the given events and a
 * emit function which doesnt require the prefix
 *
 * @param {string} prefix A prefix which scopes the listener and emitter
 * @param {object} events An optional object where every object key represents the name of the event and its value the callback function
 * @return {function} Returns an emit function which may be used tto emit and holds the prefix
 */
export type EventEmitterCall = (key: string, payload?: any) => void;
export const useEvents = (prefix = 'global', events: eventsFunc = {}): EventEmitterCall => {
    if (!canUseDOM) {
        return noop;
    }

    useEffect(() => {
        // Register Event Listender
        Object.keys(events).forEach((key) => {
            emitter.on(`${prefix}:${key}`, (events as any)[key]);
        });

        return () => {
            // Unload Events
            Object.keys(events).forEach((key) => {
                emitter.off(`${prefix}:${key}`, (events as any)[key]);
            });
        };
    }, [events, prefix]);

    const emit = (key: string, payload: any) => {
        // if (process.env.NODE_ENV !== 'production') {
        //     console.info(`EMIT: ${prefix}:${key}`, payload);
        // }
        emitter.emit(`${prefix}:${key}`, payload);
    };
    return emit;
};
