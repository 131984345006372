// A function which returns n partitions of an array
const partition = (list: Array<any>, n: number):any => {
    const isPositiveInteger = Number.isSafeInteger(n) && n > 0;
    if (!isPositiveInteger) {
        throw new RangeError('n must be a positive integer');
    }

    const partitions = [];
    const partitionLength = Math.ceil(list.length / n);

    for (let i = 0; i < list.length; i += partitionLength) {
        const partition = list.slice(i, i+partitionLength);
        partitions.push( partition );
    }

    return partitions;
}

export default partition;
