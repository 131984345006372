import VitraSpinner from 'common/primitives/spinners/vitraSpinner';
import { get } from 'common/utils/fetch';
import { geolocationGoogle, geolocationNavigator } from 'common/utils/geo';
import cookie from 'js-cookie';
import { css } from 'linaria';
import React, { useEffect } from 'react';
import { useLocation, useRoute } from 'wouter';
import { FindVitraApiResponse } from '../find-vitra-types';
import { getCityNameBySlug } from '../state/find-vitra.state';
import { useStateValue } from '../state/find-vitra.state-provider';

const styles = {
    finVitraWrapper: css`
        position: relative;
        min-height: 100vh;
        height: 100%;
    `,
    spinner: css`
        height: 100vh;
    `,
    error: css`
        display: inline-block;
        text-align: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    `
};

export const CommonBootstrapLoading: React.FunctionComponent<any> = (props: any) => {
    // State
    const [state, dispatch]: any = useStateValue();
    const [routerMatch, routerMatchParams] = useRoute<any>('/location/:country_slug/:city_slug');

    const getStartingLatLong = async (locationPathname: string) => {
        let coords;

        // Check if we have a location in the url path
        if (routerMatch) {
            const { country_slug, city_slug } = routerMatchParams;

            if (getCityNameBySlug(country_slug, city_slug)) {
                coords = await geolocationGoogle(`country:${country_slug},city:${city_slug}`);
            }
        }

        if (!coords) {
            try {
                coords = await geolocationNavigator({ timeout: 4000, maximumAge: Infinity });
            } catch {
                console.log('navigator geolocation failed');
            }
        }

        if (!coords && state.options.position && state.options.position.country) {
            // In case of Japan, geocoder delivers a wrong result if we dont use the Japanese words for it...
            const country_name = state.options.position.country !== 'JP' ? state.options.position.country : '日本';

            // ATTENTION: THERE MUST BE NO WHITESPACE!!!
            coords = await geolocationGoogle(`country:${country_name}`);
        }

        if (!coords) {
            // Wheil am Rhein
            coords = {
                latitude: 47.59331,
                longitude: 7.62082
            };
        }

        const hostname = window.location.hostname;

        cookie.set('find-vitra-coords', JSON.stringify(coords), {
            secure: hostname !== 'localhost',
            expires: 1
        });

        return coords;
    };

    // XHR Fetch the data
    const loadFindVitraData = async (locationPathname: string, searchParams: any) => {
        try {
            // Check if valid iso language-country code in url
            const language_pathArray = window.location.pathname.split('/');
            const language_segment = language_pathArray[1];
            const language_iso_pattern = new RegExp('[a-z]{2}-[a-z]{2}');
            const languageMapping = language_iso_pattern.test(language_segment) ? language_segment : 'de-de';

            // Initial Location (lat/lng)
            const latLng = await getStartingLatLong(locationPathname);

            // Censhare
            let query = `${state.options.apiUrl}?code=${languageMapping}&lat=${latLng.latitude}&lng=${latLng.longitude}`;

            if (searchParams.hasOwnProperty('purge')) {
                query = `${query}&purge=true`;
            }

            if (searchParams.hasOwnProperty('nocache')) {
                query = `${query}&nocache=true`;
            }

            const apiResponse = await get<FindVitraApiResponse>(query);
            dispatch({
                type: 'API_SUCCESS_DATA',
                data: {
                    locations: apiResponse.locations,
                    filters: apiResponse.filters,
                    position: latLng
                }
            });
        } catch (err) {
            console.error(err);
            dispatch({ type: 'API_ERROR' });
        }
    };

    const [location] = useLocation();

    useEffect(() => {
        loadFindVitraData(location, props.qs || {});
    }, []);

    return <VitraSpinner type="black" mixblendDisabled={true} className={styles.spinner} />;
};
