// https://github.com/GoogleChromeLabs/pinch-zoom

import { colors } from 'common/styles';
import { timer } from 'common/utils/animation';
import PointerTracker, { Pointer } from 'common/utils/pointer-tracker';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import throttle from 'lodash/throttle';
import React, { Fragment, useEffect, useLayoutEffect, useRef } from 'react';

export interface VitraPinchZoomProps {
    /**
     * The wrapper className
     */
    className?: string;

    /**
     * This prop sets will-transform on the positioningRef
     * It defaults to FALSE
     * BUT MUST BE ENABLED IF WE HAVE CHILDREN WHICH NOT ONLY CONSIST OF IMAGES!!!!
     */
    disableFastAndBlurry?: boolean;

    /**
     * A handler fot the wrapper
     * componen
     */
    onChange?: (opts: SetTransformOpts) => void;

    /**
     * Start with fullbleed (Content uses 100% container height)
     */
    startFullbleed?: boolean;
}

interface Point {
    clientX: number;
    clientY: number;
}

interface ApplyChangeOpts {
    panX?: number;
    panY?: number;
    scaleDiff?: number;
    originX?: number;
    originY?: number;
}

interface SetTransformOpts {
    scale: number;
    x: number;
    y: number;
}

export const styles = {
    pinchZoomWrapper: css`
        display: flex !important;
        align-items: center;
        overflow: hidden;
        touch-action: none;
        user-select: none;
        width: 100vw;
        height: 100vh;
        background: ${colors.black};
        padding-bottom: 100px;
    `,
    pinchZoomWrapperWithSlider: css`
        padding-bottom: 160px; // the Slider height added
    `,
    positioningRef: css`
        transform-origin: 0 0;
        position: relative;
        width: 100%;
        font-size: 0;
        > * {
            width: 100%;
        }
    `,
    positioningRefAllowBlurry: css`
        will-change: transform;
    `
};

const VitraPinchZoom: React.FunctionComponent<VitraPinchZoomProps> = (props) => {
    // Only works in DOM
    if (!canUseDOM) {
        return null;
    }

    let pointerTracker: PointerTracker;

    // Helper Functions and Constants
    const getDistance = (a: Point, b?: Point): number => {
        if (!b) {
            return 0;
        }
        return Math.sqrt((b.clientX - a.clientX) ** 2 + (b.clientY - a.clientY) ** 2);
    };

    const getMidpoint = (a: Point, b?: Point): Point => {
        if (!b) {
            return a;
        }
        return {
            clientX: (a.clientX + b.clientX) / 2,
            clientY: (a.clientY + b.clientY) / 2
        };
    };

    const cachedSvg = useRef<any>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));

    const getSVG = (): SVGSVGElement => {
        return cachedSvg.current;
    };

    const createMatrix = (): SVGMatrix => {
        return getSVG().createSVGMatrix();
    };

    const MIN_SCALE = 1;

    // The transforms which will be applied - WE DON'T USE A STATE VALUE
    // BECAUSE reacts rendering would collide with our measurements
    let transforms: SetTransformOpts = {
        scale: 1,
        x: 0,
        y: 0
    };
    const setTransforms = (obj: SetTransformOpts) => {
        if (!positioningRef.current) {
            return;
        }
        positioningRef.current.style.transform = `translate3d(${obj.x}px, ${obj.y}px, 0) scale(${obj.scale})`;
        transforms = obj;
    };

    // We need to elements, the outer to measure and the inner to position
    const outerRef = useRef<any>(null);
    const positioningRef = useRef<any>(null);

    // Transform the view & fire a change event */
    const applyChange = (opts: ApplyChangeOpts = {}) => {
        const { panX = 0, panY = 0, originX = 0, originY = 0, scaleDiff = 1 } = opts;

        const matrix = createMatrix()
            // Translate according to panning.
            .translate(panX, panY)
            // Scale about the origin.
            .translate(originX, originY)
            // Apply current translate
            .translate(transforms.x, transforms.y)
            .scale(scaleDiff)
            .translate(-originX, -originY)
            // Apply current scale.
            .scale(transforms.scale);

        // Convert the transform into basic translate & scale.
        setTransforms({
            scale: matrix.a,
            x: matrix.e,
            y: matrix.f
        });
    };

    const keepInBounds = (obj: SetTransformOpts): SetTransformOpts => {
        const positionRect = positioningRef.current.getBoundingClientRect();
        const outerRect = outerRef.current.getBoundingClientRect();

        // Case: we are at the MIN_SCALE 1...
        if (obj.scale <= MIN_SCALE) {
            obj.scale = MIN_SCALE;
            obj.x = 0;
            obj.y = 0;
        } else {
            // The maximum offset we allow
            const maxXOffset = 0;

            // Keep it left bounded
            if (obj.x > maxXOffset) {
                obj.x = maxXOffset;
            }

            // Keep it right bounded
            const maxNegative = (positionRect.width - outerRect.width) * -1;
            if (obj.x < maxNegative - maxXOffset) {
                obj.x = maxNegative - maxXOffset;
            }

            // Keep it centered on the horizontal axis
            if (
                // center if smaller then the pinch area
                positionRect.height < outerRect ||
                // dont let a big image got to far into negative y-axis
                positionRect.y + positionRect.height < outerRect.height / 2 ||
                // dont let a big image got to far into positive y-axis
                positionRect.y > outerRect.height / 2
            ) {
                obj.y = ((positionRect.height - (1 / obj.scale) * positionRect.height) / 2) * -1;
            }
        }

        return obj;
    };

    // Handle PointerTracker Events
    const onPointerMove = (previousPointers: Pointer[], currentPointers: Pointer[], event: any) => {
        if (!positioningRef.current) {
            return;
        }

        // Combine next points with previous points
        const currentRect = positioningRef.current.getBoundingClientRect();
        // For calculating panning movement
        const prevMidpoint = getMidpoint(previousPointers[0], previousPointers[1]);
        const newMidpoint = getMidpoint(currentPointers[0], currentPointers[1]);

        // Midpoint within the element
        const originX = prevMidpoint.clientX - currentRect.left;
        const originY = prevMidpoint.clientY - currentRect.top;

        // Calculate the desired change in scale
        const prevDistance = getDistance(previousPointers[0], previousPointers[1]);
        const newDistance = getDistance(currentPointers[0], currentPointers[1]);
        const scaleDiff = prevDistance ? newDistance / prevDistance : 1;

        applyChange({
            originX,
            originY,
            scaleDiff,
            panX: newMidpoint.clientX - prevMidpoint.clientX,
            panY: newMidpoint.clientY - prevMidpoint.clientY
        });

        // Due to an iOS bug we muss prevent that the client gets near any other area!!!!
        currentPointers.forEach((cp: Pointer) => {
            if (cp.clientY <= 60 || cp.clientY >= window.innerHeight - 60) {
                console.warn('pinch & zoom: to close to unsafe area');
                pointerTracker.triggerPointerEnd(cp, event);
                return;
            }
        });
    };

    const onEnd = () => {
        // Check if we are in bounds
        const limitOpts = keepInBounds({
            scale: transforms.scale,
            x: transforms.x,
            y: transforms.y
        });

        if (transforms.scale !== limitOpts.scale || transforms.x !== limitOpts.x || transforms.y !== limitOpts.y) {
            timer((step: number) => {
                setTransforms({
                    scale: transforms.scale + (limitOpts.scale - transforms.scale) * step,
                    x: transforms.x + (limitOpts.x - transforms.x) * step,
                    y: transforms.y + (limitOpts.y - transforms.y) * step
                });
            }, 200);
        }

        if (props.onChange) {
            props.onChange(transforms);
        }
    };

    const onEndThrottled = throttle(onEnd, 800);

    const onWheel = (event: WheelEvent) => {
        if (!positioningRef.current) {
            return;
        }
        event.preventDefault();

        const currentRect = positioningRef.current.getBoundingClientRect();
        let { deltaY } = event;
        const { ctrlKey, deltaMode } = event;

        if (deltaMode === 1) {
            // 1 is "lines", 0 is "pixels"
            // Firefox uses "lines" for some types of mouse
            deltaY *= 15;
        }

        // ctrlKey is true when pinch-zooming on a trackpad.
        const divisor = ctrlKey ? 100 : 300;
        const scaleDiff = 1 - deltaY / divisor;

        applyChange({
            scaleDiff,
            originX: event.clientX - currentRect.left,
            originY: event.clientY - currentRect.top
        });

        onEndThrottled();
    };

    // Start tracking the element on mount
    useEffect(() => {
        // Watch for pointers
        pointerTracker = new PointerTracker(outerRef.current, {
            start: (pointer, event) => {
                // We only want to track 2 pointers at most
                if (pointerTracker.currentPointers.length === 2 || !positioningRef.current) {
                    return false;
                }
                event.preventDefault();
                return true;
            },
            move: (previousPointers, changedPointers, event) => {
                onPointerMove(previousPointers, pointerTracker.currentPointers, event);
            },
            end: onEnd
        });
        outerRef.current.addEventListener('wheel', onWheel);

        // Detach listeners
        return () => {
            outerRef.current.removeEventListener('wheel', onWheel);
        };
    }, [positioningRef.current]);

    useLayoutEffect(() => {
        if (!props.startFullbleed) {
            return;
        }
        const forceLayout = () => {
            const positioningRect = positioningRef.current.getBoundingClientRect();
            const outerRect = outerRef.current.getBoundingClientRect();
            const fullScale = outerRect.height / positioningRect.height;
            const offsetX = (-1 * (fullScale * positioningRect.width)) / 2;
            // positioningRect height can be 0. Division by 0 leads to infinity and crashes
            if (isFinite(fullScale)) {
                setTransforms({
                    scale: fullScale,
                    x: offsetX + outerRect.width / 2,
                    y: 0
                });
                setTransforms(
                    keepInBounds({
                        scale: fullScale,
                        x: offsetX + outerRect.width / 2,
                        y: 0
                    })
                );
            } else {
                window.setTimeout(forceLayout, 10);
            }
        };
        forceLayout();
    }, [props.startFullbleed]);

    return (
        <Fragment>
            <div ref={outerRef} className={cx(styles.pinchZoomWrapper, props.className)}>
                <div
                    ref={positioningRef}
                    className={cx(
                        styles.positioningRef,
                        !props.disableFastAndBlurry && styles.positioningRefAllowBlurry
                    )}
                >
                    {props.children}
                </div>
            </div>
        </Fragment>
    );
};

export default VitraPinchZoom;
