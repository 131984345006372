import * as React from 'react';

const SvgV1Zoom = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={14} height={14} viewBox="0 0 14 14" {...props}>
        <g fill="#333">
            <path d="M7.5 0a.5.5 0 0 0 0 1h4.793l-4.64 4.639a.5.5 0 1 0 .708.707L13 1.707V6.5a.5.5 0 0 0 1 0V0H7.5zM6.5 13H1.707l4.639-4.639a.5.5 0 0 0-.707-.707L1 12.293V7.5a.5.5 0 0 0-1 0V14h6.5a.5.5 0 0 0 0-1z" />
        </g>
    </svg>
);

export default SvgV1Zoom;
