/* eslint-disable @typescript-eslint/no-var-requires */

import detectUserActivity from 'common/hooks/detectUserActivity';
import { matchMediaToScreenWidth } from 'common/hooks/useBreakpoints';
import IconVideoFullscreen from 'common/primitives/icons/components/V1VideoFullscreen';
import IconVideoPause from 'common/primitives/icons/components/V1VideoPause';
import IconVideoPlay from 'common/primitives/icons/components/V1VideoPlay';
import IconVideoStop from 'common/primitives/icons/components/V1VideoStop';
import IconEqualizer from 'common/primitives/icons/components/V2Equalizer';
import VitraSpinner from 'common/primitives/spinners/vitraSpinner';
import { colors, fontSizeAndLineHeight, injectBackgroundPicture, rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import Hls from 'hls.js';
import { css, cx } from 'linaria';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { addGaEvent } from '../analytics';
import { Video } from './types';

const screenfull = require('screenfull');

export interface VideoPlayerProps {
    /**
     * ClassName of the wrapper component
     */
    className?: string;

    /**
     * The testId of the Button
     */
    testId?: string;

    /**
     * The video
     */
    video: Video;

    /**
     * If the Video does play on load
     */
    autoPlay?: boolean;

    /**
     * Normally the video dimensions define the
     * width and height of the video element.
     * In some cases we must fit the parent div
     * instead which might have different dimensions
     * This mode is called "cover"
     */
    cover?: boolean;

    /**
     * If the video will loop
     */
    loop?: boolean;

    /**
     * Hides the controls
     */
    hideControls?: boolean;

    /**
     * On Stop Callback
     */
    onStop?: () => void;

    /**
     * On Pause Callback
     */
    onPause?: () => void;

    /**
     * On End Callback
     */
    onEnd?: () => void;
}

const styles = {
    wrapper: css`
        position: relative;
        height: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    `,
    video: css`
        height: 100%;
        width: 100%;
        z-index: 10;
    `,
    wrapperCover: css`
        height: 100%;
    `,
    videoCover: css`
        height: inherit;
        object-fit: cover;
    `,
    poster: css`
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
    `,
    spinner: css`
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    `,
    controls: css`
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        position: absolute;
        bottom: 0;
        background: rgba(51, 51, 51, 0.9);
        width: 100%;
        display: flex;
        /* margin-bottom: 8px; */
    `,
    controlsActive: css`
        opacity: 1;
        transition: opacity 0s linear;
    `,
    controlButton: css`
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        svg path {
            fill: #fff;
        }
        &:hover {
            background: ${colors.black};
        }
    `,
    controlProgress: css`
        background: #fff;
        opacity: 0.2;
        flex: 1;
        margin-left: ${rh(0.5)};
        &:hover {
            cursor: pointer;
        }
    `,
    controlTime: css`
        color: #fff;
        ${fontSizeAndLineHeight('12px', 1.2)}
        text-align: center;
        padding-left: ${rh(0.5)};
        display: flex;
        pointer-events: none;
    `,
    controlProgressCurrent: css`
        /* Otherwise seekposition would be buggy because
        e.target.offset would depend on this div */
        pointer-events: none;
        transition: all 100ms ease-in;
        display: block;
        height: 100%;
        flex: 1;
        background: ${colors.black};
        opacity: 1;
        z-index: 20;
    `,
    equalizerButton: css`
        position: absolute;
        bottom: 20px;
        right: 20px;
        background: white;
        border-radius: 40px;
        z-index: 1;
        width: 49px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 0;
        height: ${rh(1)};
        &:hover {
            cursor: pointer;
        }
    `,
    playButton: css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        pointer-events: none;
        z-index: 20;
        a {
            svg {
                width: 50px;
                height: 50px;
                path {
                    fill: ${colors.black};
                }
            }
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
        }
        &:hover a {
            background: rgba(255, 255, 255, 1);
        }
    `
};

declare interface VideoPlayerControlProps {
    onPlay: (e: any) => void;
    onStop?: (() => void) | null;
    onPause: () => void;
    onSeek: (position: number) => void;
    onFullscreen: () => void;
    isActive: boolean;
    status: string;
    duration: number;
    progress: number;
}

const formatTime = (seconds: number) => (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds;
const getPercentage = (progress: number, durration: number) => {
    const percent = Math.round((100 / durration) * progress);
    return Number.isNaN(percent) ? 0 : percent;
};

const VideoPlayerControl: React.FunctionComponent<VideoPlayerControlProps> = (props) => {
    const duration = props.duration !== Infinity ? formatTime(props.duration) : props.duration;
    const progress = formatTime(props.progress);
    const percent = getPercentage(props.progress, props.duration);
    const { status } = props;

    const seekPosition = (e: any) => {
        const rect = e.target.getBoundingClientRect();
        const elementMouseX = e.clientX - rect.left;
        const elementWidth = e.target.offsetWidth;
        const pc = (elementMouseX / elementWidth) * 100;
        let position = (pc / 100) * props.duration;
        position = Number.isNaN(position) ? 0 : position;
        if (status !== 'idle') {
            props.onSeek(position);
        }
    };

    return (
        <div className={cx(styles.controls, props.isActive && styles.controlsActive)}>
            {status === 'idle' && (
                <div className={styles.controlButton} onClick={props.onPlay}>
                    <IconVideoPlay />
                </div>
            )}
            {status !== 'idle' && (
                <Fragment>
                    {/*
                        In most cases a stop button is not necessary.
                        Only if a specific callback is attached to the stop button.
                    */}
                    {props.onStop && (
                        <div className={styles.controlButton} onClick={props.onStop}>
                            <IconVideoStop />
                        </div>
                    )}
                    {status === 'paused' && (
                        <div className={styles.controlButton} onClick={props.onPlay}>
                            <IconVideoPlay />
                        </div>
                    )}
                    {status !== 'paused' && (
                        <div className={styles.controlButton} onClick={props.onPause}>
                            <IconVideoPause />
                        </div>
                    )}
                </Fragment>
            )}
            <div className={styles.controlTime}>{`${progress} ${duration !== Infinity ? '/ ' + duration : ''}`}</div>
            <div className={styles.controlProgress} onClick={seekPosition}>
                <div style={{ width: `${percent}%` }} className={styles.controlProgressCurrent} />
            </div>
            <div className={styles.controlButton} onClick={props.onFullscreen}>
                <IconVideoFullscreen />
            </div>
        </div>
    );
};

export const VitraVideoPlayer: React.FunctionComponent<VideoPlayerProps> = (props) => {
    const { video, className, cover, hideControls } = props;
    const paddingTop = !cover ? `${((video.height || 1080) / (video.width || 1920)) * 100}%` : 0;
    const videoRef = useRef<HTMLVideoElement>(null);
    const wrapperRef = useRef(null);
    const [status, setStatus] = useState('idle');
    const [progress, setProgress] = useState(0);
    const [initialized, setInitialized] = useState(false);
    const roundedPercent = useRef(0);
    const [duration, setDuration] = useState(0);
    const isActive = detectUserActivity() || status === 'paused';
    // We only allow autoplay on desktop devices
    const breakpointName = matchMediaToScreenWidth();
    const isMobile = ['mobile', 'mobileX', 'tablet'].includes(breakpointName);
    const triggerAutoplay = typeof props.video.src !== 'undefined' && !isMobile && props.autoPlay;

    const videoAnalyticsEvent = (action: string) => {
        const analyticsKey = video.id || video.src;
        addGaEvent({
            eventCategory: 'Video',
            eventAction: action,
            eventLabel: analyticsKey
        });
    };

    // Initially Muted - used to show the paly button and begin from 0 frame
    const [isInitiallyMuted, setIsInitallyMuted] = useState(triggerAutoplay);

    // Duration
    const getDuration = (): number => {
        const videoEl = videoRef!.current;
        if (videoEl) {
            return Number.isNaN(videoEl.duration) ? 0 : Math.round(videoEl.duration);
        }
        return 0;
    };

    // Video Callbacks
    const onReady = () => {
        setStatus('playing');
        setDuration(getDuration());
    };

    const onSeek = (position: number) => {
        const videoEl = videoRef!.current;
        if (videoEl && videoEl.play) {
            if (status === 'playing') {
                videoEl.pause();
            }
            setTimeout(() => {
                if (videoEl) {
                    videoEl.currentTime = position;
                }
            }, 10);
            if (status === 'playing') {
                videoEl.play();
            }
        }
    };

    const onPlay = () => {
        const videoEl = videoRef!.current;
        if (videoEl && videoEl.play) {
            playVideoSrc();
            videoAnalyticsEvent('play');
            setStatus('playing');
        }
    };

    const onPause = () => {
        const videoEl = videoRef!.current;
        if (videoEl && videoEl.pause) {
            videoEl.pause();
        }
        setStatus('paused');
        videoAnalyticsEvent('pause');

        if (props.onPause) {
            props.onPause();
        }
    };

    /*
    The onStop prop makes mostly sense if you wanna attach a action to the stop event
    via a callback. Otherwise pause would be sufficient. We distinguih here between
    a user who might just pause for a short while and is intending to continue watching later on
    and a user who really wants to stop the video and we change the UX, display different views
    afterwards
    */
    const onStop = () => {
        const videoEl = videoRef!.current;
        if (videoEl && videoEl.pause) {
            videoEl.pause();
        }
        setStatus('paused');
        videoAnalyticsEvent('stop');
        if (props.onStop) {
            props.onStop();
        }
    };

    const onFullscreen = () => {
        videoAnalyticsEvent('fullscreen');
        const videoEl = videoRef!.current;
        if (videoEl && screenfull.isEnabled) {
            screenfull.request(videoEl);
        }
    };

    // Toggle Start / Pause
    const togglePlay = (e: any) => {
        const videoEl = videoRef!.current;
        if (e) {
            e.preventDefault();
        }
        if (!videoEl) {
            return false;
        }

        if (isInitiallyMuted) {
            setIsInitallyMuted(false);
            playVideoSrc();
        } else {
            // videoRef
            switch (status) {
                case 'idle':
                    playVideoSrc();
                    break;
                case 'playing':
                    onPause();
                    break;
                case 'paused':
                    onPlay();
                    break;
            }
        }
    };

    const onEnded = () => {
        if (props.loop) {
            onPlay();
        } else {
            videoAnalyticsEvent('progress 100');
            setStatus('paused');
            if (props.onEnd) {
                props.onEnd();
            }
        }
    };

    // Time Update
    const onTimeupdate = () => {
        const videoEl = videoRef!.current;
        if (videoEl && videoEl.currentTime) {
            const videoProgress = Math.round(videoEl.currentTime);
            setProgress(videoProgress);
            const percent = getPercentage(videoProgress, videoEl.duration);
            const currentRoundedPercent = Math.round(percent / 10) * 10;
            if (percent >= roundedPercent.current) {
                roundedPercent.current = currentRoundedPercent + 10;
                videoAnalyticsEvent(`progress ${currentRoundedPercent}`);
            }
        }
    };

    // On change of the video element
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        // Setup Event Handler for Video
        const videoElement = videoRef!.current;
        if (videoElement) {
            videoElement.addEventListener('loadedmetadata', onReady);
            videoElement.addEventListener('timeupdate', onTimeupdate);
            videoElement.addEventListener('ended', onEnded);
        }

        if (triggerAutoplay) {
            playVideoSrc();
        }

        // Cleanup Handler
        return () => {
            if (videoElement) {
                videoElement.removeEventListener('loadedmetadata', onReady);
                videoElement.removeEventListener('timeupdate', onTimeupdate);
                videoElement.removeEventListener('ended', onEnded);
            }
        };
    }, [videoRef.current]);

    // Start the video, this value is only set once
    const playVideoSrc = () => {
        if (!canUseDOM) {
            return;
        }

        let hlsInstance: Hls;

        setStatus('loading');
        const videoElement = videoRef!.current;
        if (!videoElement) {
            return;
        }

        if (initialized) {
            videoElement.play();
            videoAnalyticsEvent('play');
            return;
        }

        // handle native ios
        if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
            videoElement.src = video.src;
            videoElement.play();
            videoAnalyticsEvent('play');
            setInitialized(true);
        } else {
            const breakpointName = matchMediaToScreenWidth();
            const isMobile = ['mobile', 'mobileX', 'tablet'].includes(breakpointName);

            // If we have hls Support
            import('hls.js').then((hls) => {
                if (hls.default.isSupported()) {
                    hlsInstance = new hls.default({
                        autoStartLoad: true,
                        // on mobile we start with the lowest quality
                        // on dekstop with the highest
                        startLevel: isMobile ? 0 : 3
                    });
                    hlsInstance.loadSource(video.src);
                    hlsInstance.attachMedia(videoElement);

                    hlsInstance.on(hls.default.Events.MANIFEST_PARSED, () => {
                        videoElement.play();
                        videoAnalyticsEvent('play');
                        setInitialized(true);
                    });
                }
            });
        }
    };

    const { backgroundImage, backgroundClass } = injectBackgroundPicture(video.poster);
    const showBackgroundImage = ['loading', 'idle', 'stopped'].includes(status);
    const showPlayButton = ['idle', 'stopped'].includes(status) && !isInitiallyMuted;

    return (
        <div
            ref={wrapperRef}
            className={cx(className, styles.wrapper, cover && styles.wrapperCover)}
            style={{ paddingTop }}
        >
            {backgroundImage}
            {isInitiallyMuted && !hideControls && (
                <div onClick={togglePlay} className={styles.equalizerButton}>
                    <IconEqualizer />
                </div>
            )}
            {showPlayButton && !hideControls && (
                <div className={styles.playButton}>
                    <a href="#">
                        <IconVideoPlay />
                    </a>
                </div>
            )}
            <div className={cx(showBackgroundImage && backgroundClass, styles.poster)}>
                {status === 'loading' && <VitraSpinner className={cx('spinner', styles.spinner)} />}
                {props.video.src && (
                    <video
                        onClick={togglePlay}
                        ref={videoRef}
                        autoPlay={triggerAutoplay}
                        className={cx(styles.video, cover && styles.videoCover)}
                        preload="none"
                        muted={isInitiallyMuted && triggerAutoplay}
                    />
                )}
            </div>
            {!isInitiallyMuted && !showPlayButton && !hideControls && (
                <VideoPlayerControl
                    isActive={isActive}
                    onPause={onPause}
                    onSeek={onSeek}
                    onPlay={togglePlay}
                    onStop={props.onStop ? onStop : null}
                    onFullscreen={onFullscreen}
                    duration={duration}
                    progress={progress}
                    status={status}
                />
            )}
        </div>
    );
};

export default VitraVideoPlayer;
