/// <reference types="@types/applepayjs" />

import {
    BffCartResult,
    BffCartResultDelivery,
    BffCartResultMessage,
    BffProductInputProduct,
    QueryGetCartResult
} from 'common/graphql/sdk';
import { Ga4AnalyticsType, ga4Analytics } from 'common/primitives/analytics';
import Button from 'common/primitives/buttons';
import VitraModal from 'common/primitives/modal/vitraModal';
import Translate, { useTranslations } from 'common/primitives/translations';
import { fontStyles } from 'common/styles';
import { CartRowItem } from 'components/cart-v2/components/cartRow';
import { CheckoutConfig, getConfig } from 'components/checkout-v2/config';
import flatten from 'lodash/flatten';
import last from 'lodash/last';
import React, { Fragment, createContext, useContext, useState } from 'react';
import { client, getBffLanguageAndCountry } from './../graphql/client';
import { useConfig } from './useBoostrap';
import { useEvents } from './useEvents';

/**
 * New BFF
 */
type ItemType = 'product' | 'material';

export type CartRevertAction = {
    name: JSX.Element | string;
    action: Link;
};

interface CartSuccessErrorModalProps {
    result: AddToCartResult;
    closeModal: () => void;
}

interface AddToCartResult {
    type: ItemType;
    success: boolean;
    message?: string;
    cartMessages?: BffCartResultMessage[];
}

export const CartSuccessErrorModal: React.FunctionComponent<CartSuccessErrorModalProps> = ({ closeModal, result }) => {
    const shoppingCartUrl = useConfig('cartPageUrl', '/cart');
    const t = {
        modal_product_success: (
            <Translate
                id="cart.modal.product_add_success"
                defaultMessage="The product has been successfully added to your cart."
            />
        ),
        modal_material_success: (
            <Translate
                id="cart.modal.material_add_success"
                defaultMessage="The material has been successfully added to your shopping cart."
            />
        ),
        modal_error: (
            <Translate id="cart.modal.error" defaultMessage="Sorry, we could not add the item to your cart." />
        ),
        modal_messages_error_material_max_qty_exceeded: useTranslations(
            'cart.modal.messages.error_material_max_qty_exceeded',
            'You have reached the maximum amount of material samples.'
        ),
        modal_messages_error_invalid_price: useTranslations('cart.errors.price.invalid', 'Invalid price'),
        modal_messages_error_invalid_lineItem: useTranslations('cart.errors.line_item.invalid', 'Invalid line item'),
        modal_messages_error_invalid_quantity: useTranslations(
            'cart.errors.line_item.invalid_quantity',
            'Invalid quantity'
        ),

        modal_messages_error_unknown_article: (
            <Translate
                id="cart.modal.messages.error_unknown_article"
                defaultMessage="Something went wrong. Unknown article."
            />
        ),
        modal_continue: <Translate id="cart.modal.actions.continue" defaultMessage="Continue shopping" />,
        modal_go_to_checkout: <Translate id="cart.modal.actions.checkout" defaultMessage="Checkout" />,
        modal_messages_error_product_out_of_stock: (
            <Translate id="cart.errors.product.out_of_stock" defaultMessage="Product is out of stock" />
        )
    };

    return (
        <VitraModal type="small" closeOnClickOutside={true} onClose={closeModal}>
            <div style={{ maxWidth: 280 }}>
                <p className={fontStyles.p} style={{ textAlign: 'center' }}>
                    {result.success && (
                        <Fragment>
                            {result.type === 'product' && t.modal_product_success}
                            {result.type === 'material' && t.modal_material_success}
                        </Fragment>
                    )}
                </p>
                {result.cartMessages && result.cartMessages.length > 0 && (
                    <p>
                        {result.cartMessages.map((m) => {
                            if (t[`modal_messages_${m.message}` as keyof typeof t]) {
                                return <div>{t[`modal_messages_${m.message}` as keyof typeof t]}</div>;
                            } else {
                                return <div>{m.message}</div>;
                            }
                        })}
                    </p>
                )}
                {!result.success && result.message && t[`modal_messages_${result.message}` as keyof typeof t] && (
                    <p className={fontStyles.p} style={{ textAlign: 'center' }}>
                        {t[`modal_messages_${result.message}` as keyof typeof t]}
                    </p>
                )}
                {!result.success && result.cartMessages && result.cartMessages?.length === 0 && (
                    <p className={fontStyles.p} style={{ textAlign: 'center' }}>
                        {t.modal_error}
                    </p>
                )}
                {result.success && (
                    <Fragment>
                        <Button onClick={closeModal} testId="cartButtonBack" type="blackBorder">
                            {t.modal_continue}
                        </Button>
                        <Button type="black" link={{ href: shoppingCartUrl }} testId="cartButtonToCheckout">
                            {t.modal_go_to_checkout}
                        </Button>
                    </Fragment>
                )}
            </div>
        </VitraModal>
    );
};

export enum CART_STATUS {
    LOADING,
    LOADED,
    ERROR
}

export type CartContext = {
    hasCart: boolean;
    config: CheckoutConfig;

    cartStatus: CART_STATUS;
    cartTrace?: string;

    modal?: React.ReactElement | null;

    notification?: JSX.Element;
    revertAction?: CartRevertAction;

    cartCount: number;

    cartContent?: QueryGetCartResult;
    addItem: (sku: string, type: ItemType) => Promise<void>;
    createShare: () => Promise<string>;
    changeItem: (lineItemId: string, qty: number) => Promise<void>;
    removeFromCartWithRevert: (
        item: CartRowItem,
        revertName?: string | JSX.Element,
        callback?: () => void
    ) => Promise<void>;
    loadCart: (id?: string) => Promise<void>;
    reconfigureItem: (lineItemId: string, newSku: string, quantity: number) => Promise<void>;
    removeDiscountCode: (code: string) => Promise<void>;
    addDiscountCode: (code: string) => Promise<void>;
};

export const CartBffContext = createContext<CartContext>({} as CartContext);
export const useCartBffContext = (): CartContext => useContext(CartBffContext);

const findLineItem = (lineItemId: string, cartContent: QueryGetCartResult | undefined) => {
    const lineItems = flatten((cartContent as BffCartResult).cartItems.map((el) => el.lineItems));
    return lineItems.find((li) => li?.id === lineItemId);
};

export const CartBffProvider: React.FunctionComponent = ({ children }) => {
    const [addToCartResult, setAddToCartResult] = useState<AddToCartResult | null>(null);
    const { country, language, market } = getBffLanguageAndCountry();
    const checkoutConfig = getConfig(country);

    const [cartContent, setCartContent] = useState<QueryGetCartResult>();

    const closeModal = () => {
        setAddToCartResult(null);
    };

    /**
     * Modal and Notificaitons
     */
    const modal = addToCartResult ? <CartSuccessErrorModal result={addToCartResult} closeModal={closeModal} /> : null;

    /**
     * Triggers the notification banner
     */
    const [notification] = useState<JSX.Element | undefined>();

    /**
     * Shows the revert Modal
     */
    const [revertAction, setRevertAction] = useState<CartRevertAction | undefined>();

    const t = {
        checkout_actions_revert: useTranslations('cart.actions.revert', 'Undo')
    };

    // We use local state to keep track of the cart count in several components via events
    const [cartCount, setCartCount] = useState<number>(0);
    const emit = useEvents('cart', { setCartCount });

    /**
     * Common effect after a cart response
     * @param cartReponse
     */
    const setContentAndCount = (cartReponse: QueryGetCartResult) => {
        if (cartReponse.__typename === 'BffCartResult') {
            setCartContent(cartReponse);
            emit('setCartCount', cartReponse.count);
        }
    };

    const [cartStatus, setCartStatus] = useState<CART_STATUS>(CART_STATUS.LOADING);
    const [cartTrace, setCartTrace] = useState<string>('');

    /**
     * Shares the cart. The cart is copied to a shopping list
     * and fetchable via the id
     */
    const createShare = async (): Promise<string> => {
        try {
            const { createCartShare: response } = await client.createCartShare({
                country
            });
            if (response?.__typename === 'BffCartShareResult') {
                return response.url ?? '';
            }
        } catch (err) {
            // setCartStatus(CART_STATUS.ERROR);
        }
        return '';
    };

    /**
     * Add Item to cart
     * @param sku
     */
    const addItem = async (sku: string, type: ItemType): Promise<void> => {
        try {
            const req = {
                sku,
                language,
                country
            };
            const { addToCart: response } = await client.addToCartWithoutPayment(req);
            switch (response?.__typename) {
                case 'BffCartResult':
                    ga4Analytics(Ga4AnalyticsType.addToCart, { data: response.anayltics, sku });
                    setContentAndCount(response);
                    setAddToCartResult({
                        type,
                        success: true
                    });
                    break;
                case 'BffProxyError':
                    setCartTrace(response.traceId);
                    setAddToCartResult({
                        type,
                        success: false,
                        message: response.message,
                        cartMessages: response.cart?.messages || []
                    });
                    break;
                default:
            }
        } catch (err) {
            setCartStatus(CART_STATUS.ERROR);
            setAddToCartResult({
                type,
                success: false
            });
        }
    };

    const changeItem = async (lineItemId: string, quantity: number): Promise<void> => {
        try {
            const dataBefore = { ...(cartContent as BffCartResult).anayltics };
            const lineItem = findLineItem(lineItemId, cartContent);

            const { changeCart: response } = await client.changeCart({
                lineItemId,
                quantity,
                country
            });

            switch (response?.__typename) {
                case 'BffCartResult':
                    setContentAndCount(response);
                    updateProductDelivery(response);
                    if (quantity === 0) {
                        ga4Analytics(Ga4AnalyticsType.removeFromCart, {
                            data: response.anayltics,
                            dataBefore,
                            sku: lineItem?.sku
                        });
                    }
                    if (lineItem?.quantity) {
                        if (quantity > lineItem?.quantity) {
                            ga4Analytics(Ga4AnalyticsType.addToCart, {
                                data: response.anayltics,
                                dataBefore,
                                sku: lineItem?.sku
                            });
                        }
                        if (quantity < lineItem?.quantity) {
                            ga4Analytics(Ga4AnalyticsType.removeFromCart, {
                                data: response.anayltics,
                                dataBefore,
                                sku: lineItem?.sku
                            });
                        }
                    }
                    break;
                case 'BffProxyError':
                    setCartTrace(response.traceId);
                    if (response.cart) {
                        setContentAndCount(response.cart);
                    }
                    break;
                default:
            }
        } catch (err) {
            // setCartStatus(CART_STATUS.ERROR);
        }
    };

    const addDiscountCode = async (code: string): Promise<void> => {
        const { addDiscountCodeToCart: response } = await client.addDiscountCodeToCart({
            code,
            country
        });
        switch (response?.__typename) {
            case 'BffCartResult':
                setContentAndCount(response);
                updateProductDelivery(response);
                break;
            case 'BffProxyError':
                if (response.message === 'error_discount_invalid') {
                    throw response.message;
                } else {
                    setCartTrace(response.traceId);
                    throw response.message;
                }
                break;
            default:
        }
    };

    const removeDiscountCode = async (code: string): Promise<void> => {
        try {
            const { removeDiscountCodeFromCart: response } = await client.removeDiscountCodeFromCart({
                code,
                country
            });
            switch (response?.__typename) {
                case 'BffCartResult':
                    setContentAndCount(response);
                    updateProductDelivery(response);
                    break;
                case 'BffProxyError':
                    setCartTrace(response.traceId);
                    if (response.cart) {
                        setContentAndCount(response.cart);
                    }
                    break;
                default:
            }
        } catch (err) {
            // setCartStatus(CART_STATUS.ERROR);
        }
    };
    const removeFromCartWithRevert = async (
        item: CartRowItem,
        revertName?: JSX.Element | string,
        callback?: () => void
    ) => {
        try {
            const dataBefore = { ...(cartContent as BffCartResult).anayltics };
            const { changeCart: response } = await client.changeCart({
                lineItemId: item.id,
                quantity: 0,
                country
            });

            switch (response?.__typename) {
                case 'BffCartResult':
                    setContentAndCount(response);
                    ga4Analytics(Ga4AnalyticsType.removeFromCart, {
                        data: response.anayltics,
                        dataBefore,
                        sku: item.sku
                    });

                    break;
                case 'BffProxyError':
                    setCartTrace(response.traceId);
                    if (response.cart) {
                        setContentAndCount(response.cart);
                    }
                    break;
                default:
            }

            if (revertName) {
                setRevertAction({
                    name: revertName,
                    action: {
                        href: '#',
                        name: t.checkout_actions_revert,
                        onClick: async (e: any) => {
                            e.preventDefault();
                            setRevertAction(undefined);
                            try {
                                const { addToCart } = await client.addToCart({
                                    sku: item.sku,
                                    quantity: item.quantity,
                                    language,
                                    country
                                });
                                if (addToCart?.__typename === 'BffCartResult') {
                                    setContentAndCount(addToCart);
                                    updateProductDelivery(addToCart);
                                    ga4Analytics(Ga4AnalyticsType.addToCart, {
                                        data: addToCart.anayltics,
                                        dataBefore,
                                        sku: item.sku
                                    });
                                }
                                if (callback) {
                                    callback();
                                }
                            } catch (err) {
                                // we ignore errors
                            }
                        }
                    }
                });
            }
        } catch (err) {
            // we ignore errors
        }
    };

    const reconfigureItem = async (lineItemId: string, newSku: string, quantity: number): Promise<void> => {
        try {
            await client.changeCart({
                lineItemId,
                quantity: 0,
                country
            });

            const { addToCart: response } = await client.addToCart({
                sku: newSku,
                quantity,
                language,
                country
            });
            switch (response?.__typename) {
                case 'BffCartResult':
                    setContentAndCount(response);
                    updateProductDelivery(response);
                    break;
                case 'BffProxyError':
                    setCartTrace(response.traceId);
                    setAddToCartResult({
                        type: 'product',
                        success: false,
                        message: response.message,
                        cartMessages: response.cart?.messages || []
                    });

                    break;
                default:
            }
        } catch (err) {
            // setCartStatus(CART_STATUS.ERROR);
        }
    };

    /**
     * Loads the current cart
     */
    const loadCart = async (): Promise<void> => {
        // hasCart is set to false when the country is not a shop country
        if (!checkoutConfig.hasCart) {
            return;
        }
        try {
            const cartPath = window?.location?.pathname;
            setCartStatus(CART_STATUS.LOADING);
            const { getCart } = await client.getCart({
                country: country,
                language: language
            });
            switch (getCart?.__typename) {
                case 'BffCartResult':
                    setCartStatus(CART_STATUS.LOADED);
                    setContentAndCount(getCart);
                    if (cartPath && cartPath.endsWith('/cart')) {
                        ga4Analytics(Ga4AnalyticsType.viewCart, { data: getCart.anayltics });
                    }
                    updateProductDelivery(getCart);
                    break;
                case 'Error':
                    setCartStatus(CART_STATUS.ERROR);
                    setContentAndCount(getCart);
                    break;
                case 'BffProxyError':
                    setCartStatus(CART_STATUS.ERROR);
                    setCartTrace(getCart.traceId);
                    break;
                default:
                    setCartStatus(CART_STATUS.ERROR);
                    break;
            }
        } catch (err) {
            setCartStatus(CART_STATUS.ERROR);
        }
    };

    /**
     * Because CT only calculates the delivery per cartItems, we request this per line item
     */
    const updateProductDelivery = async (cartContent: BffCartResult) => {
        // only do this on the cart site
        if (last(location.pathname.split('/')) !== 'cart') {
            return;
        }
        if (cartContent?.__typename !== 'BffCartResult') {
            return;
        }
        const cart = { ...cartContent };
        const productInputs: BffProductInputProduct[] = [];

        cart.cartItems.forEach((item) => {
            item.lineItems?.forEach((lineItem) => {
                productInputs.push({
                    sku: lineItem.sku,
                    quantity: lineItem.quantity
                });
            });
        });

        const { getProducts } = await client.getProductsDelivery({
            market,
            products: productInputs
        });

        cart.cartItems = cart.cartItems.map((item) => {
            item.lineItems = item.lineItems?.map((lineItem) => {
                lineItem.delivery = getProducts.find((product) => {
                    return product.sku === lineItem.sku;
                })?.delivery as BffCartResultDelivery;
                return lineItem;
            });
            return item;
        });

        setCartContent(cart);
    };

    const providerValues = {
        hasCart: checkoutConfig.hasCart,
        config: checkoutConfig,
        addItem,
        cartContent,
        cartCount,
        cartStatus,
        cartTrace,
        changeItem,
        createShare,
        reconfigureItem,
        removeFromCartWithRevert,
        removeDiscountCode,
        addDiscountCode,
        loadCart,
        modal,
        notification,
        revertAction
    };
    return (
        <CartBffContext.Provider value={providerValues}>
            {children}
            {modal}
        </CartBffContext.Provider>
    );
};
