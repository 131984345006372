import { BffDeliveryType, BffProduct } from 'common/graphql/sdk';
import { ConfiguratorFooterButtonState, ConfiguratorFooterButtonType } from 'components/configure-v3/configureEnums';
import { canUseDOM } from 'exenv';
import { useEffect, useRef, useState } from 'react';
import { client, getBffLanguageAndCountry } from './../graphql/client';
import { emitter } from './useEvents';

interface ConfiguratorHook {
    product?: BffProduct;
    buttonState: ConfiguratorFooterButtonState;
    buttonType: ConfiguratorFooterButtonType;
    hasError: boolean;
    lastTrace: string;
    loadSingleConfiguration: (sku: string) => Promise<void>;
    loadMultipleConfiguration: (skus: string[]) => Promise<BffProduct[]>;
    setButtonState: (state: ConfiguratorFooterButtonState) => void;
}

export enum ConfiguratorHookStatus {
    idle,
    loading,
    success,
    error
}

/**
 * Checks wether there is a Configurator on the Page
 */
export const hasConfigurator = (): boolean => {
    if (!canUseDOM) {
        return false;
    }
    return !!document.querySelectorAll('[data-type="vitra--configure"]')[0];
};

export const useConfigurator = (onLoad?: (pid: string) => void, cacheKey?: string): ConfiguratorHook => {
    const { language, market } = getBffLanguageAndCountry();
    const [product, setProduct] = useState<BffProduct>();
    const cache: any = useRef({});
    const [hasError, setHasError] = useState<boolean>(false);
    const [lastTrace, setLastTrace] = useState<string>('');
    const [buttonState, setButtonState] = useState<ConfiguratorFooterButtonState>(
        ConfiguratorFooterButtonState.disabled
    );
    const [buttonType, setButtonType] = useState<ConfiguratorFooterButtonType>(ConfiguratorFooterButtonType.cart);

    // Cache Bust
    useEffect(() => {
        cache.current = {};
    }, [cacheKey]);

    const setError = () => {
        setProduct(undefined);
        setButtonState(ConfiguratorFooterButtonState.disabled);
        setButtonType(ConfiguratorFooterButtonType.none);
        setHasError(true);
    };

    const loadMultipleConfiguration = async (skus: string[]): Promise<BffProduct[]> => {
        setButtonState(ConfiguratorFooterButtonState.loading);
        setHasError(false);

        const resp = await client.getProductsAndConfiguration({
            market,
            language,
            skus
        });
        if ((resp as any).trace?.traceId) {
            setLastTrace((resp as any).trace?.traceId);
        }
        const { getProductsAndConfiguration } = resp;
        if (
            getProductsAndConfiguration?.__typename === 'BffProxyError' ||
            getProductsAndConfiguration?.__typename === 'Error'
        ) {
            setError();
            return [];
        }
        setButtonState(ConfiguratorFooterButtonState.active);
        return (getProductsAndConfiguration?.data || []) as BffProduct[];
    };

    /**
     * Loads the new configuration
     * @param productId the product Id
     */
    const loadSingleConfiguration = async (sku: string): Promise<void> => {
        // this is here is we can refresh it on the fly
        const { language, market } = getBffLanguageAndCountry();

        setButtonState(ConfiguratorFooterButtonState.loading);
        setHasError(false);

        const resp = await client.getProductsAndConfiguration({
            market,
            language,
            skus: [sku]
        });

        if ((resp as any).trace?.traceId) {
            setLastTrace((resp as any).trace?.traceId);
        }
        const { getProductsAndConfiguration } = resp;

        if (
            getProductsAndConfiguration?.__typename === 'BffProxyError' ||
            getProductsAndConfiguration?.__typename === 'Error' ||
            getProductsAndConfiguration?.data.length !== 1
        ) {
            setError();
            return;
        }
        const cProduct = getProductsAndConfiguration.data[0] as BffProduct;
        setProduct(cProduct);

        const showAddToCart =
            cProduct.delivery && cProduct.delivery.type !== BffDeliveryType.NotAvailable && cProduct.showAddToCart;
        setButtonType(showAddToCart ? ConfiguratorFooterButtonType.cart : ConfiguratorFooterButtonType.wishlist);

        setButtonState(ConfiguratorFooterButtonState.active);
        if (cProduct && cProduct.analytics) {
            emitter.emit('cart:productDetailView', cProduct.analytics);
        }
    };

    return {
        product,
        loadSingleConfiguration,
        loadMultipleConfiguration,
        hasError,
        lastTrace,
        setButtonState,
        buttonState,
        buttonType
    };
};
