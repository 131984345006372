// tslint:disable max-classes-per-file

import VitraLink from 'common/primitives/links/vitraLink';
import VitraSpinnerDots from 'common/primitives/spinners/vitraSpinnerDots';
import { colors, fontSizeAndLineHeight, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

interface ButtonProp {
    /**
     * The html id of the Button
     */
    id?: string;

    /**
     * A link Prop the button is linking to
     */
    link?: Link;

    /**
     * Link Analytics Data
     */
    analyticsData?: any;

    /**
     * The testId used for integration testing
     */
    testId: string;

    /**
     * If the Button is disabled
     */
    disabled?: boolean;

    /**
     * If the Button is loading
     */
    isLoading?: boolean;

    /**
     * The button style type
     */
    type?: 'black' | 'white' | 'blackBorder' | 'whiteBorder' | 'transparent';

    /**
     * THe button size
     */
    size?: 'regular' | 'small';

    /**
     * The html type of the button
     */
    buttonType?: 'button' | 'submit';

    /**
     * Additional Button ClassName
     */
    className?: string;

    /**
     * Additional style
     */
    style?: any;

    /**
     * A optinal onClick Handler
     */
    onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;

    /**
     * onMouseOver
     */
    onMouseOver?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;

    /**
     * onMouseOut
     */
    onMouseOut?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;

    onFocus?: React.FocusEventHandler<HTMLElement>;
}

type ButtonPropBlank = Omit<ButtonProp, 'buttonType' | 'size' | 'type'>;

interface ButtonGroupProp {
    /**
     * Additional Button ClassName
     */
    className?: string;
    /**
     * centers the buttons with the container
     * and add a max-width
     */
    center?: boolean;
}

interface ButtonGroupProp {
    /**
     * Additional Button ClassName
     */
    className?: string;
    /**
     * centers the buttons with the container
     * and add a max-width
     */
    center?: boolean;
}

interface ButtonRowProps {
    /**
     * Additional ButtonRow ClassName
     */
    className?: string;
}
export const styles = {
    resetButton: css`
        appearance: none;
        border: none;
        margin: 0;
        text-decoration: none;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        outline-offset: 2px;

        &:not(:focus) {
            outline: none;
        }
    `,
    // The follwing rule must ONLY be applied to <button> tags!!!!
    resetButtonTagWebkit: css`
        -webkit-font-smoothing: antialiased;
    `,
    button: css`
        font-family: 'vitraFutura';
        transition: background 100ms linear, transform 150ms ease;
        ${fontSizeAndLineHeight('15px', 1)}
        padding: calc(${rh(0.25)} + 1px) 21px;
        margin-top: calc(${rh(0.25)} - 1px);
        margin-bottom: calc(${rh(0.25)} - 1px);
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        color: ${colors.black};
        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
        &:active {
            transform: scale(0.98);
        }
        &:disabled {
            cursor: not-allowed;
        }
    `,
    buttonSizeRegular: css`
        font-size: 14px;
        letter-spacing: 2.1px;
        min-height: 50px;
        ${media.desktopAndUp} {
            min-width: 230px;
        }
    `,
    buttonSizeSmall: css`
        padding: ${rh(0.125)} 12px;
        font-size: 12px;
        letter-spacing: 1.2px;
    `,
    buttonPositionCenter: css`
        display: flex;
        justify-content: center;
        align-items: center;
        a,
        button {
            width: auto;
        }
    `,
    transparent: css`
        color: ${colors.black};
        background: transparent;
        &:hover {
            background: transparent;
            color: ${colors.primary};
            svg {
                fill: ${colors.primary};
            }
        }
        &:disabled {
            background: ${colors.blackDisabled};
        }
        svg {
            fill: ${colors.black};
        }
    `,
    transparentDisabled: css`
        &:hover {
            cursor: not-allowed;
            color: ${colors.black};
            svg {
                fill: ${colors.black};
            }
        }
    `,
    black: css`
        color: #fff;
        background: ${colors.black};
        &:hover {
            background: ${colors.blackHover};
        }
        &:disabled {
            background: ${colors.blackDisabled};
        }
        svg {
            fill: #fff;
        }
    `,
    blackDisabled: css`
        &:hover {
            cursor: not-allowed;
        }
        background: ${colors.blackDisabled} !important;
    `,
    blackLoading: css`
        &:hover,
        &:disabled {
            background: ${colors.black};
        }
    `,
    blank: css`
        text-align: left;
        font-family: 'vitraFutura';
        ${fontSizeAndLineHeight('17px', 1)}
        color: ${colors.black};
        background: ${colors.white};
        &:hover {
            color: ${colors.primary};
            svg {
                fill: ${colors.primary};
            }
        }
        &:disabled {
            background: ${colors.blankDisabled};
            opacity: 0.5;
            color: ${colors.black};
        }
        svg {
            fill: ${colors.black};
        }
    `,
    blankDisabled: css`
        &:hover {
            cursor: not-allowed;
        }
        background: ${colors.blankDisabled} !important;
    `,
    blankLoading: css`
        &:hover,
        &:disabled {
            background: ${colors.black};
        }
    `,
    white: css`
        color: ${colors.black};
        background: ${colors.white};
        &:hover {
            background: ${colors.whiteHover};
        }
        &:disabled {
            background: ${colors.whiteDisabled};
        }
        svg {
            fill: ${colors.black};
        }
    `,
    whiteDisabled: css`
        &:hover {
            cursor: not-allowed;
        }
        background: ${colors.whiteDisabled} !important;
    `,
    whiteLoading: css`
        &:hover,
        &:disabled {
            background: ${colors.white};
        }
    `,
    blackBorder: css`
        color: ${colors.black};
        background: transparent;
        &:hover {
            color: #fff;
            background: ${colors.black};
            svg {
                fill: #fff;
            }
        }
        svg {
            fill: ${colors.black};
        }
    `,
    blackBorderDisabled: css`
        &:hover {
            cursor: not-allowed;
        }
    `,
    blackBorderLoading: css`
        &:hover,
        &:disabled {
            background: transparent;
        }
    `,
    blackBorderSizeRegular: css`
        box-shadow: inset 0 2px 0px ${colors.black}, inset 0px -2px 0 ${colors.black};
        &:disabled {
            color: ${colors.blackDisabled};
            box-shadow: inset 0 2px 0px ${colors.blackDisabled}, inset 0px -2px 0 ${colors.blackDisabled};
            &:hover {
                background: transparent;
            }
        }
    `,
    blackBorderSizeSmall: css`
        box-shadow: inset 0 1px 0px ${colors.black}, inset 0px -1px 0 ${colors.black};
        &:disabled {
            color: ${colors.blackDisabled};
            box-shadow: inset 0 1px 0px ${colors.blackDisabled}, inset 0px -1px 0 ${colors.blackDisabled};
            &:hover {
                background: transparent;
            }
        }
    `,
    whiteBorder: css`
        color: #fff;
        background: transparent;
        &:hover {
            color: ${colors.black};
            background: #fff;
            svg {
                fill: ${colors.black};
            }
        }
        svg {
            fill: #fff;
        }
    `,
    whiteBorderDisabled: css`
        &:hover {
            cursor: not-allowed;
        }
    `,
    whiteBorderSizeRegular: css`
        box-shadow: inset 0 2px 0px ${colors.white}, inset 0px -2px 0 ${colors.white};
        &:disabled {
            color: ${colors.whiteDisabled};
            box-shadow: inset 0 2px 0px ${colors.whiteDisabled}, inset 0px -2px 0 ${colors.whiteDisabled};
            &:hover {
                background: transparent;
            }
        }
    `,
    whiteBorderSizeSmall: css`
        box-shadow: inset 0 1px 0px ${colors.white}, inset 0px -1px 0 ${colors.white};
        &:disabled {
            color: ${colors.whiteDisabled};
            box-shadow: inset 0 1px 0px ${colors.whiteDisabled}, inset 0px -1px 0 ${colors.whiteDisabled};
            &:hover {
                background: transparent;
            }
        }
    `,
    buttonGroup: css`
        /* Hint margin Bottom must be defined within the wrapper */
        width: 100%;
        display: block;
        a,
        button {
            margin-bottom: 0;
            margin-top: 0;
        }
        a.button__whiteBorder:not(:last-child),
        button.button__whiteBorder:not(:last-child) {
            box-shadow: inset 0 2px 0px white, inset 0px -2px 0 rgba(0, 0, 0, 0);
        }
        a.button__blackBorder:not(:last-child),
        button.button__blackBorder:not(:last-child) {
            box-shadow: inset 0 2px 0px ${colors.black}, inset 0px -2px 0 rgba(0, 0, 0, 0);
        }
    `,
    buttonGroupCenter: css`
        ${media.desktopAndUp} {
            margin-left: auto;
            margin-right: auto;
        }
        ${media.desktopAndUp} {
            a,
            button {
                display: block;
                width: 100%;
                padding-left: ${rh(1)};
                padding-right: ${rh(1)};
            }
        }
    `,
    buttonRow: css`
        display: flex;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        && {
            > * {
                margin-left: ${rh(0.25)};
                margin-right: ${rh(0.25)};
                width: auto;
            }
        }
    `,

    buttonInner: css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        min-width: 30px;
        ${'' /* min-width, otherwise the svg icons would shrink */}
    `,
    buttonInnerBlank: css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    `
};

export class ButtonGroup extends React.Component<ButtonGroupProp> {
    public render(): JSX.Element {
        const { className, center } = this.props;
        const groupClass = cx(styles.buttonGroup, className, center && styles.buttonGroupCenter);
        return <div className={`button__group ${groupClass}`}>{this.props.children}</div>;
    }
}

export const ButtonCenter: React.FunctionComponent = ({ children }) => {
    return <div className={styles.buttonPositionCenter}>{children}</div>;
};

export const ButtonRow: React.FunctionComponent<ButtonRowProps> = ({ children, className }) => {
    return <div className={cx(styles.buttonRow, className)}>{children}</div>;
};

export const BlankButton: React.FunctionComponent<ButtonPropBlank> = ({
    id,
    link,
    className,
    onClick,
    onMouseOut,
    onMouseOver,
    analyticsData,
    onFocus,
    testId,
    children,
    isLoading,
    disabled,
    style
}) => {
    const buttonClass = cx(
        styles.resetButton,
        styles.blank,
        isLoading && styles.blankLoading,
        disabled && styles.blankDisabled,
        className
    );
    // If we have a link, we return a Link as a Button
    if (link) {
        return (
            <VitraLink
                role="button"
                id={id}
                testId={testId}
                className={cx(buttonClass)}
                onClick={!disabled ? onClick : (e) => e.preventDefault()}
                onFocus={!disabled ? onFocus : undefined}
                analyticsData={analyticsData}
                {...link}
            >
                <div className={styles.buttonInner}>{children || link.name}</div>
            </VitraLink>
        );
    }
    return (
        <button
            id={id}
            data-testid={testId}
            type={'button'}
            onClick={onClick}
            disabled={disabled || isLoading}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            onFocus={onFocus}
            className={cx(buttonClass, styles.resetButtonTagWebkit)}
            style={style}
            {...analyticsData}
        >
            <div className={styles.buttonInner}>
                {isLoading && <VitraSpinnerDots type="black" />}
                {!isLoading && children}
            </div>
        </button>
    );
};

const Button: React.FunctionComponent<ButtonProp> = ({
    link,
    id,
    className,
    onClick,
    onMouseOut,
    onMouseOver,
    analyticsData,
    onFocus,
    testId,
    type = 'black',
    children,
    size,
    buttonType,
    isLoading,
    disabled = false,
    style
}) => {
    const classNameSizeString = size === 'small' ? 'Small' : 'Regular';
    const buttonClass = cx(
        `button__${type}`,
        styles.resetButton,
        styles.button,
        type && (styles as any)[type],
        type && (styles as any)[`buttonSize${classNameSizeString}`],
        type && (styles as any)[`${type}Size${classNameSizeString}`],
        type && isLoading && (styles as any)[`${type}Loading`],
        type && disabled && (styles as any)[`${type}Disabled`],
        className
    );

    // Loading color
    const loadingColor = type === 'black' || type === 'whiteBorder' ? 'white' : 'black';

    // If we have a link, we return a Link as a Button
    if (link) {
        return (
            <VitraLink
                role="button"
                id={id}
                testId={testId}
                className={cx(buttonClass)}
                onClick={!disabled ? onClick : (e) => e.preventDefault()}
                onFocus={!disabled ? onFocus : undefined}
                analyticsData={analyticsData}
                {...link}
            >
                <div className={styles.buttonInner}>{children || link.name}</div>
            </VitraLink>
        );
    }

    return (
        <button
            id={id}
            data-testid={testId}
            type={buttonType || 'button'}
            onClick={onClick}
            disabled={disabled || isLoading}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            onFocus={onFocus}
            className={cx(buttonClass, styles.resetButtonTagWebkit)}
            style={style}
            {...analyticsData}
        >
            <div className={styles.buttonInner}>
                {isLoading && <VitraSpinnerDots type={loadingColor as any} />}
                {!isLoading && children}
            </div>
        </button>
    );
};

export default Button;
