import useToggle from 'common/hooks/useToggle';
import { colors, injectBackgroundPicture } from 'common/styles';
import sortImageVariations from 'common/utils/sortImageVariations';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { Fragment, useEffect, useRef } from 'react';

interface PictureProps {
    /**
     * The ID of the image
     */
    id?: string;
    /**
     * A forward ref for the image
     */
    ref?: any;
    /**
     * A Classname for the <picture> Tag
     */
    className?: string;
    /**
     * The Image
     */
    image: Image;
    /**
     * If the Picture is lazy loaded
     */
    lazy?: boolean;
    /**
     * Disable Mouse Down... e.g. Useful to disable image dragging in the swiper comp
     */
    preventMouseDown?: boolean;
    /**
     * Set's the image as the
     * background and retruns a div
     */
    background?: boolean;
    /**
     * Explicity defines the alt tag
     */
    alt?: string;
}

const styles = {
    picture: css`
        display: block;
        width: 100%;
        height: auto;
        font-size: 0;
    `,
    placeholderWrap: css`
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
    `,
    placeholder: css`
        background: ${colors.lightgrey};
    `
};

const VitraPicture: React.FunctionComponent<PictureProps> = ({
    className,
    background,
    id,
    ref,
    image,
    lazy,
    preventMouseDown,
    alt
}) => {
    if (!image) {
        return <div />;
    }
    const loadingeRef = useRef();
    const [renderImage, toggleRenderImage] = useToggle(lazy ? false : true);
    const srcSet =
        (typeof image.srcSet !== 'undefined' && sortImageVariations(image.srcSet, { type: 'picture' })) || [];
    const firstImage = srcSet[0] || image;

    if (background) {
        const { backgroundImage, backgroundClass } = injectBackgroundPicture(image);
        return (
            <Fragment>
                {backgroundImage}
                <div className={cx(className, backgroundClass)} />
            </Fragment>
        );
    }

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        if ('IntersectionObserver' in window && lazy) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((element) => {
                        if (element.isIntersecting) {
                            toggleRenderImage(true);
                            observer.unobserve(element.target);
                            observer.disconnect();
                        }
                    });
                },
                {
                    rootMargin: '50px 0px',
                    threshold: 0.01
                }
            );

            if (loadingeRef && loadingeRef.current) {
                observer.observe(loadingeRef.current as any);
            }

            return () => {
                observer.disconnect();
            };
        } else {
            toggleRenderImage(true);
        }
    }, [loadingeRef]);

    if (renderImage) {
        return (
            <picture
                ref={ref}
                id={id}
                className={className}
                onMouseDown={preventMouseDown ? (e) => e.preventDefault() : (e) => e}
            >
                {srcSet.map((s) => {
                    return <source media={s.mq} srcSet={s.src} data-src-height={s.height} data-src-width={s.width} />;
                })}
                <img
                    src={firstImage.src}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        if (image.fallback && currentTarget.src !== image.fallback) {
                            currentTarget.src = image.fallback;
                        }
                    }}
                    alt={alt || image.alt || ''}
                    width={image.width}
                    height={image.height}
                    className={cx(styles.picture)}
                />
            </picture>
        );
    } else {
        return (
            <div ref={loadingeRef as any} className={cx('lazy__image', styles.placeholderWrap, className)}>
                <div
                    className={cx('image__placeholder', styles.placeholder)}
                    style={{ paddingBottom: `${(image.height / image.width) * 100}%` }}
                />
            </div>
        );
    }
};
export default VitraPicture;
