import { googleApiKeys } from 'common/utils/geo';
import GoogleMap from 'google-map-react';
import { css, cx } from 'linaria';
import defaultsDeep from 'lodash/defaultsDeep';
import supercluster from 'points-cluster';
import React, { useEffect, useRef, useState } from 'react';
import { FindVitraCoords, MapMarker, MapState } from '../find-vitra-types';
import CommonMapMarker from './commonMapMarker';
import CommonMapPosition from './commonMapPosition';

interface CommonMapProps {
    /**
     * An additional classname
     */
    className?: string;

    /**
     * Pass a click handler to each marker which will receive the marker id
     */
    onMapMarkerClick?: (id: string) => void;

    /**
     * Callback to get information of visible markers/locations
     */
    onReportVisibleMarkers?: (markers: MapMarker[]) => void;

    /**
     * The center of the map. If not given and markers present the
     * map will center on the markers
     */
    center?: FindVitraCoords;

    /**
     * The user/client position
     * if present it will be included in the mapBounds Function
     * and a user position icon will be shown on the map
     */
    position?: FindVitraCoords;

    /** Display the user position */
    showPosition?: boolean;

    /**
     * Setting the default zoom of the map
     */
    defaultZoom?: number;

    /**
     * An array of markers/locations to display on the map
     */
    markers?: MapMarker[];

    /**
     * Options for google-map-react
     */
    options?: any;

    /**
     * Options for google-map-react
     */
    useClustering?: boolean;
}

export const styles = {
    wrapper: css`
        width: 100%;
        height: 100%;
    `
};

/**
 *  Map Component
 *  @param {string} className
 *  @param {() =>{}} onMapMarkerClick
 *  @param {any} center
 *  @param {any} position
 *  @param {boolean} showPosition
 *  @param {number} defaultZoom
 *  @param {any} markers
 *  @param {any} options
 *  @param {boolean} useClustering
 */
const CommonMap: React.FunctionComponent<CommonMapProps> = ({
    className,
    onMapMarkerClick,
    onReportVisibleMarkers,
    center,
    position,
    showPosition,
    defaultZoom,
    markers,
    options,
    useClustering
}) => {
    // Default options for google-map-react
    const defaultOptions = {
        panControl: true,
        fullscreenControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        styles: [
            {
                featureType: 'administrative.land_parcel',
                elementType: 'all',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'landscape.man_made',
                elementType: 'all',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'road',
                elementType: 'labels',
                stylers: [{ visibility: 'simplified' }, { lightness: 20 }]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ hue: '#f49935' }]
            },
            {
                featureType: 'road.highway',
                elementType: 'labels',
                stylers: [{ visibility: 'simplified' }]
            },
            {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [{ hue: '#fad959' }]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'road.local',
                elementType: 'geometry',
                stylers: [{ visibility: 'simplified' }]
            },
            {
                featureType: 'road.local',
                elementType: 'labels',
                stylers: [{ visibility: 'simplified' }]
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ hue: '#a1cdfc' }, { saturation: 30 }, { lightness: 49 }]
            }
        ]
    };

    if (typeof markers === 'undefined') {
        markers = [];
    }

    const googleMapRendered: any = useRef();
    const googleMapApi: any = useRef();

    // Callback centers the map around the found markers
    const onGoogleApiLoaded = () => {
        // defaultZoom must block getMapBounds because otherwise
        // it would be ignored.
        // If we have more thatn 1 marker we use getMapBounds
        if (markers && markers.length > 1 && !defaultZoom) {
            getMapBounds();
        }
        // If we only have one maker we center the map on this marker
        if (markers && markers.length == 1 && !defaultZoom) {
            const c = new googleMapApi.current.LatLng(markers[0].latitude, markers[0].longitude);
            googleMapRendered.current.setCenter(c);
        }
    };

    // Get the bounds of a map including all markers
    const getMapBounds = () => {
        if (!markers || !googleMapRendered.current || !googleMapApi.current) {
            return;
        }
        const bounds = new googleMapApi.current.LatLngBounds();

        markers.forEach((marker: MapMarker) => {
            bounds.extend({
                lat: marker.latitude,
                lng: marker.longitude
            });
        });
        if (position) {
            bounds.extend({
                lat: position.latitude,
                lng: position.longitude
            });
        }
        googleMapRendered.current.fitBounds(bounds);
    };

    // Save the mapState which is important if we use clustering
    const [mapState, setMapState] = useState<MapState | {}>({});
    const handleMapChange = (ms: MapState) => {
        setMapState(ms);
    };

    // Cluster the markers if desired
    const [clusteredMarkers, setClusteredMarkers] = useState<MapMarker[]>([]);
    const getClusters = (ms: MapState, mk: MapMarker[]) => {
        let markers = mk ? mk.map((m) => ({ ...m, lat: m.latitude, lng: m.longitude })) : [];
        if (useClustering && ms.bounds) {
            // Pick special markers because we dont want them to be clustered
            let specialMarkers: any = [];
            markers = markers.filter((m) => {
                if (
                    [
                        'address.community_map.airbnb.',
                        'address.community_map.publicSpaces.',
                        'address.community_map.dealer_POI.premiumPartner.',
                        'address.community_map.dealer_POI.premiumPartnerOffice.',
                        'address.community_map.dealer_POI.premiumPartnerVitraSpace.'
                    ].indexOf(m.type) != -1
                ) {
                    specialMarkers.push(m);
                    return false;
                }
                return true;
            });

            let clusteredMarkers = supercluster(markers, {
                minZoom: 5,
                maxZoom: 16,
                radius: Math.pow(1.5, 13 - ms.zoom) + 4
            });
            clusteredMarkers = clusteredMarkers(ms).map(({ wx, wy, numPoints, points }: any) => {
                return {
                    lat: wy,
                    lng: wx,
                    numPoints,
                    id: points[0].id,
                    points,
                    isHighlighted: points.filter((p: any) => p.isHighlighted).length > 0
                };
            });
            return clusteredMarkers.concat(specialMarkers);
        } else {
            return markers;
        }
    };

    // Check/set if a marker is visible on the map and report it via a callback
    const reportVisibleMarkes = (ms: MapState, mk: MapMarker[]) => {
        if (!mk || !googleMapRendered.current || !googleMapApi.current) {
            return;
        }
        const bounds = googleMapRendered.current.getBounds();
        const vm = mk.filter((m: MapMarker) => {
            const l = new googleMapApi.current.LatLng(m.latitude, m.longitude);
            return bounds.contains(l);
        });
        if (onReportVisibleMarkers) {
            onReportVisibleMarkers(vm);
        }
    };

    useEffect(() => {
        reportVisibleMarkes(mapState as MapState, markers!);
    }, [mapState]);

    useEffect(() => {
        setClusteredMarkers(getClusters(mapState as MapState, markers!));
    }, [mapState, markers]);

    // Fit to bounds if the markers have changed
    useEffect(() => {
        getMapBounds();
    }, [`${markers.length}${(markers[0] || {}).id}`]);

    // GoogleMap Properties
    const GoogleMapProps: any = {
        center: {
            lat: 47.59331,
            lng: 7.62082
        },
        bootstrapURLKeys: googleApiKeys,
        options: defaultsDeep(options || {}, defaultOptions),
        onGoogleApiLoaded: ({ map, maps }: any) => {
            googleMapApi.current = maps;
            googleMapRendered.current = map;
            onGoogleApiLoaded();
        },
        onChange: handleMapChange,
        defaultZoom: defaultZoom || 15,
        yesIWantToUseGoogleMapApiInternals: true
    };

    // We MUST set the center because there is the case that the prop
    // defaultZoom is set. If defaultZoom is set we dont call mapFitBounds
    // which would change the zoom...
    if (center) {
        GoogleMapProps.center = {
            lat: center.latitude,
            lng: center.longitude
        };
    } else if (markers.length === 1) {
        GoogleMapProps.center = {
            lat: markers[0].latitude,
            lng: markers[0].longitude
        };
    } else if (!center && markers.length === 0 && position) {
        GoogleMapProps.center = {
            lat: position.latitude,
            lng: position.longitude
        };
    }
    return (
        <div className={cx(styles.wrapper, className)}>
            <GoogleMap {...GoogleMapProps}>
                {position && showPosition && <CommonMapPosition lat={position.latitude} lng={position.longitude} />}
                {(clusteredMarkers as any).map((m: any, k: number) => (
                    <CommonMapMarker
                        key={k}
                        id={m.id}
                        lat={m.lat}
                        lng={m.lng}
                        type={m.type}
                        isHighlighted={m.isHighlighted}
                        numPoints={m.numPoints}
                        onClick={onMapMarkerClick ? onMapMarkerClick : undefined}
                    />
                ))}
            </GoogleMap>
        </div>
    );
};

export default CommonMap;
