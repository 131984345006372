import { useEffect } from 'react';

const setDvhCssVariable = () => {
    document.documentElement.style.setProperty('--dvh', `${window.innerHeight * 0.01}px`);
};

const useDvhUnitCssVariableFallback = (): void => {
    useEffect(() => {
        const hasNativeDvhSupport = CSS.supports('height: 100dvh');

        if (!hasNativeDvhSupport) {
            setDvhCssVariable();
            window.addEventListener('resize', setDvhCssVariable);
        }

        return () => {
            window.removeEventListener('resize', setDvhCssVariable);
        };
    }, []);
};

export default useDvhUnitCssVariableFallback;
