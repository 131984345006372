import { emitter } from 'common/hooks/useEvents';
import { unmarshalSKU } from 'common/utils/sanitize';
import { canUseDOM } from 'exenv';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

const debugMode = (): boolean =>
    (canUseDOM && window.location.hostname.includes('.dev')) || window.location.hostname.includes('localhost');

/**
 * Checks if the dom is ready
 */
const whenDomReady = (): Promise<void> =>
    new Promise((resolve) => {
        if (!canUseDOM) {
            return resolve();
        }

        const loadedStates = ['interactive', 'complete'];

        // Use global document if we don't have one
        const doc: any = window.document;
        if (loadedStates.includes(doc.readyState)) {
            resolve();
        } else {
            doc.addEventListener('DOMContentLoaded', resolve);
        }
    });

export const addGaEvent = (data: AnalyticsEvent): void => {
    addToDataLayer({
        ...data,
        event: 'gaEvent'
    });
};

// window.dataLayer.push{(
//     event: 'ga4Event',
//     event_name: "navigation_interaction",
//     click: {
//         label: "navigation_element-products"
//     }
// )};

export const addGa4Event = (data: AnalyticsEvent): void => {
    const payload: any = {
        event: 'ga4Event',
        event_name: data.eventCategory
    };
    if (data.eventAction) {
        payload[data.eventAction] = {
            label: data.eventLabel
        };
    }

    addToDataLayer(payload);
};

export const addCheckoutActionEvent = (eventLabel: string): void => {
    addGaEvent({
        eventCategory: 'Enhanced eCommerce',
        eventAction: 'Checkout Action',
        eventLabel
    });
};

/**
 * Adds a payload to the Datalayer
 * @param data Datalayer argument
 */
export const addToDataLayer = (data: AnalyticsDataLayerEvent): boolean => {
    if (debugMode()) {
        console.log(`addToDataLayer DEBUG: `, JSON.stringify(data));
    }
    // Note: we always push to datalayer since it's just a global

    // Push into Datalayer
    (window as any).dataLayer.push(data);

    return true;
};

/**
 * Debounces adding elements to the datalayer
 */
export const addToDataLayerDebounced = debounce(addToDataLayer, 200);

/**
 * Extracts the anayltics data from the dom element
 * @param el tghe analytics dom element
 */
const getAnalyticsDataFromElement = (el: any): any => {
    if (get(el, 'dataset.analyticsEnabled') !== 'true') {
        return false;
    }
    const data = el.dataset;
    switch (data.analyticsType) {
        case 'EECpromotionImpression':
            return {
                type: data.analyticsType,
                creative: data.analyticsCreative,
                position: data.analyticsPostion,
                id: data.analyticsId,
                name: data.analyticsName
            };
            break;
        case 'Link':
            return {
                type: data.analyticsType,
                category: data.analyticsCategory,
                action: data.analyticsAction,
                label: data.analyticsLabel
            };

        case 'LinkGa4':
            return {
                type: data.analyticsType,
                action: data.analyticsAction,
                category: data.analyticsCategory,
                label: data.analyticsLabel
            };
    }

    return false;
};

export const watchForImpressions = (): void => {
    whenDomReady().then(() => {
        // Setup watcher for EECpromotionImpression
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const target = entry.target as any;
                    io.unobserve(target);

                    const data = getAnalyticsDataFromElement(target) as any;

                    switch (data.type) {
                        case 'EECpromotionImpression':
                            addToDataLayer({
                                event: data.type,
                                ecommerce: {
                                    promoView: {
                                        promotions: [data]
                                    }
                                }
                            });
                            break;
                    }
                }
            });
        });

        // Make sure we do this when the dom is loaded
        const watchedElement = document.querySelectorAll('[data-analytics-type="EECpromotionImpression"]');
        [].forEach.call(watchedElement, (el: any) => {
            if (get(el, 'dataset.analyticsEnabled') === 'true') {
                io.observe(el);
            }
        });
    });
};

if (canUseDOM) {
    (window as any).vitrawatchForImpressions = watchForImpressions;
}

/**
 * Init the Anayltics by settings up
 * The event Listener on the Elements
 */
export const initAnalytics = (): void => {
    if (canUseDOM) {
        whenDomReady().then(() => {
            // Setup Click Handler
            document.addEventListener('pointerdown', (event) => {
                const target = event.target as any;
                const link = target.closest('[data-analytics-enabled]');
                const data = getAnalyticsDataFromElement(link) as any;

                if (data) {
                    switch (data.type) {
                        // For promotion Impression we need to send the click event
                        case 'EECpromotionImpression':
                            addToDataLayer({
                                event: 'EECpromotionClick',
                                ecommerce: {
                                    promoClick: {
                                        promotions: [data]
                                    }
                                }
                            });
                            break;
                        case 'Link':
                            addGaEvent({
                                eventCategory: data.category,
                                eventAction: data.action,
                                eventLabel: data.label
                            });
                            break;
                        case 'LinkGa4':
                            addGa4Event({
                                eventCategory: data.category,
                                eventAction: data.action,
                                eventLabel: data.label
                            });
                            break;
                    }
                }
            });

            setTimeout(() => {
                watchForImpressions();
            }, 500);
        });

        // Hook into event handler
        emitter.on('cart:addToCart', (data) => {
            const { currencyCode, ...product } = data;
            addToDataLayer({
                event: 'EECaddToCart',
                ecommerce: {
                    currencyCode,
                    add: {
                        products: [product]
                    }
                }
            });
        });

        emitter.on('cart:removeFromCart', (data) => {
            const { currencyCode, ...product } = data;
            addToDataLayer({
                event: 'EECremoveFromCart',
                ecommerce: {
                    currencyCode,
                    add: {
                        products: [product]
                    }
                }
            });
        });

        emitter.on('cart:productDetailView', (data) => {
            const { currencyCode, ...product } = data;
            addToDataLayer({
                event: 'EECproductDetailView',
                ecommerce: {
                    currencyCode,
                    detail: {
                        products: [product]
                    }
                }
            });
        });

        emitter.on('cart:virtPath', (data: any) => {
            addToDataLayer({
                event: 'virtPath',
                virtPath: data.virtPath
            } as any);
        });

        emitter.on('cart:checkout', (ecommerce: any) => {
            addToDataLayer({
                event: 'EECcheckout',
                ecommerce
            });
        });

        emitter.on('cart:purchase', (ecommerce: any) => {
            const { dimension18, ...ecommerceAgrs } = ecommerce;
            addToDataLayer({
                event: 'EECpurchase',
                dimension18,
                ecommerce: ecommerceAgrs
            });
        });

        emitter.on('cart:payment', (data: any) => {
            if (data.option) {
                addToDataLayer({
                    event: 'EECcheckoutOption',
                    ecommerce: {
                        // 3+1 is payment
                        checkout_option: {
                            actionField: { step: 4, option: data.option }
                        }
                    }
                });
            }
        });
    }
};

interface AnalyticsProp {
    enabled: boolean;
    position?: number;
    name?: string;
    type: 'EECpromotionImpression' | 'EECpromotionClick' | 'Link' | 'LinkGa4';
    data: LinkAnalytics | undefined;
}

/**
 * Get's the data props for a watched anayltics element
 * @param analytics Analytics Data
 */
export const getAnayltics = (analytics: AnalyticsProp): Record<string, string> => {
    const { enabled, type, data, position, name } = analytics;
    const linkData = data || ({} as any);
    return omitBy(
        {
            'data-analytics-enabled': `${Boolean(enabled)}`,
            'data-analytics-type': type,
            'data-analytics-creative': linkData.creative,
            'data-analytics-id': linkData.label,
            'data-analytics-action': linkData.action,
            'data-analytics-category': linkData.category,
            'data-analytics-label': linkData.label,
            'data-analytics-postion': position,
            'data-analytics-name': name
        },
        isNil
    );
};

export interface iProductAnayltics {
    item_id?: string;
    item_name?: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price?: number;
    quantity?: number;
    item_product_family?: string;
    item_topics?: string;
    item_type?: string;
    item_shipping_tier?: string;
}

export interface iAnaylticsRespose {
    items: iProductAnayltics[];
    totalProductsIncludingShipping: number;
    totalProducts: number;
    shippingPrice: number;
    currencyCode: string;
    totalTax: number;
    coupon?: string;
}

export enum Ga4AnalyticsType {
    addToCart = 'add_to_cart',
    removeFromCart = 'remove_from_cart',
    viewCart = 'view_cart',
    login = 'login',
    signUp = 'sign_up',
    beginCheckout = 'begin_checkout',
    checkouStep = 'checkout_step',
    addShippingInfo = 'add_shipping_info',
    addPaymentInfo = 'add_payment_info',
    purchase = 'purchase'
}

export interface Ga4AnalyticsPayload {
    data?: iAnaylticsRespose;
    dataBefore?: iAnaylticsRespose;
    sku?: string;
    step?: string;
    paymentType?: string;
    orderId?: string;
}

export const ga4Analytics = (type: Ga4AnalyticsType, input?: Ga4AnalyticsPayload): void => {
    const { data, sku, dataBefore, step, paymentType, orderId } = input || {};
    const { articleNo, configurationId } = unmarshalSKU(sku || '');

    const filterEffectItems = (items: iProductAnayltics[]) =>
        items.filter((el) => el.item_id === articleNo && `${el.item_variant || ''}` === `${configurationId || ''}`);

    const allItems = data?.items || [];
    const affectedItems = filterEffectItems(allItems);
    const affectedItemsBefore = filterEffectItems(dataBefore?.items || []);

    const shippingTiers = data?.items.map((el) => el.item_shipping_tier);
    const shippingTiersUnique = [...new Set(shippingTiers)];

    switch (type) {
        case Ga4AnalyticsType.beginCheckout:
            addToDataLayer({
                event: Ga4AnalyticsType.beginCheckout,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    items: allItems
                }
            });
            break;
        case Ga4AnalyticsType.addToCart:
            addToDataLayer({
                event: Ga4AnalyticsType.addToCart,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    items: affectedItems
                }
            });
            break;
        case Ga4AnalyticsType.removeFromCart:
            addToDataLayer({
                event: Ga4AnalyticsType.removeFromCart,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    items: affectedItemsBefore
                }
            });
            break;

        case Ga4AnalyticsType.login:
            addToDataLayer({
                event: Ga4AnalyticsType.login
            });
            break;

        case Ga4AnalyticsType.signUp:
            addToDataLayer({
                event: Ga4AnalyticsType.signUp
            });
            break;

        case Ga4AnalyticsType.viewCart:
            addToDataLayer({
                event: Ga4AnalyticsType.viewCart,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    items: allItems
                }
            });
            break;
        case Ga4AnalyticsType.checkouStep:
            addToDataLayer({
                event: Ga4AnalyticsType.checkouStep,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    items: data?.items,
                    checkout_step: step
                }
            });
            break;
        case Ga4AnalyticsType.addShippingInfo:
            addToDataLayer({
                event: Ga4AnalyticsType.addShippingInfo,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    coupon: data?.coupon,
                    shipping_tier: shippingTiersUnique.join(', '),
                    items: allItems
                }
            });
            break;
        case Ga4AnalyticsType.addPaymentInfo:
            addToDataLayer({
                event: Ga4AnalyticsType.addPaymentInfo,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    coupon: data?.coupon,
                    payment_type: paymentType,
                    items: allItems
                }
            });
            break;
        case Ga4AnalyticsType.purchase:
            addToDataLayer({
                event: Ga4AnalyticsType.purchase,
                ecommerce: {
                    currency: data?.currencyCode,
                    value: data?.totalProductsIncludingShipping,
                    coupon: data?.coupon,
                    purchase_payment_type: paymentType,
                    transaction_id: orderId,
                    shipping: data?.shippingPrice,
                    tax: data?.totalTax,
                    items: allItems
                }
            });
            break;

        default:
            break;
    }
};
