import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { ReactNode } from 'react';

export interface DropDownProps {
    title?: ReactNode;
    children: ReactNode;
    footer?: ReactNode;
    className?: string;
    isMobile?: boolean;
}

const styles = {
    wrapper: css`
        width: 320px;
        background-color: ${colors.white};
        & > * {
            padding: ${rh(0.625)};
        }
    `,
    mobile: css`
        width: auto;
        h3 {
            // in the mobile nav, extra headlines are displayed
            // outside of the actual dropdown component, so we
            // can hide the other one
            display: none;
        }
    `,
    title: css`
        font-weight: 600;
        margin-bottom: ${rh(0.25)};
    `,
    content: css`
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        button:last-child,
        [role='button'] {
            margin-bottom: ${rh(0.125)};

            &:first-of-type {
                margin-top: ${rh(0.625)};
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    `,
    footer: css`
        border-top: 1px solid ${colors.inputBorderColor};
    `
};

const DropDown: React.FunctionComponent<DropDownProps> = ({ title, children, footer, className, isMobile = false }) => {
    return (
        <div className={cx('dropdown', styles.wrapper, className, isMobile && styles.mobile)}>
            <div className={cx('dropdown__content', styles.content)}>
                {title && <h3 className={cx('dropdown__title', styles.title)}>{title}</h3>}
                {children}
            </div>
            {footer && <div className={cx('dropdown__footer', styles.footer)}>{footer}</div>}
        </div>
    );
};

export default DropDown;
