// https://github.com/GoogleChromeLabs/pinch-zoom

import { canUseDOM } from 'exenv';
import { cx } from 'linaria';
import React, { useState } from 'react';

import VitraPinchZoomSlider from './vitraPinchZoomSlider';
import VitraPinchZoomArea from './vitraPinchZoomArea';
import {styles as VitraPinchZoomAreaStyles} from './vitraPinchZoomArea';

interface SetTransformOpts {
    scale: number;
    x: number;
    y: number;
}

export interface VitraPinchZoomProps {
    /**
     * The wrapper className
     */
    className?: string;

    /**
     * This prop sets will-transform on the positioningRef
     * It defaults to FALSE
     * BUT MUST BE ENABLED IF WE HAVE CHILDREN WHICH NOT ONLY CONSIST OF IMAGES!!!!
     */
    disableFastAndBlurry?: boolean;

    /**
     * A handler fot the wrapper
     * componen
     */
    onChange?: (opts: SetTransformOpts) => void;

    /**
     * Start with fullbleed (Content uses 100% container height)
     */
    startFullbleed?: boolean;

    /**
     * images must be passed as an array
     */
    images?: Image[];

    /**
     * Start index which can be set if multiple images are passed as an array
     */
    imagesStartIndex?: number;
}


const VitraPinchZoom: React.FunctionComponent<VitraPinchZoomProps> = props => {

    // Only works in DOM
    if (!canUseDOM) {
        return null;
    }

    const [sliderIdx, setSliderIdx] = useState(props.imagesStartIndex || 0);

    const areaSliderClassTrue = props.images && props.images.length > 0;

    return (
        <div className={cx('vitraPinchZoom', props.className)}>
             {!props.children && props.images && props.images?.length > 1 && (
                <VitraPinchZoomSlider
                    images={props.images}
                    onChange={setSliderIdx}
                    currentIdx={sliderIdx}
                />
            )}

            <VitraPinchZoomArea
                key={`VitraPinchZoomArea_${sliderIdx}`}
                disableFastAndBlurry={props.disableFastAndBlurry}
                onChange={props.onChange}
                startFullbleed={props.startFullbleed}
                className={areaSliderClassTrue ? VitraPinchZoomAreaStyles.pinchZoomWrapperWithSlider : ''}
            >
                {props.children}
                {props.images && <img src={`${props.images[sliderIdx].src}`}/>}
            </VitraPinchZoomArea>
        </div>
    );
};

export default VitraPinchZoom;
