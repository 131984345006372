import { isMobile } from 'common/hooks/useBreakpoints';
import VitraModal from 'common/primitives/modal/vitraModal';
import { canUseDOM } from 'exenv';
import { css } from 'linaria';
import React, { useEffect } from 'react';
import DesktopOverlayContact from '../desktop/overlay/desktopOverlayContact';
import DesktopOverlayWishlist from '../desktop/overlay/desktopOverlayWishlist';
import MobileOverlayContact from '../mobile/overlay/mobileOverlayContact';
import MobileOverlayFilter from '../mobile/overlay/mobileOverlayFilter';
import MobileOverlaySearch from '../mobile/overlay/mobileOverlaySearch';
import MobileOverlayWishlist from '../mobile/overlay/mobileOverlayWishlist';
import { useStateValue } from '../state/find-vitra.state-provider';

const styles = {
    mobileOverlay: css`
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        background: white;
        z-index: 99999999999;
    `,
    desktopModalClass: css`
        max-width: 1440px;
        padding: 20px 10px 0;
    `
};
const CommonOverlay: React.FunctionComponent = () => {
    const [state, dispatch]: any = useStateValue();

    const overlayType = state.ux.mobileOverlayActive;
    if (!overlayType) {
        return null;
    }

    const onClose = () => {
        dispatch({ type: 'UX_TOGGLE_OVERLAY', overlayName: '' });
    };

    useEffect(() => {
        if (canUseDOM) {
            return;
        }

        const htmlEl = document.getElementsByTagName('html')[0];
        if (!htmlEl.classList.contains('community__mobile__overlay--active')) {
            htmlEl.classList.add('community__mobile__overlay--active');
        }
        return () => {
            const htmlEl = document.getElementsByTagName('html')[0];
            if (htmlEl.classList.contains('community__mobile__overlay--active')) {
                htmlEl.classList.remove('community__mobile__overlay--active');
            }
        };
    }, []);

    return (
        (isMobile() && (
            <div className={styles.mobileOverlay}>
                {overlayType === 'search' && <MobileOverlaySearch />}
                {overlayType === 'filter' && <MobileOverlayFilter />}
                {overlayType === 'contact' && <MobileOverlayContact />}
                {overlayType === 'wishlist' && <MobileOverlayWishlist />}
            </div>
        )) || (
            <VitraModal
                modalClass={styles.desktopModalClass}
                onClose={onClose}
                blockBody={true}
                closeOnClickOutside={true}
                type="large"
            >
                {overlayType === 'contact' && <DesktopOverlayContact />}
                {overlayType === 'wishlist' && <DesktopOverlayWishlist />}
            </VitraModal>
        )
    );
};

export default CommonOverlay;
