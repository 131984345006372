import ViraConfigureModal from 'components/configure-v3/general/configureModalCheckout';
import React, { useState } from 'react';

interface ConfiguratorModalHook {
    startConfiguration: (productId: string) => Promise<string>;
    configurationModal: JSX.Element | undefined;
}

export const useConfiguratorModal = (isCheckout: boolean): ConfiguratorModalHook => {
    const [configurationModal, setConfigurationModal] = useState<JSX.Element | undefined>();
    const startConfiguration = (id: string): Promise<string> => {
        return new Promise((resolve) => {
            setConfigurationModal(
                <ViraConfigureModal
                    id={id}
                    isCheckout={isCheckout}
                    onClose={() => {
                        resolve(id);
                        setConfigurationModal(undefined);
                    }}
                    onChangeProduct={(pid: string) => {
                        resolve(pid);
                        setConfigurationModal(undefined);
                    }}
                />
            );
        });
    };

    return {
        startConfiguration,
        configurationModal
    };
};
