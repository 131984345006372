import { useConfig } from 'common/hooks/useBoostrap';
import sortImageVariations from 'common/utils/sortImageVariations';
import { canUseDOM } from 'exenv';
import React from 'react';
import { colors } from './colors';
import {
    addGridOverlay,
    adjustFontSizeTo,
    containerCentered,
    containerCenteredV2,
    fontSizeAndLineHeight,
    fontStyles,
    linkColor,
    rh,
    vh,
    visuallyHidden,
    vitraFont,
    widgetBaseStyle
} from './fonts';
import { media, mediaIos } from './media';

/**
 * sets the path for static files
 * @param staticFile relative path to the static file
 */
const staticPath = (staticFile: string): string => {
    if (process.env.STATIC_PATH) {
        return `${process.env.STATIC_PATH}/${staticFile}`;
    }
    return `/static/${staticFile}`;
};

export const generateProductImageUrl = (productId: string): string => {
    if (!canUseDOM) {
        return '';
    }
    const productIdParts = `${productId}`
        .toUpperCase()
        .replace('CONF|', '')
        .replace('SUB|', '')
        .replace('MATERIAL|', '')
        .split('|');

    // always remove the first part
    const p = [productIdParts[0].toLowerCase()];
    if (productIdParts[1]) {
        p.push(productIdParts[1]);
    }

    const domain = useConfig('staticDomain', 'https://static.vitra.dev');

    // return `http://localhost:4444/v1/${p.join('/')}.jpg?ref=${referal}`;
    // @todo: remove ts > forces the CF cache to be ignored
    return `${domain}/products/${p.join('/')}.jpg`;
};

/**
 * A rewrite for image path so we can cache slow image responses from censhare
 * @param imagePath the image path, can be a vitra.com url
 */
const imagePath = (path: string): string => {
    return path;
    // if (process.env.CURRENT_RELEASE) {
    //     return imagePath.replace('https://www.vitra.com/de-de/_storage', `/media/${modifier}`);
    // }

    // return imagePath.replace('https://www.vitra.com/de-de/_storage', `http://localhost:8080/${modifier}`)
};

/**
 * Fast hash for a given string
 * @param input The string to hash
 */
const hashCode = (input: string): number => {
    let hash = 0;
    let i;
    let chr;
    if (input.length === 0) {
        return hash;
    }
    // tslint:disable
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    // tslint:enable
    return hash;
};

/**
 * Generate a inline inject style tag and a global style selector
 * based on the image url so pagespeed does pick up inlined background images
 * @param image a image URL
 */

interface InjectBackgroundImage {
    backgroundImage: JSX.Element | null;
    backgroundClass: string;
}

const injectBackgroundImage = (image: string): InjectBackgroundImage => {
    const bgImagePath = imagePath(image);
    const className = `im__${hashCode(bgImagePath)}`;
    return {
        backgroundImage: <style>{`.${className} { background-image: url(${bgImagePath});}`}</style>,
        backgroundClass: className
    };
};

const injectBackgroundPicture = (image?: Image): InjectBackgroundImage => {
    if (!image) {
        return { backgroundImage: null, backgroundClass: '' };
    }
    let srcSets = [image] as any;
    // Check if we have a srcSet
    if (image.srcSet && image.srcSet[0]) {
        srcSets = image.srcSet;
    }

    srcSets = sortImageVariations(srcSets, { type: 'css' });

    const className = `im__${hashCode(srcSets[0].src)}`;
    return {
        backgroundImage: (
            <style>{`
        ${srcSets
            .map((img: any) => {
                return img.mq
                    ? `
                    @media ${img.mq} {
                        .${className} { background-image: url(${img.src}); }
                    }`
                    : `
                        .${className} { background-image: url(${img.src}); }
                    `;
            })
            .join(' ')}
        `}</style>
        ),
        backgroundClass: className
    };
};

const isIE = (): boolean => {
    if (!canUseDOM) {
        return false;
    }

    const ua = window.navigator.userAgent;
    const ieUA = ['MSIE ', 'Trident/', 'Edge/'];
    let found = false;
    ieUA.some((u) => {
        if (ua.includes(u)) {
            found = true;
            return true;
        }
    });
    return found;
};

const isIos = (): boolean => {
    if (!canUseDOM) {
        return false;
    }
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
};

/**
 * Export everything so we don't need to import everything
 */
export {
    addGridOverlay,
    adjustFontSizeTo,
    // colors
    colors,
    containerCentered,
    containerCenteredV2,
    fontSizeAndLineHeight,
    fontStyles,
    hashCode,
    imagePath,
    injectBackgroundImage,
    injectBackgroundPicture,
    isIE,
    isIos,
    linkColor,
    media,
    mediaIos,
    rh,
    // path rewrites
    staticPath,
    vh,
    visuallyHidden,
    vitraFont,
    widgetBaseStyle
};
