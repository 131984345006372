import * as React from 'react';

const SvgV2Cart = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 21.5 17" {...props} width="21.5px" height="17px">
        <path d="M0 .5a.5.5 0 0 0 .5.5h2.11l.9 3.61 2 8A.5.5 0 0 0 6 13h13a.5.5 0 0 0 .48-.38l2-8a.5.5 0 0 0-.36-.62H4.39L3.48.38A.5.5 0 0 0 3 0H.5a.5.5 0 0 0-.5.5zM4.64 5h15.72l-1.75 7H6.39z" />
        <circle cx={16.71} cy={15.21} r={1.79} />
        <circle cx={8.29} cy={15.21} r={1.79} />
    </svg>
);

export default SvgV2Cart;
