import { css } from 'linaria';

export const styles = {
    container: css`
        position: fixed;
        z-index: 50;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    `,

    cardContainer: css`
        opacity: 1;
        transition: all 0.3s cubic-bezier(.18;-0.27;.79;1.28);
        position: relative;
        z-index: 4;
        margin: 0;
        margin-bottom: 8px;
        border-radius: 8px;
        padding: 0.6rem 0.6rem;
        max-width: 32rem;
        min-width: 17rem;
        background-color: #333;
        color: #fff;
    `,

    cardContent: css`
        text-align: left;
        padding: 0;
        display: flex;
        align-items: flex-start;
        margin: 0;
    `,
    cardIcon: css`
        margin: 0;
        padding: 0;
        display: inline-block;
        margin-right: 0.4rem;
        position: relative;
        top: 3px;
    `,

    cardTitle: css`
        text-transform: capitalize;
        paddingleft: 0rem;
        margin: 0;
        paddingbottom: 0.4rem;
    `,

    cardMessage: css`
        display: inline-block;
        position: relative;
        font-size: 1rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
    `,

    icon: css`
        width: 1.5rem;
        height: 1.5rem;
    `,

    closeButton: css`
        position: absolute;
        width: 2rem;
        border: none;
        background: transparent;
        boxshadow: none;
        right: -3%;
        top: -6%;
        z-index: 999;
        cursor: pointer;
    `,
    animateIn: css`
        // transform: translateY(0vw);
        opacity: 1;
    `,
    animateOut: css`
        transform: translateY(2vw);
        opacity: 0;
    `
};

export const positionStyles = {
    'bottom-left': css`
        left: 3%;
        bottom: 3%;
        margin-right: auto;
    `,

    'bottom-right': css`
        right: 20px;
        bottom: 20px;
        margin-left: auto;
    `,

    'top-left': css`
        left: 3%;
        top: 3%;
        margin-right: auto;
    `,

    'top-right': css`
        right: 20px;
        margin-left: auto;
        top: 20px;
    `,

    'top-center': css`
        margin: auto;
        top: 3%;
        left: 50%;
        transform: translateX(-50%);
    `,

    'bottom-center': css`
        margin: auto;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    `,

    center: css`
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `
};
