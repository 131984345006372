import { isMobile } from 'common/hooks/useBreakpoints';
import IconZoom from 'common/primitives/icons/components/V1Zoom';
import VitraMediaModal from 'common/primitives/modal/vitraMediaModal';
import { css, cx } from 'linaria';
import React, { useState } from 'react';
import VitraPicture from './vitraPicture';

interface PicturePinchZoomProps {
    /**
     * The IMage
     */
    image: Image;

    /**
     * Optional ClasName for the warpper
     */
    className?: string;
}

const styles = {
    wrapper: css`
        font-size: 0;
        position: relative;
    `,
    iconWrapper: css`
        pointer-events: none;
        background: rgba(255, 255, 255, 0.6);
        width: 50px;
        height: 50px;
        font-size: 0;
        line-height: 50px;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
        text-align: center;
    `,
    icon: css`
        width: 14px;
        height: 14px;
        position: relative;
        top: 7px;
    `
};

const VitraPicturePinchZoom: React.FunctionComponent<PicturePinchZoomProps> = ({ className, image }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const handleClick = () => () => {
        isMobile() && setModalOpen(true);
    };
    // Render
    return (
        <div className={cx('image__zoom', styles.wrapper, className)} onClick={handleClick()}>
            {isMobile() && (
                <div className={styles.iconWrapper}>
                    <IconZoom className={styles.icon} />
                </div>
            )}
            <VitraPicture image={image} className={cx('zoom__picture')} />
            {modalOpen && (
                <VitraMediaModal id="find-vitra-gallary" content={[image]} onClose={() => setModalOpen(false)} />
            )}
        </div>
    );
};

export default VitraPicturePinchZoom;
