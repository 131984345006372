/* eslint-disable @typescript-eslint/ban-ts-comment */
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Tus from '@uppy/tus';
import Webcam from '@uppy/webcam';
import { useConfig } from 'common/hooks/useBoostrap';
import { useTranslations } from 'common/primitives/translations';
import { colors, fontSizeAndLineHeight, media, rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { forwardRef, useImperativeHandle } from 'react';

const styles = {
    wrapper: css`
        position: relative;
        * {
            font-family: inherit !important;
        }
        .uppy-Dashboard-AddFiles-title {
            font-weight: 100 !important;
            ${fontSizeAndLineHeight('17px', 1, true)}
            margin: 0 0 ${rh(0.5)};
            ${media.desktopAndUp} {
                ${fontSizeAndLineHeight('17px', 1, true)}
            }
        }
        button {
            color: ${colors.primary};
            font-weight: 100;
        }
        .uppy-Dashboard-inner {
            border-color: ${colors.inputBorderColor};
            background: transparent;
        }
        .uppy-Dashboard-browse:focus,
        .uppy-Dashboard-browse:hover {
            border-color: ${colors.primary};
        }
        .uppy-StatusBar-actions {
            .uppy-StatusBar-actionBtn--upload {
                background: ${colors.black};
            }
        }
    `,
    dragdrop: css`
        margin-bottom: ${rh(1)};
    `,
    markAsRequired: css`
        .uppy-Dashboard-inner,
        .uppy-Dashboard-AddFiles {
            border-color: ${colors.formError} !important;
        }
    `,
    markAsRequiredMessage: css`
        position: absolute;
        left: 50%;
        top: -${rh(0.5)};
        transform: translateX(-50%);
        background: ${colors.formError};
        color: white;
        z-index: 1;
        padding: 0px 8px;
        border-radius: 4px;
    `
};

interface VitraUploadProps {
    ref: any;
    /**
     * Class name of the wrapper
     */
    className?: string;

    /**
     * Callback which contains the information about the successful or failed uploaded files
     */
    onUploadComplete?: (result?: Uppy.UploadResult) => void;

    /**
     * Required Flag - The upload dashbard gets a red border
     */
    markAsRequired?: boolean;

    /**
     * Required Message
     */
    markAsRequiredMessage?: string;

    /**
     * restrictions
     */
    restrictions?: {
        maxFileSize?: number;
        maxNumberOfFiles?: number;
        minNumberOfFiles?: number;
        allowedFileTypes?: string[];
    };
}

const VitraUpload: React.FunctionComponent<VitraUploadProps> = forwardRef(
    (
        {
            className,
            markAsRequired,
            markAsRequiredMessage,
            onUploadComplete,
            restrictions = {
                maxFileSize: 16777216,
                maxNumberOfFiles: 10,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif', 'video/*']
            }
        },
        ref
    ) => {
        if (!canUseDOM) {
            return null;
        }

        const locale = useConfig('code', 'de-de').split('-')[0];

        let uppyLocales = {};
        switch (locale) {
            case 'de':
                uppyLocales = require('@uppy/locales/lib/de_DE');
                break;
            case 'es':
                uppyLocales = require('@uppy/locales/lib/es_ES');
                break;
            case 'fr':
                uppyLocales = require('@uppy/locales/lib/fr_FR');
                break;
            case 'ja':
                uppyLocales = require('@uppy/locales/lib/ja_JP');
                break;
            default:
                uppyLocales = require('@uppy/locales/lib/en_US');
        }

        const uppy = React.useMemo(() => {
            const tusEndpoint = useConfig('uploadUrl', 'https://www-staging.vitra.com/api/v1/upload/');

            return Uppy({ debug: true, locale: uppyLocales as any, autoProceed: false, restrictions })
                .use(Webcam, {
                    title: useTranslations('camera'),
                    locale: {
                        smile: useTranslations('smile'),
                        takePicture: useTranslations('takePicture'),
                        startRecording: useTranslations('startRecording'),
                        stopRecording: useTranslations('stopRecording'),
                        allowAccessTitle: useTranslations('allowAccessTitle'),
                        allowAccessDescription: useTranslations('allowAccessDescription'),
                        noCameraTitle: useTranslations('noCameraTitle'),
                        noCameraDescription: useTranslations('noCameraDescription'),
                        recordingStoppedMaxSize: useTranslations('recordingStoppedMaxSize'),
                        recordingLength: useTranslations('recordingLength'),
                        submitRecordedFile: useTranslations('submitRecordedFile'),
                        discardRecordedFile: useTranslations('discardRecordedFile')
                    },
                    videoConstraints: {
                        facingMode: 'environment'
                    }
                })
                .use(Tus, {
                    endpoint: tusEndpoint
                });
        }, []);

        // We only have a css loader in the frontend/client not in ssr
        // so we must import the css files here
        const loadUppyStyles = async () => {
            // @ts-ignore
            await import('@uppy/core/dist/style.css');
            // @ts-ignore
            await import('@uppy/dashboard/dist/style.css');
            // @ts-ignore
            await import('@uppy/url/dist/style.css');
            // @ts-ignore
            await import('@uppy/webcam/dist/style.css');
        };

        React.useEffect(() => {
            if (canUseDOM) {
                loadUppyStyles();
            }
            return () => uppy.close();
        }, []);

        uppy.on('complete', (result) => {
            if (onUploadComplete) {
                onUploadComplete(result);
            }
        });

        /**
         * The doUpload function can be called from a parent
         */
        useImperativeHandle(ref, () => ({
            doUpload: async () => {
                const state = uppy.getState();
                const count = state.files && Object.keys(state.files).length;
                if (count > 0) {
                    return uppy.upload();
                } else {
                    return Promise.resolve();
                }
            },
            hasFiles: () => {
                const state = uppy.getState();
                const count = state.files && Object.keys(state.files).length;
                return count > 0;
            }
        }));

        return (
            <div className={cx(styles.wrapper, className, markAsRequired && styles.markAsRequired)}>
                {markAsRequired && markAsRequiredMessage && (
                    <div className={styles.markAsRequiredMessage}>{markAsRequiredMessage}</div>
                )}
                <Dashboard
                    showProgressDetails={true}
                    note={'Images and video only, 2–3 files, up to 5 MB'}
                    height="300px"
                    width="100%"
                    proudlyDisplayPoweredByUppy={false}
                    hideUploadButton={true}
                    className={styles.dragdrop}
                    uppy={uppy}
                    plugins={['Webcam']}
                />
            </div>
        );
    }
);

VitraUpload.displayName = 'VitraUpload';

export default VitraUpload;
