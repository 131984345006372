import * as React from 'react';

/* tslint:disable */
const SvgArrowSmallLeft = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  viewBox="0 0 11.248 19" {...props}>
    <path fill="#333333" d="M0,9.501l10.412-9.373c0.205-0.186,0.521-0.168,0.706,0.037c0.187,0.205,0.169,0.521-0.036,0.707L1.493,9.5
        l9.589,8.629c0.203,0.186,0.223,0.5,0.036,0.705c-0.186,0.205-0.501,0.223-0.706,0.037L0,9.501z"/>
    </svg>
);
/* tslint:enable */

export default SvgArrowSmallLeft;
