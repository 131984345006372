import * as React from 'react';

const SvgV2CheckCircled = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={25} height={25} {...props}>
        <g fillRule="nonzero" fill="none">
            <circle fill="#F78181" cx={12.5} cy={12.5} r={12.5} />
            <path fill="#FFF" d="M9.536 16.021L6 12.486l1.414-1.414 3.536 3.535 7.071-7.07 1.414 1.413-8.485 8.486z" />
        </g>
    </svg>
);

export default SvgV2CheckCircled;
