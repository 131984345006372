import { useCartBffContext } from 'common/hooks/useCart-v2';
import type { WishlistBffContext } from 'common/hooks/useWishlist';
import { useWishlistBffContext } from 'common/hooks/useWishlist';
import { BlankButton } from 'common/primitives/buttons';
import IconCartV2 from 'common/primitives/icons/components/V2Cart';
import IconClose from 'common/primitives/icons/components/V2CloseSmall';
import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';

const styles = {
    button: css`
        display: flex;
        align-items: center;
        svg {
            width: 21px;
        }
        margin-bottom: ${rh(0.4)};
    `,
    iconWrapper: css`
        position: absolute;
        z-index: 1;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: white;
        align-items: center;
        justify-content: center;
        &:hover svg {
            fill: ${colors.primary};
        }
    `,
    iconWrapperRemove: css`
        right: 24px;
        top: 32px;
    `,
    iconWrapperRemoveCart: css`
        right: 24px;
        top: 0;
    `
};

interface WishlistProducts {
    product: Product;
    type: 'all' | 'cart';
}

const vitraProductWishlistButtons: React.FunctionComponent<WishlistProducts> = ({ type, product }) => {
    const wishlistContext: WishlistBffContext = useWishlistBffContext();
    const cart = useCartBffContext();

    const onRemoveProduct = (e: any) => {
        e.preventDefault();
        wishlistContext.removeItem(product.id);
    };

    const addToCart = async (e: any) => {
        e.preventDefault();
        await cart.addItem(product.sku!, 'product');
    };

    const hasCartAttribute = (product as any).cart /*legacy*/ || (product as any).isSellable; /*bff*/

    return (
        <Fragment>
            {cart.hasCart && hasCartAttribute && (
                <div className={cx(styles.iconWrapper, styles.iconWrapperRemoveCart)}>
                    <BlankButton testId="addToCart" onClick={addToCart} className={styles.button}>
                        <IconCartV2 />
                    </BlankButton>
                </div>
            )}
            {type === 'all' && (
                <div className={cx(styles.iconWrapper, styles.iconWrapperRemove)}>
                    <BlankButton testId="removeWishlist" onClick={onRemoveProduct} className={styles.button}>
                        <IconClose />
                    </BlankButton>
                </div>
            )}
        </Fragment>
    );
};

export default vitraProductWishlistButtons;
