import { useBoostrap } from 'common/hooks/useBoostrap';
import { reportError } from 'common/hooks/useError';
import { TextInput } from 'common/primitives/forms';
import V2ArrowRightSmall from 'common/primitives/icons/components/V2ArrowRightSmall';
import V2Search from 'common/primitives/icons/components/V2Search';
import { colors, rh } from 'common/styles';
import { get } from 'common/utils/fetch';
import { css, cx } from 'linaria';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

const styles = {
    wrapper: css`
        position: relative;
        svg {
            width: 18px;
            height: 18px;
            position: absolute;
            right: 13px;
            top: 21px;
            fill: ${colors.textGrey};
            pointer-events: none;
        }
        label {
            max-width: calc(100% - 32px);
            text-overflow: ellipsis;
        }
    `,
    proposals: css`
        border: 1px solid ${colors.black};
        background: white;
        margin-top: calc(-${rh(0.65)} - 1px);
        position: absolute;
        z-index: 1;
        width: 100%;
        max-height: calc(5 * (${rh(0.5)} + ${rh(1)}));
        overflow: scroll;
    `,
    proposalsItem: css`
        display: flex;
        flex-direction: row;
        padding: ${rh(0.25)} ${rh(0.5)};
        &:hover {
            cursor: pointer;
            * {
                color: ${colors.primary} !important;
            }
        }
    `,
    proposalsItemCopy: css`
        flex: 0 1 auto;
    `,
    proposalsItemCopyText: css`
        color: ${colors.black};
    `,
    proposalsItemCopyDescription: css`
        color: ${colors.textGrey};
        font-weight: 100;
    `,
    proposalsItemIcon: css`
        flex: 0 1 7px;
        margin-left: auto;
    `
};

interface AddressInputProps extends InputProps {
    countryIso: string;
    onSuccess: (a: AddressProposalRetrieveResultItem | undefined) => void;
    errors: any;
    showErrorMessage: boolean;
}

interface AddressProposalFindItem {
    Id: string;
    Type: 'Street' | 'Address' | 'District';
    Text: string;
    Highlight: string;
    Description: string;
}

interface AddressProposalFindResult {
    Items: [AddressProposalFindItem];
}

export interface AddressProposalRetrieveResultItem {
    Id: string;
    DomesticId: string;
    Language: string;
    LanguageAlternatives: string;
    Department: string;
    PostalCode: string;
    Company: string;
    ProvinceName: string;
    SubBuilding: string;
    BuildingNumber: string;
    BuildingName: string;
    SecondaryStreet: string;
    ProvinceCode: string;
    Street: string;
    Block: string;
    Neighbourhood: string;
    District: string;
    City: string;
}

interface AddressProposalRetrieveResult {
    Items: [AddressProposalRetrieveResultItem];
}

const AddressInput: React.FunctionComponent<AddressInputProps> = (props) => {
    const { countryIso, name, label, className, inputRef, onSuccess, errors, showErrorMessage } = props;

    const configuration = useBoostrap('loqateServiceApi', {
        apiFindUrl: 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws',
        apiRetrieveUrl: 'https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3ex.ws',
        apiKey: 'ZR19-WK37-AX15-CN11'
    });

    const makeAPIFindCall = async (text: string, container?: string) => {
        const { apiFindUrl, apiKey } = configuration;
        const countries = countryIso.toUpperCase();
        const limit = 5;
        const url = `${apiFindUrl}?Key=${apiKey}&Countries=${countries}&Limit=${limit}&Text=${text}&Container=${container}`;
        try {
            return await get<AddressProposalFindResult>(`${url}`);
        } catch (err) {
            reportError(err, 'loqate address - find service');
        }
    };

    // Fetch deeper by passing a previous id of a District or Street
    const [containerID, setContainerId] = useState<string | undefined>(undefined);
    const fetchContainerContents = (text: string, container: string) => {
        makeAPIFindCall(text, container).then((result) => {
            if (result && result.Items) {
                setAddressProposals(result.Items);
            }
        });
    };

    // Fetch proposals by user input
    const [addressProposals, setAddressProposals] = useState<AddressProposalFindItem[]>([]);
    const fetchAddressProposals = debounce(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length < 4) {
                setAddressProposals([]);
                setContainerId(undefined);
                return;
            }
            makeAPIFindCall(e.target.value, containerID).then((result) => {
                if (result && result.Items) {
                    setAddressProposals(result.Items);
                }
            });
        },
        120,
        { leading: false }
    );

    // Retrieve a full address object based by its id
    const makeAPIRetrieveCall = async (id: string) => {
        const { apiRetrieveUrl, apiKey } = configuration;
        const url = `${apiRetrieveUrl}?Key=${apiKey}&Id=${id}`;
        try {
            const result = await get<AddressProposalRetrieveResult>(`${url}`);
            return result.Items[0];
        } catch (err) {
            reportError(err, 'loqate adress - retrieve service');
        }
    };

    const handleProposalClick = (a: AddressProposalFindItem) => {
        if (a.Type === 'Address') {
            makeAPIRetrieveCall(a.Id).then((r: AddressProposalRetrieveResultItem | undefined) => {
                onSuccess(r);
                setAddressProposals([]);
            });
        } else {
            // We remember the container id because its important if the user
            // starts to type again
            setContainerId(a.Id);
            fetchContainerContents(a.Text, a.Id);
        }
    };

    const renderAddressPropsoals = () => {
        return addressProposals.map((a, ix) => {
            return (
                <div
                    data-testid={`address-suggest-${ix}`}
                    role="button"
                    className={styles.proposalsItem}
                    onClick={() => handleProposalClick(a)}
                >
                    <div className={styles.proposalsItemCopy}>
                        <span className={styles.proposalsItemCopyText}>{a.Text}</span>
                        <span className={styles.proposalsItemCopyDescription}>&nbsp;-&nbsp;{a.Description}</span>
                    </div>
                    {a.Type !== 'Address' && (
                        <div className={styles.proposalsItemIcon}>
                            <V2ArrowRightSmall />
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        <div className={cx(styles.wrapper, className)}>
            <TextInput
                name={name}
                label={label}
                onChange={fetchAddressProposals}
                autoComplete={false}
                errors={errors}
                showErrorMessage={showErrorMessage}
                inputRef={inputRef}
            />
            <V2Search />
            {addressProposals && addressProposals.length > 0 && (
                <div className={styles.proposals}>{renderAddressPropsoals()}</div>
            )}
        </div>
    );
};

export default AddressInput;
