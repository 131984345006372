import useToggle from 'common/hooks/useToggle';
import V2Filter from 'common/primitives/icons/components/V2Filter';
import { colors, containerCentered, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import flatten from 'lodash/flatten';
import React from 'react';
import Translate from '../translations';
import { ListingFilter } from './types';
import { DropDownListCategories, DropDownListItem, VitraDropDownList } from './vitraDropDownList';
import VitraListingCounter from './vitraListingFilterCounter';
import VitraListingFilterSelected from './vitraListingFilterSelected';
import VitraListingSorting from './vitraListingSorting';

const styles = {
    wrapper: css`
        position: relative;
        display: block;
        ${media.tablet} {
            display: none;
        }
    `,
    mainToggle: css`
        ${linkColor(colors.black, colors.primary)};
        text-decoration: none;
        font-size: 14px;
        svg {
            margin-right: 13px;
        }
    `,
    filterRow: css`
        display: flex;
        align-items: center;
        height: ${rh(2)};
        border-bottom: 1px solid ${colors.lightgrey};
    `,
    filterRowCount: css`
        color: ${colors.lightgrey};
        font-weight: 500;
        margin-left: auto;
    `,
    sortRow: css`
        margin-left: auto;
        height: ${rh(3)};
        display: flex;
        align-items: center;
    `,
    mobilActiveFiltersAndSorting: css`
        display: flex;
        width: 100%;
    `
};

interface ListingFilterMobile extends Omit<ListingFilter, 'onSelect'> {
    onSelect: (key: string, value: string) => void;
    onClearAllFilters: () => void;
}

const VitraListingMobile: React.FunctionComponent<ListingFilterMobile> = ({
    categories,
    activeFilters,
    onSort,
    total,
    showCount,
    activeSorting,
    sorting,
    onSelect,
    onClearAllFilters,
    type
}) => {
    // First we need to extract the categories from the diffrent filters
    const dropdownItems: DropDownListCategories[] = [];
    const [showFilters, toggleFilters] = useToggle(false);

    // Map values
    const mapValue = (value: any, catId: string): DropDownListItem => {
        const activeFilter = activeFilters.find((f) => f.id === catId.toLocaleLowerCase());
        const active = activeFilter ? activeFilter.values : [];
        const isActive = active.includes(value.id);

        return {
            id: value.id.toLowerCase(),
            isActive,
            disabled: value.disabled,
            onClick: (toggleIsOpen, e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                onSelect(catId, value.id);
                toggleFilters();
            },
            link: {
                href: value.href || '#',
                name: value.name
            }
        };
    };

    const onToggleMobileFilters = (e: React.MouseEvent) => {
        e.preventDefault();
        toggleFilters();
    };

    // Create Category tree
    categories.forEach((cat) => {
        const children = cat.items || [];
        if (cat.type === 'list') {
            dropdownItems.push({
                title: cat.name,
                children: children.map((value: any) => mapValue(value, cat.id))
            });
        }
        if (cat.type === 'list-rows') {
            dropdownItems.push({
                title: cat.name,
                children: flatten(children.map((v: any) => v.items)).map((value: any) => mapValue(value, cat.id))
            });
        }
        if (cat.type === 'custom') {
            dropdownItems.push({
                title: cat.name,
                children: [],
                render: (onClose) => cat.render && cat.render(activeFilters, onClose, () => toggleFilters())
            });
        }
    });

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className={cx(styles.wrapper)}>
            <div className={cx(styles.filterRow, containerCentered)}>
                <a href="#" onClick={onToggleMobileFilters} className={styles.mainToggle}>
                    <V2Filter />
                    <Translate id="listing.main.filter" defaultMessage="Filter" />
                </a>

                {showCount && (
                    <div className={styles.filterRowCount}>
                        <VitraListingCounter type={type} total={total!} />
                    </div>
                )}
            </div>

            {showFilters && <VitraDropDownList items={dropdownItems} />}
            <div className={cx(containerCentered, styles.mobilActiveFiltersAndSorting)}>
                {activeFilters.length > 0 && (
                    <VitraListingFilterSelected
                        activeFilters={activeFilters}
                        removeFilter={onSelect}
                        categories={categories}
                        onClearAllFilters={onClearAllFilters}
                    />
                )}

                {sorting && sorting.length > 0 && (
                    <div className={cx(styles.sortRow)}>
                        <VitraListingSorting onSort={onSort} options={sorting as any} active={activeSorting} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default VitraListingMobile;
