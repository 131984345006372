/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { initialSetup, initialState } from '../constants/state.constants';
import { LibrarySetup, ReactZoomPanPinchProps, ReactZoomPanPinchState } from '../models/context.model';

export const createState = (props: ReactZoomPanPinchProps): ReactZoomPanPinchState => {
    return {
        previousScale: props.initialScale ?? initialState.scale,
        scale: props.initialScale ?? initialState.scale,
        positionX: props.initialPositionX ?? initialState.positionX,
        positionY: props.initialPositionY ?? initialState.positionY
    };
};

export const createSetup = (props: any): LibrarySetup => {
    const newSetup = { ...initialSetup };

    Object.keys(props).forEach((key) => {
        const validValue = typeof (props as any)[key] !== 'undefined';
        const validParameter = typeof (initialSetup as any)[key] !== 'undefined';
        if (validParameter && validValue) {
            const dataType = Object.prototype.toString.call((initialSetup as any)[key]);
            const isObject = dataType === '[object Object]';
            const isArray = dataType === '[object Array]';
            if (isObject) {
                (newSetup as any)[key] = { ...(initialSetup as any)[key], ...props[key] };
            } else if (isArray) {
                (newSetup as any)[key] = [...(initialSetup as any)[key], ...props[key]];
            } else {
                (newSetup as any)[key] = props[key];
            }
        }
    });

    return newSetup;
};
