import { useConfig } from 'common/hooks/useBoostrap';
import { useWishlistBffContext } from 'common/hooks/useWishlist';
import Button from 'common/primitives/buttons';
import VitraProduct from 'common/primitives/product/vitraProduct';
import Translate, { useTranslations } from 'common/primitives/translations';
import { colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import DropDown from './dropDown';
import { generateNavigationAnayltics } from './helpers';
import { IconHeart } from './icons';

interface DropDownWishlistProps {
    items?: Product[];
}

const styles = {
    hasItems: css`
        .dropdown__title {
            margin-bottom: ${rh(0.5)};
        }
    `,
    inlineIcon: css`
        display: inline-block;
        margin: 0 0.1em;
    `,
    wishlistItems: css`
        border-block: 1px solid ${colors.inputBorderColor};
        margin: 0 ${rh(-0.625)};
        padding: 0 ${rh(0.625)} ${rh(0.625)};
        max-height: 320px;
        overflow-y: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
    `,
    wishlistItem: css`
        .product__name,
        .product__designer {
            ${fontSizeAndLineHeight('14px', 0.75)}
            padding: 0 ${rh(0.1)};
            margin: 0;
        }
        .product__designer {
            color: ${colors.textGrey};
        }
    `,
    button: css`
        margin: ${rh(0.625)} 0 0;
    `
};

// we need to use the icon inside a translation string and the Translate component
// only allows an HTML string instead of a React element, so we prerender it first
const iconHeartMarkup = ReactDOMServer.renderToStaticMarkup(
    <IconHeart className={styles.inlineIcon} width={18} height={18} viewBox="0 0 24 24" />
);

const DropDownWishlist: React.FunctionComponent<DropDownWishlistProps> = ({ items }) => {
    const wishlistContext = useWishlistBffContext();

    const { wishlist } = wishlistContext;

    const i = items || wishlist?.lineItems || [];
    const numberOfItems = i.length;

    // Becayuse we set the wishlist badge via signals,
    // we need to make sure that the wishlist data is loaded as soon as the dropdown is opened
    useEffect(() => {
        wishlistContext.loadWishlistData(true);
    }, []);

    const t = {
        titleEmpty: useTranslations('dropdown.wishlist.title.empty', 'Your Wish list is empty'),
        titleSingleProduct: useTranslations('dropdown.wishlist.title.single.product', '1 Item in your Wish list'),
        titleMultipleProducts: useTranslations(
            'dropdown.wishlist.title.multiple.products',
            '{numberOfItems} Items in your Wish list',
            { numberOfItems: numberOfItems.toString() }
        ),
        link: useTranslations('dropdown.wishlist.link', 'Wish list')
    };

    const title = (() => {
        if (numberOfItems > 1) return t.titleMultipleProducts;
        if (numberOfItems === 1) return t.titleSingleProduct;
        return t.titleEmpty;
    })();

    const wishlistLink: Link = {
        href: useConfig('wishlistUrl', '/wishlist'),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-wishlist'
        }
    };

    return (
        <DropDown title={title} className={cx(numberOfItems && styles.hasItems)}>
            {numberOfItems ? (
                <ul className={styles.wishlistItems}>
                    {i.map((item) => (
                        <VitraProduct className={styles.wishlistItem} product={item as any} />
                    ))}
                </ul>
            ) : (
                <Translate
                    html
                    id="dropdown.wishlist.empty.message"
                    defaultMessage="Use {wishlistIcon} to add items to your wish list"
                    data={{ wishlistIcon: iconHeartMarkup }}
                />
            )}
            <Button
                className={styles.button}
                testId="dropDownWishlistLink"
                link={wishlistLink}
                analyticsData={generateNavigationAnayltics(wishlistLink)}
            >
                {t.link}
            </Button>
        </DropDown>
    );
};

export default DropDownWishlist;
