import { useBreakpoint } from 'common/hooks/useBreakpoints';
import { useEvents } from 'common/hooks/useEvents';
import { useKey } from 'common/hooks/useKey';
import IconShrink from 'common/primitives/icons/components/V2Shrink';
import VitraPinchZoom from 'common/primitives/pinchZoom/vitraPinchZoom';
import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import VitraPicture from '../images/vitraPicture';
import { Slide, Swiper } from '../swiper/vitraSwiper';
import { Video } from '../video/types';
import VitraModal from './vitraModal';

export interface VitraMediaModalProps {
    /**
     * Id used to push events
     */

    id: string;
    /**
     * A Classname for the wrapper
     */
    className?: string;

    /**
     * array of images
     */
    content: (Image | Video)[];

    /**
     * On which image should it start
     */
    startIndex?: number;

    onClose?: () => void;
}

const styles = {
    wrapper: css`
        position: relative;
    `,
    slide: css`
        flex: 0 0 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    picture: css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (orientation: portrait) {
            img {
                max-width: 85vw;
                height: auto;
            }
        }
        @media (orientation: landscape) {
            img {
                max-height: 85vh;
                width: auto;
            }
        }
    `,
    arrow: css`
        path {
            fill: #fff;
        }
    `,
    desktopModalClass: css`
        overflow: hidden;
    `,
    mobileModalClass: css`
        width: 100vw;
        height: 100vh;
        margin: 0;
        max-height: 100%;
        background: ${colors.black};
    `,
    mobileModalClose: css`
        background: rgba(255, 255, 255, 0.9);
        position: absolute;
        z-index: 5000;
        top: 0px;
        right: 0px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 14px;
            height: 14px;
            fill: ${colors.black};
        }
        :hover {
            cursor: pointer;
        }
    `
};

const VitraMediaModal: React.FunctionComponent<VitraMediaModalProps> = ({ id, startIndex, content, onClose }) => {
    const eventId = `media-modal-${id}`;
    const emit = useEvents(eventId);
    const breakpoint = useBreakpoint('desktop');
    const isMobile = ['mobile', 'mobileX', 'tablet'].includes(breakpoint);

    // Close on Escape
    useKey((key) => {
        if (key === 'RIGHT') {
            emit('scrollNext');
        }
        if (key === 'LEFT') {
            emit('scrollPrev');
        }
    });

    if (isMobile) {
        return (
            <VitraModal
                modalClass={styles.mobileModalClass}
                closeComponent={
                    <div onClick={onClose} className={styles.mobileModalClose}>
                        <IconShrink />
                    </div>
                }
            >
                <VitraPinchZoom
                    images={content.map((item: any) => (item.poster ? item.poster : item))}
                    imagesStartIndex={startIndex}
                />
            </VitraModal>
        );
    }

    return (
        <VitraModal
            type="minimal"
            onClose={onClose}
            blockBody={true}
            modalClass={styles.desktopModalClass}
            closeOnClickOutside={true}
        >
            <div className={styles.wrapper}>
                <Swiper
                    arrowsClassName={styles.arrow}
                    eventId={eventId}
                    options={{ startIndex, draggable: isMobile, loop: true }}
                    showArrows={true}
                >
                    {content.map((item: any) => {
                        const image = item.poster ? item.poster : item;
                        return (
                            <Slide className={cx(styles.slide)}>
                                <div className={styles.picture}>
                                    <VitraPicture image={image} />
                                </div>
                            </Slide>
                        );
                    })}
                </Swiper>
            </div>
        </VitraModal>
    );
};
export default VitraMediaModal;
