import * as React from 'react';

const SvgV2ArrowRightSmall = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={11.314} height={11.314} {...props}>
        <path
            d="M5.563.095a.133.133 0 01.188 0l5.468 5.469a.133.133 0 010 .188L5.751 11.22a.133.133 0 01-.188 0l-.566-.566a.133.133 0 010-.188l4.808-4.809L4.997.85a.134.134 0 010-.19z"
            data-name="icon/single/arrow-right/small"
        />
    </svg>
);

export default SvgV2ArrowRightSmall;
