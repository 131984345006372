import * as React from 'react';

const SvgLinkedin = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  height="1em" viewBox="0 0 22 22" {...props}>
        <path
            fill="#333"
            d="M20.371 0H1.623C.728 0 0 .71 0 1.585v18.827C0 21.289.728 22 1.623 22h18.748c.896 0 1.63-.711 1.63-1.588V1.585C22 .71 21.267 0 20.371 0zM6.527 18.746H3.26V8.248h3.267v10.498zM4.895 6.812a1.891 1.891 0 1 1 0-3.784 1.892 1.892 0 0 1 0 3.784zm13.851 11.934h-3.26v-5.104c0-1.218-.024-2.784-1.696-2.784-1.697 0-1.957 1.326-1.957 2.695v5.193H8.574V8.248h3.128v1.434h.044c.435-.826 1.5-1.696 3.088-1.696 3.301 0 3.911 2.174 3.911 5.001v5.759z"
        />
    </svg>
);

export default SvgLinkedin;
