import IconArrowDown from 'common/primitives/icons/components/V1ArrowDown';
import { colors, fontSizeAndLineHeight, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';

const styles = {
    table: css`
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        ${fontSizeAndLineHeight('14px')}
        a {
            ${linkColor(colors.black, colors.primary)};
        }

        thead {
            display: none;
            ${media.desktop} {
                display: table-header-group;
            }
        }

        td {
            display: flex;
            &:before {
                content: attr(data-label);
                font-weight: 600;
                width: 120px;
                min-width: 120px;
            }

            ${media.desktop} {
                display: table-cell;
                &:before {
                    display: none;
                }
            }
        }
    `,
    thead: css``,
    th: css`
        border-bottom: 1px solid ${colors.black};
        font-style: normal;
        font-weight: 600;
        text-align: left;
        padding: ${rh(0.25)} ${rh(0.5)};

        a:hover svg path {
            fill: ${colors.primary};
        }
    `,
    tbody: css``,
    td: css`
        padding: ${rh(0.25)} ${rh(0.5)};
    `,
    alignRight: css`
        text-align: right;
    `,
    tr: css`
        &:nth-child(even) {
            background: ${colors.silverDark};
        }
    `,
    sortable: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        svg {
            transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }
    `,
    sortableActive: css`
        color: ${colors.primary}!important;
        svg path {
            fill: ${colors.primary};
        }
    `,
    sortableActiveReverse: css`
        svg {
            transform: rotate(180deg);
        }
    `
};

export interface VitraTableColumn {
    /**
     * The Coloum name
     * eg. `Sprache`
     */
    name: string | JSX.Element;

    /**
     * The JS Object Key
     */
    key: string;

    /**
     * If the colum is sortable
     * `Boolean` will compare the name, otherwise
     * the compare function will be used
     * (data: any) => boolean | boolean;
     */
    sortable?: any;

    /**
     * Where to align the thead and tbody
     */
    align?: 'left' | 'right';
}

interface VitraTableSortable {
    onClick: (e: any) => void;
    /**
     * If the sorting is active
     */
    isActive: boolean;
    /**
     * If the sorting is active and reversed
     */
    reverse: boolean;
}

interface VitraTableProps {
    /**
     * The Headers
     */
    columns: VitraTableColumn[];

    /**
     * The Data for the table
     * as an array of objects
     */
    data: Record<string, unknown>[];

    /**
     * The className of the table
     */
    className?: string;

    /**
     * Class name of the Column
     */
    rowCassName?: string;
}

const Sortable: React.FunctionComponent<VitraTableSortable> = ({ onClick, isActive, reverse, children }) => (
    <a
        href="#"
        className={cx(reverse && styles.sortableActiveReverse, isActive && styles.sortableActive, styles.sortable)}
        onClick={onClick}
    >
        {children}
        <IconArrowDown />
    </a>
);

const VitraTable: React.FunctionComponent<VitraTableProps> = ({ columns, data, className, rowCassName }) => {
    const [sorting, setSorting] = useState<any>();
    const columnKeys = columns.map((c) => c.key);

    const onSort = (column: VitraTableColumn) => (e: any) => {
        e.preventDefault();
        if (sorting && sorting.key === column.key) {
            setSorting({ ...sorting, reverse: !sorting.reverse });
        } else {
            const sortByKeyOrFunc = typeof column.sortable === 'boolean' ? column.key : column.sortable;
            setSorting({ key: column.key, sort: sortByKeyOrFunc, reverse: false });
        }
    };
    // We only show data that has a header column
    let filteredAndSortedData = data.map((row: any) => pick(row, columnKeys));

    // We sort the data
    if (sorting) {
        filteredAndSortedData = sortBy(filteredAndSortedData, sorting.sort);
        if (sorting.reverse) {
            filteredAndSortedData = filteredAndSortedData.reverse();
        }
    }

    return (
        <table className={cx(styles.table, className)}>
            <thead className={styles.thead}>
                {columns.map((column) => {
                    const isActiveSort = sorting && column.key === sorting.key;
                    const sortable = column.sortable;
                    return (
                        <th className={cx(rowCassName, styles.th, column.align === 'right' && styles.alignRight)}>
                            {!sortable && column.name}
                            {!!sortable && (
                                <Sortable
                                    isActive={isActiveSort}
                                    reverse={isActiveSort && sorting.reverse}
                                    onClick={onSort(column)}
                                >
                                    {column.name}
                                </Sortable>
                            )}
                        </th>
                    );
                })}
            </thead>
            <tbody className={styles.tbody}>
                {filteredAndSortedData.map((row) => {
                    return (
                        <tr className={styles.tr}>
                            {Object.keys(row).map((key, ix) => {
                                const head = columns[ix];
                                const isRight = head && head.align === 'right';
                                return (
                                    <td
                                        data-label={head.name}
                                        className={cx(styles.td, row.className, isRight && styles.alignRight)}
                                    >
                                        {row[key]}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default VitraTable;
