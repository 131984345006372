import React from 'react';
import { useStateValue } from '../state/find-vitra.state-provider';
import { CommonSearchResultList, CommonSearchResultListEmpty } from './commonSearchResultList';

interface CommonSearchResultProps {
    className?: string;
    type?: 'desktop' | 'mobile';
}

const CommonSearchResult: React.FunctionComponent<CommonSearchResultProps> = ({ type = 'desktop', className }) => {
    const [state]: any = useStateValue();

    const locationsChunked = state.locationsFiltered.chunked[state.pagination.page - 1];

    return (
        <div className={className}>
            {locationsChunked && locationsChunked.length > 0 && <CommonSearchResultList type={type} />}
            {(!locationsChunked || locationsChunked.length === 0) && <CommonSearchResultListEmpty type={type} />}
        </div>
    );
};

export default CommonSearchResult;
