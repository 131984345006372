import * as React from 'react';

const SvgYoutube = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  height="1em" viewBox="0 0 52.715 22" {...props}>
        <path d="M19.721 17.682h-1.985v-1.15c-.753.863-1.391 1.299-2.087 1.299-.61 0-1.034-.287-1.246-.809-.129-.316-.221-.809-.221-1.535v-8.36h1.985v8.568c.047.293.171.4.424.4.38 0 .724-.33 1.145-.922V7.126h1.985v10.556zM12.719 10.575c0-1.128-.227-1.975-.627-2.508-.529-.722-1.359-1.017-2.195-1.017-.946 0-1.666.295-2.194 1.017-.407.533-.625 1.389-.625 2.517l-.002 3.754c0 1.121.194 1.896.601 2.424.528.721 1.385 1.102 2.221 1.102s1.707-.381 2.236-1.102c.4-.527.585-1.303.585-2.424v-3.763zm-1.986 3.972c.104 1.045-.22 1.562-.836 1.562s-.94-.518-.836-1.562v-4.181c-.104-1.045.22-1.535.836-1.535s.941.489.837 1.535l-.001 4.181zM4.882 11.934v5.748H2.791v-5.748S.626 4.887.179 3.574h2.194L3.84 9.078 5.3 3.573h2.194l-2.612 8.361zM40.143 8.805c-.312 0-.669.165-.985.477l-.002 6.337c.316.316.675.477.987.477.545 0 .791-.4.791-1.445v-4.493c0-1.046-.246-1.353-.791-1.353zM47.059 8.805c-.632 0-.899.267-.899 1.243v1.156l1.776-.002v-1.141c0-.965-.25-1.256-.877-1.256z" />
        <path d="M52.225 4.713s-.306-2.141-1.241-3.083C49.798.393 48.467.387 47.858.314 43.49 0 36.94 0 36.94 0h-.014s-6.55 0-10.917.314C25.4.387 24.07.393 22.884 1.63c-.937.943-1.24 3.083-1.24 3.083s-.312 2.514-.312 5.028v2.356c0 2.514.312 5.027.312 5.027s.304 2.141 1.24 3.084c1.187 1.238 2.746 1.197 3.44 1.328 2.496.238 10.609.312 10.609.312s6.557-.01 10.924-.324c.609-.072 1.94-.078 3.126-1.316.936-.943 1.241-3.084 1.241-3.084s.312-2.514.312-5.027V9.741c0-2.514-.311-5.028-.311-5.028zM28.186 17.682h-2.091V5.664H23.9V3.68l6.584-.002v1.985h-2.299v12.019zm7.523 0h-1.985v-1.15c-.753.863-1.391 1.299-2.087 1.299-.609 0-1.034-.287-1.246-.809-.129-.316-.22-.809-.22-1.535V7.231h1.985v8.464c.046.293.171.4.423.4.38 0 .725-.33 1.145-.922V7.231h1.985v10.451zm7.211-3.135c0 .965-.083 1.646-.209 2.09-.253.775-.804 1.18-1.557 1.18-.673 0-1.362-.404-1.994-1.188l-.003 1.053h-1.881V3.678h1.881l-.002 4.565c.611-.752 1.303-1.18 1.999-1.18.753 0 1.26.435 1.512 1.217.127.42.254 1.096.254 2.085v4.182zm7.005-1.777h-3.766v1.877c-.007.965.268 1.449.917 1.449.466 0 .737-.252.846-.758.018-.104.014-.555.014-1.314h1.985v.297c0 .609-.05 1.041-.067 1.23-.063.42-.212.799-.442 1.133-.523.758-1.3 1.133-2.288 1.133-.989 0-1.743-.355-2.29-1.07-.402-.521-.659-1.299-.659-2.408V10.68c0-1.116.234-1.979.636-2.507.548-.714 1.301-1.092 2.267-1.092.949 0 1.702.378 2.232 1.092.396.528.616 1.345.616 2.461v2.136z" />
    </svg>
);

export default SvgYoutube;
