import * as React from 'react';

const SvgV1Share2 = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={18} height={17} viewBox="0 0 18 17" {...props}>
        <g fillRule="evenodd">
            <path d="M17.5 9.992a.5.5 0 00-.5.5v5H1v-5a.5.5 0 00-1 0v5.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-5.5a.5.5 0 00-.5-.5" />
            <path d="M6.87 4.079L8.5 2.286v7.707a.5.5 0 001 0V2.286l1.63 1.793a.5.5 0 10.74-.673L9.37.656a.517.517 0 00-.74 0l-2.5 2.75a.5.5 0 10.74.673" />
        </g>
    </svg>
);

export default SvgV1Share2;
