import { colors, linkColor, rh } from 'common/styles';
import { css, cx } from 'linaria';

import React from 'react';
import V2ArrowLeftSmall from 'common/primitives/icons/components/V2ArrowLeftSmall';
import V2ArrowRightSmall from 'common/primitives/icons/components/V2ArrowRightSmall';

export interface Pagination {
    /**
     * Total number of items
     */
    total: number;

    /**
     * How many pages to show right/left
     * to the current page
     */
    showPages: number;

    /**
     * used to generate te link for SEO
     */
    generateLink?: (page: number) => string;

    /**
     * How many items per page
     */
    perPage: number;

    /**
     * The current Page
     */
    currentPage: number;

    /**
     * Gets called when the user clicks on a page
     */
    onPage: (page: number) => void;
}

const styles = {
    pagination: css`
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    `,
    prev: css`
        svg {
            fill: ${colors.textGrey};
        }
    `,
    next: css`
        svg {
            fill: ${colors.textGrey};
        }
    `,
    items: css`
        color: ${colors.textGrey};
        font-weight: 100;
        > * {
            padding: ${rh(0.5)} 8px;
        }
    `,
    text: css`
        ${linkColor(colors.black, colors.primary)};
        svg {
            position: relative;
            top: -1px;
        }
    `,
    link: css`
        display: inline-flex;
        ${linkColor(colors.textGrey, colors.primary)};
        margin-left: 3px;
        margin-right: 3px;
        text-decoration: none;
    `,
    linkActive: css`
        ${linkColor(colors.black, colors.black)};
        font-weight: 600;
    `
};

// Kicked out translations pagination_next, pagination_prev
const VitraPagination: React.FunctionComponent<Pagination> = ({
    total,
    showPages,
    perPage,
    generateLink,
    currentPage,
    onPage
}) => {
    const numberOfPages = Math.ceil(total / perPage);

    if (numberOfPages <= 1) {
        return null;
    }

    // taken from https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-2788898
    const pagArray = [];
    for (let i = Math.max(2, currentPage - showPages); i <= Math.min(numberOfPages - 1, currentPage + showPages); i++) {
        pagArray.push(i);
    }

    if (currentPage - showPages > 2) {
        pagArray.unshift('...');
    }
    if (currentPage + showPages < numberOfPages - 1) {
        pagArray.push('...');
    }

    pagArray.unshift(1);
    pagArray.push(numberOfPages);
    const hasNext = currentPage < numberOfPages;
    const hasPrev = currentPage > 1;

    const goToPage = (index: number | string) => (e: any) => {
        e.preventDefault();
        if (typeof index === 'string') {
            return;
        }
        if (index !== currentPage) {
            onPage(index);
        }
    };

    return (
        <div className={cx('pagination', styles.pagination)}>
            <div className={styles.prev}>
                {hasPrev && (
                    <a
                        href={generateLink ? generateLink(currentPage - 1) : '#'}
                        className={styles.text}
                        onClick={goToPage(currentPage - 1)}
                    >
                        <V2ArrowLeftSmall />
                    </a>
                )}
            </div>
            <div className={styles.items}>
                {pagArray.map((page) => {
                    if (page === '...') {
                        return <div className={styles.link}>{page}</div>;
                    }
                    return (
                        <a
                            href={generateLink ? generateLink(page as number) : '#'}
                            onClick={goToPage(page)}
                            className={cx(styles.link, page === currentPage && styles.linkActive)}
                        >
                            {page}
                        </a>
                    );
                })}
            </div>
            <div className={styles.next}>
                {hasNext && (
                    <a
                        href={generateLink ? generateLink(currentPage + 1) : '#'}
                        className={styles.text}
                        onClick={goToPage(currentPage + 1)}
                    >
                        <V2ArrowRightSmall />
                    </a>
                )}
            </div>
        </div>
    );
};

export default VitraPagination;
