import { hashCode } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';

interface ColumnsCSSItem {
    /**
     * one of the defined media query strings
     */
    media: string;
    /**
     * Amount of colums
     */
    columns: number;
}

interface ColumnsProps {
    columns: ColumnsCSSItem[];
    className?: string;
    children: JSX.Element[];
    padding?: boolean;
    ref?: any;
}

const styles = {
    vitraColumns: css`
        display: flex;
        column-gap: 16px;
        flex-wrap: wrap;
        &.vitra__columns--no-padding {
            gap: 0;
        }
    `,
    columnsItem: css`
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
    `
};
const VitraColumns: React.FunctionComponent<ColumnsProps> = (options) => {
    const { padding = true, columns, className, children, ref } = options;

    const cssId = `columns__${hashCode(JSON.stringify(columns))}`;

    // To account fo the space created by the gap we use the following formula:
    // calc((100% / n) - (gap * (n-1))/n)
    // where n is the number of child elements.
    return (
        <Fragment>
            <style>
                {columns.map(
                    (colCSS) =>
                        `${colCSS.media} {#${cssId} .columns__item {
                        flex: 1 0 calc(${100 / colCSS.columns}% - ${
                            padding ? (16 * (colCSS.columns - 1)) / colCSS.columns : 0
                        }px);
                        max-width: calc(${100 / colCSS.columns}% - ${
                            padding ? (16 * (colCSS.columns - 1)) / colCSS.columns : 0
                        }px);
                        width: calc(${100 / colCSS.columns}% - ${
                            padding ? (16 * (colCSS.columns - 1)) / colCSS.columns : 0
                        }px);
                    }}`
                )}
            </style>
            <div
                ref={ref}
                id={cssId}
                className={cx(
                    'vitra__columns',
                    !padding && 'vitra__columns--no-padding',
                    className,
                    styles.vitraColumns
                )}
            >
                {children.map((c) => (
                    <div className={cx('columns__item', !padding && 'columns__item--no-padding', styles.columnsItem)}>
                        {c}
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default VitraColumns;
