import { useConfig } from 'common/hooks/useBoostrap';
import Button from 'common/primitives/buttons';
import { colors, fontSizeAndLineHeight, fontStyles, linkColor, rh } from 'common/styles';
import { MediaContentPoi } from 'components/media/media';
import { css, cx } from 'linaria';
import React, { useState } from 'react';
import { addGaEvent } from '../analytics';
import VitraProductInfo from '../product/vitraProductInfo';
import VitraTooltip from '../tooltip/vitraTooltip';
import VitraPicture from './vitraPicture';

const poiWidth = 30;
const styles = {
    vitraPicturePoiWrapper: css`
        position: relative;
        height: 100%;
        overflow: hidden;
    `,
    image: css`
        width: 100%;
        height: auto;
    `,
    poiPositioning: css`
        position: absolute;
    `,
    poiAppearance: css`
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${poiWidth}px;
        height: ${poiWidth}px;
        background: rgb(235, 235, 235, .8);
        border-radius: 50%;
        border: 2px solid transparent;
        box-shadow: 0 0 3px 0px #00000026
        &:before {
            content: "";
            display: block;
            background: #FFF;
            border-radius: 50%;
            width: ${poiWidth - 10}px;
            height: ${poiWidth - 10}px;
        }
        &:after {
            position: absolute;
            content: "";
            display: block;
            border: 1px solid white;
            border-radius: 100%;
            opacity: 0;
            width: ${poiWidth + poiWidth}px;
            height: ${poiWidth + poiWidth}px;
            transform: scale(0.5);
            animation-name: ping;
            animation-iteration-count: infinite;
            animation-duration: 8s;
            animation-delay: 3s;
            animation-timing-function: ease-out;
        }
        &:hover {
            border-color: #FFF;
        }
        @keyframes ping {
            0% {
                transform: scale(0.5);
                opacity: 0.5;
            }
            25% {
                transform: scale(1);
                opacity: 0;
            }
            100% {
                transform: scale(1);
                opacity: 0;
            }
        }
    `,
    productWrapper: css`
        transition: none;
        padding: ${rh(0.5)};
        padding-left: ${rh(1)};
        padding-right: ${rh(1)};
        background: #fff;
    `,
    product: css`
        margin-bottom: ${rh(0.5)};
    `,
    infoWrapper: css`
        transition: none;
        background: #fff;
        max-width: 300px;
    `,
    infoButton: css`
        padding: ${rh(0.5)};
    `,
    infoTitlesNoImage: css`
        padding-top: ${rh(0)}!important;
    `,
    infoTitle: css`
        margin-bottom: ${rh(0)};
        padding-top: ${rh(0.5)};
        ${fontSizeAndLineHeight('18px')}
    `,
    infoSubtitle: css`
        margin-bottom: 0 !important;
    `,
    infoTitles: css`
        padding: ${rh(0.5)} ${rh(0.5)} 0;
        font-size: 15px;
        line-height: 21px;
        font-weight: 100;
        padding-top: 0;
        strong {
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
        }
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `,
    infoContent: css`
        padding: 0 ${rh(0.5)} ${rh(0.5)};
        font-size: 15px;
        line-height: 21px;
        font-weight: 100;
        padding-top: 0;
        strong {
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
        }
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `,
    infoContentNoImage: css`
        padding-top: ${rh(0.5)}!important;
    `
};

interface ItemPoi {
    position: any;
    point: MediaContentPoi;

    /**
     * Triggered when loading a configuration
     */
    onLoadConfiguration?: (productId: string) => void;
}

const VitraPoi: React.FunctionComponent<ItemPoi> = ({ point, onLoadConfiguration, position }) => {
    const showWishlist = useConfig('showWishlist', true);

    const onOpen = () => {
        let eventLabel = point.title || point.subtitle;

        // use product id
        if (point.type === 'product') {
            eventLabel = point.product?.id;
        }

        // fallback to link label
        if (!eventLabel) {
            eventLabel = point.info?.link?.analytics?.label;
        }

        addGaEvent({
            eventCategory: 'Product Interactions',
            eventAction: 'PIO Open',
            eventLabel
        });
    };

    const tipContent = () => {
        switch (point.type) {
            case 'product':
                return (
                    <div className={styles.productWrapper}>
                        <VitraProductInfo
                            display="full"
                            showDescription={false}
                            onLoadConfiguration={onLoadConfiguration}
                            showWishlist={showWishlist}
                            className={styles.product}
                            product={(point as any).product as Product}
                        />
                    </div>
                );
            case 'info':
                return (
                    <div className={styles.infoWrapper}>
                        {point.info && point.info.image && <VitraPicture lazy={true} image={point.info.image} />}
                        {(point.title || point.subtitle) && (
                            <div
                                className={cx(
                                    (!point.info || !point.info.image) && styles.infoTitlesNoImage,
                                    styles.infoTitles
                                )}
                            >
                                {point.title && (
                                    <h3
                                        className={cx(fontStyles.h3, styles.infoTitle)}
                                        dangerouslySetInnerHTML={{ __html: point.title }}
                                    />
                                )}
                                {point.subtitle && (
                                    <p
                                        className={cx(fontStyles.p, styles.infoSubtitle)}
                                        dangerouslySetInnerHTML={{ __html: point.subtitle }}
                                    />
                                )}
                            </div>
                        )}
                        {point.info && point.info.content && (
                            <div
                                className={cx(!point.info.image && styles.infoContentNoImage, styles.infoContent)}
                                dangerouslySetInnerHTML={{ __html: point.info.content }}
                            />
                        )}
                        {point.info && point.info.link && (
                            <div className={styles.infoButton}>
                                <Button
                                    type="blackBorder"
                                    testId="poi-info-button"
                                    link={point.info.link}
                                    size="small"
                                />
                            </div>
                        )}
                    </div>
                );

            default:
                return <div />;
        }
    };

    return (
        <div className={cx(styles.poiPositioning)} style={position}>
            <VitraTooltip onOpen={onOpen} trigger="click" tooltip={tipContent()}>
                <div className={cx(styles.poiAppearance, 'vitra__poi')} />
            </VitraTooltip>
        </div>
    );
};

interface PicturePoi {
    /**
     * The image
     */
    image: Image;

    /**
     * Optional classname for the wrapper
     */
    className?: string;

    /**
     * Points of interest
     */
    pois: MediaContentPoi[];

    /**
     * Triggered when loading a configuration
     */
    onLoadConfiguration?: (productId: string) => void;
}

const VitraPicturePoi: React.FunctionComponent<PicturePoi> = ({ className, onLoadConfiguration, image, pois }) => {
    const [points, setPoints] = useState<any>([]);

    const calculatePois = () => {
        setPoints(
            pois
                .map((p) => {
                    const position = {
                        top: `calc(${p.poi.y * 100}% - ${poiWidth / 2}px)`,
                        left: `calc(${p.poi.x * 100}% - ${poiWidth / 2}px)`
                    };
                    return { point: p, position };
                })
                .filter((el) => {
                    if (el.point.type === 'product') {
                        return !!el.point.product;
                    }
                    if (el.point.type === 'info') {
                        return !!el.point.info;
                    }
                })
        );
    };

    // Render
    return (
        <div className={cx(styles.vitraPicturePoiWrapper, className)}>
            <img
                src={image.src}
                alt={image.alt}
                onLoad={calculatePois}
                width={image.width}
                height={image.height}
                className={cx(styles.image)}
            />
            {points.map((item: any) => {
                return (
                    <VitraPoi point={item.point} onLoadConfiguration={onLoadConfiguration} position={item.position} />
                );
            })}
        </div>
    );
};

export default VitraPicturePoi;
