import VitraButton from 'common/primitives/buttons';
import { colors, fontStyles, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

interface PageStickerPropLink extends Link {
    onClick?: () => void;
}

export interface PageStickerProp {
    /**
     * ClassName of the wrapper component
     */
    className?: string;

    /**
     * Classname of the body
     */
    classNameBody?: string;

    /**
     *  The Background of the sticker
     */
    background: 'none' | 'white' | 'black';

    /**
     * The font color of the sticker
     */
    color: 'black' | 'white';

    /**
     * The Headline for the Sticker
     */
    headline?: string;

    /**
     * The content of the sticker
     */
    content?: string;

    /**
     * The link on the end of the Footer
     */
    link?: PageStickerPropLink;

    /**
     * The testId of the Button
     */
    testId?: string;
}

const styles = {
    wrapper: css`
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        height: 100%;
        width: 100%;
    `,
    fixedWrapper: css`
        display: flex;
        align-items: center;
    `,
    background: css`
        text-align: center;
        max-width: 620px;

        margin-left: ${rh(1)};
        margin-right: ${rh(1)};

        padding: ${rh(0.5)} ${rh(1)};
        ${media.desktop} {
            padding: ${rh(1)} ${rh(5)} ${rh(1)};
        }
    `,
    background__white: css`
        background: rgba(255, 255, 255, 0.7);
    `,
    background__black: css`
        background: rgba(0, 0, 0, 0.7);
    `,
    headline: css`
        margin-bottom: ${rh(0.25)};
        ${media.tablet} {
            margin-bottom: ${rh(0.5)};
            color: #fff;
        }
    `,
    content: css`
        margin-bottom: ${rh(0.25)};
        ${media.tablet} {
            margin-bottom: ${rh(0.5)};
        }
        &:last-child {
            margin-bottom: 0;
        }
    `,
    headline__white: css`
        color: #fff;
    `,
    headline__black: css`
        color: ${colors.black};
    `,
    content__white: css`
        color: #fff;
    `,
    content__black: css`
        color: ${colors.black};
    `,
    button: css`
        width: auto;
    `
};

export default class VitraPageSticker extends React.Component<PageStickerProp> {
    public render(): JSX.Element {
        const { background, color, headline, content, link, classNameBody, className, testId, children } = this.props;
        const stickerColor = color ? color : 'black';
        const backgroundColor = background ? background : 'white';

        const backgroundStyle = cx(styles.background, (styles as any)[`background__${backgroundColor}`], classNameBody);
        const headlineStyle = cx(fontStyles.h1, styles.headline, (styles as any)[`headline__${stickerColor}`]);
        const contentStyle = cx(fontStyles.p, styles.content, (styles as any)[`content__${stickerColor}`]);
        const buttonType = stickerColor === 'black' ? 'blackBorder' : 'whiteBorder';
        return (
            <div className={cx(styles.wrapper, className)}>
                <div className={styles.fixedWrapper}>
                    <div className={backgroundStyle}>
                        {!!headline && <h2 className={headlineStyle} dangerouslySetInnerHTML={{ __html: headline }} />}
                        {!!content && <p className={contentStyle} dangerouslySetInnerHTML={{ __html: content }} />}
                        {children}
                        {link && link.href && (
                            <VitraButton
                                className={styles.button}
                                testId={testId || 'sticker'}
                                type={buttonType}
                                link={link}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
