import useId from 'common/hooks/useId';
import useToggle from 'common/hooks/useToggle';
import globalStyle from 'common/primitives/forms/styles';
import IconEyeClosed from 'common/primitives/icons/components/V2EyeClosed';
import IconEyeOpen from 'common/primitives/icons/components/V2EyeOpen';
import { adjustFontSizeTo, colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

const styles = defaults(
    {
        textInput: css`
            width: 100%;
            ${fontSizeAndLineHeight('17px')}
            color: ${colors.black};
            border: 1px solid ${colors.inputBorderColor};
            border-radius: 0px;
            &:disabled {
                background: ${colors.inputDisabledBackground};
            }
            &:hover,
            &:active,
            &:focus {
                outline: none;
                &:not(:disabled) {
                    border-bottom-color: ${colors.black};
                }
            }
        `,
        passwordVisibilityIcon: css`
            svg {
                fill: ${colors.inputBorderColor};
                width: 18px;
                height: 18px;
                position: absolute;
                right: 16px;
                top: 22px;
                user-select: none;
                &:hover {
                    cursor: pointer;
                    fill: ${colors.black};
                }
            }
        `,
        // With a label we must position the input differeently
        // underneath the label..
        textInputWithLabel: css`
            padding: ${rh(0.65)} ${rh(0.5)} ${rh(0.15)};
        `,
        // Without a label we must position the input vertically
        // centered...
        textInputWithOutLabel: css`
            padding: ${rh(0.4)} ${rh(0.5)} ${rh(0.4)};
        `,
        textInputLabelFocussed: css`
            opacity: 1;
            top: 4px;
            left: calc(${rh(0.5)} - ${rh(0.15)});
            font-size: ${adjustFontSizeTo('12px')};
            width: auto;
            background: transparent;
            padding: 0 ${rh(0.15)};
        `,
        textInputLabelFocussedError: css`
            color: ${colors.error};
        `,
        textInputError: css`
            border-bottom-color: ${colors.error};
            &:hover,
            &:active,
            &:focus {
                border-bottom-color: ${colors.error};
            }
        `,
        textInputHidden: css`
            margin: 0 !important;
        `
    },
    globalStyle
);

interface TextInputProps extends InputProps {
    /**
     * Input type, defaults to text
     */
    type?: 'text' | 'password' | 'hidden' | 'search';

    /**
     * Input placehholder
     */
    placeholder?: string;

    /**
     * Let the input field automatically get focus when the page loads
     */
    autoFocus?: boolean;

    /**
     * On/Off for browser autocomplete features
     */
    autoComplete?: boolean;

    /**
     * The errors object passed from react-hook-form
     */
    errors?: any;

    /**
     * The defaultValues object passed from react-hook-form
     */
    defaultValues?: any;

    /**
     * Forces the input to be focussed. Useful if it appears dynamically
     */
    initallyFocussed?: boolean;

    /**
     * The input max length
     */
    maxLength?: number;

    /**
     * Strip leading and trailing whitespace
     */
    trimWhitespace?: 'all' | 'leading';
}

const TextInput: React.FunctionComponent<TextInputProps> = (props) => {
    const {
        inputRef,
        name,
        label,
        className,
        onChange,
        onBlur,
        onFocus,
        isRequired,
        placeholder,
        type = 'text',
        autoFocus,
        autoComplete,
        disabled,
        maxLength,
        errors,
        showErrorMessage,
        defaultValues,
        initallyFocussed = false,
        trimWhitespace
    } = props;

    const [isFocused, setIsFocused] = useState<boolean>(initallyFocussed);
    const [isTouched, setIsTouched] = useState<boolean>(false);

    // InitallyFocussed may change fom the outside
    useEffect(() => {
        setIsFocused(initallyFocussed);
    }, [initallyFocussed]);

    // Error
    const hasError = errors && get(errors, name);

    // Input id
    const id = useId();

    // Label
    const labelClassName = cx(
        styles.label,
        (isFocused || (!isTouched && defaultValues && get(defaultValues, name) !== undefined)) &&
            styles.textInputLabelFocussed,
        hasError && styles.textInputLabelFocussedError
    );

    const [passwordVisible, togglePasswordVisible] = useToggle(false);

    // Events
    const handleOnChange = (e: any) => {
        if (trimWhitespace) {
            // remove leading whitespaces
            if (trimWhitespace === 'leading') {
                e.target.value = e.target.value.replace(/^\s+/g, '');
            } else {
                e.target.value = e.target.value.trim();
            }
        }
        if (onChange) {
            onChange(e);
        }
    };

    const handleOnFocus = (e: any) => {
        setIsFocused(true);
        setIsTouched(true);
        if (onFocus) {
            onFocus(e);
        }
    };

    const handleOnBlur = (e: any) => {
        if (e.currentTarget.value === '') {
            setIsFocused(false);
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    // Avoid Chrome Autocomplete
    const chromeAutocompleteOff =
        autoComplete === false
            ? {
                  autocapitalize: 'off',
                  autocomplete: 'off',
                  autocorrect: 'off',
                  spellcheck: 'false'
              }
            : {};

    let inputType = type;
    if (passwordVisible && type === 'password') {
        inputType = 'text';
    }

    return (
        <div
            className={cx(
                'form__field',
                'form__field--input',
                type === 'hidden' && styles.textInputHidden,
                styles.wrapper,
                className
            )}
        >
            <input
                id={id}
                name={name}
                className={cx(
                    styles.textInput,
                    label && styles.textInputWithLabel,
                    !label && styles.textInputWithOutLabel,
                    hasError && styles.textInputError
                )}
                maxLength={maxLength}
                required={isRequired}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                type={inputType}
                ref={inputRef}
                aria-label={props['aria-label']}
                {...chromeAutocompleteOff}
            />
            {type !== 'hidden' && label && (
                <label className={labelClassName} htmlFor={id}>
                    {label}
                    {isRequired ? ' *' : ''}
                </label>
            )}
            {type === 'password' && (
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        togglePasswordVisible();
                    }}
                    className={styles.passwordVisibilityIcon}
                >
                    {passwordVisible && <IconEyeOpen />}
                    {!passwordVisible && <IconEyeClosed />}
                </div>
            )}
            {type !== 'hidden' && hasError && showErrorMessage && (
                <div className={cx('errorMessage', styles.errorMessage)}>{get(errors, name).message}</div>
            )}
        </div>
    );
};

export default TextInput;
