import IconCart from 'common/primitives/icons/components/V1Cart';
import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect, useState } from 'react';
import { FindVitraContentLocation, FindVitraContentLocationTag } from '../find-vitra-types';
import { premiumPartners } from '../premium/premium-partners';

interface CommonLocationTagsProps {
    location: FindVitraContentLocation;
    limit?: number;
    className?: string;
}

export const styles = {
    wrapper: css`
        flex: 0 0 100%;
        display: inline-flex;
        flex-wrap: wrap;
        user-select: none;
        line-height: 1.3em;

        .tags__item {
            display: inline-flex;
            background: ${colors.findVitraColorSilver};
            padding: 0.265em 0.795em;
            margin-right: 6px;
            margin-top: 8px;
            align-items: center;
            user-select: none;
            svg {
                width: 14px;
                height: 14px;
            }
            margin-bottom: 0;
            margin-top: 8px;
            user-select: none;
        }
    `
};

const CommonLocationTags: React.FunctionComponent<CommonLocationTagsProps> = ({ location, className }) => {
    /**
     * Sort the tags in the following order:
     *  - Authorized Dealer
     *  - Premium Partner Home with Vitra Space, Premium Partner Home, Premium Partner Office
     *  - Promotion Tags (e.g. Home Stories for Winter 2023/2024), alphabetically sorted
     *  - Home, Office
     */
    const tagGroups = [
        ['root.location.resellersShowrooms.authorizedDealer'],
        [...premiumPartners],
        ['promotion'],
        ['Home', 'Office']
    ];

    const [tagsToRender, setTagsToRender] = useState<FindVitraContentLocationTag[]>([]);

    useEffect(() => {
        const tagsSorted = [];
        for (const g in tagGroups) {
            let t = [];
            if (tagGroups[g][0] == 'promotion') {
                t = location.tags
                    .filter((tag) => !isNaN(parseInt(tag.id, 10)))
                    .sort((a, b) => a.name.localeCompare(b.name));
            } else {
                t = location.tags.filter((tag) => tagGroups[g].includes(tag.id));
            }
            tagsSorted.push(t);
        }
        setTagsToRender(tagsSorted.flat());
    }, [location.tags]);

    if (!location.tags || location.tags.length === 0) {
        return null;
    }

    return (
        <div className={cx(styles.wrapper, className)}>
            {location.purchasable && (
                <div className="tags__item">
                    <IconCart />
                </div>
            )}

            {tagsToRender.map((tag, tagIdx) => {
                return (
                    <div className="tags__item" key={tagIdx}>
                        {tag.name}
                    </div>
                );
            })}
        </div>
    );
};

export default CommonLocationTags;
