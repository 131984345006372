// tslint:disable max-line-length
import Button from 'common/primitives/buttons';
import { SelectInput } from 'common/primitives/forms';
import { adjustFontSizeTo, colors, containerCentered, fontSizeAndLineHeight, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Locale } from '../main-navigation-types';
import { IconClose } from './icons';

interface NavigationLocaleSwitcherProps {
    /**
     * On Submitting the form, gets called with the local
     * eg. `de-de`
     */
    onSubmit: (locale: any) => void;
    /**
     * What happens if the user closes the Switcher
     */
    onClose: () => void;
    /**
     * The user locale, needs to be comupted from browser or server side
     * eg `de` or `gb` or `us`
     */
    userLocale: string;
    /**
     * The proposed locale, the new site prefix
     * eg `de-de` or `en-as`, `en-lp`
     */
    proposedLocale: string;
    /**
     * The locales specified in the json
     * those values are fixed
     */
    locales: Locale[];
}

const COUNTRY_SWITCHER_STRINGS = {
    fr: {
        copy: 'Sélectionnez un autre pays ou une autre région pour voir le contenu disponible et magasiner en ligne.',
        continue: 'Continuer',
        other: 'Autre pays ou région'
    },
    en: {
        copy: 'Choose another country or region to see content specific to your location and shop online.',
        continue: 'Continue',
        other: 'Other country or region'
    },
    nl: {
        copy:
            'Kies een ander land of andere regio als je de content voor jouw locatie wilt zien en online wilt shoppen.',
        continue: 'Ga door',
        other: 'Kies je land of regio'
    },
    de: {
        copy:
            'Wähle ein anderes Land oder eine andere Region, um Inhalte für deinen Standort zu sehen und online einzukaufen.',
        continue: 'Weiter',
        other: 'Land oder Region auswählen'
    },
    jp: {
        copy:
            'お住まいの場所に合ったコンテンツを表示させ、オンラインで購入できるようにするには、国または地域を選んでください。',
        continue: '続ける',
        other: 'ほかの国または地域を選ぶ'
    },
    es: {
        copy: 'Escolha outro país ou região para ver conteúdo específico para sua localização e comprar online.',
        continue: 'Continuar',
        other: 'Elige tu país o región'
    }
};

const styles = {
    wrapper: css`
        width: 100%;
        overflow: hidden;
        text-align: center;
        background: ${colors.black};
        color: #fff;
        font-weight: 100;
        padding-top: ${rh(0.25)};
        padding-bottom: ${rh(0.25)};
    `,
    flex: css`
        ${media.tabletAndUp} {
            display: flex;
            flex: 1 1 100%;
            flex-wrap: nowrap;
            align-items: center;
        }
    `,
    form: css`
        ${media.tabletAndUp} {
            display: flex;
            flex: 1 1 100%;
            flex-wrap: nowrap;
            align-items: center;
        }
    `,
    copy: css`
        ${fontSizeAndLineHeight('14px')};
        line-height: 16px;
        width: 100%;
        padding-right: ${rh(2)};
        text-align: left;
        margin-bottom: ${rh(1)};
        margin-top: ${rh(1)};
        ${media.tabletAndUp} {
            margin-bottom: 0;
            margin-top: 0;
            margin-right: ${rh(1)};
            padding-right: 0;
        }
    `,
    closeIcon: css`
        width: 25px;
        height: 25px;
        path {
            fill: #fff;
        }
        &:hover {
            opacity: 0.5;
        }
    `,
    button: css`
        margin-right: ${rh(1)};
        margin-bottom: ${rh(1)};
        ${media.tabletAndUp} {
            max-width: 200px;
            margin-bottom: 6px;
        }
    `,
    select: css`
        margin-right: ${rh(2)};
        margin-bottom: ${rh(0.5)};
        ${media.tabletAndUp} {
            margin-bottom: 0 !important;
        }
        font-size: ${adjustFontSizeTo('17px')};
        select {
            color: #fff !important;
            border: 1px solid #999 !important;
            background: ${colors.black};
            padding-top: ${rh(0.3)};
            padding-bottom: ${rh(0.25)};
            &:hover,
            &:active {
                border: 1px solid #fff;
            }
            &::-ms-value {
                color: #fff;
            }
        }
        svg {
            top: 25px;
            path {
                fill: #fff !important;
            }
        }
    `,
    close: css`
        position: absolute;
        top: 0;
        right: 23px;
        ${media.tabletAndUp} {
            top: 10px;
            right: 10px;
            position: relative;
            top: auto;
            left: auto;
            opacity: 0.5;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                opacity: 1;
            }
        }
    `
};

const NavigationLocaleSwitcher: React.FunctionComponent<NavigationLocaleSwitcherProps> = ({
    userLocale,
    onClose,
    onSubmit,
    proposedLocale,
    locales
}) => {
    const avLocales: any[] = flatten(
        locales.map((loc) => {
            return loc.languages.map((lang) => ({
                id: `${lang.code}-${loc.iso}`,
                iso: loc.iso,
                name: `${loc.name} / ${lang.name}`,
                value: `${lang.code}`
            }));
        })
    );

    const onClickClose = (event: React.MouseEvent) => {
        event.preventDefault();
        onClose();
    };

    const onDoSubmit = (value: any) => {
        if (onSubmit) {
            onSubmit(avLocales.find((l) => l.id === value.locale));
        }
    };

    const switcherCopy = (COUNTRY_SWITCHER_STRINGS as any)[userLocale] || COUNTRY_SWITCHER_STRINGS.en;
    if (!locales || locales.length === 0) {
        return null;
    }

    // Filter out the proposedLocale
    let proposedLocaleOption = avLocales.find((l) => l.value === proposedLocale);
    if (!proposedLocaleOption) {
        proposedLocaleOption = avLocales.find((l) => l.value === 'de-de');
    }
    let selectLocates = avLocales.filter((f) => f.value !== proposedLocale);

    // Sort by names
    selectLocates = sortBy(selectLocates, 'name');

    // Add disabled
    selectLocates.unshift({
        name: '----------------',
        value: '',
        disabled: true
    });

    // Add proposed option
    selectLocates.unshift(proposedLocaleOption as any);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            locale: proposedLocaleOption ? proposedLocaleOption.id : null
        }
    });

    return (
        <div className={cx(styles.wrapper, 'navigation__localeswitcher')}>
            <div className={containerCentered}>
                <div className={styles.flex}>
                    <div className={styles.copy}>{switcherCopy.copy}</div>
                    <form onSubmit={handleSubmit(onDoSubmit)} className={styles.form}>
                        <SelectInput
                            name="locale"
                            className={styles.select}
                            options={selectLocates.map((s) => ({ name: s.name, value: s.id })) as NameValue[]}
                            inputRef={register}
                        />
                        <Button
                            buttonType="submit"
                            className={styles.button}
                            testId="navigaton-set-locale"
                            type="whiteBorder"
                        >
                            {switcherCopy.continue}
                        </Button>
                    </form>
                    <a data-test="navigation-close-locale" className={styles.close} href="#" onClick={onClickClose}>
                        <IconClose className={styles.closeIcon} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NavigationLocaleSwitcher;
