import { canUseDOM } from 'exenv';
import { BrowserHistory, createBrowserHistory } from 'history';
import { useEffect, useMemo } from 'react';

const cleanHash = (hash: string) => (hash || '').replace('#', '');

export const getUrlParams = (search: string): Record<string, unknown> => {
    if (!search) {
        return {};
    }
    const s = search.slice(search.indexOf('?') + 1);
    if (!s || s === 'undefined') {
        return {};
    }
    const hashes = s.split('&');
    return hashes.reduce((params, hash) => {
        const [key, val] = hash.split('=');
        return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
};

export const useHash = (onChange: (hash: string) => void): BrowserHistory | null => {
    if (!canUseDOM) {
        return null;
    }

    const history = useMemo(() => createBrowserHistory(), []);
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        // Inital Hash
        onChange(cleanHash(history.location.hash));

        // Listener
        const unlisten = history.listen((location: any) => {
            if (onChange) {
                onChange(cleanHash(location.hash));
            }
        });

        return unlisten;
    }, []);

    return history;
};
