import { colors, fontSizeAndLineHeight, linkColor, rh } from 'common/styles';
import { css, cx } from 'linaria';

import React from 'react';
import VitraLink from '../links/vitraLink';
import VitraPicture from '../images/vitraPicture';

export interface VitraArticleProps {
    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * The Article
     */
    article: Article;

    /**
     * Lazy Load the article image, defaults to false
     */
    lazy?: boolean;
}

const styles = {
    wrapper: css`
        position: relative;
        text-align: center;
        letter-spacing: 0.2px;
        font-weight: 100;
        ${fontSizeAndLineHeight('15px', 0.75)}
    `,
    articleLink: css`
        display: block;
        ${linkColor(colors.black, colors.primary)};
        text-decoration: none;
    `,
    image: css`
        display: block;
        margin-bottom: ${rh(0.25)};
        padding-bottom: ${rh(0.25)};
    `,
    name: css`
        font-weight: 600;
    `
};

const VitraArticle: React.FunctionComponent<VitraArticleProps> = ({ className, article, lazy = false }) => {
    return (
        <div className={cx('vitra__article', className, styles.wrapper)}>
            <VitraLink
                {...article.link}
                className={styles.articleLink}
            >
                <VitraPicture
                    className={styles.image}
                    image={article.image}
                    lazy={lazy}
                />
                <div className={styles.name}>
                    {article.name}
                </div>
                {article.description && (
                    <div>{article.description}</div>
                )}
            </VitraLink>
        </div>
    );
};
export default VitraArticle;
