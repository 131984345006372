import IconArrowBottomSmall from 'common/primitives/icons/components/V2ArrowBottomSmall';
import IconArrowLong from 'common/primitives/icons/components/V2ArrowLong';
import IconArrowTopSmall from 'common/primitives/icons/components/V2ArrowTopSmall';
import IconCart from 'common/primitives/icons/components/V2Cart4';
import IconChevron from 'common/primitives/icons/components/V2Chevron';
import IconClose from 'common/primitives/icons/components/V2CloseSmall';
import IconContact from 'common/primitives/icons/components/V2Contact';
import IconContactService from 'common/primitives/icons/components/V2ContactServiceCombined';
import IconFindVitra from 'common/primitives/icons/components/V2FindVitra';
import IconHeart from 'common/primitives/icons/components/V2Heart4';
import IconMenu from 'common/primitives/icons/components/V2Menu';
import IconSearch from 'common/primitives/icons/components/V2Search3';

import IconUser from 'common/primitives/icons/components/V2User';
import IconCountry from './countryIcon';

export {
    IconArrowBottomSmall,
    IconArrowLong,
    IconArrowTopSmall,
    IconCart,
    IconChevron,
    IconClose,
    IconContact,
    IconContactService,
    IconCountry,
    IconFindVitra,
    IconHeart,
    IconMenu,
    IconSearch,
    IconUser
};
