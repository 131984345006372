import { SelectInput } from 'common/primitives/forms';
import { colors } from 'common/styles';
import { css } from 'linaria';
import React from 'react';

export interface ListingFilterSorting {
    id: string;
    name: string;
}

interface ListingSortProps {
    onSort: (sorting: string) => void;
    options: ListingFilterSorting[];
    active?: string;
    className?: string;
}

const styles = {
    sortingSelect: css`
        margin-bottom: 0;
        width: auto;
        svg {
            top: calc(50% + 1px);
            right: 0;
        }
        select {
            text-align: right;
            padding-right: 20px;
            border: none !important;
            width: auto;
            font-size: 14px;
            font-weight: 500;
            &:hover,
            &:active,
            &:focus {
                outline: none;
                border: 1px solid ${colors.inputBorderColor};
            }
        }
    `
};

const VitraListingSorting: React.FunctionComponent<ListingSortProps> = ({ onSort, options, active }) => {
    const activeSortingOption = options.find((o) => o.id === active);

    // The SelectInput expects a different mapping
    const sortingToSelectMap = options.map((o) => {
        return {
            value: o.id,
            name: o.name
        };
    });

    if (!activeSortingOption) {
        return null;
    }

    const setSorting = (id: string) => {
        if (id !== active) {
            onSort(id);
        }
    };

    return (
        <SelectInput
            className={styles.sortingSelect}
            defaultOption={activeSortingOption.id}
            name="sorting"
            options={sortingToSelectMap as []}
            onChange={(e) => setSorting(e.target.value)}
        />
    );
};

export default VitraListingSorting;
