/**
 * Because react-hook-form`s getValues returns a flat object in the addressInput Component
 * and defaultValues is a nested object, we need to flatten the defaultValues object in some
 * occasions especially if we wanna merge defaultValues with other values.
 *
 * @param obj
 * @param parentKey
 * @returns
 */

export const flattenToDotNotation = (obj: Record<string, any>, parentKey = ''): Record<string, any> => {
    return Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(acc, flattenToDotNotation(obj[key], newKey));
        } else {
            acc[newKey] = obj[key];
        }

        return acc;
    }, {});
};
