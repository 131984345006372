import { CartBffContext } from 'common/hooks/useCart-v2';
import { WishlistButton } from 'common/hooks/useWishlist';
import IconAddToCart from 'common/primitives/icons/components/V2Cart';
import CloseIcon from 'common/primitives/icons/components/V2Close';
import { colors, media } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment, useContext } from 'react';
import { BlankButton } from '../buttons';
import VitraMaterial, { VitraMaterialType } from './vitraMaterial';

export interface VitraMaterialListingProps {
    /**
     * A classname for the wrapper
     */
    className?: string;

    /**
     * The material
     */
    materials: Material[];

    /**
     * The render variant of a material
     */
    display?: 'small' | 'medium' | 'big';

    /**
     * The material type,
     */
    type: VitraMaterialType;

    /**
     * Add to wishlist action
     */
    onAddToWishlist?: () => void;

    /**
     * Add to wishlist action
     */
    onAddToCart?: () => void;

    /**
     * Callback on Delte an item
     */
    onDelete?: (item: Material) => void;
}

const styles = {
    grid: css`
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        ${media.tablet} {
            grid-template-columns: 1fr 1fr 1fr;
        }
        ${media.desktop} {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    `,
    gridbig: css`
        ${media.desktop} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    `,
    wrapper: css`
        position: relative;
        &:hover {
            .iconWrapper {
                display: flex;
            }
        }
        ${media.belowDesktop} {
            .iconWrapper {
                display: flex;
            }
        }
    `,
    iconWrapper: css`
        display: none;
        position: absolute;
        z-index: 1;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: white;
        align-items: center;
        justify-content: center;
        &:hover svg {
            fill: ${colors.primary};
        }
    `,
    iconWrapperAddToWishlist: css`
        right: 24px;
        top: 24px;
    `,
    iconWrapperRemove: css``,
    iconRemove: css`
        width: 16px;
        height: 16px;
    `,
    iconAddToWishlist: css`
        width: 21px;
        height: 21px;
    `,
    iconWrapperAddToCart: css`
        right: 24px;
        top: 64px;
    `,
    iconAddToCart: css`
        width: 21px;
        height: 21px;
    `
};

const VitraMaterialListing: React.FunctionComponent<VitraMaterialListingProps> = ({
    className,
    materials,
    display = 'small',
    type,
    onDelete
}) => {
    const cartBff = useContext(CartBffContext);

    const addToCart = (item: Material) => async (e: React.MouseEvent) => {
        e.preventDefault();
        await cartBff.addItem(item.id, 'material');
    };

    const onDeleteItem = (item: Material) => async (e: React.MouseEvent) => {
        e.preventDefault();
        if (onDelete) {
            onDelete(item);
        }
    };
    return (
        <Fragment>
            <div className={cx(styles.grid, display === 'big' && styles.gridbig)}>
                {materials.map((material) => {
                    return (
                        <div className={styles.wrapper} key={material.id}>
                            {type !== 'material' && (
                                <div className={cx('iconWrapper', styles.iconWrapper, styles.iconWrapperAddToWishlist)}>
                                    {!onDelete && (
                                        <WishlistButton
                                            sku={material.id}
                                            className={styles.iconAddToWishlist}
                                            showModal={true}
                                            type={'material'}
                                        />
                                    )}
                                    {onDelete && (
                                        <BlankButton
                                            testId={`RemoveFromWishlist-${material.id}`}
                                            className={cx('iconWrapper', styles.iconWrapper, styles.iconWrapperRemove)}
                                            onClick={onDeleteItem(material)}
                                        >
                                            <CloseIcon className={styles.iconRemove} />
                                        </BlankButton>
                                    )}
                                </div>
                            )}
                            {type !== 'material' && material.addToCart && (
                                <BlankButton
                                    testId={`AddToCart-${material.id}`}
                                    className={cx('iconWrapper', styles.iconWrapper, styles.iconWrapperAddToCart)}
                                    onClick={addToCart(material)}
                                >
                                    <IconAddToCart className={styles.iconAddToCart} />
                                </BlankButton>
                            )}
                            <VitraMaterial
                                display={display}
                                className={className}
                                material={material}
                                type={type}
                                lazy={true}
                            />
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
};
export default VitraMaterialListing;
