/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
A request animation frame timer from 0 to 100
*/
export const timer = (fn: any, duration: number, callback: any = undefined): any => {
    const start = +new Date();
    const end = start + duration;
    let current = start;
    let lastPercent = 0;

    function step() {
        current = +new Date();
        lastPercent = (current - start) / duration;
        fn((lastPercent = 1 > lastPercent ? lastPercent : 1));
        if (current > end) {
            if (lastPercent !== 1) {
                requestAnimationFrame(() => {
                    fn(1);
                });
            } else if (callback) {
                return callback();
            }
        } else {
            requestAnimationFrame(step);
        }
    }
    requestAnimationFrame(step);
};

/*
Easing Function, acceleration until halfway, then deceleration
*/
export const easeInOut = (t: number): number => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
export const easeOutQuad = (t: number): number => t * (2 - t);
export const easeInOutQuad = (t: number): number => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
export const easeOutCubic = (t: number): number => --t * t * t + 1;

/**
 * Behaves the same as setTimeout except uses
 * requestAnimationFrame() where possible for better performance
 *
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
export const requestTimeout = (fn: any, delay: number): any => {
    if (
        !(window as any).requestAnimationFrame &&
        !(window as any).webkitRequestAnimationFrame &&
        !((window as any).mozRequestAnimationFrame && (window as any).mozCancelRequestAnimationFrame) &&
        !(window as any).oRequestAnimationFrame &&
        !(window as any).msRequestAnimationFrame
    ) {
        return window.setTimeout(fn, delay);
    }

    const start = new Date().getTime();
    const handle = {};
    const loop = () => {
        const current = new Date().getTime();
        const delta = current - start;
        delta >= delay ? fn.call() : ((handle as any).value = window.requestAnimationFrame(loop));
    };

    (handle as any).value = window.requestAnimationFrame(loop);
    return handle;
};

/**
 * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
export const clearRequestTimeout = (handle: any): any => {
    if (!handle) {
        return;
    }
    const v = handle.value;
    window.cancelAnimationFrame
        ? window.cancelAnimationFrame(v)
        : (window as any).webkitCancelAnimationFrame
        ? (window as any).webkitCancelAnimationFrame(v)
        : (window as any).webkitCancelRequestAnimationFrame
        ? (window as any).webkitCancelRequestAnimationFrame(v)
        : (window as any).mozCancelRequestAnimationFrame
        ? (window as any).mozCancelRequestAnimationFrame(v)
        : (window as any).oCancelRequestAnimationFrame
        ? (window as any).oCancelRequestAnimationFrame(v)
        : (window as any).msCancelRequestAnimationFrame
        ? (window as any).msCancelRequestAnimationFrame(v)
        : clearTimeout(handle);
};
