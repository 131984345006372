import * as React from 'react';

const SvgV2Close = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={22.627} height={22.628} viewBox="0 0 22.627 22.628" {...props}>
        <g id="V2-close_svg__icon_single_close" data-name="icon/single/close" transform="translate(-138.686 -250.93)">
            <path
                id="V2-close_svg__Rechteck_11735"
                d="M0 0h30v2H0z"
                data-name="Rechteck 11735"
                transform="rotate(45 -232.85 294.58)"
            />
            <path
                id="V2-close_svg__Rechteck_11736"
                d="M0 0h30v2H0z"
                data-name="Rechteck 11736"
                transform="rotate(135 28.395 159.581)"
            />
        </g>
    </svg>
);

export default SvgV2Close;
