import { addGaEvent } from 'common/primitives/analytics';
import VitraColumns from 'common/primitives/columns/vitraColumns';
import IconZoom from 'common/primitives/icons/components/V1Zoom';
import VitraMediaModal from 'common/primitives/modal/vitraMediaModal';
import { injectBackgroundImage, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useState } from 'react';
import { FindVitraContentLocation } from '../find-vitra-types';

interface CommonLocationImagesProps {
    className?: string;
    location: FindVitraContentLocation;
}

export const styles = {
    wrapper: css`
        margin-bottom: ${rh(2)};
    `,
    columns: css`
        ${media.mobileAndDown} {
            .columns__item:first-of-type {
                width: calc(100% - 16px) !important;
                min-width: calc(100% - 16px) !important;
                flex: 1 0 calc(100% - 16px) !important;
            }
        }
    `,
    galleryImageWrapper: css`
        overflow: hidden;
        position: relative;
        margin-bottom: ${rh(0.5)};
    `,
    galleryImage: css`
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        transition: all 0.2s linear;
        &:hover {
            cursor: pointer;
            transform: scale(1.05) rotate(0.02deg); // rotate to prevent flickering
        }
    `,
    galleryImageIconWrapper: css`
        pointer-events: none;
        background: rgba(255, 255, 255, 0.6);
        width: 50px;
        height: 50px;
        font-size: 0;
        line-height: 50px;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
        text-align: center;
    `,
    galleryImageIcon: css`
        width: 14px;
        height: 14px;
        position: relative;
        top: 7px;
    `
};

const columns = [
    { media: media.mobileAndDown, columns: 2 },
    { media: media.tablet, columns: 2 },
    { media: media.desktop, columns: 3 }
];

const CommonLocationImages: React.FunctionComponent<CommonLocationImagesProps> = ({ className, location }) => {
    if (!location.images || location.images.length === 0) {
        return null;
    }

    let galleryImages: any = location.images.filter((image) => image.meta.purpose === 'gallery');

    // Transform the old image structure to the performance structure
    galleryImages = galleryImages.map((i: any) => ({
        src: i.srcSet[i.srcSet.length - 1].src,
        width: i.srcSet[i.srcSet.length - 1].width,
        height: i.srcSet[i.srcSet.length - 1].height,
        srcSet: [
            {
                src: i.srcSet[i.srcSet.length - 1].src,
                width: i.srcSet[i.srcSet.length - 1].width,
                height: i.srcSet[i.srcSet.length - 1].height
            }
        ]
    }));

    const [modalOpen, setModalOpen] = useState(false);
    const [galleryIdx, setGalleryIdx] = useState(0);
    const handleClick = (idx: number) => () => {
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'POI Details',
            eventLabel: `${location.cn} | Detail Images`
        });
        setGalleryIdx(idx);
        setModalOpen(true);
    };

    return (
        <div className={cx(styles.wrapper, className)}>
            <VitraColumns className={styles.columns} columns={columns}>
                {galleryImages.map((image: any, idx: number) => {
                    const { backgroundImage, backgroundClass } = injectBackgroundImage(image.src);
                    return (
                        <div className={styles.galleryImageWrapper}>
                            <div className={styles.galleryImageIconWrapper}>
                                <IconZoom className={styles.galleryImageIcon} />
                            </div>
                            <div className={cx(styles.galleryImage, backgroundClass)} onClick={handleClick(idx)}>
                                {backgroundImage}
                            </div>
                        </div>
                    );
                })}
            </VitraColumns>
            {modalOpen && (
                <VitraMediaModal
                    id="find-vitra-gallary"
                    content={galleryImages}
                    onClose={() => setModalOpen(false)}
                    startIndex={galleryIdx}
                />
            )}
        </div>
    );
};

export default CommonLocationImages;
