import * as React from 'react';

const SvgV2ArrowLong = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24.04} height={18} {...props}>
        <path
            strokeMiterlimit={10}
            d="M15.669 16.949l-.778-.778a.182.182 0 010-.259l5.909-5.909H.501v-1.5h20.207l-5.815-5.812a.187.187 0 010-.263l.778-.778a.184.184 0 01.259 0l7.518 7.521a.182.182 0 010 .259l-7.518 7.519a.185.185 0 01-.131.054.188.188 0 01-.13-.054z"
        />
    </svg>
);

export default SvgV2ArrowLong;
