import { useEffect, useRef } from 'react';

export const useClickoutSide = <t extends HTMLElement>(
    callback: (e: any) => void,
    deps: any = [],
    /**
     * additional elements that are not connected to the original ref,
     * which also should not cause the callback to be called when clicked within
     */
    ignoredRefs: React.RefObject<HTMLElement>[] = []
): React.MutableRefObject<t | null> => {
    const container = useRef<t>();
    useEffect(() => {
        const el = container.current;
        if (!el) {
            return;
        }
        let isTouch = false;
        const handle = (e: any) => {
            if (e.type === 'touchend') {
                isTouch = true;
            }
            if (e.type === 'click' && isTouch) {
                return;
            }
            const ignoredElements = ignoredRefs
                .map((ref) => ref.current)
                .filter((el): el is HTMLElement => el !== null);

            if (![el, ...ignoredElements].some((el) => el.contains(e.target))) {
                callback(e);
            }
        };

        document.addEventListener('touchend', handle, true);
        document.addEventListener('click', handle, true);

        return () => {
            document.removeEventListener('touchend', handle, true);
            document.removeEventListener('click', handle, true);
        };
    }, [container.current, ignoredRefs, ...deps]);

    return container as React.MutableRefObject<t | null>;
};
