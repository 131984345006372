import { colors, media, rh } from 'common/styles';
import { css } from 'linaria';
import React from 'react';

const styles = {
    sectionHeader: css`
        border-top: 2px solid ${colors.checkoutBorder};
        background: ${colors.checkoutSection};
        margin-bottom: ${rh(1)};
        display: flex;
        align-items: center;
        margin-left: -20px;
        width: calc(100% + (20px * 2));
        padding: ${rh(0.5)} 20px;
        flex-wrap: wrap;
        ${media.tablet} {
            margin-left: -60px;
            width: calc(100% + (60px * 2));
            padding: ${rh(0.5)} 60px;
        }
    `,
    sectionHeaderHeadline: css`
        font-size: 17px;
        flex: 0 0 100%;
        ${media.tablet} {
            padding-right: 16px;
            flex-basis: auto;
        }
    `
};

const VitraCheckoutCommonSectionHeader: React.FunctionComponent = ({ children }) => {
    return (
        <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderHeadline}>{children}</div>
        </div>
    );
};
export default VitraCheckoutCommonSectionHeader;
