import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect, useState } from 'react';

interface ToogleProps {
    testId: string;
    defaultChecked?: boolean;
    onChange: any;
    disabled?: boolean;
    className?: string;
    label: string;
}

const styles = {
    toggleWrapper: css`
        display: flex;
    `,
    toggleLabel: css`
        margin-left: 8px;
        line-height: 18px;
        position: relative;
        top: 3px;
        &:hover {
            cursor: pointer;
        }
    `,
    toggle: css`
        touch-action: pan-x;
        display: inline-block;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
    `,
    toggleInput: css`
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    `,
    toggleCheckContainer: css`
        width: 50px;
        height: 24px;
        padding: 0;
        border-radius: 30px;
        background-color: ${colors.black};
        transition: all 0.2s ease;
    `,
    toggleCheck: css`
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        opacity: 0;
        transition: opacity 0.25s ease;
    `,
    toggleCheckChecked: css`
        left: 8px;
    `,
    toggleCheckUnChecked: css`
        opacity: 1;
        right: 10px;
    `,
    toggleCircle: css`
        // transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #fafafa;
        box-sizing: border-box;
        // transition: all 0.25s ease;
    `
};

const Toggle: React.FunctionComponent<ToogleProps> = (props) => {
    const { defaultChecked, onChange, disabled, className, label, testId } = props;
    const [toggle, setToggle] = useState(defaultChecked);

    useEffect(() => {
        if (defaultChecked) {
            setToggle(defaultChecked);
        }
    }, [defaultChecked]);

    const triggerToggle = () => {
        if (disabled) {
            return;
        }

        setToggle(!toggle);

        if (typeof onChange === 'function') {
            onChange(!toggle);
        }
    };

    return (
        <div className={cx('react__toggle', styles.toggleWrapper, className)}>
            <div onClick={triggerToggle} data-testid={testId} className={styles.toggle}>
                <div className={styles.toggleCheckContainer}>
                    <div
                        style={{ opacity: toggle ? 1 : 0 }}
                        className={cx(styles.toggleCheck, styles.toggleCheckChecked)}
                    ></div>
                    <div
                        style={{ opacity: toggle ? 0 : 1 }}
                        className={cx(styles.toggleCheck, styles.toggleCheckUnChecked)}
                    ></div>
                </div>
                <div style={{ left: toggle ? '27px' : '2px' }} className={styles.toggleCircle}></div>
                <input type="checkbox" aria-label="Toggle Button" className={styles.toggleInput} />
            </div>
            {label && (
                <div onClick={triggerToggle} className={cx('toggle__label', styles.toggleLabel)}>
                    {label}
                </div>
            )}
        </div>
    );
};

export default Toggle;
