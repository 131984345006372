import * as React from 'react';

const SvgV1Sendlist = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={20} height={12} viewBox="0 0 20 12" {...props}>
        <g fillRule="evenodd">
            <path d="M13.23 10.293l-2.876-2.877-.708.707 3.585 3.584 6.353-6.353-.707-.708zM0 1h11V0H0zM0 4h11V3H0zM0 7h8V6H0z" />
        </g>
    </svg>
);

export default SvgV1Sendlist;
