import { BffShoppingListLineItem } from 'common/graphql/sdk';
import type { LegacyWishlistItem, WishlistBffContext } from 'common/hooks/useWishlist';
import { useWishlistBffContext } from 'common/hooks/useWishlist';
import { addGaEvent } from 'common/primitives/analytics';
import VitraColumns from 'common/primitives/columns/vitraColumns';
import V2Check from 'common/primitives/icons/components/V2Check';
import VitraProduct from 'common/primitives/product/vitraProduct';
import { colors, media, rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { Fragment, useEffect, useState } from 'react';
import { FindVitraContentLocation } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';
import CommonFormContact from './commonFormContact';

const styles = {
    columns: css`
        margin-bottom: ${rh(2)};
    `,
    columnsCentered: css`
        justify-content: center;
    `,
    // We have different image sizes in the wishlist.
    // From emersya or s3. Because of that we have to
    // make this nasty styles
    product: css`
        margin-bottom: ${rh(0.25)};
        * {
            user-select: none !important;
        }
        picture {
            padding-top: 100%;
            position: relative;
            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    `,
    checkboxBox: css`
        width: 24px;
        height: 24px;
        border: 1px solid ${colors.inputBorderColor};
        position: relative;
        z-index: 1;
        margin: auto auto 0;
        svg {
            position: absolute;
            top: 3px;
            left: 4px;
            fill: transparent;
        }
        &:hover {
            cursor: pointer;
        }
    `,
    checkboxBoxSelected: css`
        svg {
            fill: ${colors.black};
        }
    `
};

const DesktopOverlayWishlist: React.FunctionComponent = () => {
    const [state]: any = useStateValue();
    const location = state.locations.find(
        (loc: FindVitraContentLocation) => loc._code === state.ux.activeLocation._code
    );
    const wishlist: WishlistBffContext = useWishlistBffContext();
    const [wishlistData, setWishlistData] = useState<LegacyWishlistItem[] | BffShoppingListLineItem[]>([]);

    const [selectedWishlistItems, setSelectedWishlistItems] = useState(wishlistData.map((p) => p.id));
    const onToggleWishlist = (id: string) => () => {
        var index = selectedWishlistItems.indexOf(id);
        if (index === -1) {
            selectedWishlistItems.push(id);
        } else {
            selectedWishlistItems.splice(index, 1);
        }
        // We must deconstruct otherwise its the same array and no re-render
        setSelectedWishlistItems([...selectedWishlistItems]);
    };

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        wishlist.loadWishlistData(true).then((res) => {
            if (res) {
                const p = res && res.lineItems;
                setWishlistData(p);
                setSelectedWishlistItems(p.map((p: any) => p.id));
            }
        });
    }, []);

    const [success, setSuccess] = useState(false);
    const onSuccess = () => {
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'POI Details',
            eventLabel: `${location.cn} | Contact`
        });
        setSuccess(true);
    };

    const columns = [
        { media: media.mobileX, columns: 2 },
        { media: media.tablet, columns: 3 },
        { media: media.desktop, columns: 6 }
    ];

    return (
        <Fragment>
            {!success && (
                <VitraColumns columns={columns} className={cx(styles.columns, styles.columnsCentered)}>
                    {wishlistData.map((product) => (
                        <Fragment>
                            <VitraProduct
                                className={styles.product}
                                product={(product as unknown) as Product}
                                lazy={true}
                            />
                            <div
                                onClick={onToggleWishlist(product.id)}
                                className={cx(
                                    styles.checkboxBox,
                                    selectedWishlistItems.indexOf(product.id) !== -1 && styles.checkboxBoxSelected
                                )}
                            >
                                <V2Check />
                            </div>
                        </Fragment>
                    ))}
                </VitraColumns>
            )}
            <CommonFormContact onSuccess={onSuccess} wishlistProductIds={selectedWishlistItems} />
        </Fragment>
    );
};

export default DesktopOverlayWishlist;
