import * as React from 'react';

const SvgTwitter = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  height="1em" viewBox="0 0 22 22" {...props}>
        <path
            fill="#333"
            d="M20.877 4.874a8.127 8.127 0 0 1-2.327.638 4.057 4.057 0 0 0 1.78-2.242 8.086 8.086 0 0 1-2.573.983 4.054 4.054 0 0 0-6.906 3.696 11.497 11.497 0 0 1-8.352-4.234 4.054 4.054 0 0 0 1.253 5.412 4.053 4.053 0 0 1-1.835-.508v.051a4.056 4.056 0 0 0 3.251 3.974 4.042 4.042 0 0 1-1.83.07 4.052 4.052 0 0 0 3.785 2.814 8.118 8.118 0 0 1-5.033 1.735c-.327 0-.649-.02-.967-.057a11.468 11.468 0 0 0 6.212 1.82c7.454 0 11.532-6.174 11.532-11.53 0-.175-.005-.351-.013-.525a8.2 8.2 0 0 0 2.023-2.097z"
        />
    </svg>
);

export default SvgTwitter;
