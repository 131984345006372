import { useClickoutSide } from 'common/hooks/useClickOutside';
import useId from 'common/hooks/useId';
import Calendar from 'common/primitives/forms/components/calendar';
import globalStyle from 'common/primitives/forms/styles';
import V2ArrowBottomSmall from 'common/primitives/icons/components/V2ArrowBottomSmall';
import V2Cal from 'common/primitives/icons/components/V2Cal';
import { colors, rh } from 'common/styles';
import configureDayjs from 'common/utils/time';
import dayjs from 'dayjs';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

const styles = defaults(
    {
        dateWrapper: css`
            position: relative;
            min-width: 320px;
            display: inline-flex;
        `,
        dateButton: css`
            user-select: none;
            width: 100%;
            font-weight: 100;
            font-size: 17px;
            border: 1px solid ${colors.inputBorderColor};
            padding: ${rh(0.4)} ${rh(0.5)} ${rh(0.4)} 50px;
            position: relative;
            &:hover {
                border: 1px solid ${colors.black};
                cursor: pointer;
            }
        `,
        dateButtonFocussed: css`
            border: 1px solid ${colors.black};
        `,
        dateButtonIconArrow: css`
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        `,
        dateButtonIconCalendar: css`
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            width: 20px;
        `,
        dateButtonError: css`
            border-color: ${colors.error};
        `,
        dateButtonCalendar: css`
            display: inline-flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            top: calc(${rh(1.9)} - 1px);
            z-index: 1;
            border: 1px solid ${colors.black};
            border-top: none;
            padding-bottom: ${rh(0.5)};
        `
    },
    globalStyle
);

interface DateInputProps extends InputProps {
    placeholder?: string;

    validateDate?: (date: dayjs.Dayjs) => boolean;

    /**
     * Locale to translate weeks and weekdays
     */
    locale: string;

    /**
     * Current date value - if not set its today
     */
    date?: string;

    /**
     * A list of start and enddates which shall be not selectable
     * and an optional corresponding error message li
     */
    blockedDateRanges?: [{ startDate: string; endDate?: string }];

    /**
     * List of blocked weekdays. The week starts with sunday and
     * sunday has an index of 0
     */
    blockedWeekdays?: number[];

    /**
     * Allow selecting the current day
     */
    todaySelectable?: boolean;

    /**
     * On Change callback
     */
    onChange?: (date: string) => void;
}

const DateInput: React.FunctionComponent<DateInputProps> = ({
    className,
    locale,
    inputRef,
    errors,
    validateDate,
    name,
    placeholder,
    date,
    blockedDateRanges,
    blockedWeekdays,
    todaySelectable = false,
    onChange
}) => {
    /**
     * Calendar Functions
     */
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDateFromatted, setSelectedDateFormatted] = useState(placeholder);
    const [calendarVisible, setCalendarVisible] = useState(false);

    // Error
    const hasError = errors && get(errors, name);

    // Input id
    const id = useId();

    configureDayjs(locale);

    useEffect(() => {
        if (date) {
            const d = (date as any).toISOString();
            const ds = dayjs(date).format('dddd, LL');
            setCalendarVisible(false);
            setSelectedDateFormatted(ds);
            setSelectedDate(d);
        }
    }, []);

    const toggleCalendar = () => {
        setCalendarVisible(!calendarVisible);
    };

    const closeCalendar = () => {
        setCalendarVisible(false);
    };

    const targetRef = useClickoutSide(closeCalendar);

    const handleCalendarChange = (date: string) => {
        setCalendarVisible(false);
        setSelectedDateFormatted(dayjs(date).format('dddd, LL'));
        setSelectedDate(date);
        if (onChange) {
            onChange(date);
        }
    };

    return (
        <div className={cx('form__field', 'form__field--date', styles.wrapper, className)}>
            <div className={styles.dateWrapper} ref={targetRef as any}>
                <a
                    role="button"
                    className={cx(
                        styles.dateButton,
                        calendarVisible && styles.dateButtonFocussed,
                        hasError && styles.dateButtonError
                    )}
                    onClick={toggleCalendar}
                >
                    <V2Cal className={styles.dateButtonIconCalendar} />
                    {selectedDateFromatted}
                    <V2ArrowBottomSmall className={styles.dateButtonIconArrow} />
                </a>
                {calendarVisible && (
                    <Calendar
                        locale={locale}
                        date={date}
                        blockedDateRanges={blockedDateRanges}
                        blockedWeekdays={blockedWeekdays}
                        todaySelectable={todaySelectable}
                        validateDate={validateDate}
                        className={styles.dateButtonCalendar}
                        onChange={handleCalendarChange}
                    />
                )}
                <input name={name} id={id} type="hidden" ref={inputRef} value={selectedDate} />
            </div>
        </div>
    );
};

export default DateInput;
