import * as React from 'react';

const SvgV1Info = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={14} height={14} viewBox="0 0 14 14" {...props}>
        <g fillRule="evenodd">
            <path d="M7 0a7 7 0 100 14A7 7 0 007 0m0 1c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6" />
            <path d="M6.5 4h1V3h-1zM6.5 11h1V5h-1z" />
        </g>
    </svg>
);

export default SvgV1Info;
