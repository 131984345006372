import take from 'lodash/take';
/**
 * Sanatize sku
 * @param sku
 * @returns
 */
export const sanitizeSku = (sku: unknown): string => {
    const filterList = ['material', 'materials', 'conf', 'sub'];
    // we split into parts, SAP has min of 7 numbers per sku

    const parts = `${sku}`.toString().split('|');
    return take(parts, 3)
        .filter((el) => {
            if (filterList.includes(el.toLowerCase())) {
                // Remove fragment if it is in the list
                return false;
            }
            if (el.length < 8) {
                // Remove fragment
                return false;
            }
            // Keep the current fragment
            return true;
        })
        .join('|');
};

export const unmarshalSKU = (
    sku: string
): {
    sku: string;
    articleNo: string;
    configurationId: string | null;
} => {
    const skuString = sanitizeSku(sku);
    const parts = skuString.split('|');
    return {
        sku: skuString,
        articleNo: parts[0],
        configurationId: parts[1] || null
    };
};

export const isSameSku = (skuA: unknown, skuB: unknown): boolean => {
    return sanitizeSku(skuA).toLowerCase() === sanitizeSku(skuB).toLowerCase();
};
