import V2ArrowBottomMedium from 'common/primitives/icons/components/V2ArrowBottomMedium';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

const styles = {
    accordion: css`
        display: flex;
        flex-direction: column;
    `,
    accordionIconWrapper: css`
        margin-left: auto;
        flex: 0 0 32px;
        padding-left: 16px;
    `,
    accordionIcon: css`
        transition: transform 0.1s ease-out;
    `,
    accordionIconOpen: css`
        transform: rotate(180deg);
    `,

    accordionTitle: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        &:hover {
            cursor: pointer;
        }
    `,
    accordionContent: css`
        overflow: hidden;
        transition: max-height 0.1s ease-out;
        img {
            width: 100%;
        }
    `
};

interface AccordionProps {
    /**
     * Optional opOpen handler
     */
    onOpen?: () => void;
    /**
     * An additional className for the component
     */
    className?: string;

    /**
     * The title of the accordion
     */
    title: string | ReactElement;

    /**
     * If the Accordion is
     * open on start
     */
    isOpen?: boolean;
}

/**
 * This accordion is pretty naked from a css style perspective
 * because it should be styled from the outside
 * to simply serve the accordion functionality and
 * nothing else
 */
const VitraAccordion: React.FunctionComponent<AccordionProps> = (props) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(!!props.isOpen);
    const [contentHeight, setContentHeight] = useState<number>(0);

    // TO fix rendering issues if the accordion is initally open
    // we diasable the maxHeight attribute which would contain the wrong height
    // because some contents, especially images, are not loaded yet
    const [allowMaxHeight, setAllowMaxHeight] = useState(!props.isOpen);

    const handleTitlteClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setAllowMaxHeight(true);
        if (contentRef.current) {
            setIsOpen(!isOpen);
            setContentHeight(isOpen ? 0 : contentRef.current.scrollHeight);
            if (!isOpen && props.onOpen) {
                props.onOpen();
            }
        }
    };

    // Listen to a change on props.isOpen
    useEffect(() => {
        if (canUseDOM) {
            setIsOpen(!!props.isOpen);
            window.setTimeout(() => {
                if (!!props.isOpen && contentRef.current) {
                    setContentHeight(contentRef.current!.scrollHeight);
                }
            }, 100);
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (canUseDOM) {
            window.setTimeout(() => {
                if (isOpen && contentRef.current) {
                    setContentHeight(contentRef.current!.scrollHeight);
                }
            }, 200);
        }
    }, [contentRef.current]);

    return (
        <div className={cx(props.className, styles.accordion)}>
            <a role="button" className={cx('accordionTitle', styles.accordionTitle)} onClick={handleTitlteClick}>
                {props.title}
                <div className={styles.accordionIconWrapper}>
                    <V2ArrowBottomMedium className={cx(styles.accordionIcon, isOpen && styles.accordionIconOpen)} />
                </div>
            </a>
            <div
                ref={contentRef}
                style={{ maxHeight: allowMaxHeight ? `${contentHeight}px` : 'none' }}
                className={cx('accordionContent', styles.accordionContent)}
            >
                {props.children}
            </div>
        </div>
    );
};

export default VitraAccordion;
