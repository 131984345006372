import {css, cx} from 'linaria';

import IconClear from 'common/primitives/icons/components/V1Clear';
import React from 'react';
import Translate from 'common/primitives/translations';
import {colors} from 'common/styles';
import {flattenDeepByChildren} from '../state/find-vitra.state';
import {useStateValue} from '../state/find-vitra.state-provider';

export const styles = {
    activeFilters: css`
        margin-top: 10px;
    `,
    filtersItem: css`
        display: inline-flex;
        background: ${colors.findVitraColorSilver};
        padding: 4px 12px;
        margin-right: 6px;
        margin-bottom: 8px;
        position: relative;
        padding: 12px 44px 13px 20px;
        line-height: 1.2em;
        &:hover {
            cursor: pointer;
            .itemClear {
                background: ${colors.findVitraColorGray};
            }
        }
    `,
    itemClear: css`
        width: 20px;
        height: 20px;
        background: ${colors.findVitraColorBorder};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 12px;
        top: 12px;
        svg {
            width: 8px;
            height: 8px;
            stroke: white;
            fill: white;
        }
    `
};


const CommonSearchFiltersActive: React.FunctionComponent = () => {

    // StoreState
    const [state, dispatch]: any = useStateValue();

    // Order of weekdays
    const weekdayMapping = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
    ];

    // Regular Filters
    const filters = state.scope.live.filters;
    const flatFilters = flattenDeepByChildren(filters);
    const activeFilters = flatFilters.filter((f: any) => f.active);
    const unsetFilter = (id: string) => (evt: React.MouseEvent) => {
        dispatch({type: 'TOGGLE_FILTER', scope: 'live', id});
        dispatch({type: 'FILTER_LOCATIONS'});
    };

    // Special Filters
    const activeOpenNow = state.scope.live.openNow;
    const activeDayAndTime = state.scope.live.openAtDate;
    const activeDistance = state.scope.live.distance !== state.options.distance ? state.scope.live.distance : false;

    return (
        <div className={styles.activeFilters}>
            {activeFilters.length !== 0 && activeFilters.map((f: any, i: number) => {
                return (
                    <div
                        className={styles.filtersItem}
                        key={i}
                        onClick={unsetFilter(f.id)}
                    >
                        {f.name}
                        <div
                            className={cx('itemClear', styles.itemClear)}
                        >
                            <IconClear/>
                        </div>
                    </div>
                );
            })}
            {activeOpenNow && (
                <div
                    className={styles.filtersItem}
                    key="openNow"
                    onClick={() => {
                        dispatch({type: 'SET_OPENNOW_FILTER', scope: 'live', active: undefined});
                        dispatch({type: 'FILTER_LOCATIONS'});
                    }}
                >
                    <Translate id="community_contact_opening_hours_open"/>
                    <div
                        className={cx('itemClear', styles.itemClear)}
                    >
                        <IconClear/>
                    </div>
                </div>
            )}
            {activeDayAndTime && (
                <div
                    className={styles.filtersItem}
                    key="openAtDate"
                    onClick={() => {
                        dispatch({type: 'SET_OPENATDATE_FILTER', scope: 'live', date: undefined});
                        dispatch({type: 'FILTER_LOCATIONS'});
                    }}
                >
                    <Translate id={`weekdays_shortcurts_${weekdayMapping[activeDayAndTime.getDay()]}`}/>
                    ,&nbsp;
                    {`${activeDayAndTime.getHours()}:${('0' + activeDayAndTime.getMinutes()).slice(-2)}`}
                    <div
                        className={cx('itemClear', styles.itemClear)}
                    >
                        <IconClear/>
                    </div>
                </div>
            )}
            {activeDistance && (
                <div
                    className={styles.filtersItem}
                    key="distance"
                    onClick={() => {
                        dispatch({type: 'SET_DISTANCE', scope: 'live', distance: state.options.distance});
                        dispatch({type: 'FILTER_LOCATIONS'});
                    }}
                >
                    <Translate
                        id="community_search_results_title_distance"
                        data={{
                            distance: String(activeDistance),
                            unit: state.options.unit
                        }}
                    />
                    <div
                        className={cx('itemClear', styles.itemClear)}
                    >
                        <IconClear/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommonSearchFiltersActive;

