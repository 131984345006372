// tslint:disable max-line-length
/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum mediaIos {
    iPad = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)',
    iPadFirst = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)',
    iPadFirstLandscape = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)',
    iPadFirstPotrait = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)',
    iPadLandscape = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)',
    iPadMini = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)',
    iPadMiniLandscape = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)',
    iPadMiniPortrait = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)',
    iPadPortrait = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)',
    iPadRetina = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)',
    iPadRetinaLandscape = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)',
    iPadRetinaPortrait = '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)',
    iPhone4 = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-device-pixel-ratio: 2)',
    iPhone5 = '@media only screen and (min-device-width: 320px) and (max-device-width: 568px)',
    iPhone5Landscape = '@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape)',
    iPhone5Portrait = '@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait)',
    iPhone6 = '@media only screen and (min-device-width: 375px) and (max-device-width: 667px)',
    iPhone6Landscape = '@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape)',
    iPhone6Plus = '@media only screen and (min-device-width: 414px) and (max-device-width: 736px)',
    iPhone6PlusLandscape = '@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape)',
    iPhone6PlusPortrait = '@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) ',
    iPhone6Portrait = '@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait)',
    iPhone = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px)',
    iPhoneFirst = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px)',
    iPhoneFirstLandscape = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape)',
    iPhoneLandscape = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape)',
    iPhonePortait = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait)',
    iPhonePortrait = '@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait)'
}

export const media = {
    mobileNavigation: '@media only (max-width: 979px)',
    desktopNavigation: '@media only screen and (min-width: 1480px)',

    desktopAndUp: '@media only screen and (min-width: 1024px)',
    belowDesktop: '@media only screen and (max-width: 1023px)',
    tabletAndDown: '@media only screen and (max-width: 768px)',
    tabletAndUp: '@media only screen and (min-width: 768px)',
    mobileAndDown: '@media only screen and (max-width: 480px)',

    mobile: '@media only screen and (max-width: 374px)',
    mobileX: '@media only screen and (min-width: 375px)',
    tablet: '@media only screen and (min-width: 768px)',
    desktop: '@media only screen and (min-width: 1024px)',
    desktopX: '@media only screen and (min-width: 1200px)'
};
