import * as React from 'react';

const SvgV2Heart = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={22} height={18.857} viewBox="0 0 22 18.857" {...props}>
        <path
            d="M20.429 5.746a5.351 5.351 0 0 0-.264-1.756 3.428 3.428 0 0 0-.675-1.209 3.242 3.242 0 0 0-1-.73 4.432 4.432 0 0 0-1.154-.381 7.021 7.021 0 0 0-1.2-.1 3.532 3.532 0 0 0-1.375.313 6.276 6.276 0 0 0-1.357.786q-.62.473-1.062.884a9.5 9.5 0 0 0-.737.755.736.736 0 0 1-.6.27.736.736 0 0 1-.6-.27 9.5 9.5 0 0 0-.737-.755q-.442-.411-1.062-.884a6.276 6.276 0 0 0-1.363-.783 3.532 3.532 0 0 0-1.375-.313 7.021 7.021 0 0 0-1.2.1 4.432 4.432 0 0 0-1.154.381 3.242 3.242 0 0 0-1 .73 3.428 3.428 0 0 0-.679 1.202 5.351 5.351 0 0 0-.264 1.756q0 2.063 2.3 4.358L11 16.979l7.121-6.863q2.308-2.308 2.308-4.37zm1.571 0q0 2.713-2.811 5.525l-7.649 7.365a.771.771 0 0 1-1.08 0l-7.66-7.39a4.052 4.052 0 0 1-.338-.319q-.215-.221-.681-.8a10.864 10.864 0 0 1-.835-1.2A7.435 7.435 0 0 1 .289 7.44 5.378 5.378 0 0 1 0 5.746a5.631 5.631 0 0 1 1.559-4.223A5.905 5.905 0 0 1 5.868.001a4.893 4.893 0 0 1 1.553.264 6.285 6.285 0 0 1 1.473.712q.681.448 1.172.841a11.311 11.311 0 0 1 .933.835 11.311 11.311 0 0 1 .933-.835q.491-.393 1.172-.841a6.285 6.285 0 0 1 1.473-.712A4.893 4.893 0 0 1 16.13.001a5.905 5.905 0 0 1 4.309 1.522A5.631 5.631 0 0 1 22 5.746z"
            data-name="icon/single/heart"
        />
    </svg>
);

export default SvgV2Heart;
