import { canUseDOM } from 'exenv';
import { useEffect } from 'react';

export const useKey = (onPress: (key: 'RIGHT' | 'LEFT' | 'UP' | 'DOWN' | 'ESC' | string) => void): void => {
    if (!canUseDOM) {
        return undefined;
    }

    const onKeypress = (e: KeyboardEvent) => {
        if (e.defaultPrevented) {
            return;
        }
        const key = e.key || e.keyCode;
        if (key === 'Escape' || key === 'Esc' || key === 27) {
            return onPress('ESC');
        }
        if (key === 'ArrowRight' || key === 39) {
            return onPress('RIGHT');
        }
        if (key === 'ArrowLeft' || key === 37) {
            return onPress('LEFT');
        }
        if (key === 'ArrowUp' || key === 38) {
            return onPress('UP');
        }
        if (key === 'ArrowDown' || key === 40) {
            return onPress('DOWN');
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', onKeypress);
        return () => {
            document.removeEventListener('keyup', onKeypress);
        };
    }, []);
};
