import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect } from 'react';

const styles = {
    stepIndicatorWrapper: css`
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        padding: ${rh(1.5)} 0;
        -webkit-font-smoothing: antialiased;

        --step-circle-size: 14px;
        --step-circle-margin: 10px;

        &:has(> *:nth-child(1)) {
            --child-count: 1;
        }
        &:has(> *:nth-child(2)) {
            --child-count: 2;
            max-width: calc(2 * 180px);
        }
        &:has(> *:nth-child(3)) {
            --child-count: 3;
            max-width: calc(3 * 180px);
        }
        &:has(> *:nth-child(4)) {
            --child-count: 4;
            max-width: calc(4 * 180px);
        }
        &:has(> *:nth-child(5)) {
            --child-count: 5;
            max-width: calc(5 * 180px);
        }
        &:has(> *:nth-child(6)) {
            --child-count: 6;
            max-width: calc(6 * 180px);
        }
    `,
    step: css`
        position: relative;
        flex: 1 0 calc(100% / var(--child-count));
        pointer-events: none;
        color: ${colors.textGrey};
        &:before {
            content: '';
            color: black;
            width: var(--step-circle-size);
            height: var(--step-circle-size);
            line-height: var(--step-circle-size);
            text-align: center;
            display: block;
            border-radius: 50%;
            background-color: ${colors.textGrey};
            margin: 0 auto;
        }
        &:after {
            content: '';
            position: absolute;
            background-color: ${colors.inputBorderColor};
            height: 1px;
            width: calc(100% - (var(--step-circle-size) + (var(--step-circle-margin) * 2)));
            top: calc((var(--step-circle-size) / 2) - 1px);
            left: calc(-50% + (var(--step-circle-size) / 2) + var(--step-circle-margin));
        }
        &:first-child:after {
            content: none;
        }
    `,
    stepActive: css`
        color: ${colors.black};
        &:before {
            background-color: ${colors.primary};
        }
    `,
    stepText: css`
        margin: ${rh(0.5)} auto 0;
        text-align: center;
        line-height: 1.25;
    `
};

interface VitraStepIndicatorProps {
    className?: string;
    steps: string[];
    currentStepIndex: number;
}

export const VitraStepIndicator: React.FunctionComponent<VitraStepIndicatorProps> = ({
    className,
    currentStepIndex = 0,
    steps
}) => {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        // FF does not support :has selector at the moment. Can be removed in 2024
        if (ref.current) {
            try {
                // Attempt to use :has selector
                ref.current.querySelector(':has(*)');
            } catch (_) {
                const children = ref.current.children;
                ref.current.style.setProperty('--child-count', children.length.toString());
                ref.current.style.setProperty('max-width', children.length * 180 + 'px');
            }
        }
    }, []);

    return (
        <div className={cx(styles.stepIndicatorWrapper, className)} ref={ref}>
            {steps.map((label, ix) => {
                return (
                    <div className={cx(styles.step, ix === currentStepIndex && styles.stepActive)}>
                        <div className={styles.stepText}>{label}</div>
                    </div>
                );
            })}
        </div>
    );
};
