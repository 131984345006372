import { isMobile } from 'common/hooks/useBreakpoints';
import { rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect } from 'react';
import { Route, Switch } from 'wouter';
import DesktopSearchV2 from '../desktop/desktopSearchV2';
import MobileSearch from '../mobile/mobileSearch';
import { useStateValue } from '../state/find-vitra.state-provider';
import { CommonBootstrapLoading } from './commonBootstrapLoading';
import CommonDetail from './commonDetail';

const styles = {
    wrapper: css`
        margin-bottom: ${rh(2)};
    `,
    wrapperDoubleBottomMargin: css`
        margin-bottom: ${rh(4)};
    `,
    wrapperNoBottomMargin: css`
        margin-bottom: 0;
    `
};
export const CommonBootstrapRoutes: React.FunctionComponent<any> = ({ queryParams }) => {
    // State
    const [state, dispatch]: any = useStateValue();

    /**
     * We only write once to state.locations
     * so we filter now for the first time
     */
    useEffect(() => {
        if (state.locations.length !== 0) {
            dispatch({ type: 'SET_FILTERS_FROM_QUERY', queryParams });
            dispatch({ type: 'FILTER_LOCATIONS' });
            if (queryParams.showShareWithRetailer === 'true') {
                dispatch({ type: 'UX_SHOW_SHARE_WITH_RETAILER', active: true });
            }
        }
    }, [state.locations]);

    // We have just differenes in the search view
    const FindVitraSearch = isMobile() ? MobileSearch : DesktopSearchV2;

    return (
        <Switch>
            {state.ux.loading && <Route component={() => CommonBootstrapLoading({ qs: queryParams })} />}
            {!state.ux.loading && (
                <div
                    className={cx(
                        state.options.margin === 'DefaultBottomMargin' && styles.wrapper,
                        state.options.margin === 'DoubleBottomMargin' && styles.wrapperDoubleBottomMargin,
                        state.options.margin === 'NoBottomMargin' && styles.wrapperNoBottomMargin
                    )}
                >
                    <Route path="/details/:retailerId" component={CommonDetail} />
                    <Route path="/location/:country_slug/:city_slug" component={FindVitraSearch} />
                    <Route path="/location" component={FindVitraSearch} />
                    <Route path="*" component={FindVitraSearch} />
                    <Route component={FindVitraSearch} />
                </div>
            )}
        </Switch>
    );
};
