/**
 * The fields which are address input fields
 */
import { AvailableCommerceToolsAddressFieldsTypes } from './vitraAddressFormTypes';

/**
 * How to Output an address on the screen
 */
export type AddressOutputFormatDescription = (keyof AvailableCommerceToolsAddressFieldsTypes)[][];

/**
 * The default way to output an address on the screen
 */
export const defaultAddressFormat: AddressOutputFormatDescription = [
    ['company'],
    ['salutation', 'firstName', 'lastName'],
    ['additionalAddressInfo'],
    ['streetName', 'streetNumber'],
    ['postalCode', 'city'],
    ['country']
];
