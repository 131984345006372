import { BffCartResult, BffCartResultItem } from 'common/graphql/sdk';
import { useConfig } from 'common/hooks/useBoostrap';
import { useCartBffContext } from 'common/hooks/useCart-v2';
import Button from 'common/primitives/buttons';
import { useTranslations } from 'common/primitives/translations';
import { colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment, useEffect, useState } from 'react';
import DropDown from './dropDown';
import DropDownCartItem from './dropDownCartItem';
import { generateNavigationAnayltics } from './helpers';

interface DropDownCartProps {
    products?: BffCartResultItem[];
    numberOfArticles?: number;
}

const styles = {
    hasArticles: css`
        .dropdown__title {
            margin-bottom: ${rh(0.5)};
        }
    `,
    cartItems: css`
        border-block: 1px solid ${colors.inputBorderColor};
        margin: 0 ${rh(-0.625)};
        padding: 0 ${rh(0.625)};
        max-height: 320px;
        overflow-y: auto;
        display: flex;
        flex-flow: column nowrap;
        ${fontSizeAndLineHeight('14px', 0.7)};

        > * + * {
            border-top: 1px solid ${colors.inputBorderColor};
        }
    `
};

const DropDownCart: React.FunctionComponent<DropDownCartProps> = ({ products, numberOfArticles = 0 }) => {
    // TODO: retrieve actual cart items in here if not passed via props
    const [cartItems, setCartItems] = useState<BffCartResultItem[]>(products || []);
    const { cartContent, loadCart } = useCartBffContext();

    // Because we set the cart badge via signals,
    // we need to make sure that the cart data is loaded as soon as the dropdown is opened
    useEffect(() => {
        loadCart();
    }, []);

    useEffect(() => {
        const c = cartContent as BffCartResult;
        const ci = c?.cartItems;
        if (!ci || ci.length === 0) {
            return;
        }
        const li = ci[0].lineItems;
        if (li && li.length > 0) {
            setCartItems(li!);
        }
    }, [cartContent]);

    const t = {
        titleEmpty: useTranslations('dropdown.cart.title.empty', 'Your Shopping Cart is empty'),
        titleSingleProduct: useTranslations('dropdown.cart.title.single.product', '1 Item in your Shopping Cart'),
        titleMultipleProducts: useTranslations(
            'dropdown.cart.title.multiple.products',
            '{numberOfArticles} Items in your Shopping Cart',
            { numberOfArticles: numberOfArticles.toString() }
        ),
        emptyMessage: useTranslations('dropdown.cart.empty.message', 'Your cart is currently empty.'),
        linkRecommendations: useTranslations('dropdown.cart.link.recommendations', 'Our Recommendations'),
        linkCart: useTranslations('dropdown.cart.link.cart', 'To Shopping Cart'),
        linkCheckout: useTranslations('dropdown.cart.link.checkout', 'To Checkout')
    };

    const title = (() => {
        if (numberOfArticles > 1) return t.titleMultipleProducts;
        if (numberOfArticles === 1) return t.titleSingleProduct;
        return t.titleEmpty;
    })();

    const checkoutLink: Link = {
        href: useConfig('checkoutUrl', '/checkout'),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-checkout'
        }
    };

    const cartLink: Link = {
        href: useConfig('cartPageUrl', '/cart'),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-cart'
        }
    };

    const productsOverviewLink: Link = {
        href: useConfig('productsOverviewUrl', '/product'),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-cart-products-overview'
        }
    };

    return (
        <DropDown title={title} className={cx(numberOfArticles && styles.hasArticles)}>
            {cartItems.length ? (
                <Fragment>
                    <ul className={styles.cartItems}>
                        {cartItems.map((product) => (
                            <DropDownCartItem product={product} />
                        ))}
                    </ul>
                    <Button
                        testId="DropDownCartLinkCheckout"
                        link={checkoutLink}
                        analyticsData={generateNavigationAnayltics(checkoutLink)}
                    >
                        {t.linkCheckout}
                    </Button>
                    <Button
                        type="blackBorder"
                        testId="DropDownCartLinkCart"
                        link={cartLink}
                        analyticsData={generateNavigationAnayltics(cartLink)}
                    >
                        {t.linkCart}
                    </Button>
                </Fragment>
            ) : (
                <Fragment>
                    <div>{t.emptyMessage}</div>
                    <Button
                        testId="DropDownCartLinkRecommendations"
                        link={productsOverviewLink}
                        analyticsData={generateNavigationAnayltics(productsOverviewLink)}
                    >
                        {t.linkRecommendations}
                    </Button>
                </Fragment>
            )}
        </DropDown>
    );
};

export default DropDownCart;
