import * as React from 'react';

const SvgV2Locate = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <path d="M10.05 7.16a1.5 1.5 0 00-.17 3 1.54 1.54 0 001.59-1.5 1.5 1.5 0 00-1.42-1.5z" />
        <path d="M4.73 13.52c-.13-.12-.25-.25-.37-.38a6.78 6.78 0 01.37-9.58 7.77 7.77 0 0110.54 0q.2.18.39.39a6.79 6.79 0 01-.39 9.57L10 18.5zM10 2.61a6.5 6.5 0 00-4.44 1.74c-.11.1-.21.2-.31.31a5.72 5.72 0 00.31 8.07L10 16.89l4.46-4.16a3.43 3.43 0 00.33-.33 5.7 5.7 0 00-.33-8A6.5 6.5 0 0010 2.61z" />
    </svg>
);

export default SvgV2Locate;
