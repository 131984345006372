/**
 * Scroll the element to the top as best as possible but respect the breadcrumbbar
 * @param targetEl
 * @param bcNavHeight
 */
const srollElementTop = (targetEl: HTMLElement, bcNavHeight: number): void => {
    //el.offsetTop returns wrong values in some situations so we use boundingClientRect
    const rect = targetEl.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const distance = rect.top + scrollTop - bcNavHeight;
    window.scroll({ top: distance, behavior: 'smooth' });
};

export default srollElementTop;
