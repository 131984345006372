import * as React from 'react';

const SvgV2ContactServiceCombined = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <path d="M17.78 14.45L15.37 12a.82.82 0 00-.67-.22 1.08 1.08 0 00-.51.23 5.26 5.26 0 00-.78.71 5.77 5.77 0 01-.68.63.64.64 0 01-.18.1 17.88 17.88 0 01-3.34-2.67 17.77 17.77 0 01-2.66-3.29.56.56 0 01.09-.17 5.23 5.23 0 01.64-.69A5.26 5.26 0 008 5.85a1.18 1.18 0 00.23-.51.82.82 0 00-.23-.67l-2.4-2.4a1.84 1.84 0 00-1-.64h-.17a1.88 1.88 0 00-1 .37l-.08.06A5.93 5.93 0 001.9 8.21a15.26 15.26 0 003.93 6 15.18 15.18 0 006 3.93 5.78 5.78 0 002 .34A5.9 5.9 0 0018 16.74l.06-.07a1.74 1.74 0 00.36-1.23 1.87 1.87 0 00-.64-.99zm-.51 1.63a4.83 4.83 0 01-5.14 1.13 14.53 14.53 0 01-5.61-3.69 14.43 14.43 0 01-3.68-5.61A4.81 4.81 0 014 2.8a1.46 1.46 0 01.44-.2h.05c.06 0 .18.08.46.36l2.26 2.31a5.22 5.22 0 01-.6.64 7.29 7.29 0 00-.75.82 1.76 1.76 0 00-.26.51 1 1 0 00.08.68 18 18 0 002.86 3.59 18.06 18.06 0 003.59 2.86 1 1 0 00.67.07 1.92 1.92 0 00.52-.25 7.2 7.2 0 00.81-.75 4.55 4.55 0 01.64-.6l2.31 2.31a1.21 1.21 0 01.34.47.82.82 0 01-.15.46zM4.55 17.12a1.2 1.2 0 01-.84.34 1.17 1.17 0 01-.83-.34 1.14 1.14 0 010-1.64l1.37-1.38-.66-.67-1.36 1.37a2.11 2.11 0 000 3 2.15 2.15 0 001.49.63 2.11 2.11 0 001.49-.63l1.35-1.35-.67-.67z" />
        <path d="M9.17 9.17l1.62-1.63a.54.54 0 00.12-.49 3.36 3.36 0 013.17-4.49h.06a.3.3 0 01.24.09l-1.51 1.5a.55.55 0 00-.14.32.48.48 0 00.14.34l2.32 2.31a.45.45 0 00.33.14.45.45 0 00.31-.12l1.6-1.49a3.38 3.38 0 01-3.36 3.64 3.31 3.31 0 01-1.07-.2.45.45 0 00-.16 0 .45.45 0 00-.33.15l-1.6 1.59.7.67L13 10.08a4.2 4.2 0 001.12.15 4.31 4.31 0 004.06-5.76.52.52 0 00-.33-.3h-.11a.5.5 0 00-.33.12l-1.9 1.83-1.6-1.64 1.83-1.83a.42.42 0 00.12-.44.44.44 0 00-.3-.33 4.51 4.51 0 00-1.45-.25 4.34 4.34 0 00-3 1.25 4.45 4.45 0 00-1.2 4.22L8.49 8.52z" />
    </svg>
);

export default SvgV2ContactServiceCombined;
