import * as React from 'react';

const SvgArrowBigRight = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="1em" height="1em" viewBox="0 0 25 45" {...props}>
        <path
            d="M.139.157A.494.494 0 0 0 .16.864l23.355 21.637L.161 44.137a.493.493 0 0 0-.021.707.516.516 0 0 0 .722.02L25 22.501.861.137a.518.518 0 0 0-.722.02z"
        />
    </svg>
);

export default SvgArrowBigRight;
