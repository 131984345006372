import React, { ReactNode, forwardRef } from 'react';

interface LinkProps extends Link, AriaAttributes {
    children?: ReactNode;
    id?: string;
    className?: string;
    testId?: string;
    role?: string;
    rel?: string;
    analyticsData?: any;
    tabIndex?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    onMouseOver?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    onFocus?: React.FocusEventHandler<HTMLAnchorElement>;
}

const VitraLink = forwardRef<HTMLAnchorElement, LinkProps>((props, linkRef) => {
    const {
        href,
        role,
        name,
        title,
        testId,
        target,
        rel,
        analyticsData,
        onMouseOver,
        tabIndex,
        onClick,
        onFocus,
        className,
        id,
        children,
        ...otherProps
    } = props;
    // discard "analytics" prop that is included in the type, but
    // should not be spread onto the link DOM element
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { analytics, ...ariaAttributes } = otherProps;
    const hasChildren = React.Children.count(children) > 0;
    const elementToRender = hasChildren ? children : name;
    return (
        <a
            {...ariaAttributes}
            ref={linkRef}
            id={id}
            target={target}
            role={role}
            rel={rel}
            data-testid={testId}
            onMouseOver={onMouseOver}
            className={className}
            onClick={onClick}
            onFocus={onFocus}
            href={href}
            tabIndex={tabIndex}
            title={title || ''}
            {...analyticsData}
        >
            {elementToRender}
        </a>
    );
});

VitraLink.displayName = 'VitraLink';

export default VitraLink;
