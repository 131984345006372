import V2ArrowLeftMedium from 'common/primitives/icons/components/V2ArrowLeftMedium';
import VitraLink from 'common/primitives/links/vitraLink';
import VitraLogo from 'common/primitives/logos/vitraLogo';
import { useTranslations } from 'common/primitives/translations';
import { colors, containerCenteredV2, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

const styles = {
    checkoutHeaderWrapper: css`
        background: #fff;
        display: flex;
        justify-content: center;
        height: 70px;
        border-bottom: 1px solid ${colors.checkoutBorder};
        ${media.tablet} {
            border-bottom: none;
            height: 80px;
        }
    `,
    inner: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `,
    linkContact: css`
        > span {
            text-decoration: underline;
        }
    `,
    linkCart: css`
        display: flex;
        align-items: center;
        svg {
            margin-right: ${rh(0.5)};
            width: 15.6px;
        }
        &:hover {
            svg {
                fill: ${colors.primary};
            }
        }
    `,
    linkCartCopy: css`
        display: none;
        ${media.tablet} {
            display: inline-block;
        }
    `,
    logo: css`
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    `,
    help: css`
        flex: 0 1 auto;
        text-align: right;
        ${media.tablet} {
            max-width: calc(50vw - 140px);
        }
    `,
    helpDesktop: css`
        display: none;
        ${media.tablet} {
            display: inline-block;
        }
    `,
    helpMobile: css`
        display: inline-block;
        ${media.tablet} {
            display: none;
        }
    `
};

interface CheckoutHeader {
    /**
     * Link to the cart
     */
    cart: Link;

    /**
     * Link to contact
     */
    contact: Link;
}

const VitraCheckoutHeader: React.FunctionComponent<CheckoutHeader> = ({ cart, contact }) => {
    const t = {
        header_help: useTranslations('checkout.header.help.headline', 'Do you have a question?'),
        header_mobile: useTranslations('checkout.header.help.mobile', 'Help?'),
        header_desktop: useTranslations('checkout.header.help.desktop', "We're happy to help")
    };

    return (
        <div className={styles.checkoutHeaderWrapper}>
            <div className={cx(styles.inner, containerCenteredV2)}>
                <VitraLink className={styles.linkCart} {...cart}>
                    <V2ArrowLeftMedium />
                    <span className={styles.linkCartCopy}>{cart.name}</span>
                </VitraLink>
                <VitraLogo className={styles.logo} />
                <div className={styles.help}>
                    <span className={styles.helpDesktop} dangerouslySetInnerHTML={{ __html: t.header_help }} />
                    &nbsp;
                    <VitraLink className={styles.linkContact} {...contact}>
                        <span className={styles.helpDesktop} dangerouslySetInnerHTML={{ __html: t.header_desktop }} />
                        <span className={styles.helpMobile} dangerouslySetInnerHTML={{ __html: t.header_mobile }} />
                    </VitraLink>
                </div>
            </div>
        </div>
    );
};
export default React.memo(VitraCheckoutHeader, () => true);
