import * as React from 'react';

const SvgV1Directions = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
        <path
            fillRule="evenodd"
            d="M11.992 12.502v-2.5h-4v3h-2v-4c0-.55.45-1 1-1h5v-2.5l3.5 3.5-3.5 3.5zm7.71-3.21l-9-9a.996.996 0 00-1.41 0l-9 9a.996.996 0 000 1.41l9 9c.39.39 1.02.39 1.41 0l9-9a.996.996 0 000-1.41z"
        />
    </svg>
);

export default SvgV1Directions;
