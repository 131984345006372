import { colors, media, rh } from 'common/styles';
import { css } from 'linaria';
import React from 'react';
import VitraModal from '../modal/vitraModal';
import VitraProductInfo from './vitraProductInfo';

export interface VitraProductModalProps {
    /**
     * The Products
     */
    products: Product[];

    onClose?: () => void;

    /**
     * Triggered when loading a configuration
     */
    onLoadConfiguration: (productId: string) => void;
}

const styles = {
    modalDesktop: css`
        display: none;
        flex-grow: 1;
        min-height: 0;
        ${media.tablet} {
            background: #fff;
            display: block;
        }
    `,
    modalMobile: css`
        ${media.tablet} {
            display: none;
        }
    `,
    modalMobileInner: css`
        display: flex;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        scroll-snap-type: x proximity;
        padding: 0 37px;
        &:after {
            content: '';
            display: inline-table;
            height: 100%;
            width: 37px;
            border: 1px solid transparent;
        }
        // Hide Scrollbars
        &::-webkit-scrollbar {
            display: none; // Chrome, Safari
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    `,
    modalInnerGrid: css`
        padding: ${rh(1)};
        background: white;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
    `,
    modalProduct: css`
        background: #fff;
        border: 1px solid transparent;
        padding: ${rh(0.5)};
        flex: 1;
        &:hover {
            border: 1px solid ${colors.inputBorderColor};
        }
    `,
    modalProductMobile: css`
        scroll-snap-align: center;
        margin-right: 10px;
        flex: 0 0 100%;
        background: #fff;
        border: 1px solid transparent;
        padding: ${rh(0.5)};
        &:hover {
            border: 1px solid ${colors.inputBorderColor};
        }
    `,
    modal: css`
        padding: 0;
        background: transparent;
        overflow: auto;
        ${media.tablet} {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0;
            max-width: 1300px;
            padding-top: 0;
        }
    `
};

const VitraProductModal: React.FunctionComponent<VitraProductModalProps> = ({
    products,
    onLoadConfiguration,
    onClose
}) => {
    return (
        <VitraModal modalClass={styles.modal} closeOnClickOutside={true} blockBody={true} type="full" onClose={onClose}>
            <div className={styles.modalMobile}>
                <div className={styles.modalMobileInner}>
                    {products.map((product) => {
                        return (
                            <div className={styles.modalProductMobile}>
                                <VitraProductInfo
                                    display="full"
                                    onLoadConfiguration={onLoadConfiguration}
                                    product={product}
                                    showDescription={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.modalDesktop}>
                <div className={styles.modalInnerGrid}>
                    {products.map((product) => {
                        return (
                            <div className={styles.modalProduct}>
                                <VitraProductInfo
                                    onLoadConfiguration={onLoadConfiguration}
                                    display="hover"
                                    product={product}
                                    showDescription={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </VitraModal>
    );
};
export default VitraProductModal;
