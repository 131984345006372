import { useClickoutSide } from 'common/hooks/useClickOutside';
import useToggle from 'common/hooks/useToggle';
import { rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import placement from './placement';

const styles = {
    wrapper: css`
        display: inline;
    `,
    overlay: css`
        opacity: 0;
        pointer-events: none;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
        margin-bottom: 10px;
        &:after,
        &:before {
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
    `,
    arrowBottom: css`
        margin-bottom: ${rh(0.75)};
        &:after,
        &:before {
            top: 100%;
            left: 50%;
        }

        &:before {
            border-width: 11px;
            margin-left: -11px;
        }
        &:after {
            border-color: rgba(0, 0, 0, 0);
            border-top-color: #fff;
            border-width: 10px;
            margin-left: -10px;
        }
    `,
    arrowTop: css`
        margin-top: ${rh(0.75)};
        &:after,
        &:before {
            bottom: 100%;
            left: 50%;
        }
        &:after {
            border-bottom-color: #fff;
            border-width: 10px;
            margin-left: -10px;
        }
        &:before {
            border-color: rgba(0, 0, 0, 0);
            border-bottom-color: #fff;
            border-width: 11px;
            margin-left: -11px;
        }
    `,
    overlayVisible: css`
        pointer-events: all;
        z-index: 100;
        opacity: 1;
    `
};

interface Tooltip {
    /**
     * Extra styles for the content
     */
    className?: string;

    /**
     * Extra styles for the wrapper
     */
    wrapperClassName?: string;

    /**
     * Teh tooltip
     */
    tooltip: any;
    /**
     * What triggers the tooltip
     * we always trigger on click
     */
    trigger: 'hover' | 'click';

    onOpen?: () => void;
}

function usePortal() {
    const invisibleRootContainer = document.createElement('div');
    invisibleRootContainer.style.overflow = 'hidden';
    invisibleRootContainer.style.width = '0px';
    invisibleRootContainer.style.height = '0px';
    const rootElemRef = React.useRef(invisibleRootContainer);
    document.body.appendChild(rootElemRef.current);
    useEffect(() => {
        return () => {
            if (rootElemRef.current) {
                document.body.removeChild(rootElemRef.current);
            }
        };
    }, []);
    return rootElemRef.current;
}

const VitraTooltip: React.FunctionComponent<Tooltip> = ({
    children,
    className,
    wrapperClassName,
    trigger,
    tooltip,
    onOpen
}) => {
    const wrapperRef = useRef(null);
    const [isOpen, toggleOpen] = useToggle(false);
    const overlayRef = useClickoutSide(() => toggleOpen(false));
    const [align, setAlign] = useState<'bottom' | 'top'>('top');

    const updatePlacement = () => {
        if (isOpen) {
            const { side } = placement(wrapperRef.current, overlayRef.current, 'top', 'center');
            setAlign(side as any);
        }
    };

    useEffect(() => {
        if (canUseDOM) {
            updatePlacement();
        }
    }, [isOpen]);

    const hide = () => toggleOpen(false);

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        window.addEventListener('resize', hide);
        return () => {
            window.removeEventListener('resize', hide);
        };
    }, []);

    const onMouseOver = () => {
        if (trigger === 'hover') {
            toggleOpen(true);
            if (onOpen) onOpen();
        }
    };
    const onMouseOut = () => {
        if (trigger === 'hover') {
            toggleOpen(false);
            if (onOpen) onOpen();
        }
    };

    const onClick = () => {
        toggleOpen(true);
        if (onOpen) onOpen();
    };

    if (!canUseDOM) {
        return <div className={className}>{children}</div>;
    }

    return (
        <div onClick={onClick} onMouseOut={onMouseOut} onMouseOver={onMouseOver} className={styles.wrapper}>
            {createPortal(
                <div
                    className={cx(
                        isOpen && styles.overlayVisible,
                        align === 'top' && styles.arrowBottom,
                        align === 'bottom' && styles.arrowTop,
                        wrapperClassName,
                        styles.overlay
                    )}
                    ref={overlayRef as any}
                >
                    {isOpen && tooltip}
                </div>,
                usePortal()
            )}
            <div ref={wrapperRef} className={className}>
                {children}
            </div>
        </div>
    );
};

export default VitraTooltip;
