import * as React from 'react';

const SvgV2ShareCart = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={26} height={18} viewBox="0 0 26 18" {...props}>
        <g fillRule="nonzero">
            <path d="M24.125.397a1 1 0 01.993.883l.007.117v14.8a1 1 0 01-.883.993l-.117.007h-17a1 1 0 01-.117-1.993l.117-.007h16V3.181l-8.81 6.424a1 1 0 01-1.068.07l-.108-.068-9.987-7.225a1 1 0 01-.82-.868l-.007-.117a1 1 0 01.883-.993l.117-.007h20.8zm-3.32 1.999l-14.217.001 7.134 5.163 7.083-5.164z" />
            <path d="M6.5 7.397a1 1 0 01.117 1.993l-.117.007H1a1 1 0 01-.117-1.993L1 7.397h5.5zM8.25 11.022a1 1 0 01.117 1.993l-.117.007H1a1 1 0 01-.117-1.993L1 11.022h7.25z" />
        </g>
    </svg>
);

export default SvgV2ShareCart;
