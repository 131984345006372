// tslint:disable max-classes-per-file
import { useClickoutSide } from 'common/hooks/useClickOutside';
import { useKey } from 'common/hooks/useKey';
import CloseIcon from 'common/primitives/icons/components/V2Close';
import { media } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

export interface VitraModalProps {
    /**
     * Class Name of the wrapper
     */
    className?: string;
    /**
     * Class Name of the modal window
     */
    modalClass?: string;
    /**
     * On Click of the Close Button
     * if it is not set, it will not display the default close button X
     */
    onClose?: () => void;
    /**
     * Custom Close Component
     */
    closeComponent?: React.ReactChild;
    /**
     * Types
     */
    type?: 'small' | 'large' | 'video' | 'image' | 'full' | 'minimal';
    /**
     * If the body should be blocked
     */
    blockBody?: boolean;
    /**
     * Closes the modal when checking outside
     * of the content
     */
    closeOnClickOutside?: boolean;
}

const styles = {
    wrapper: css`
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 100020;
        background-color: rgba(51, 51, 51, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    modal: css`
        background: white;
        width: auto;
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    `,
    modalTypes: css`
        max-height: calc(100vh - 190px);
        max-height: calc(100dvh - 120px);
        margin: 140px 33.7px 80px;
        ${media.desktopAndUp} {
            max-height: calc(100vh - 120px - 80px);
            margin: 120px 80px;
        }
        > div {
            margin: 0 auto;
            display: block;
            width: 100%;
        }
    `,
    modalSmall: css`
        padding: 25px 30px;
        ${media.desktopAndUp} {
            padding: 50px 60px;
        }
    `,
    modalLarge: css`
        padding: 50px 60px;
    `,
    modalFull: css`
        width: 90%;
        height: 75%;
        padding: 20px;
    `,
    modalVideo: css`
        background: transparent;
        width: 100%;
        max-height: 100vh !important;
    `,
    modalImage: css`
        overflow: hidden;
        height: auto;
        width: auto;
        text-align: center;
        padding: 50px 60px;
        img {
            display: inline;
            max-height: calc(100vh - 120px - 80px - 200px);
        }
    `,
    modalMinimal: css`
        background: transparent;
        max-height: calc(100vh - 90px);
        margin: 0;
    `,
    close: css`
        position: absolute;
        z-index: 5000;
        top: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 22.6px;
            height: 22.6px;
            fill: white;
        }
        :hover {
            cursor: pointer;
        }
    `
};

const VitraModal: React.FunctionComponent<VitraModalProps> = (props) => {
    // Only works in DOM
    if (!canUseDOM) {
        return null;
    }

    // Mount/Unmount lifecylce
    function usePortal() {
        const rootElemRef = React.useRef(document.createElement('div'));
        document.body.appendChild(rootElemRef.current);
        useEffect(() => {
            return () => {
                if (rootElemRef.current) {
                    document.body.removeChild(rootElemRef.current);
                }
            };
        }, []);
        return rootElemRef.current;
    }

    const onCloseModal = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const onClose = (e: any) => {
        e.preventDefault();

        // prevents double triggering the onClose
        if (!props.closeOnClickOutside) {
            onCloseModal();
        }
    };

    // Close on Escape
    useKey((key) => {
        if (key === 'ESC') {
            onCloseModal();
        }
    });

    // A script block which holds styles to block the underlaying page from beein scrolled
    const blockBodyScrollScript = <style>{'body{position: absolute;width:100%}html{overflow: hidden;}'}</style>;

    const targetRef = useClickoutSide((e) => {
        if (props.closeOnClickOutside) {
            e.preventDefault();
            onCloseModal();
        }
    });

    // The Modal Container
    const modalContainer = (
        <div className={cx('modal__wrapper', styles.wrapper, props.className)}>
            {props.blockBody && blockBodyScrollScript}
            {props.onClose && !props.closeComponent && (
                <a onClick={onClose} className={styles.close}>
                    <CloseIcon />
                </a>
            )}
            {props.closeComponent}
            <div
                ref={targetRef as any}
                className={cx(
                    styles.modal,
                    props.type !== 'full' && styles.modalTypes,
                    props.type === 'small' && styles.modalSmall,
                    props.type === 'large' && styles.modalLarge,
                    props.type === 'video' && styles.modalVideo,
                    props.type === 'full' && styles.modalFull,
                    props.type === 'image' && styles.modalImage,
                    props.type === 'minimal' && styles.modalMinimal,
                    props.modalClass
                )}
            >
                {props.children}
            </div>
        </div>
    );

    return createPortal(modalContainer, usePortal());
};

export default VitraModal;
