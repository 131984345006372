import * as React from 'react';

function V2Trash(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
            <path
                fill="#333"
                d="M18.8 4h-4.2V2.8c0-1.5-1.2-2.8-2.8-2.8h-4C6.2 0 5 1.2 5 2.8V4H.8c-.5 0-.8.3-.8.8s.3.8.8.8H2v11.2c0 1.5 1.2 2.8 2.8 2.8h10c1.5 0 2.8-1.2 2.8-2.8V5.5h1.2c.4 0 .8-.3.8-.8s-.4-.7-.8-.7zM6.5 2.8c0-.7.6-1.2 1.2-1.2h4c.7 0 1.2.6 1.2 1.2V4H6.5V2.8zm9.5 14c0 .7-.6 1.2-1.2 1.2h-10c-.7 0-1.2-.6-1.2-1.2V5.5H16v11.3z"
            />
            <path
                fill="#333"
                d="M7.8 8c-.5 0-.8.3-.8.8v6c0 .4.3.8.8.8s.8-.3.8-.8v-6c-.1-.5-.4-.8-.8-.8zm4 0c-.5 0-.8.3-.8.8v6c0 .4.3.8.8.8s.8-.3.8-.8v-6c-.1-.5-.4-.8-.8-.8z"
            />
        </svg>
    );
}

export default V2Trash;
