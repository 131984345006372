import React from 'react';

interface CommonMapPositionProps {
    lat: number;
    lng: number;
}

const CommonMapPosition: React.FunctionComponent<CommonMapPositionProps> = (props) => (
    <div className="map__position" style={{
        position: 'absolute',
        zIndex: 3,
        width: '21px',
        height: '21px',
        borderRadius: '50%',
        left: '-10px',
        top: '-10px',
        background: '#3d92e0',
        border: '4px solid white',
        boxShadow: '0 0 5px rgba(0,0,0,0.5)'
    }}/>
);

export default CommonMapPosition;
