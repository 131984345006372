import { css, cx } from 'linaria';
import React from 'react';
import VitraPicture from './vitraPicture';

interface PictureZoomProps {
    /**
     * The IMage
     */
    image: Image;

    /**
     * Optional ClasName for the warpper
     */
    className?: string;
}

const styles = {
    wrapper: css`
        font-size: 0;
        overflow: hidden;
        &:hover {
            cursor: pointer;
            .zoom__picture img {
                transform: scale(1.05) rotate(0.02deg);
            }
        }
    `,
    picture: css`
        img {
            transition: all 0.2s linear;
        }
    `
};

const VitraPictureZoom: React.FunctionComponent<PictureZoomProps> = ({ className, image }) => {
    // Render
    return (
        <div className={cx('image__zoom', styles.wrapper, className)}>
            <VitraPicture image={image} className={cx('zoom__picture', styles.picture)} />
        </div>
    );
};

export default VitraPictureZoom;
