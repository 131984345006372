import React, { Fragment } from 'react';

import { colors } from 'common/styles';
import { ListingType } from 'components/listing/listing';
import { css } from 'linaria';
import Translate from '../translations';

interface ListingFilterCounter {
    total: number;
    type: ListingType;
}

const styles = {
    listingCounter: css`
        font-size: 14px;
        color: ${colors.textGrey};
    `
};

const VitraListingCounter: React.FunctionComponent<ListingFilterCounter> = ({ total, type }) => {
    if (!total || total === 0) {
        return <div />;
    }
    const t: Record<ListingType, JSX.Element> = {
        products: (
            <Translate
                id="listing.filter.count.products"
                defaultMessage="{total} products"
                data={{ total: `${total}` }}
            />
        ),
        materials: (
            <Translate
                id="listing.filter.count.materials"
                defaultMessage="{total} materials"
                data={{ total: `${total}` }}
            />
        ),
        articles: (
            <Translate id="listing.filter.count.articles" defaultMessage="{total} items" data={{ total: `${total}` }} />
        ),
        'products-inline': (
            <Translate
                id="listing.filter.count.products"
                defaultMessage="{total} products"
                data={{ total: `${total}` }}
            />
        )
    };

    return <div className={styles.listingCounter}>{total !== 0 && <Fragment>{t[type]}</Fragment>}</div>;
};

export default VitraListingCounter;
