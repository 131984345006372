import { useConfig } from 'common/hooks/useBoostrap';
import { isMobile } from 'common/hooks/useBreakpoints';
import useToggle from 'common/hooks/useToggle';
import { useWishlistBffContext, WishlistBffContext } from 'common/hooks/useWishlist';
import { addGaEvent } from 'common/primitives/analytics';
import IconInfo from 'common/primitives/icons/components/V1Info';
import { colors, injectBackgroundPicture, media } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment, useRef } from 'react';
import { useLocation } from 'wouter';
import { FindVitraContentLocationExtended } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';
import CommonLocationButtons from './commonLocationButtons';
import CommonLocationTags from './commonLocationTags';
import CommonSearchResultListLocationIsOpen from './commonSearchResultListLocationIsOpen';

const styles = {
    listLocation: css`
        width: 100%;
        padding: 12px 0px 28px;
        display: flex;
        flex-flow: wrap;
        font-size: 15px;
        &:not(:last-child):after {
            content: '';
            width: 100%;
            height: 1px;
            background: ${colors.findVitraColorBorder};
            position: relative;
            top: 22px;
            display: block;
            z-index: 1;
        }
        &:hover {
            cursor: pointer;
        }
    `,
    listLocationDesktop: css`
        padding: 12px 16px 28px;
        background: ${colors.findVitraColorSilver};
    `,
    locationInfo: css`
        font-size: 15px;
        flex: 1;
    `,
    locationInfoHighlight: css`
        margin-left: -16px;
        padding-left: 16px;
        box-shadow: inset 3px 0 0 0px ${colors.findVitraColorRed};
    `,
    locationInfoName: css`
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 8px;
        svg {
            margin-left: 6px;
            width: 14px;
            height: 14px;
            fill: ${colors.findVitraColorGray};
        }
    `,
    locationInfoAddress: css`
        color: ${colors.findVitraColorGray};
        margin-bottom: 0;
    `,
    locationMeta: css`
        margin-left: auto;
    `,
    metaDistance: css`
        text-align: right;
        white-space: nowrap;
        margin-bottom: 8px;
        font-size: 16px;
    `,
    metaPicture: css`
        width: 100px;
        height: 100px;
        background: #d8d8d8;
        background-size: cover;
    `,
    locationTagsDesktop: css`
        margin-bottom: 10px;
        .tags__item {
            background: white;
        }
    `,
    locationTags: css`
        margin-bottom: 10px;
        ${media.tablet} {
            margin-top: 10px;
            margin-bottom: 0;
        }
    `,
    locationButtons: css`
        margin-top: 10px;
    `
};

interface CommonSearchResultListLocationProps {
    location: FindVitraContentLocationExtended;
    guaranteeVisibility?: (el: any) => void;
    type: 'desktop' | 'mobile';
}

const CommonSearchResultListLocation: React.FunctionComponent<CommonSearchResultListLocationProps> = (props) => {
    const wishlistContext: WishlistBffContext = useWishlistBffContext();

    const baseUrl = useConfig('baseUrl', 'https://www.vitra.com');

    // Wouter location (we name this path )
    const [, setPath] = useLocation();

    const locationRef = useRef(null);
    const location = props.location;

    // State
    const [state, dispatch]: any = useStateValue();

    // Thumbnail
    let thumbnailInlineCSS = null;
    let thumbnailClassName = null;
    const thumbnail = location?.images?.find((img) => {
        return img.meta?.purpose === 'thumbnail';
    });

    if (thumbnail) {
        // Transform old image definition to the new types
        const newImageType = {
            src: `${baseUrl}${(thumbnail as any).srcSet[1].src}`,
            width: 200,
            height: 200
        };
        const i = injectBackgroundPicture(newImageType);
        thumbnailInlineCSS = i.backgroundImage;
        thumbnailClassName = i.backgroundClass;
    }

    // Location Click
    const [showLocationButtons, toggleShowLocationButtons] = useToggle(false);
    const handleLocationClick = () => {
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'Click POI',
            eventLabel: location.cn
        });

        const { has_detail } = location;
        if (!has_detail) {
            toggleShowLocationButtons();
        }
        if (has_detail) {
            setPath(`/details/${location._code}`, { replace: true });
        }
        if (!showLocationButtons && props.guaranteeVisibility) {
            props.guaranteeVisibility(locationRef!.current as any);
        }
    };

    // Mouse Events
    const handleMouseEnterLeave = (id: string) => (evt: React.MouseEvent) => {
        dispatch({ type: 'UX_TOGGLE_HIGHLIGHT_LOCATION', id });
    };

    return (
        <Fragment>
            {thumbnailInlineCSS}
            <div
                id={`resultListLocationId--${location._code}`}
                ref={locationRef}
                className={cx(styles.listLocation, props.type == 'desktop' && styles.listLocationDesktop)}
                onMouseEnter={handleMouseEnterLeave(location._code)}
                onMouseLeave={handleMouseEnterLeave(location._code)}
                onClick={() => {
                    handleLocationClick();
                }}
            >
                <div
                    className={cx(
                        styles.locationInfo,
                        location._code === state.ux.highlightLocation &&
                            props.type === 'desktop' &&
                            styles.locationInfoHighlight
                    )}
                >
                    <div className={styles.locationInfoName}>
                        {location.cn}
                        {location.has_detail && <IconInfo />}
                    </div>
                    <div className={styles.locationInfoAddress}>
                        {location.sa}
                        <br />
                        {location.pc} {location.city}
                        <br />
                        {location.phone}
                    </div>
                    {location.openingHours && location.openingHours.length > 0 && (
                        <CommonSearchResultListLocationIsOpen className="info__isopen" location={location} />
                    )}
                </div>
                <div className={styles.locationMeta}>
                    <div className={styles.metaDistance}>{location.distanceFormatted || '0 km'}</div>
                    {typeof thumbnail !== 'undefined' && <div className={cx(styles.metaPicture, thumbnailClassName)} />}
                </div>
                <CommonLocationTags
                    location={location}
                    className={cx(styles.locationTags, !isMobile() && styles.locationTagsDesktop)}
                />
                {showLocationButtons && (
                    <CommonLocationButtons
                        className={styles.locationButtons}
                        withWishlist={wishlistContext.getWishlistCount && wishlistContext.getWishlistCount() > 0}
                        location={location}
                        withIcons={true}
                    />
                )}
            </div>
        </Fragment>
    );
};

export default React.memo(CommonSearchResultListLocation);
