/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import ArrowLeft from 'common/primitives/icons/components/V1ArrowLeft';
import ArrowRight from 'common/primitives/icons/components/V1ArrowRight';
import { colors, linkColor } from 'common/styles';
import { easeOutQuad, timer } from 'common/utils/animation';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import get from 'lodash/get';
import set from 'lodash/set';
import takeRightWhile from 'lodash/takeRightWhile';
import takeWhile from 'lodash/takeWhile';
import React from 'react';

const styles = {
    wrapper: css`
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0);
        will-change: transform;
    `,
    next: css`
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: absolute;
        z-index: 1;
        right: 10px;
        top: calc(50% - 30px);
        background: white;
    `,
    prev: css`
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: absolute;
        z-index: 1;
        left: 10px;
        top: calc(50% - 30px);
        background: white;
    `,
    slide: css`
        position: absolute;
        left: 0;
        top: 0;
        will-change: transform;
        transform-origin: left;
        transition: filter 0.5s ease;
        &.slide--active .slide__caption {
            transition-delay: 800ms;
            opacity: 1;
        }
        &:hover {
            cursor: pointer;
            filter: brightness(110%);
        }
    `,
    imageScaleOuter: css`
        width: 100%;
        height: 100%;
        will-change: transform;
        transform-origin: left;
    `,
    imageScaleInner: css`
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        will-change: transform;
        transform-origin: left;
        background-color: silver;
        &:nth-child(2n) {
            background: grey;
        }
    `,
    caption: css`
        user-select: none;
        font-size: 14px;
        text-align: right;
        opacity: 0;
        transition: opacity 0.2s;
        will-change: transform;
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `
};

interface SliderProps {
    slides: any;
    className?: string;
    animationIndex?: number;
    showControls?: boolean;
    onAnimationEnd?: () => void;
    onAnimationStart?: () => void;
}

export default class Slider extends React.Component<SliderProps> {
    private slides: any = [];

    // Refs
    private wrapperRef = React.createRef<HTMLDivElement>();
    private slideRefs: any = [];

    // Animation
    private animationSpacing = 16; // 16, the gaps between the slides
    private animationMargin = 90; // 90, the left margin
    private animationIndex = 0; // Current active slide
    private animationInactiveWidth = 480; // Gets overidden based on the size of the wrapper
    private animationAnimating = false; // Is the animation in Progress

    // Sizing
    private wrapperHeight = 0;
    private wrapperWidth = 0;

    // Swiping
    private gesture: any = {};

    // Init
    public UNSAFE_componentWillMount(): any {
        this.slides = this.props.slides;
        this.slideRefs = this.slides.map(() => React.createRef<HTMLDivElement>());
    }

    public componentDidMount(): any {
        this.slides = this.calculateSlideDimensions(this.slides);
        this.gotoIndex(this.slides, this.props.animationIndex || 0, false);
        if (canUseDOM) {
            window.addEventListener('resize', this.handleResize);
        }
        this.initMouseAndTouchGestures();
    }

    // Destroy
    public componentWillUnmount(): any {
        if (canUseDOM) {
            window.removeEventListener('resize', this.handleResize);
        }
        if (canUseDOM && this.gesture.destroy) {
            this.gesture.destroy();
        }
    }

    // Assure we only update if required
    public shouldComponentUpdate(nextProps: SliderProps): any {
        if (nextProps.animationIndex !== this.props.animationIndex) {
            return true;
        }
        return false;
    }

    // Handles mouse and touch gestures
    public initMouseAndTouchGestures(): any {
        if (!canUseDOM) {
            return;
        }
        const TinyGesture = require('common/utils/tinygesture').default;
        this.gesture = new TinyGesture(this.wrapperRef.current);
        this.gesture.on('panmove', () => {
            if (this.gesture.swipingDirection === 'horizontal' || this.gesture.swipingDirection === 'pre-horizontal') {
                if (this.gesture.touchMoveX < 0) {
                    this.gotoNextIndex();
                } else if (this.gesture.touchMoveX > 0) {
                    this.gotoPrevIndex();
                }
            }
        });
        this.gesture.on('tap', (event: any) => {
            // layerX differs in Firefox so we just use the screen size
            // If you click left of you screen we go back, else forwand
            if (event.clientX > document.body.clientWidth / 2) {
                this.gotoNextIndex();
            } else {
                this.gotoPrevIndex();
            }
        });
    }

    public handleResize = () => {
        this.calculateSlideDimensions(this.slides);
        this.gotoIndex(this.slides, this.animationIndex, false);
    };

    public calculateSlideDimensions(s: any): any {
        if (this.wrapperRef.current) {
            const wrapperRect = this.wrapperRef.current.getBoundingClientRect();
            this.wrapperHeight = Number(wrapperRect.height.toFixed(0));
            this.wrapperWidth = Number(wrapperRect.width.toFixed(0));
            // 480/685 are the proportions straight out of the design
            this.animationInactiveWidth = (480 / 685) * this.wrapperHeight;
            s.forEach((slide: any, i: number) => {
                const width = Math.round((slide.image.width / slide.image.height) * this.wrapperHeight);
                set(slide, 'height', this.wrapperHeight);
                set(slide, 'width', width);
                const el = this.slideRefs[i].current as HTMLElement;
                if (el) {
                    el.style.width = this.animationSpacing + slide.width + 'px';
                    el.style.height = slide.height + 'px';
                }
            });
        }
        return s;
    }

    public animationDoStart(s: any, newIdx = 0): any {
        /* tslint:disable:max-line-length*/

        if (newIdx >= s.length || newIdx < 0 || this.animationAnimating || !canUseDOM) {
            return;
        }

        if (this.props.onAnimationStart) {
            this.props.onAnimationStart();
        }

        this.animationAnimating = true;

        /*
        The cursor is our starting point for translateX calculations.
        We move negative to the invisible left of the wrapper to get the
        position from the most outer left slide.

        - marginOffset: All Slides except the first one have a left margin
        - borderOffset: We must subtract the border from the first slide
        */
        const marginOffset = newIdx !== 0 ? this.animationMargin : 0;
        const borderOffset = this.animationSpacing * -1;

        let cursorX = takeWhile(s, (v, i) => {
            return i < newIdx;
        }).reduce(
            (sum: number, slide: any) => sum - (slide.width + this.animationSpacing),
            marginOffset + borderOffset
        );

        /*
        We calcaulate the remaining width of all slides
        to assure that the screen is always filled with slides
        */
        const remainingSlidesWidth = takeRightWhile(s, (v, i) => {
            return i >= newIdx;
        }).reduce((sum: number, slide: any, i: number) => {
            // The new slide (i === 0) will be full width...
            // all others (i !== 0) will be scaled down wo we need the scaled width
            return (
                sum + slide.width * (i === 0 ? 1 : this.animationInactiveWidth / slide.width) + this.animationSpacing
            );
        }, 0);

        // Check the remaining space and adjust the animation positon
        if (remainingSlidesWidth < this.wrapperWidth) {
            cursorX += this.wrapperWidth - remainingSlidesWidth - (marginOffset + borderOffset);
        }

        /*
        We map the CURRENT values to FROM and the target VALUES to TO
        This is needed bacause in the timer function we need to know our
        animation ranges
        */
        s.forEach((slide: any, i: number) => {
            set(slide, 'animateFrom.translateX', slide.translateX || 0);
            set(
                slide,
                'animateFrom.scale',
                slide.scale || (i <= newIdx ? 1 : this.animationInactiveWidth / slide.width)
            );
            set(slide, 'animateTo.translateX', cursorX);
            set(slide, 'animateTo.scale', i <= newIdx ? 1 : this.animationInactiveWidth / slide.width);
            cursorX +=
                i <= newIdx ? slide.width + this.animationSpacing : this.animationInactiveWidth + this.animationSpacing;
        });

        this.animationIndex = newIdx;

        return s;

        /* tslint:enable:max-line-length*/
    }

    public animationDoApply(s: any, newIdx: number, step: number) {
        if (!s || s.length === 0) {
            return;
        }

        /* tslint:disable:max-line-length*/
        s.forEach((slide: any, i: number) => {
            if (!this.slideRefs[i] || !this.slideRefs[i].current) {
                return;
            }
            const el = this.slideRefs[i].current as HTMLElement;
            const firstChild = el.children[0] as HTMLElement;
            const secondChild = el.children[0].children[0] as HTMLElement;

            const fromTranslateX = slide.animateFrom.translateX;
            const toTranslateX = slide.animateTo.translateX;
            const fromScale = slide.animateFrom.scale;
            const toScale = slide.animateTo.scale;

            const stepEased = easeOutQuad(step);

            // The slide position
            const translateX = fromTranslateX + (toTranslateX - fromTranslateX) * stepEased;
            /*
            Center the background image via a negative x offset
            IT'S IMPORTANT TO UNDERSTAND that EVERYTHING remains left aligned
            So we move the background picture to the left. This way it appears to be centered.
            */
            const scale = i <= newIdx ? 1 : fromScale + toScale * stepEased;
            const backgroundPositionX =
                (slide.width - (fromScale + (toScale - fromScale) * stepEased) * slide.width) / 2;

            // Apply all the styles
            if (el) {
                el.style.transform = `translate3d(${translateX.toFixed(0)}px,0,0)`;
                firstChild.style.transform = `scale(${scale.toFixed(1)}, 1)`;
                secondChild.style.transform = `scale(${1 / scale.toFixed(1)}, 1)`;
                secondChild.style.backgroundPositionX = `-${backgroundPositionX.toFixed(0)}px`;

                // Add a class which indicates the active slide
                if (i === this.animationIndex) {
                    el.classList.add('slide--active');
                } else {
                    el.classList.remove('slide--active');
                }
            }
        });
        /* tslint:enable:max-line-length*/
    }

    public animationDoEnd(s: any) {
        s.forEach((slide: any) => {
            set(slide, 'translateX', slide.animateTo.translateX);
            set(slide, 'scale', slide.animateTo.scale);
        });

        this.animationAnimating = false;

        if (this.props.onAnimationEnd) {
            this.props.onAnimationEnd();
        }
    }

    // This function is meant to be accessed by an outer component
    public goNextPrevious(direction: 'previous' | 'next'): boolean {
        if (this.animationAnimating) {
            return true;
        }
        if (direction === 'next') {
            return this.gotoNextIndex();
        }
        return this.gotoPrevIndex();
    }
    public hasPrev(): boolean {
        return this.animationIndex !== 0;
    }

    public hasNext(): boolean {
        return this.animationIndex < this.slides.length - 1;
    }

    public next(): boolean {
        if (this.hasNext()) {
            if (!this.animationAnimating) {
                this.gotoIndex(this.slides, this.animationIndex + 1);
            }
            return true;
        }
        return false;
    }

    public prev(): boolean {
        if (this.hasPrev()) {
            if (!this.animationAnimating) {
                this.gotoIndex(this.slides, this.animationIndex - 1);
            }
            return true;
        }
        return false;
    }

    public hasNextPrevious(direction: 'previous' | 'next'): boolean {
        if (direction === 'next') {
            return this.animationIndex < this.slides.length - 1;
        }
        return this.animationIndex !== 0;
    }

    public gotoNextIndex(): boolean {
        if (this.hasNextPrevious('next')) {
            if (!this.animationAnimating) {
                this.gotoIndex(this.slides, this.animationIndex + 1);
            }
            return true;
        }
        return false;
    }

    public gotoPrevIndex(): boolean {
        if (this.hasNextPrevious('previous')) {
            if (!this.animationAnimating) {
                this.gotoIndex(this.slides, this.animationIndex - 1);
            }
            return true;
        }
        return false;
    }

    public gotoIndex(s: any, newIdx = 0, animate = true) {
        this.animationDoStart(s, newIdx);
        timer(
            (step: number) => {
                this.animationDoApply(s, newIdx, step);
            },
            animate ? 640 : 0,
            () => {
                this.animationDoEnd(s);
            }
        );
    }

    public render() {
        return (
            <div id="slider_wrapper" ref={this.wrapperRef} className={styles.wrapper}>
                {this.slides.map((s: any, i: number) => {
                    const backgroundImage = get(s.image, 'srcSet[0].src', s.image.src);
                    return (
                        <div
                            className={styles.slide}
                            style={{ borderLeft: `${this.animationSpacing}px solid white` }}
                            ref={this.slideRefs[i]}
                        >
                            <div className={styles.imageScaleOuter}>
                                <div
                                    className={styles.imageScaleInner}
                                    style={{ backgroundImage: `url(${backgroundImage})` }}
                                />
                            </div>
                            <div
                                dangerouslySetInnerHTML={{ __html: s.description }}
                                className={cx('slide__caption', styles.caption)}
                            />
                        </div>
                    );
                })}
                {this.props.showControls && (
                    <div className={styles.prev} onClick={() => this.gotoPrevIndex()}>
                        <ArrowLeft />
                    </div>
                )}
                {this.props.showControls && (
                    <div className={styles.next} onClick={() => this.gotoNextIndex()}>
                        <ArrowRight />
                    </div>
                )}
            </div>
        );
    }
}
