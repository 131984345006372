import useId from 'common/hooks/useId';
import globalStyle from 'common/primitives/forms/styles';
import IconArrowDown from 'common/primitives/icons/components/V2ArrowBottomSmall';
import { adjustFontSizeTo, colors, fontSizeAndLineHeight, rh, vitraFont } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import React from 'react';

const styles = defaults(
    {
        selectInput: css`
            font-family: Arial, sans-serif;
            font-weight: 500;
            ${vitraFont}
            font-weight: 100;
            ${fontSizeAndLineHeight('17px')}
            -webkit-font-smoothing: antialiased;
            appearance: none;
            width: 100%;
            padding-right: ${rh(2)};
            padding-left: ${rh(0.5)};
            padding-top: ${rh(0.65)};
            padding-bottom: ${rh(0.15)};
            color: ${colors.black};
            border: 1px solid ${colors.lightgrey};
            border-radius: 0px;
            background: #fff;
            &:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 #000;
            }
            &:hover,
            &:active,
            &:focus {
                outline: none;
                border: 1px solid ${colors.black};
            }
            &:hover {
                cursor: pointer;
            }
        `,
        selectWrapperDisabled: css`
            pointer-events: none;
            background: ${colors.lightgrey};
            label,
            &:hover label {
                color: ${colors.textGrey};
                background: transparent;
            }
        `,
        selectInputNoLabel: css`
            padding-top: ${rh(0.15)};
        `,
        selectInputLabelFocused: css`
            opacity: 1;
            top: 4px;
            left: calc(${rh(0.5)} - ${rh(0.2)});
            font-size: ${adjustFontSizeTo('12px')};
            width: auto;
            background: #fff;
            padding: 0 ${rh(0.25)};
            padding-right: ${rh(0.5)};
        `,
        selectWrapper: css`
            &:hover,
            &:focus {
                label {
                    color: ${colors.black};
                    span {
                        color: ${colors.black};
                    }
                }
                path {
                    fill: ${colors.black};
                }
            }
        `,
        selectInputError: css`
            border: 1px solid ${colors.error};
            &:hover,
            &:active,
            &:focus {
                border: 1px solid ${colors.error};
            }
        `,
        iconSelectArrow: css`
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        `,
        iconSelectArrowNolabel: css`
            top: 23px;
        `
    },
    globalStyle
);

interface SelectInputProps extends InputProps {
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onFocus?: (event: React.ChangeEvent<HTMLSelectElement>) => void;

    defaultOption?: string;
    placeholder?: string;
    options: NameValue[];
}

const SelectInput: React.FunctionComponent<SelectInputProps> = (props) => {
    const {
        name,
        label,
        errors,
        options = [],
        inputRef,
        isRequired,
        showErrorMessage,
        className,
        disabled = false,
        placeholder,
        defaultOption,
        onChange,
        onBlur,
        onFocus
    } = props;

    const handleOnBlur = (e: any) => {
        if (onBlur) {
            onBlur(e);
        }
    };

    const handleOnChange = (e: any) => {
        if (onChange) {
            onChange(e);
        }
    };

    // Error
    const hasError = errors && errors[name];

    // Input id
    const id = useId();

    return (
        <div
            className={cx(
                'form__field',
                'form__field--select',
                styles.wrapper,
                styles.selectWrapper,
                disabled && styles.selectWrapperDisabled,
                className
            )}
        >
            <select
                className={cx(
                    styles.selectInput,
                    hasError && styles.selectInputError,
                    !label && styles.selectInputNoLabel
                )}
                id={id}
                name={name}
                ref={inputRef}
                disabled={disabled}
                value={defaultOption}
                onFocus={onFocus}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            >
                {placeholder && (
                    <option key="-1" value="" disabled>
                        {placeholder}
                    </option>
                )}
                {options.map((opt) => (
                    <option value={opt.value || opt.name}>{opt.name}</option>
                ))}
            </select>
            {!disabled && (
                <IconArrowDown className={cx(styles.iconSelectArrow, !label && styles.iconSelectArrowNolabel)} />
            )}
            {label && (
                <label
                    className={cx(styles.label, styles.selectInputLabelFocused, hasError && styles.errorLabel)}
                    htmlFor={id}
                >
                    {label} {isRequired ? ' *' : ''}
                </label>
            )}
            {hasError && showErrorMessage && (
                <div className={cx('errorMessage', styles.errorMessage)}>{props.errors[props.name].message}</div>
            )}
        </div>
    );
};

export default SelectInput;
