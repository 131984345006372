import React from 'react';
import VitraArticle from './vitraArticle';
import { css } from 'linaria';
import { media } from 'common/styles';

export interface VitraArticleListingProps {
    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * The Article
     */
    articles: Article[];
}

const styles = {
    grid: css`
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        ${media.tablet} {
            grid-template-columns: 1fr 1fr 1fr;
        }
        ${media.desktop} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    `
};

const VitraArticleListing: React.FunctionComponent<VitraArticleListingProps> = ({
    className, articles
}) => {
    return (
        <div className={styles.grid}>
            {articles.map((article) => (
                <div key={article.id}>
                    <VitraArticle
                        className={className}
                        article={article}
                        lazy={true}
                    />
                </div>
            ))}
        </div>
    );
};
export default VitraArticleListing;
