import { colors } from 'common/styles';
import { css } from 'linaria';
import React, { useState } from 'react';

const styles = {
    wrapper: css`
        width: 100%;
        min-width: 100px;
        min-height: 24px;
        display: flex;
        align-items: center;
        * {
            outline: none;
        }
        input[type='range'] {
            -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
            width: 100%; /* Specific width is required for Firefox. */
            background: transparent; /* Otherwise white in Chrome */
        }
        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
        }

        input[type='range']:focus {
            outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }

        input[type='range']::-ms-track {
            width: 100%;
            cursor: pointer;

            /* Hides the slider so custom styles can be added */
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: ${colors.findVitraColorBlack};
            border-radius: 100%;
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        }

        /* All the same stuff for Firefox */
        input[type='range']::-moz-range-thumb {
            background: ${colors.findVitraColorBlack};
            border-radius: 100%;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        /* All the same stuff for IE */
        input[type='range']::-ms-thumb {
            background: ${colors.findVitraColorBlack};
            width: 24px;
            height: 24px;
            border-radius: 100%;
            cursor: pointer;
        }

        input[type='range']::-webkit-slider-runnable-track {
            width: 100%;
            height: 1px;
            background: ${colors.findVitraColorBorder};
        }

        input[type='range']:focus::-webkit-slider-runnable-track {
            height: 1px;
            background: ${colors.findVitraColorBlack};
        }
        input[type='range']::-moz-range-progress {
            height: 1px;
            background: ${colors.findVitraColorBlack};
        }

        input[type='range']::-moz-range-track {
            height: 1px;
            width: 100%;
            background: ${colors.findVitraColorBorder};
        }

        input[type='range']::-ms-track {
            height: 1px;
            width: 100%;
            background: ${colors.findVitraColorBorder};
        }
        input[type='range']::-ms-fill-lower {
            background: ${colors.findVitraColorBlack};
        }
        input[type='range']:focus::-ms-fill-lower {
            background: ${colors.findVitraColorBlack};
        }
        input[type='range']::-ms-fill-upper {
            background: ${colors.findVitraColorBorder};
        }
        input[type='range']:focus::-ms-fill-upper {
            background: ${colors.findVitraColorBorder};
        }
    `
};

interface RangeSliderProps {
    /**
     * An additional className fpr the component
     */
    className?: string;

    /**
     * Max Value
     */
    min: number;

    /**
     * Max Value
     */
    max: number;

    /**
     * Step
     */
    step?: number;

    /**
     * value
     */
    value: number;

    /**
     * Show Tooltip
     */
    tooltip?: boolean;

    /**
     * OnChange
     */
    onChange?: (value: number) => void;

    /**
     * OnChangeComplete
     */
    onChangeComplete?: (evt: React.MouseEvent) => void;
}

const VitraRangeSlider: React.FunctionComponent<RangeSliderProps> = (props) => {
    const [value, setValue] = useState<number>(props.value || 1);

    const handleOnChange = (e: any) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };

    const onMouseUp = (e: any) => {
        if (props.onChangeComplete) {
            props.onChangeComplete(e);
        }
    };

    return (
        <div className={styles.wrapper}>
            <input
                type="range"
                step={props.step}
                value={value}
                min={props.min}
                max={props.max}
                onChange={handleOnChange}
                onMouseUp={onMouseUp}
            />
        </div>
    );
};

export default VitraRangeSlider;
