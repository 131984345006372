import VerticalRhythm from 'compass-vertical-rhythm';
import { css } from 'linaria';
import { colors } from './colors';
import { media } from './media';

const VerticalRhythmConfig = {
    baseFontSize: '17px',
    baseLineHeight: 1.9,
    roundToNearestHalfLine: false
};

export const vh = VerticalRhythm(VerticalRhythmConfig);

/**
 * Creates a rhythm for the relative font-size and LineHeight: `1.3`
 * @param factor Multiplication Factor
 */
export const rh = (factor = 1): string => {
    return VerticalRhythm(VerticalRhythmConfig).rhythm(factor);
};

/**
 * Gets the percentage for a font size
 * @param size font size to ajuest eg. `14px`
 */
export const adjustFontSizeTo = (size: string): string => {
    return VerticalRhythm(VerticalRhythmConfig).adjustFontSizeTo(size).fontSize;
};

/**
 * Generates a css string with font-size and lineHeight
 * based on the VerticalRhythm settings
 *
 * @param size the desired font size with px appended
 * @param lines either the requested lineheight or a estimation of the best line-height to fit
 */
export const fontSizeAndLineHeight = (size: string, lines: number | 'auto' = 'auto', important = false): string => {
    const f = VerticalRhythm(VerticalRhythmConfig).adjustFontSizeTo(size, lines);
    return `
        font-size: ${f.fontSize}${important ? ' !important' : ''};
        line-height ${f.lineHeight}${important ? ' !important' : ''};
    `;
};

/**
 * Adds a Grid overlay for debugging
 */
export const addGridOverlay = (lh: string = rh(1)): string => {
    return `
        background: linear-gradient(rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 0.15) 1px, transparent 1px);
        background-size: 1px ${lh};
    `;
};

/**
 * Sets the link colors
 * @param normal color
 * @param hover color
 * @param active color
 * @param visited color
 * @param focus color
 */
// prettier-ignore
export const linkColor = (
    normal: string | colors,
    hover: string | colors,
    active?: string | colors,
    visited?: string | colors,
    focus?: string | colors
): string => {
    return `
        color: ${normal};
        ${visited ? `
        &:visited { color: ${visited}; }
        ` : `&:visited { color: ${normal}; }`}
        &:hover { color: ${hover}; }
        ${active ? `
        &:active { color: ${active}; }
        ` : ''}
        ${focus ? `
        &:focus { color: ${focus}; }
        ` : ''}
    `;
};

/**
 * Sets the max-width for a container
 */
export const containerCentered = css`
    position: relative;
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    width: 100%;
    ${media.desktopAndUp} {
        padding: 0 10px;
    }
`;

// Thew new max-width... this rule replaces
// the old one completly as sonn as we are finished
export const containerCenteredV2 = css`
    position: relative;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    width: 100%;
    ${media.desktopAndUp} {
        padding: 0 10px;
    }
`;

/**
 * The base font style injected into every base widget
 */
export const vitraFont = `
    font-family: "vitraFutura", "Futura", Arial, sans-serif;
`;

export const widgetBaseStyle = css`
    ${vitraFont};
    text-underline-offset: 0.3ex;
    width: 100%;
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    line-height: 1.9;
    color: ${colors.black};
`;

export const fontStyles = {
    h1: css`
        margin: 0 0 ${rh(1)};
        text-align: center;
        font-weight: 100;
        ${fontSizeAndLineHeight('32px', 2)}
        ${media.desktopAndUp} {
            ${fontSizeAndLineHeight('50px', 2)}
        }
    `,
    h2: css`
        margin: 0;
        text-align: center;
        font-weight: 100;
        ${fontSizeAndLineHeight('26px', 1)}
        margin: 0 0 ${rh(1)};
        ${media.desktopAndUp} {
            ${fontSizeAndLineHeight('32px', 1)}
        }
    `,
    h3: css`
        margin: 0;
        text-align: left;
        font-weight: 600;
        ${fontSizeAndLineHeight('22px', 1)}
        margin: 0 0 ${rh(0.5)};
        ${media.desktopAndUp} {
            ${fontSizeAndLineHeight('22px', 1)}
        }
    `,
    p: css`
        margin: 0 0 ${rh(1)};
        font-weight: 100;
        ${fontSizeAndLineHeight('17px', 1)}
        ${media.desktopAndUp} {
            ${fontSizeAndLineHeight('22px', 1)}
        }
        strong,
        b {
            font-weight: 600;
        }
        a {
            text-decoration: none;
            border-bottom: 1px solid ${colors.black};
            color: ${colors.black};
            &:active,
            &:focus {
                outline: none;
            }
            &:visited {
                color: inherit;
            }

            &:hover {
                color: ${colors.primary};
                border-bottom: 1px solid ${colors.primary};
            }
        }
    `,
    small: css`
        ${fontSizeAndLineHeight('14px', 1)}
        ${media.desktopAndUp} {
            ${fontSizeAndLineHeight('18px', 1)}
        }
    `,
    a: css`
        color: ${colors.black};
        text-decoration: none;
        border-bottom: 1px solid ${colors.black};
        &:hover {
            opacity: 0.8;
        }
        &:active,
        &:focus {
            outline: none;
        }
    `,
    ul: css`
        list-style: none;
        font-weight: 100;
        li {
            position: relative;
            padding-left: 20px;
        }
        li:before {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            background: ${colors.textGrey};
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    `
};

/** helper style to make something invisible on the screen,
 * but keep it accessible to screen readers
 */
export const visuallyHidden = `
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    `;
