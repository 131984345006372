import * as React from 'react';

const SvgV2MoveToWishlist = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={25} height={20} viewBox="0 0 25 20" {...props}>
        <g fillRule="nonzero">
            <path d="M14.002 19.194v-2.18l6.121-5.9c1.529-1.533 2.3-3 2.3-4.369a5.265 5.265 0 00-.262-1.757 3.391 3.391 0 00-.674-1.21 3.222 3.222 0 00-1-.728c-.364-.18-.75-.31-1.15-.384a7.218 7.218 0 00-1.207-.1c-.473.013-.94.12-1.371.315a6.25 6.25 0 00-1.359.787 16.25 16.25 0 00-1.062.881 8.862 8.862 0 00-.732.758.752.752 0 01-.605.271.747.747 0 01-.6-.271 9.652 9.652 0 00-.732-.758 16.091 16.091 0 00-1.062-.881 6.2 6.2 0 00-1.357-.787 3.6 3.6 0 00-1.373-.315c-.404 0-.807.033-1.205.1-.4.074-.788.203-1.152.384a3.222 3.222 0 00-1 .728 3.451 3.451 0 00-.674 1.21 5.336 5.336 0 00-.266 1.757c.006.528.111 1.05.309 1.54H2.252a5.181 5.181 0 01-.242-1.54 5.659 5.659 0 011.561-4.227A5.932 5.932 0 017.882.998c.527.004 1.05.094 1.549.266.52.171 1.017.41 1.477.708.441.293.836.576 1.172.842.325.262.635.541.93.836.27-.268.574-.542.934-.836.318-.256.7-.531 1.172-.842.46-.3.956-.538 1.477-.708a4.859 4.859 0 011.549-.266 5.932 5.932 0 014.311 1.52 5.671 5.671 0 011.561 4.227c0 1.8-.945 3.657-2.811 5.526l-7.186 6.919-.015.004z" />
            <path d="M6.531 17.861a.758.758 0 010-1.064l2.012-2.01H.752a.749.749 0 110-1.499h7.791l-2.012-2.01a.751.751 0 011.064-1.059l3.182 3.182a.743.743 0 01.219.537.818.818 0 01-.006.233.758.758 0 01-.217.511l-3.182 3.182a.749.749 0 01-1.06-.003z" />
        </g>
    </svg>
);

export default SvgV2MoveToWishlist;
