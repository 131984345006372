import useId from 'common/hooks/useId';
import globalStyle from 'common/primitives/forms/styles';
import { adjustFontSizeTo, colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { vitraFont } from 'common/styles/fonts';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import React, { useState } from 'react';

const styles = defaults(
    {
        textarea: css`
            width: 100%;
            resize: none;
            max-width: 100%;
            min-height: ${rh(5)};
            ${fontSizeAndLineHeight('17px')}
            padding: ${rh(0.65)} ${rh(0.5)} ${rh(0.15)};
            ${vitraFont};
            color: ${colors.black};
            border: 1px solid ${colors.lightgrey};
            border-radius: 0px;
            &:hover,
            &:active,
            &:focus {
                outline: none;
                border: 1px solid ${colors.black};
            }
        `,
        textareaLabelFocused: css`
            opacity: 1;
            top: 4px;
            left: calc(${rh(0.5)} - ${rh(0.15)});
            font-size: ${adjustFontSizeTo('12px')};
            width: auto;
            background: #fff;
            padding: 0 ${rh(0.15)};
        `,
        textareaError: css`
            border-color: ${colors.error}!important;
        `,
        textareaResizable: css`
            resize: vertical;
        `
    },
    globalStyle
);

interface TextareaInputProps extends InputProps {
    /**
     * OnChange handler
     * @todo find right events
     */
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

    /**
     * OnBlur handler
     */
    onBlur?: (event: React.FocusEventHandler<HTMLTextAreaElement>) => void;

    /**
     * OnFocus handler
     */
    onFocus?: (event: React.FocusEventHandler<HTMLTextAreaElement>) => void;

    /**
     * A optinal onClick Handler
     */
    onClick?: (event: React.MouseEvent<HTMLTextAreaElement>) => void;

    /**
     * Input placehholder
     */
    placeholder?: string;

    /**
     * Let the input field automatically get focus when the page loads
     */
    autoFocus?: boolean;

    /**
     * The defaultValues object passed from react-hook-form
     */
    defaultValues?: string;

    /**
     * Should the textarea be resizable, defaults to false
     */
    resize?: boolean;
}

const TextareaInput: React.FunctionComponent<TextareaInputProps> = (props) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isTouched, setIsTouched] = useState<boolean>(false);

    const {
        inputRef,
        name,
        label,
        className,
        isRequired,
        onChange,
        onBlur,
        onFocus,
        onClick,
        placeholder,
        autoFocus,
        disabled,
        errors,
        showErrorMessage,
        defaultValues,
        resize = false
    } = props;

    // Error
    const hasError = errors && errors[name];

    // Input id
    const id = useId();

    // Label
    const labelClassName = cx(
        styles.label,
        (isFocused || (!isTouched && defaultValues && get(defaultValues, name) !== undefined)) &&
            styles.textareaLabelFocused
    );

    // Events
    const handleOnChange = (e: any) => {
        if (onChange) {
            onChange(e);
        }
    };

    const handleOnClick = (e: any) => {
        if (onClick) {
            onClick(e);
        }
    };

    const handleOnFocus = (e: any) => {
        setIsFocused(true);
        setIsTouched(true);
        if (onFocus) {
            onFocus(e);
        }
    };

    const handleOnBlur = (e: any) => {
        if (e.currentTarget.value === '') {
            setIsFocused(false);
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    return (
        <div onClick={handleOnClick} className={cx('form__field', 'form__field--input', styles.wrapper, className)}>
            <textarea
                name={name}
                id={id}
                className={cx(styles.textarea, hasError && styles.textareaError, resize && styles.textareaResizable)}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                ref={inputRef}
            />
            {label && (
                <label className={labelClassName} htmlFor={id}>
                    {label}
                    {isRequired ? ' *' : ''}
                </label>
            )}
            {hasError && showErrorMessage && (
                <div className={cx('errorMessage', styles.errorMessage)}>{props.errors[props.name].message}</div>
            )}
        </div>
    );
};

export default TextareaInput;
