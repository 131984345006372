import * as React from 'react';

const SvgV2Equalizer = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.407}
        height={11.973}
        data-name="Ebene 2"
        viewBox="0 0 17.407 11.973"
        {...props}
    >
        <path d="M9.173 2.406h1.79v7.16h-1.79zM12.395 5.628h1.79v3.938h-1.79zM15.617 4.554h1.79v5.012h-1.79zM3.036 8.497H0V3.456h3.036L6.55 0v11.973L3.036 8.497z" />
    </svg>
);

export default SvgV2Equalizer;
