import Button from 'common/primitives/buttons';
import VitraModal from 'common/primitives/modal/vitraModal';
import { fontStyles } from 'common/styles';
import React, { Fragment, useState } from 'react';

interface ConfirmModal {
    title?: string | JSX.Element;
    confirm?: string | JSX.Element;
    cancel?: string | JSX.Element;
    actions?: JSX.Element;
    onConfirm?: () => void;
}
type ModalReturn = {
    modal: JSX.Element | null;
    openModal: (settings?: ConfirmModal) => Promise<boolean>;
    closeModal: () => void;
};

export const useConfirmModal = ({ title, confirm, cancel, actions, onConfirm }: ConfirmModal): ModalReturn => {
    const [settings, setSettings] = useState<ConfirmModal>({ title, confirm, cancel, actions, onConfirm });

    const [isOpen, setIsOpen] = useState(false);
    const awaitingPromiseRef = React.useRef<{
        resolve: (ok: boolean) => void;
    }>();

    const closeModal = () => {
        setIsOpen(false);
    };

    const onCloseButton = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(false);
            closeModal();
        }
    };

    const onConfirmButton = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(true);
            closeModal();
            if (onConfirm) {
                onConfirm();
            }
        }
    };

    const openModal = (overwriteSettings?: ConfirmModal): Promise<boolean> => {
        if (overwriteSettings) {
            setSettings({
                ...settings,
                ...overwriteSettings
            });
        }
        setIsOpen(true);
        return new Promise((resolve) => {
            awaitingPromiseRef.current = { resolve };
        });
    };

    const modal = isOpen ? (
        <VitraModal closeOnClickOutside={true} type="small" onClose={closeModal}>
            <div style={{ maxWidth: 280 }}>
                {settings.title && (
                    <p className={fontStyles.p} style={{ textAlign: 'center' }}>
                        {settings.title}
                    </p>
                )}
                {settings.confirm && (
                    <Button onClick={onConfirmButton} testId="confirmModalCancel" type="blackBorder">
                        {settings.confirm}
                    </Button>
                )}
                {settings.cancel && (
                    <Button type="black" onClick={onCloseButton} testId="confirmModalConfirm">
                        {settings.cancel}
                    </Button>
                )}
                {settings.actions && <Fragment>{settings.actions}</Fragment>}
            </div>
        </VitraModal>
    ) : null;

    return { modal, openModal, closeModal };
};
