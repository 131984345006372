import * as React from 'react';

const SvgLocate = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="1em" height="1em" viewBox="0 0 12.85 17" {...props}>
        <path
            fill="#333"
            d="M6.326 17a.506.506 0 0 1-.407-.208L1.51 10.697C.529 9.566-.017 8.067 0 6.511.042 2.964 2.953.043 6.49 0h.001c1.773 0 3.359.659 4.543 1.855a6.282 6.282 0 0 1 1.815 4.527 6.592 6.592 0 0 1-1.631 4.244l-4.486 6.165a.495.495 0 0 1-.406.209zm.243-15.997c-3.063.037-5.53 2.513-5.564 5.52a5.317 5.317 0 0 0 1.292 3.551l4.03 5.568L10.434 10c.903-1.039 1.396-2.315 1.411-3.631a5.282 5.282 0 0 0-1.524-3.809 5.244 5.244 0 0 0-3.752-1.557zm-.163 8.083a2.755 2.755 0 0 1-1.993-.814 2.756 2.756 0 0 1-.797-1.985c.017-1.549 1.289-2.824 2.833-2.842h.034c.745 0 1.441.29 1.962.816.521.528.804 1.233.795 1.983-.019 1.55-1.29 2.824-2.834 2.842zm.056-4.637a1.88 1.88 0 0 0-1.843 1.85c-.004.481.347 1.784 1.755 1.784a1.882 1.882 0 0 0 1.861-1.851C8.15 4.454 6.462 4.449 6.462 4.449z"
        />
    </svg>
);

export default SvgLocate;
