import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import * as React from 'react';

const styles = {
    tag: css`
        color: ${colors.black};
        display: inline-flex;
        cursor: pointer;
        text-align: center;
        background: transparent;
        outline: none;
        padding: 0 4px 0 20px;
        border-radius: 33px;
        min-width: 59px;
        font-size: 14px;
        line-height: 16px;
        transition: border-color 0.15s ease-in-out;
        border: 1px solid ${colors.findVitraColorBorder};
        font-family: 'vitraFutura';
        &:hover {
            border: 1px solid ${colors.primary};
            .tag__close:before,
            .tag__close:after {
                background-color: ${colors.black};
            }
        }
    `,
    tagLabel: css`
        padding: 10px 0px;
    `,
    tagClose: css`
        display: inline-block;
        width: 36px;
        height: 36px;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 1.5px;
            background-color: ${colors.textGrey};
            transform-origin: center;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    `
};

interface VitraTagProps {
    className?: string;
    label: string;
    onClick: (e: any) => void;
}

const VitraTag: React.FunctionComponent<VitraTagProps> = (props) => {
    return (
        <button onClick={props.onClick} className={cx('tag', styles.tag, props.className)}>
            <div className={cx('tag__label', styles.tagLabel)}>{props.label}</div>
            <div className={cx('tag__close', styles.tagClose)} />
        </button>
    );
};

export default VitraTag;
