import { cx } from 'linaria';
import React from 'react';
import { ListingFilter } from './types';
import VitraListingFilterDesktop from './vitraListingFilterDesktop';
import VitraListingFilterMobile from './vitraListingFilterMobile';

const VitraListingFilter: React.FunctionComponent<ListingFilter> = ({
    categories,
    className,
    showCaption,
    onSelect,
    onSort,
    sorting,
    activeSorting,
    showCount,
    total,
    activeFilters,
    type
}) => {
    // Toogle a Filter
    const onToggleCategory = (id: string, value: string) => {
        const aFilters = [...activeFilters];
        id = id.toLowerCase();
        value = value.toLowerCase();

        // get Active filter
        const filter = aFilters.find((f) => f.id === id);

        // If filter is not set,
        // create the filter
        if (!filter) {
            aFilters.push({ id, values: [value] });
            return onSelect(aFilters);
        }
        // Current filter without active filter
        const restFilter = aFilters.filter((f) => f.id !== id);

        // if the filter exists
        const fvalues = filter.values || [];
        const ixFilter = fvalues.indexOf(value);

        // Value does not exists
        if (ixFilter === -1) {
            restFilter.push({ id, values: [...fvalues, value] });
        } else {
            // value exists
            restFilter.push({ id, values: fvalues.filter((v) => v !== value) });
        }

        onSelect(restFilter.filter((t) => t.values.length > 0));
    };

    const onResetFiltersByCategory = (id: string) => {
        const filters = activeFilters.filter((f) => f.id !== id);
        onSelect(filters);
    };

    const onClearAllFilters = () => {
        onSelect([]);
    };

    return (
        <div className={cx(className)}>
            <VitraListingFilterDesktop
                categories={categories}
                onSelect={onToggleCategory}
                onSort={onSort}
                sorting={sorting}
                onReset={onResetFiltersByCategory}
                onClearAllFilters={onClearAllFilters}
                activeSorting={activeSorting}
                showCount={showCount}
                total={total}
                activeFilters={activeFilters}
                type={type}
            />
            <VitraListingFilterMobile
                categories={categories}
                showCaption={showCaption}
                onSelect={onToggleCategory}
                onSort={onSort}
                sorting={sorting}
                onClearAllFilters={onClearAllFilters}
                activeSorting={activeSorting}
                showCount={showCount}
                total={total}
                activeFilters={activeFilters}
                type={type}
            />
        </div>
    );
};
export default VitraListingFilter;
