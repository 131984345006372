import * as React from 'react';

const SvgV1World2 = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            d="M1 8c0-.88.17-1.72.468-2.497l4.13 3.186c.254.196.402.498.402.819v2.317c0 .097.078.175.174.175h.664c.089 0 .162.072.162.162v1.508c0 .205.121.39.309.472.423.182 1.243.499 2.192.692A7.002 7.002 0 018 15c-3.86 0-7-3.141-7-7m14 0a6.954 6.954 0 01-1.212 3.931l-1.665-.867a.903.903 0 01-.221-.163l-.761-.76A.48.48 0 0111 9.802V8.154a.187.187 0 00-.151-.184L6 7l-.533-.098a.084.084 0 01-.051-.133l.464-.612A.4.4 0 016.197 6H7l.903-1.806a.65.65 0 01.291-.291l1.669-.835a.948.948 0 00.245-.176l.725-.725c.153-.153.266-.282.352-.393C13.446 2.936 15 5.287 15 8M8 0a8 8 0 100 16A8 8 0 008 0"
        />
    </svg>
);

export default SvgV1World2;
