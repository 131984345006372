import Translate from 'common/primitives/translations';
import { colors } from 'common/styles';
import { css } from 'linaria';
import React, { useEffect } from 'react';
import { useStateValue } from '../state/find-vitra.state-provider';

const styles = {
    wrapper: css`
        background: ${colors.findVitraColorSilver};
        padding: 16px 32px;
        margin-bottom: 32px;
        border-radius: 5px;
    `
};
const CommonSearchInstructions: React.FunctionComponent = () => {
    const [state, dispatch]: any = useStateValue();
    if (!state.ux.showShareWithRetailer) {
        return null;
    }
    // Only show once
    useEffect(() => {
        return () => dispatch({ type: 'UX_SHOW_SHARE_WITH_RETAILER', active: false });
    }, []);

    return (
        <div className={styles.wrapper}>
            <Translate id="community_share_with_retailer" />
        </div>
    );
};

export default CommonSearchInstructions;
