import Translate from 'common/primitives/translations';
import { media } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import { FindVitraContentLocation } from '../find-vitra-types';

interface CommonLocationOpeningHoursProps {
    location: FindVitraContentLocation;
    className?: string;
}

export const styles = {
    wrapper: css`
        ${media.tablet} {
            width: 50%;
            padding-right: 16px;
        }
        vertical-align: top;
        margin-bottom: 32px;
        display: inline-flex;
        flex-wrap: wrap;
        background: white;
    `,
    openingHoursTitle: css`
        font-weight: 600;
        font-size: 17px;
        flex: 0 1 100%;
        text-align: center;
        ${media.tablet} {
            text-align: left;
        }
    `,
    openingHoursData: css`
        display: table;
        width: 100%;
        .data__row {
            display: table-row;
            width: 100%;
            &.data__row--multiline {
                margin: 8px 0;
            }
            .row__day {
                display: table-cell;
                width: 50%;
            }
            .row__hours {
                text-align: right;
                ${media.tablet} {
                    text-align: left;
                }
                display: table-cell;
                width: 50%;
                white-space: nowrap;
            }
        }
    `,
    openingHoursComment: css`
        margin-top: 16px;
        font-size: 12px;
    `
};

const CommonLocationOpeningHours: React.FunctionComponent<CommonLocationOpeningHoursProps> = ({
    location,
    className
}) => {
    if (!location.openingHours || location.openingHours.length === 0) {
        return null;
    }

    return (
        <div className={cx(styles.wrapper, className)}>
            <h2 className={styles.openingHoursTitle}>
                <Translate id="community_contact_opening_hours_title" />
            </h2>
            <div className={styles.openingHoursData}>
                {location.openingHours.map((row, ri) => {
                    return (
                        <div
                            key={ri}
                            className={cx('data__row', row.openingHours.length > 1 && 'data__row--multiline')}
                        >
                            <Translate id={`weekdays_shortcurts_${row.key}`} />
                            <div className="row__hours">
                                {row.openingHours.length > 0 &&
                                    row.openingHours.map((o, i) => {
                                        if (o.open === o.closed) {
                                            return (
                                                <div>
                                                    <Translate id="community_contact_opening_hours_open_all_day" />
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={i} className="hours__section">
                                                {o.open}
                                                &nbsp;-&nbsp;
                                                {o.closed}
                                            </div>
                                        );
                                    })}
                                {row.openingHours.length === 0 && (
                                    <div className="hours__section">
                                        <Translate id="community_contact_opening_hours_closed" />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            {location.openingHoursComment && (
                <div className={styles.openingHoursComment}>{location.openingHoursComment}</div>
            )}
        </div>
    );
};

export default CommonLocationOpeningHours;
