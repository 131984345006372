import { canUseDOM } from 'exenv';
import { useEffect, useState } from 'react';

// Safari doesnt support smooth scrolling at the moment
// Dynamic import (blacklisted) @see webpack-config.js
const loadPolyfill = async () => {
    const smoothscroll = await import('smoothscroll-polyfill');
    smoothscroll.polyfill();
};
if (canUseDOM && !('scrollBehavior' in document.documentElement.style)) {
    loadPolyfill();
}

type ScrollDirection = 'up' | 'down';
type ScrollPosition = {
    scrollPosition: number;
    scrollDirection: ScrollDirection;
};

const useScrollPosition = (): ScrollPosition => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('down');

    useEffect(() => {
        let lastKnownScrollPosition = 0;
        let ticking = false;
        const handleScroll = () => {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(() => {
                    setScrollPosition(lastKnownScrollPosition);
                    setScrollDirection(scrollPosition > lastKnownScrollPosition ? 'up' : 'down');
                    ticking = false;
                });

                ticking = true;
            }
        };
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return { scrollPosition, scrollDirection };
};

export const scrollTop = (top: number): void => {
    window.scroll({ top, behavior: 'smooth' });
};

export default useScrollPosition;
