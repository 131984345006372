import useId from 'common/hooks/useId';
import globalStyle from 'common/primitives/forms/styles';
import { adjustFontSizeTo, colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

const styles = defaults(
    {
        textInput: css`
            width: 100%;
            ${fontSizeAndLineHeight('17px')}
            padding: ${rh(0.25)} ${rh(0.5)};
            color: ${colors.black};
            border: 1px solid ${colors.lightgrey};
            border-radius: 2px;
            &:hover,
            &:active,
            &:focus {
                outline: none;
                border-bottom: 1px solid ${colors.black};
            }
        `,
        // With a label we must position the input differeently
        // underneath the label..
        textInputWithLabel: css`
            padding: ${rh(0.65)} ${rh(0.5)} ${rh(0.15)};
        `,
        textInputLabelFocussedError: css`
            color: ${colors.error};
        `,
        textInputLabelFocussed: css`
            opacity: 1;
            top: 4px;
            left: calc(${rh(0.5)} - ${rh(0.15)});
            font-size: ${adjustFontSizeTo('12px')};
            width: auto;
            background: transparent;
            padding: 0 ${rh(0.15)};
        `,
        textInputError: css`
            border-color: ${colors.error}!important;
        `
    },
    globalStyle
);

interface maskedInputProps extends InputProps {
    /**
     * A mask which is either given as a static string or a function
     * which will be called on every value change
     */
    mask: string | ((v?: any) => string);

    /**
     * The character which represents a mask field.
     * IMPORTANT: the value in the onChange handler will contain the char!!!!!!
     */
    maskChar?: any;

    /**
     * Input type, defaults to text
     */
    type?: 'text' | 'hidden';

    /**
     * Input placehholder
     */
    placeholder?: string;

    /**
     * On/Off for browser autocomplete features
     */
    autoComplete?: boolean;

    /**
     * React hook form control which mus be passed in
     */
    control: any;

    /**
     * Validation rules
     */
    rules?: any;

    /**
     * Used to force the label to the top
     */
    initallyFocussed?: boolean;

    /**
     * Default values
     */
    defaultValues?: any;
}

const MaskedInput: React.FunctionComponent<maskedInputProps> = (props) => {
    const {
        label,
        name,
        mask,
        maskChar = null,
        autoComplete = false,
        className,
        showErrorMessage,
        errors,
        control,
        rules,
        defaultValues,
        initallyFocussed = false
    } = props;

    // Error
    const hasError = errors && get(errors, name);

    // Input id
    const id = useId();

    const [isFocused, setIsFocused] = useState<boolean>(initallyFocussed);
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [activeMask, setActiveMask] = useState<string>(
        typeof mask === 'function' ? mask(props.control.defaultValuesRef.current[name]) : mask
    );
    const [currentValue, setCurrentValue] = useState(props.control.defaultValuesRef.current[name]);

    // InitallyFocussed may change fom the outside
    useEffect(() => {
        setIsFocused(initallyFocussed);
    }, [initallyFocussed]);

    // Label
    const labelClassName = cx(
        styles.label,
        (isFocused || (!isTouched && defaultValues && get(defaultValues, name) !== undefined)) &&
            styles.textInputLabelFocussed,
        hasError && styles.textInputLabelFocussedError
    );

    const handleOnFocus = () => {
        setIsFocused(true);
        setIsTouched(true);
    };

    const handleOnBlur = ([e]: any) => {
        if (e.target.value === '') {
            setIsFocused(false);
        }
    };

    const handleOnChange = ([e]: any) => {
        if (typeof mask === 'function') {
            setActiveMask(mask(e.target.value));
        }
        setCurrentValue(e.target.value);
        // If we use the onChange prop on the controller
        // we must pass the value back again...
        return e.target.value;
    };

    const beforeMaskedValueChange = (newState: any, oldState: any, userInput: any) => {
        if (maskChar !== null) {
            return newState;
        }
        let { value } = newState;

        // Assure that the cursor is at the very end!!!
        // This is needed if masks are defined via a funciton
        let selection = {
            start: value.length,
            end: value.length
        };

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !currentValue.endsWith('-')) {
            let cursorPosition = newState.selection ? selection.start : null;
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }
        return {
            value,
            selection
        };
    };

    // Avoid Chrome Autocomplete
    const autoCompleteOffString = () => `off-${Math.floor(Math.random() * 10000001)}`;

    return (
        <div className={cx('form__field', 'form__field--input', styles.wrapper, className)}>
            <Controller
                rules={rules}
                defaultValue={defaultValues && defaultValues[name]}
                className={cx(styles.textInput, label && styles.textInputWithLabel, hasError && styles.textInputError)}
                as={
                    <InputMask
                        autoComplete={autoComplete === false ? autoCompleteOffString() : 'on'}
                        onFocus={handleOnFocus}
                        beforeMaskedValueChange={beforeMaskedValueChange}
                        maskChar={maskChar}
                        mask={activeMask}
                        defaultValue={defaultValues && defaultValues[name]}
                        formatChars={{
                            '*': '[a-z,A-Z,0-9,-,+,*]',
                            '#': '[0-9,-]', //we allow - because of Portugal
                            $: '[a-z, A-Z]'
                        }}
                    >
                        {(inputProps: any) => {
                            return <input {...inputProps} />;
                        }}
                    </InputMask>
                }
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                control={control}
                name={name}
                id={id}
            />
            {label && (
                <label className={labelClassName} htmlFor={id}>
                    {label}
                </label>
            )}
            {hasError && showErrorMessage && (
                <div className={cx('errorMessage', styles.errorMessage)}>{get(errors, name).message}</div>
            )}
        </div>
    );
};

export default MaskedInput;
