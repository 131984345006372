import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nl';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

const configureDayjs = (initLocale = 'en'): void => {
    const availableLocales = ['en', 'es', 'fr', 'de', 'ja', 'nl', 'it'];
    if (!availableLocales.includes(initLocale)) {
        initLocale = 'en';
    }

    /**
     * Initialize dayjs
     */
    dayjs.extend(isBetween);
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);
    dayjs.extend(weekday);
    dayjs.locale(initLocale);
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
};

export default configureDayjs;
