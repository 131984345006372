import { hashCode, media } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

interface VitraGridProps {
    className?: string;
    columnClassName?: string;
    children: JSX.Element[];
    ref?: any;
    columns?: number;
}

const styles = {
    vitraGrid: css`
        display: grid;
        grid-auto-rows: 1fr; // Assure all grid items have the same height
    `,
    vitraGridColumns3: css`
        ${media.tabletAndUp} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    `,
    vitraGridColumns4: css`
        ${media.tabletAndUp} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        ${media.desktopAndUp} {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    `
};
const VitraGrid: React.FunctionComponent<VitraGridProps> = (options) => {
    const { columns = 4, className, columnClassName, children, ref } = options;

    const cssId = `columns__${hashCode(JSON.stringify(columns))}`;

    return (
        <div
            ref={ref}
            id={cssId}
            className={cx('vitra__grid', styles.vitraGrid, (styles as any)[`vitraGridColumns${columns}`], className)}
        >
            {children.map((c) => (
                <div className={cx('grid__item', columnClassName)}>{c}</div>
            ))}
        </div>
    );
};

export default VitraGrid;
