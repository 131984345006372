import { useBreakpoint } from 'common/hooks/useBreakpoints';
import { useEvents } from 'common/hooks/useEvents';
import V2Minus from 'common/primitives/icons/components/V2Minus';
import V2Plus from 'common/primitives/icons/components/V2Plus';

import { reportError } from 'common/hooks/useError';
import { colors } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { memo, useEffect, useRef, useState } from 'react';

const styles = {
    wrapper: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    toolbar: css`
        position: absolute;
        left: 30px;
        bottom: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
    toolbarIcon: css`
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
            path,
            g {
                fill: ${colors.black};
            }
        }
        &:hover {
            svg path {
                fill: ${colors.primary};
                stroke: ${colors.primary};
            }
        }
    `,
    toolbarIconDisabled: css`
        svg {
            opacity: 0.5;
        }
        &:hover {
            svg path {
                fill: ${colors.black}!important;
            }
        }
    `,
    error: css`
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        flex-flow: column;
        text-align: center;
    `,
    errorMessage: css`
        color: ${colors.primary};
    `,
    errorCode: css`
        color: ${colors.lightgrey};
    `
};

interface Vitra3dViewerToolbar {
    /**
     * The rotate level from 0-360deg
     * eg. `90`
     */
    rotate: number;

    /**
     * The Zoom Level of the compoent
     * eg `0-100`
     */
    zoomLevel: number;

    /**
     * On Reset
     */
    onReset: () => void;
    /**
     * On Zoom in
     */
    onZoomIn: () => void;
    /**
     * On Zoom Out
     */
    onZoomOut: () => void;
    /**
     * On Rotate
     */
    onRotate: () => void;
}

interface Vitra3dViewerProps {
    /**
     * The Emersia iFrame URL
     */
    url: string;

    /**
     * The Id of the Viewer
     * This is the prefix
     * for all events
     */
    id: string;

    /**
     * Inital Product ID
     * Loads a product via `setFurnitureConfiguration`
     */
    productId?: string;

    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * Show the toolbar of the configurator
     */
    showToolbar?: boolean;
}

interface Vitra3dViewerError {
    /**
     * Emersya Error Code
     */
    code: string;

    /**
     * Emersya Error Message
     */
    message: string;
}

/**
 * Prevents a click and calls the bound function
 * @param func callback function
 */
const onClickPreventDefault = (func: any) => (e: any) => {
    e.preventDefault();
    func();
};

/**
 * Gets the rotation and zoomLevel from the state
 * @param cameraState 3dViewer state
 */
const getAngleAndZoomLevel = (cameraState: any) => {
    const pos = [cameraState.position[0], 0, cameraState.position[2]];
    const len = Math.sqrt(pos[0] * pos[0] + pos[2] * pos[2]);
    pos[0] /= len;
    pos[2] /= len;
    const radAngle = pos[0] > 0 ? Math.acos(pos[2]) : -Math.acos(pos[2]);
    const rotation = Math.ceil((360 + (180 * radAngle) / Math.PI) % 360);

    const zoomLevel = Math.ceil(cameraState.zoomProportion * 100);
    return { rotation, zoomLevel };
};

const Vitra3dViewerToolbar: React.FunctionComponent<Vitra3dViewerToolbar> = ({ zoomLevel, onZoomIn, onZoomOut }) => {
    return (
        <div className={styles.toolbar}>
            {/* <a href="#" className={styles.toolbarIcon} onClick={onClickPreventDefault(onReset)}>
                <IconReplay />
            </a> */}
            <a
                href="#"
                className={cx(zoomLevel === 100 && styles.toolbarIconDisabled, styles.toolbarIcon)}
                onClick={onClickPreventDefault(onZoomIn)}
            >
                <V2Plus />
            </a>
            <a
                href="#"
                className={cx(zoomLevel === 0 && styles.toolbarIconDisabled, styles.toolbarIcon)}
                onClick={onClickPreventDefault(onZoomOut)}
            >
                <V2Minus />
            </a>
            {/* <a href="#" className={styles.toolbarIcon} onClick={onClickPreventDefault(onRotate)}>
                <IconRotate
                    style={{
                        transform: `rotate(${rotate}deg)`
                    }}
                />
            </a> */}
        </div>
    );
};

const Vitra3dViewerError: React.FunctionComponent<Vitra3dViewerError> = ({ code, message }) => {
    return (
        <div className={styles.error}>
            <div className={styles.errorCode}>{code}</div>
            <div className={styles.errorMessage}>{message}</div>
        </div>
    );
};

/**
 * Check if we have an init Event
 * @param data event data
 */
const isInitEvent = (data: any): boolean =>
    data &&
    data.action &&
    data.action === 'onStateChange' &&
    data.state.viewerState === 'loaded' &&
    data.state.loadingProgress === 1;

/**
 * Checks if the event is an angle or zoom event
 * @param data event data
 */
const isAngleOrZoomEvent = (data: any): boolean =>
    data &&
    data.action &&
    ['onGetCamera', 'onEndCameraInteraction', 'onStartCameraInteraction', 'onUpdateCameraInteraction'].includes(
        data.action
    );

/**
 * If there is an error Event
 * @param data event data
 */
const isErrorEvent = (data: any): boolean => data && data.action && data.action === 'onError';

interface Vitra3dVieweriFrameProps {
    onloadFrame: () => void;
    iframeRef: any;
    url: string;
}

class Vitra3dVieweriFrame extends React.Component<Vitra3dVieweriFrameProps> {
    public shouldComponentUpdate() {
        return false;
    }
    public render() {
        const { onloadFrame, iframeRef, url } = this.props;
        return (
            <iframe
                ref={iframeRef}
                src={url}
                frameBorder="0"
                onLoad={onloadFrame}
                allow="camera; gyroscope; accelerometer; magnetometer;"
                width="100%"
                height="100%"
                allowFullScreen
            />
        );
    }
}

/**
 *  The 3D Viewer
 * @param Vitra3dViewerProps
 */
const Vitra3dViewer: React.FunctionComponent<Vitra3dViewerProps> = ({
    className,
    productId,
    children,
    showToolbar,
    id,
    url
}) => {
    const iframeRef = useRef(null);
    const [rotate, setRotate] = useState<number>(0);
    const [hasInit, setHasInit] = useState(false);
    const [zoomLevel, setZoomLevel] = useState<number>(55);
    const [initTriggerId, setInitTriggerId] = useState<string | null>(null);
    const breakpoint = useBreakpoint();
    // const [hasError, sethasError] = useState<Vitra3dViewerError | false>(false);

    const postMessage = (value: any) => {
        const iFrame = iframeRef.current as any;
        if (iFrame) {
            iFrame.contentWindow.postMessage(value, '*');
        }
    };

    const onloadFrame = () => {
        postMessage({ action: 'registerCallback' });
        if (productId) {
            setTimeout(() => {
                postMessage({ action: 'setFurnitureConfiguration', configuration: productId });
            }, 1500);
        }
    };

    /**
     * Event Functions
     */
    const onStartViewer = ({ triggerId }: any) => {
        if (!hasInit) {
            if (triggerId) {
                setInitTriggerId(triggerId);
            }
            postMessage({ action: 'startViewer' });
        }
    };

    const onPlay = ({ triggerId }: any) => {
        if (!hasInit) {
            return onStartViewer({ triggerId });
        }
        postMessage({ action: 'triggerAnimation', triggerId: parseInt(triggerId, 10) });
    };

    const OnMouseOver = ({ triggerId }: any) => {
        if (hasInit) {
            postMessage({ action: 'setTriggerColor', color: '#f88d8d', triggerId: parseInt(triggerId, 10) });
        }
    };

    const OnMouseOut = ({ triggerId }: any) => {
        if (hasInit) {
            postMessage({ action: 'setTriggerColor', color: '#333333', triggerId: parseInt(triggerId, 10) });
        }
    };

    const onFurnitureConfiguration = (configuration: string) => {
        if (hasInit) {
            postMessage({ action: 'setFurnitureConfiguration', configuration });
        }
    };

    /**
     * Toolbar Functions
     */
    const onReset = () => {
        if (hasInit) {
            postMessage({ action: 'resetAnimations' });
            postMessage({ action: 'setDefaultView' });
        }
    };

    const onZoomIn = () => {
        let newLevel = zoomLevel + 10;
        newLevel = newLevel <= 100 ? newLevel : 100;
        postZoomLevel(newLevel);
    };

    const onZoomOut = () => {
        let newLevel = zoomLevel - 10;
        newLevel = newLevel >= 0 ? newLevel : 0;
        postZoomLevel(newLevel);
    };

    const postZoomLevel = (level: number) => {
        setZoomLevel(level);
        const value = level / 100;
        if (!hasInit) {
            return postMessage({ action: 'startViewer' });
        }
        postMessage({ action: 'setZoomProportion', value });
    };

    const onRotate = () => {
        if (!hasInit) {
            return postMessage({ action: 'startViewer' });
        }
        postMessage({ action: 'rotateCameraY', value: 45 });
    };

    /**
     * Register Event callbacks
     */
    const emit = useEvents(id, {
        onStartViewer,
        onReset,
        onPlay,
        OnMouseOver,
        OnMouseOut,
        onFurnitureConfiguration
    });

    const onStatusChange = ({ data }: any) => {
        const isDesktop = ['desktop', 'desktopX'].includes(breakpoint);

        // on init we get the camera
        if (isInitEvent(data)) {
            setHasInit(true);

            // On Desktop we enable realtime
            if (isDesktop) {
                // postMessage({ action: 'enableRealTimeFeedback' });
            } else {
                postMessage({ action: 'disableMouseButtons' });
                postMessage({ action: 'disableMouseWheel' });
            }

            if (initTriggerId) {
                postMessage({ action: 'triggerAnimation', triggerId: parseInt(initTriggerId, 10) });
                setInitTriggerId(null);
            }
        }

        if (data.action === 'onTriggerClicked') {
            emit('onTriggerClicked', data);
        }

        // Angle or Zoom Event
        if (isAngleOrZoomEvent(data)) {
            const currentLevel = getAngleAndZoomLevel(data.camera);
            setRotate(currentLevel.rotation);
            setZoomLevel(currentLevel.zoomLevel);
        }

        // Error Event
        if (isErrorEvent(data)) {
            reportError(data, '3dviewer');
        }
    };

    // Event listener,
    // this needs to re-run every time something in onStatusChange changes
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        window.addEventListener('message', onStatusChange, false);
        return () => {
            window.removeEventListener('message', onStatusChange, false);
        };
    }, [iframeRef.current, hasInit, initTriggerId]);

    // Chnage product Id on props change
    useEffect(() => {
        if (hasInit && productId) {
            onFurnitureConfiguration(productId);
        }
    }, [productId]);

    return (
        <div className={cx(styles.wrapper, className)}>
            {showToolbar && (
                <Vitra3dViewerToolbar
                    onZoomOut={onZoomOut}
                    onRotate={onRotate}
                    onZoomIn={onZoomIn}
                    onReset={onReset}
                    rotate={rotate}
                    zoomLevel={zoomLevel}
                />
            )}
            {/* {!!hasError && <Vitra3dViewerError {...hasError} />} */}
            <Vitra3dVieweriFrame iframeRef={iframeRef} url={url} onloadFrame={onloadFrame} />
            {children}
        </div>
    );
};

// We never re-render this component
export default memo(Vitra3dViewer);
