import { getBffLanguageAndCountry } from 'common/graphql/client';
import { useConfig } from 'common/hooks/useBoostrap';
import Button, { BlankButton } from 'common/primitives/buttons';
import VitraLink from 'common/primitives/links/vitraLink';
import { useTranslations } from 'common/primitives/translations';
import { colors, fontSizeAndLineHeight, linkColor, rh } from 'common/styles';
import { useAccountBffContext } from 'components/account-v2/state/accountProvider';
import { getConfig } from 'components/checkout-v2/config';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';
import DropDown from './dropDown';
import { generateNavigationAnayltics } from './helpers';

const styles = {
    primaryLink: css`
        ${fontSizeAndLineHeight('17px')}
        ${linkColor(colors.black, colors.primary)};
        display: block;
        padding: ${rh(0.15)} 0;
        text-decoration: none;
    `,
    secondaryLink: css`
        ${fontSizeAndLineHeight('14px')}
        ${linkColor(colors.textGrey, colors.black)};
        display: block;
    `,
    centered: css`
        text-align: center;
        text-decoration: none;
    `
};

const DropDownLogin: React.FunctionComponent = () => {
    const authUrl = useConfig('authUrl', '');
    const isoCode = useConfig('code', 'de-de');
    const countryCode = useConfig('country', 'DE');
    const { country: checkoutCountry } = getBffLanguageAndCountry();
    const { hasCart } = getConfig(checkoutCountry);

    const t = {
        title: useTranslations('dropdown.account.login.title', 'Your Login'),
        ctaLabel: useTranslations('dropdown.account.login.login.cta.label', 'Online Shop Login'),
        registerBtnLabel: useTranslations('dropdown.account.login.login.register.label', 'Create new Account'),
        dealerLoginLabel: useTranslations('dropdown.account.login.login.professionals.label', 'Dealer Login')
    };

    const dealerLoginLink: Link = {
        href: useConfig('professionalsUrl', 'https://professionals.vitra.com'),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-dealer_login'
        }
    };

    const loginLink: Link = {
        href: `${authUrl}/login?returnUrl=${encodeURIComponent(window.location.href)}&country=${countryCode}`,
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-login'
        }
    };

    const registerLink: Link = {
        href: useConfig('registerUrl', `/${isoCode}/register`),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-register'
        }
    };

    return (
        <DropDown
            title={t.title}
            footer={
                <BlankButton
                    analyticsData={generateNavigationAnayltics(dealerLoginLink)}
                    link={dealerLoginLink}
                    testId="dropDownLoginProfessionalsBtn"
                >
                    {t.dealerLoginLabel}
                </BlankButton>
            }
        >
            {hasCart && (
                <Fragment>
                    <Button
                        link={loginLink}
                        analyticsData={generateNavigationAnayltics(loginLink)}
                        testId="dropDownLoginCta"
                    >
                        {t.ctaLabel}
                    </Button>
                    <VitraLink
                        {...registerLink}
                        analyticsData={generateNavigationAnayltics(registerLink)}
                        className={cx(styles.secondaryLink, styles.centered)}
                        testId="dropDownLoginRegisterBtn"
                    >
                        {t.registerBtnLabel}
                    </VitraLink>
                </Fragment>
            )}
        </DropDown>
    );
};

interface DropDownAccountProps {
    customer: any;
}

const DropDownUser: React.FunctionComponent<Required<DropDownAccountProps>> = ({ customer }) => {
    const authUrl = useConfig('authUrl', '');
    const isoCode = useConfig('code', 'de-de');
    const userName = `${customer.firstName} ${customer.lastName}`;

    const t = {
        title: useTranslations('dropdown.account.user.title', 'Welcome {userName}', { userName }),
        linkAccount: useTranslations('dropdown.account.user.link.account', 'Your Account'),
        linkOrders: useTranslations('dropdown.account.user.link.orders', 'Orders'),
        linkCareRepair: useTranslations('dropdown.account.user.link.care.repair', 'Care & Repair'),
        linkContact: useTranslations('dropdown.account.user.link.contact', 'Contact & Service'),
        linkLogout: useTranslations('dropdown.account.user.link.logout', 'Logout')
    };
    const logoutLink: Link = {
        href: `${authUrl}/logout?returnUrl=${encodeURIComponent(window.location.href)}`,
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-logout'
        }
    };
    const accountLink: Link = {
        href: useConfig('accountUrl', `/${isoCode}/account`),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-account'
        }
    };

    const ordersLink: Link = {
        href: useConfig('accountUrl', `/${isoCode}/account?tab=orders`),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-orders'
        }
    };

    const repairLink: Link = {
        href: useConfig('careRepairUrl', `/${isoCode}/care-repair`),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-repair'
        }
    };

    const contactLink: Link = {
        href: useConfig('contactServiceUrl', `/${isoCode}/contact-service`),
        analytics: {
            action: 'navigation_interaction',
            creative: 'navigation_element-contact'
        }
    };
    return (
        <DropDown
            title={t.title}
            footer={
                <VitraLink
                    {...logoutLink}
                    analyticsData={generateNavigationAnayltics(logoutLink)}
                    className={styles.secondaryLink}
                    testId="flyoutLogoutLink"
                >
                    {t.linkLogout}
                </VitraLink>
            }
        >
            <VitraLink
                {...accountLink}
                analyticsData={generateNavigationAnayltics(accountLink)}
                className={styles.primaryLink}
                testId="flyoutAccountLink"
            >
                {t.linkAccount}
            </VitraLink>
            <VitraLink
                {...ordersLink}
                analyticsData={generateNavigationAnayltics(ordersLink)}
                className={styles.primaryLink}
                testId="flyoutOrdersLink"
            >
                {t.linkOrders}
            </VitraLink>
            <VitraLink
                {...repairLink}
                analyticsData={generateNavigationAnayltics(repairLink)}
                className={styles.primaryLink}
                testId="flyoutCareRepairLink"
            >
                {t.linkCareRepair}
            </VitraLink>
            <VitraLink
                {...contactLink}
                analyticsData={generateNavigationAnayltics(contactLink)}
                className={styles.primaryLink}
                testId="flyoutContactServiceLink"
            >
                {t.linkContact}
            </VitraLink>
        </DropDown>
    );
};

const DropDownAccount: React.FunctionComponent = () => {
    const accountContext = useAccountBffContext();
    return accountContext.customer ? <DropDownUser customer={accountContext.customer} /> : <DropDownLogin />;
};

export default DropDownAccount;
