import useId from 'common/hooks/useId';
import globalStyle from 'common/primitives/forms/styles';
import { adjustFontSizeTo, colors, fontSizeAndLineHeight, rh } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

const styles = defaults(
    {
        textInput: css`
            width: 100%;
            ${fontSizeAndLineHeight('17px')}
            padding: ${rh(0.25)} ${rh(0.5)};
            color: ${colors.black};
            border: 1px solid ${colors.lightgrey};
            border-radius: 2px;
            &:hover,
            &:active,
            &:focus {
                outline: none;
                border: 1px solid ${colors.black};
            }
        `,
        textInputLabelFocused: css`
            opacity: 1;
            top: -11px;
            left: calc(${rh(0.5)} - ${rh(0.15)});
            font-size: ${adjustFontSizeTo('15px')};
            width: auto;
            background: #fff;
            padding: 0 ${rh(0.15)};
        `,
        textInputError: css`
            border-color: ${colors.error}!important;
        `
    },
    globalStyle
);

interface TextInputProps extends InputProps {
    placeholder?: string;
    autoFocus?: boolean;
    autoComplete?: boolean;
    control: any;
    defaultValue?: string;
}

const TimeInput: React.FunctionComponent<TextInputProps> = ({
    label,
    name,
    defaultValue,
    className,
    showErrorMessage,
    errors,
    control
}) => {
    // Error
    const hasError = errors && errors[name];

    // Input id
    const id = useId();

    const labelClassName = cx(styles.label, styles.textInputLabelFocused, hasError && styles.errorLabel);

    return (
        <div className={cx('form__field', 'form__field--input', styles.wrapper, className)}>
            <Controller
                className={cx(styles.textInput, hasError && styles.textInputError)}
                as={InputMask}
                control={control}
                mask="hh:mm"
                defaultValue={defaultValue}
                name={name}
                id={id}
                formatChars={{
                    h: '[0-9]',
                    m: '[0-9]'
                }}
            />
            {label && (
                <label className={labelClassName} htmlFor={id}>
                    {label}
                </label>
            )}
            {hasError && showErrorMessage && (
                <div className={cx('errorMessage', styles.errorMessage)}>{errors[name].message}</div>
            )}
        </div>
    );
};

export default TimeInput;
