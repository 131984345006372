import { css } from 'linaria';
import React, { useContext, useEffect, useRef } from 'react';
import { Context } from './transform-context';

const styles = {
    wrapper: css`
        position: relative;
        width: -moz-fit-content;
        width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        overflow: hidden;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        margin: 0;
        padding: 0;
    `,
    content: css`
        display: flex;
        flex-wrap: wrap;
        width: -moz-fit-content;
        width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0;
        padding: 0;
        transform-origin: 0% 0%;
        img {
            pointer-events: none;
        }
    `
};
type Props = {
    children: React.ReactNode;
    wrapperClass?: string;
    contentClass?: string;
    wrapperStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
};

export const TransformComponent: React.FC<Props> = ({
    children,
    wrapperClass = '',
    contentClass = '',
    wrapperStyle,
    contentStyle
}: Props) => {
    const { setComponents } = useContext(Context);

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const content = contentRef.current;
        if (wrapper !== null && content !== null && setComponents) {
            setComponents(wrapper, content);
        }
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={`react-transform-wrapper ${styles.wrapper} ${wrapperClass}`}
            style={wrapperStyle}
        >
            <div
                ref={contentRef}
                className={`react-transform-component ${styles.content} ${contentClass}`}
                style={contentStyle}
            >
                {children}
            </div>
        </div>
    );
};

export default TransformComponent;
