import { colors, containerCentered, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useState } from 'react';
import { ListingFilter, ListingFilterCategory } from './types';
import { VitraListingFilterList, VitraListingFilterListRows } from './vitraListingFilterLists';

import { useClickoutSide } from 'common/hooks/useClickOutside';
import { useEvents } from 'common/hooks/useEvents';
import ArrowRotate from 'common/primitives/icons/components/V2ArrowRotate';
import { canUseDOM } from 'exenv';
import Translate from '../translations';
import VitraListingCounter from './vitraListingFilterCounter';
import VitraListingFilterSelected from './vitraListingFilterSelected';
import VitraListingSorting from './vitraListingSorting';

interface ListingFilterDesktop extends Omit<ListingFilter, 'onSelect'> {
    onSelect: (key: string, value: string) => void;
    onReset: (key: string) => void;
    onClearAllFilters: () => void;
}

const styles = {
    listWrapper: css`
        position: relative;
        display: none;
        ${media.tablet} {
            display: block;
        }
    `,
    listWrapperMain: css`
        display: flex;
        align-items: center;
        height: ${rh(2.5)};
        font-weight: 100;
        border-bottom: 1px solid ${colors.lightgrey};
    `,
    caption: css`
        padding: ${rh(0.25)} 0;
        color: ${colors.lightgrey};
        font-weight: 500;
    `,
    captionProducts: css`
        padding-top: ;
        margin-bottom: 0;
    `,
    captionRight: css`
        padding: ${rh(0.75)} 0 ${rh(0.75)} ${rh(0.25)};
        margin-left: auto;
        position: relative;
        z-index: 0;
    `,
    captionCounter: css`
        font-size: 14px;
        color: ${colors.black};
        margin-left: auto;
        margin-bottom: 0;
    `,
    mainCategories: css`
        display: flex;
        font-weight: 500;
    `,
    mainCategoryLabel: css`
        color: ${colors.textGrey};
        padding: ${rh(0.25)} ${rh(0.75)} ${rh(0.25)} 0;
    `,
    mainCategoryItem: css`
        padding: ${rh(0.25)} ${rh(0.75)};
        &:first-child {
            padding-left: 0;
        }
        display: flex;
        align-items: center;
        ${linkColor(colors.black, colors.textGrey)};
        text-decoration: none;
        &:hover svg {
            fill: ${colors.textGrey};
        }
        svg {
            margin-left: 10px;
        }
    `,
    mainCategoryItemActive: css`
        ${linkColor(colors.primary, colors.primary)};
        svg {
            fill: ${colors.primary};
        }
        &:hover svg {
            fill: ${colors.primary};
        }
    `,
    mainCategoryWrapper: css`
        background: ${colors.footerGreyLight};
        position: relative;
        margin-top: -1px;
        border-bottom: 1px solid ${colors.lightgrey};
    `,
    mainCategory: css`
        display: none;
    `,
    mainCategoryActive: css`
        display: block;
        width: 100%;
        padding: 0 32px;
    `,
    mainActiveSorting: css`
        display: flex;
        width: 100%;
    `
};

const VitraListingFilterDesktop: React.FunctionComponent<ListingFilterDesktop> = ({
    categories,
    onSelect,
    onSort,
    sorting,
    activeSorting,
    onReset,
    onClearAllFilters,
    showCount,
    total,
    activeFilters,
    type
}) => {
    const [activeMainCategory, setActiveMainCategory] = useState<ListingFilterCategory | null>(null);

    /**
     * Toggle Active Main category
     */
    const toggleMainCategory = (category: ListingFilterCategory) => (e: any) => {
        e.preventDefault();
        if (activeMainCategory && activeMainCategory.id === category.id) {
            return setActiveMainCategory(null);
        }
        setActiveMainCategory(category);
    };

    const emit = useEvents('listing');

    // Close Filter Dropdown
    const onCloseFilter = () => {
        setActiveMainCategory(null);
        emit('onCloseFilter');
    };

    // Reset Filter By Categroy
    const onResetFilter = (id: string) => {
        onReset(id);
        onCloseFilter();
    };

    // Remove Filters
    const removeFilter = (id: string, value: string) => {
        onSelect(id, value);
    };

    const onToggleCategory = (id: string, value: string) => {
        onSelect(id, value);
    };

    // Click outside - But take care of the main category links category, name, designer
    // Also dont close if a filter is removed from beneath
    const targetRef = useClickoutSide((e: any) => {
        if (!e.target.className.indexOf) {
            return;
        }
        if (!e.target.className.includes('items__element') && !e.target.className.includes('selectedFilterLink')) {
            onCloseFilter();
        }
    });

    // Render the Active Category
    const renderActiveCategory = (category: ListingFilterCategory) => {
        const activeFilter = activeFilters.find((f) => f.id === category.id.toLocaleLowerCase());
        const active = activeFilter ? activeFilter.values : [];
        switch (category.type) {
            case 'list':
            case 'list-search':
                return (
                    <VitraListingFilterList
                        onReset={onResetFilter}
                        onClose={onCloseFilter}
                        hasSearch={category.type === 'list-search'}
                        onSelect={onToggleCategory}
                        activeFilters={active}
                        category={category}
                    />
                );
            case 'list-rows':
                return (
                    <VitraListingFilterListRows
                        onReset={onResetFilter}
                        onClose={onCloseFilter}
                        onSelect={onToggleCategory}
                        activeFilters={active}
                        category={category}
                    />
                );
            case 'custom':
                if (category.render) {
                    return category.render(activeFilters, onToggleCategory, onCloseFilter);
                }
                break;
            default:
                return <div />;
        }
    };

    // Dont render the caption if it makes no sense
    if (categories.length === 0 && !(sorting && sorting.find((o) => o.id === activeSorting))) {
        return null;
    }

    return (
        <div className={cx('list__wrapper', styles.listWrapper)}>
            <div className={cx('list__main', containerCentered)}>
                <div className={styles.listWrapperMain}>
                    <div className={cx('main__items', styles.mainCategoryLabel)}>
                        <Translate id="listing.filter.label.products" defaultMessage="Filter products:" />
                    </div>
                    <div className={cx('main__items', styles.mainCategories)}>
                        {categories.map((cat) => {
                            const isActive = activeMainCategory && activeMainCategory.id === cat.id;
                            return (
                                <a
                                    href="#"
                                    onClick={toggleMainCategory(cat)}
                                    className={cx(
                                        'items__element',
                                        isActive && styles.mainCategoryItemActive,
                                        styles.mainCategoryItem
                                    )}
                                >
                                    {cat.name}
                                    <ArrowRotate up={isActive || false} />
                                </a>
                            );
                        })}
                    </div>
                    {canUseDOM && showCount && total && (
                        <div className={cx(styles.caption, styles.captionCounter)}>
                            <VitraListingCounter type={type} total={total} />
                        </div>
                    )}
                </div>
            </div>
            <div ref={targetRef as any} className={styles.mainCategoryWrapper}>
                {categories.map((cat) => {
                    return (
                        <div
                            className={cx(
                                styles.mainCategory,
                                activeMainCategory && activeMainCategory.id === cat.id && styles.mainCategoryActive
                            )}
                        >
                            {renderActiveCategory(cat)}
                        </div>
                    );
                })}
            </div>
            <div className={cx(containerCentered, styles.mainActiveSorting)}>
                {activeFilters && activeFilters.length > 0 && (
                    <VitraListingFilterSelected
                        activeFilters={activeFilters}
                        removeFilter={removeFilter}
                        categories={categories}
                        onClearAllFilters={onClearAllFilters}
                    />
                )}
                <div className={cx(styles.caption, styles.captionRight, type === 'products' && styles.captionProducts)}>
                    {sorting && <VitraListingSorting onSort={onSort} options={sorting} active={activeSorting} />}
                </div>
            </div>
        </div>
    );
};

export default VitraListingFilterDesktop;
