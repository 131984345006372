import * as React from 'react';

const SvgV2Shrink = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
        <path
            d="M.5 9a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h4.793l-4.64 4.639a.5.5 0 0 0 0 .708.5.5 0 0 0 .708 0L6 10.707V15.5a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5V9zm15-3h-4.793l4.639-4.639a.5.5 0 0 0 0-.707.5.5 0 0 0-.707 0L10 5.293V.5a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5V7h6.5a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5z"
            data-name="icon/single/shrink"
        />
    </svg>
);

export default SvgV2Shrink;
