import * as React from 'react';

const SvgGridMedium = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="25px" height="25px" viewBox="0 0 50 50" {...props}>
        <path
            fill="#B2B2B2"
            d="M22.779 22.778H0V0h22.779v22.778zM1.976 20.803h18.826V1.976H1.976v18.827zM50 22.778H27.221V0H50v22.778zm-20.803-1.975h18.826V1.976H29.197v18.827zM.988 26.986H21.79v1.977H.988zM28.537 26.986H49.34v1.977H28.537zM.988 31.44H21.79v1.977H.988zM28.537 31.44H49.34v1.977H28.537zM.988 35.896H21.79v1.977H.988zM28.537 35.896H49.34v1.977H28.537z"
        />
    </svg>
);

export default SvgGridMedium;
