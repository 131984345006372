import * as React from 'react';

const SvgV1Cross2 = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 17 17" {...props}>
        <circle cx={8.5} cy={8.5} r={1.5} />
        <path d="M17 8h-2a6.5 6.5 0 00-6-6V0H8v2a6.5 6.5 0 00-6 6H0v1h2a6.5 6.5 0 006 6v2h1v-2a6.5 6.5 0 006-6h2V8zm-8 6v-2H8v2a5.51 5.51 0 01-5-5h2V8H3a5.51 5.51 0 015-5v2h1V3a5.51 5.51 0 015 5h-2v1h2a5.51 5.51 0 01-5 5z" />
    </svg>
);

export default SvgV1Cross2;
