import { css } from 'linaria';
import React from 'react';
import { NavigationRootItemV2 } from '../main-navigation-types';

const styles = {
    wrapper: css`
        display: none;
    `
};
const NavigationSeo: React.FunctionComponent<{
    navigation: NavigationRootItemV2[];
}> = (props) => {
    const { navigation } = props;

    const findLinksRecursive = (jsonData: typeof navigation) => {
        const result: Link[] = [];
        function flatten(data: any) {
            if (Array.isArray(data)) {
                data.forEach(flatten);
            } else if (typeof data === 'object' && data !== null) {
                if (data.link && data.link.href && data.link.name) {
                    result.push(data.link);
                }
                Object.values(data).forEach(flatten);
            }
        }
        flatten(jsonData);
        return result;
    };

    const navigationFlat = findLinksRecursive(navigation);
    return (
        <div className={styles.wrapper}>
            {navigationFlat?.map((item) => (
                <a aria-hidden="true" tabIndex={-1} href={item.href}>
                    {item.name}
                </a>
            ))}
        </div>
    );
};

export default NavigationSeo;
