import { useConfig } from 'common/hooks/useBoostrap';
import globalStyle from 'common/primitives/forms/styles';
import { rh } from 'common/styles';
import Turnstile from 'common/utils/turnstile';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import React from 'react';

const styles = defaults(
    {
        turnstileInputWrapper: css`
            margin: 0 !important;
            > div:not(.errorMessage) {
                font-size: 0;
            }
        `,
        turnstileInputWrapperInteractive: css`
            margin-bottom: ${rh(0.75)} !important;
        `
    },
    globalStyle
);

interface TurnstyleInputProps extends InputProps {
    setValue: any;
    errors?: any;
    clearError?: any;
    className?: string;
}

const TurnstyleInput: React.FunctionComponent<TurnstyleInputProps> = (props) => {
    const { id, inputRef, name, setValue, className, errors, clearError, showErrorMessage } = props;
    const cfTurnstileSiteKey = useConfig('cfTurnstileSiteKey', '0x4AAAAAAAG2rcuJiI090eq8');
    const hasError = errors && get(errors, name);
    const [isInteractive, setIsInteractive] = React.useState(false);
    return (
        <div
            className={cx(
                'form__field',
                'form__field--input',
                'form__field--turnstile',
                styles.turnstileInputWrapper,
                isInteractive && styles.turnstileInputWrapperInteractive,
                className
            )}
        >
            <input id={id} name={name} required={true} autoFocus={false} disabled={true} type="hidden" ref={inputRef} />
            <Turnstile
                sitekey={cfTurnstileSiteKey}
                appearance="interaction-only"
                theme="light"
                tabIndex={-1}
                onBeforeInteractive={() => setIsInteractive(true)}
                onVerify={(token) => {
                    setValue(name, token);
                    if (errors && clearError && token) {
                        clearError(name);
                    }
                }}
            />
            {showErrorMessage && hasError && (
                <div className={cx('errorMessage', styles.errorMessage)}>{get(errors, name).message}</div>
            )}
        </div>
    );
};

export default TurnstyleInput;
