import * as React from 'react';

const SvgFacebook = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  height="1em" viewBox="0 0 22.001 22" {...props}>
        <path
            fill="#333"
            d="M20.787 0H1.215C.543 0 0 .543 0 1.214v19.572C0 21.457.543 22 1.215 22h10.537v-8.519H8.884V10.16h2.867V7.711c0-2.841 1.736-4.389 4.271-4.389 1.214 0 2.258.09 2.562.131v2.97h-1.758c-1.378 0-1.646.655-1.646 1.616v2.12h3.288l-.429 3.321H15.18V22h5.606c.67 0 1.214-.543 1.214-1.214V1.214A1.212 1.212 0 0 0 20.787 0z"
        />
    </svg>
);

export default SvgFacebook;
