import useId from 'common/hooks/useId';
import globalStyle from 'common/primitives/forms/styles';
import V2Check from 'common/primitives/icons/components/V2Check';
import { colors, fontSizeAndLineHeight, fontStyles, media } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import React from 'react';

const styles = defaults(
    {
        labelCheckbox: css`
            display: flex;
            align-items: baseline;
            user-select: none;

            &:hover {
                cursor: pointer;
                svg {
                    fill: ${colors.whiteHover};
                }
            }
        `,
        labelCheckboxDisabled: css`
            &:hover {
                cursor: default;
            }
        `,
        checkboxLabelInner: css`
            font-weight: 400;
            ${fontSizeAndLineHeight('17px', 'auto')}
            width: calc(100% - 30px);
            margin-left: 12px;
            margin-bottom: 0;
        `,
        checkboxLabelInnerSmall: css`
            ${media.tablet} {
                ${fontSizeAndLineHeight('14px', 0.75)}
            }
            position: relative;
            top: -1px;
            margin-left: 14px;
            font-weight: 100;
        `,
        checkboxLabelInnerLarge: css`
            ${media.tablet} {
                ${fontSizeAndLineHeight('22px', 'auto')}
            }
            margin-left: 20px;
        `,
        checkboxInput: css`
            opacity: 0;
            position: absolute;

            &:focus {
                + label .checkbox__box {
                    outline-style: solid;
                }
            }

            &:checked + label .checkbox__box {
                svg {
                    fill: ${colors.black};
                }
            }
        `,
        checkboxInputDisabled: css`
            &:checked + label .checkbox__box {
                svg {
                    fill: ${colors.green};
                }
            }
        `,
        checkboxBox: css`
            display: block;
            width: 24px;
            height: 24px;
            border: 1px solid ${colors.inputBorderColor};
            background-color: ${colors.white};
            position: relative;
            top: 5px;
            svg {
                position: absolute;
                top: 3px;
                left: 4px;
                fill: transparent;
            }
        `,
        checkboxBoxLarge: css`
            width: 30px;
            height: 30px;
            border: 1px solid ${colors.lightgrey};
            background-color: ${colors.white};
            top: 7px;
            svg {
                position: absolute;
                top: 4px;
                left: 5px;
                width: 16px;
                height: 16px;
            }
        `,
        checkboxBoxError: css`
            border: 1px solid ${colors.error};
        `,
        checkboxLabelError: css`
            color: ${colors.error};
        `,
        checkboxBoxDisabled: css`
            border-color: transparent;
        `
    },
    globalStyle
);

interface CheckboxInputProps extends InputProps {
    testId?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    readyOnly?: boolean;
    appearance?: 'small' | 'regular' | 'large';
}

/*
 * CheckboxInput
 *
 * Note: If we use checked as a prop the input is a "controlled input". Checked or not checked is
 * determined via props. react-hook-form thogh prefers "uncontrolled inputs". That means we read the values
 * at the very end (onSubmit) and hence need to keep record of the state via setState to visually
 * indicate the current value
 *
 * @param {*} props
 */
const CheckboxInput: React.FunctionComponent<CheckboxInputProps> = (props) => {
    const {
        inputRef,
        name,
        label,
        disabled,
        readyOnly,
        className,
        testId,
        appearance = 'regular',
        onChange,
        checked,
        errors,
        defaultChecked
    } = props;

    const isControlled = Object.prototype.hasOwnProperty.call(props, 'checked');
    const controlledProps = {} as CheckboxInputProps;
    if (isControlled) {
        controlledProps.checked = checked;
    }

    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (readyOnly) {
            e.preventDefault();
        }
        if (onChange) {
            onChange(e);
        }
        return e;
    };

    // Error
    const hasError = errors?.[name];

    // Input id
    const id = useId();

    return (
        <div className={cx('form__field', 'form__field--checkbox', styles.wrapper, className)}>
            <input
                {...controlledProps}
                id={id}
                name={name}
                type="checkbox"
                className={cx(styles.checkboxInput, disabled && styles.checkboxInputDisabled)}
                disabled={disabled}
                ref={inputRef}
                onChange={handleOnChange}
                defaultChecked={defaultChecked}
            />
            <label
                data-testid={testId ?? name}
                className={cx(styles.labelCheckbox, disabled && styles.labelCheckboxDisabled)}
                htmlFor={id}
            >
                <div
                    className={cx(
                        'checkbox__box',
                        styles.checkboxBox,
                        appearance === 'large' && styles.checkboxBoxLarge,
                        disabled && styles.checkboxBoxDisabled,
                        hasError && styles.checkboxBoxError
                    )}
                >
                    <V2Check />
                </div>
                {label && (
                    <div
                        className={cx(
                            fontStyles.p,
                            styles.checkboxLabelInner,
                            appearance === 'large' && styles.checkboxLabelInnerLarge,
                            appearance === 'small' && styles.checkboxLabelInnerSmall,
                            hasError && styles.checkboxLabelError
                        )}
                        dangerouslySetInnerHTML={{ __html: label }}
                    />
                )}
            </label>
        </div>
    );
};

export default CheckboxInput;
