import { useRef } from 'react';
import { useEffect } from 'react';

/**
 * https://github.com/mhfen/swipe-detect/blob/42aff746f1741631b4b6fc9950a5f5f446361384/dist/index.js
 * Used to ignore longer touches that are probably not
 * meant as a swipe by the user
 *
 * @constant ALLOWED_SWIPE_TIME
 */
const ALLOWED_SWIPE_TIME = 300;

export const useSwipe = (
    callback: (direction: 'left' | 'right' | 'up' | 'down') => void,
    threshold = 150
): React.MutableRefObject<undefined> => {
    const container = useRef();

    useEffect(() => {
        const el = container.current;
        if (!el) {
            return;
        }
        let startX: number;
        let startY: number;
        let startTime: number;

        const recordTouchStartValues = (e: any) => {
            const touch = e.changedTouches[0];
            if (!touch) {
                return;
            }
            startX = touch.pageX;
            startY = touch.pageY;
            startTime = new Date().getTime();
        };

        const detectSwipeDirection = (e: any) => {
            const touch = e.changedTouches[0];
            if (!touch) {
                return;
            }
            const distX = touch.pageX - startX;
            const distY = touch.pageY - startY;
            const absX = Math.abs(distX);
            const absY = Math.abs(distY);
            const elapsedTime = new Date().getTime() - startTime;

            if (elapsedTime > ALLOWED_SWIPE_TIME) {
                return;
            }

            switch (true) {
                case absX >= threshold && absX > absY && distX < 0:
                    callback('left');
                    break;
                case absX >= threshold && absX > absY && distX > 0:
                    callback('right');
                    break;
                case absY >= threshold && absY > absX && distY < 0:
                    callback('up');
                    break;
                case absY >= threshold && absY > absX && distY > 0:
                    callback('down');
                    break;
            }
        };

        document.addEventListener('touchstart', recordTouchStartValues);
        document.addEventListener('touchend', detectSwipeDirection);

        return () => {
            document.removeEventListener('touchstart', recordTouchStartValues);
            document.removeEventListener('touchend', detectSwipeDirection);
        };
    }, [container.current]);

    return container;
};
