import Button from 'common/primitives/buttons';
import V2ArrowLeftSmall from 'common/primitives/icons/components/V2ArrowLeftSmall';
import VitraLink from 'common/primitives/links/vitraLink';
import { useTranslations } from 'common/primitives/translations';
import { colors, linkColor, media, rh } from 'common/styles';
import { CheckoutConfig } from 'components/checkout-v2/config';
import { css } from 'linaria';
import React from 'react';
import { CHECKOUT_STEP } from '../../state/checkoutProvider.d';

const styles = {
    footer: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        ${media.tablet} {
            flex-direction: row;
        }
    `,
    footerSubmit: css`
        margin-left: auto;
        order: 0;
        ${media.tablet} {
            max-width: 330px;
            order: 1;
        }
    `,
    footerBack: css`
        padding: ${rh(0.5)} 16px ${rh(0.5)} 0;
        order: 1;
        ${media.tablet} {
            order: 0;
        }
        white-space: nowrap;
        text-decoration: none;
        ${linkColor(colors.black, colors.primary)};
        svg {
            margin-right: ${rh(0.5)};
        }
        &:hover {
            svg {
                fill: ${colors.primary};
            }
        }
    `
};

interface CheckoutCommonStepFooterProps {
    /**
     * Checkout Config
     */
    config: CheckoutConfig;

    /**
     * Current Step
     */
    currentStep: CHECKOUT_STEP;

    /**
     * On Prev Action
     */
    onPrev?: () => void;

    /**
     * If a function is given this function will be called on submit otherwise the button behaves as a regular type="submit" button
     * If null is given then the button wont be rendererd at all
     */
    onNext?: (() => void) | null;

    /**
     * Disable submit
     */
    disableSubmit?: boolean;
}

const VitraCheckoutCommonStepFooter: React.FunctionComponent<CheckoutCommonStepFooterProps> = ({
    currentStep,
    onNext,
    onPrev,
    disableSubmit
}) => {
    const t = {
        [CHECKOUT_STEP.CUSTOMER]: useTranslations(
            'checkout.footer.actions.continue_to_shipping',
            'Continue to shipping'
        ),
        [CHECKOUT_STEP.SHIPPING]: useTranslations('checkout.footer.actions.continue_to_payment', 'Continue to payment'),
        [CHECKOUT_STEP.PAYMENT_SELECT]: useTranslations(
            'checkout.footer.actions.continue_to_review',
            'Continue to review'
        ),
        [CHECKOUT_STEP.CONFIRM]: useTranslations('checkout.footer.actions.buy_now', 'buy now'),
        back: useTranslations('checkout.footer.actions.go_to_previous_step', 'Back')
    };

    const handleOnNext = (e: React.MouseEvent) => {
        if (onNext) {
            e.preventDefault();
            onNext();
        }
        return e;
    };

    const handleOnPrev = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onPrev) {
            onPrev();
        }
    };

    return (
        <div className={styles.footer}>
            {onPrev && (
                <VitraLink href="#" onClick={handleOnPrev} className={styles.footerBack}>
                    <V2ArrowLeftSmall />
                    {t.back}
                </VitraLink>
            )}
            {onNext !== null && (
                <Button
                    testId="checkoutSubmitNextStep"
                    onClick={handleOnNext}
                    buttonType="submit"
                    className={styles.footerSubmit}
                    disabled={disableSubmit}
                >
                    {(t as any)[currentStep]}
                </Button>
            )}
        </div>
    );
};
export default VitraCheckoutCommonStepFooter;
