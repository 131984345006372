import * as React from 'react';

const SvgV2Cal = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 20.2 20.2" {...props}>
        <path d="M14.1 12.1h2v2h-2zm-5 0h2v2h-2z" className="V2-cal_svg__st0" />
        <path
            d="M19.1 2.1h-4v-2h-2v2h-6v-2h-2v2h-4c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-16c0-.5-.4-1-1-1zm-1 16h-16v-8h16v8zm0-10h-16v-4h3v2h2v-2h6v2h2v-2h3v4z"
            className="V2-cal_svg__st0"
        />
        <path d="M4.1 12.1h2v2h-2z" className="V2-cal_svg__st0" />
    </svg>
);

export default SvgV2Cal;
