import { useTranslations } from 'common/primitives/translations';
import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import VitraPicture from 'common/primitives/images/vitraPicture';
import { BffCartResultItem } from 'common/graphql/sdk';

interface DropDownCartItemProps {
    product: BffCartResultItem;
}

const styles = {
    content: css`
        padding: ${rh(0.625)} 0;
        margin-left: ${rh(-0.3125)};
        display: grid;
        grid-template-columns: 100px 1fr 1fr;
        grid-template-rows: repeat(2, minmax(50px, auto));
        grid-template-areas:
            'image name name'
            'image quantity price';
        column-gap: ${rh(0.3125)};
    `,
    image: css`
        grid-area: image;
        width: 100px;
    `,
    name: css`
        grid-area: name;
        font-weight: 600;
    `,
    quantity: css`
        grid-area: quantity;
    `,
    price: css`
        grid-area: price;
    `,
    label: css`
        color: ${colors.textGrey};
    `
};

const DropDownCartItem: React.FunctionComponent<DropDownCartItemProps> = ({ product }) => {
    const t = {
        labelQuantity: useTranslations('dropdown.cart.label.quantity', 'Quantity'),
        labelTotalPrice: useTranslations('dropdown.cart.label.total.price', 'Total')
    };
    return (
        <div>
            <div className={cx(styles.content)}>
                <VitraPicture className={styles.image} image={product.image} lazy />
                <div className={styles.name}>{product.name}</div>
                {product.quantity && (
                    <div className={styles.quantity}>
                        <div className={styles.label}>{t.labelQuantity}</div>
                        <div>{product.quantity}</div>
                    </div>
                )}
                {product.totalPrice?.formatted && (
                    <div className={styles.price}>
                        <div className={styles.label}>{t.labelTotalPrice}</div>
                        <div>{product.totalPrice.formatted}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DropDownCartItem;
