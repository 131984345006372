import * as React from 'react';

const SvgV2ContactGeneral = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
        <path d="M40 25.55A14.45 14.45 0 1054.45 40 14.46 14.46 0 0040 25.55zM41.62 48h-3.24V37.47h3.24zM40 36a2 2 0 01-2-2 2 2 0 012-2 2 2 0 011.39.58A1.92 1.92 0 0142 34a2 2 0 01-2 2z" />
        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zm0 57a17 17 0 1117-17 17 17 0 01-17 17z" />
    </svg>
);

export default SvgV2ContactGeneral;
