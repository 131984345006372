import { colors, rh } from 'common/styles';
import { css } from 'linaria';
import React from 'react';

const styles = {
    divider: css`
        border-top: 2px solid ${colors.checkoutBorder};
        margin: ${rh(1)} 0;
    `
};

const VitraDivider: React.FunctionComponent = () => {
    return <div className={styles.divider} />;
};
export default VitraDivider;
