/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { canUseDOM } from 'exenv';
import noop from 'lodash/noop';
import { useEffect, useState } from 'react';
import screenfull from 'screenfull';
import { useEvents } from './useEvents';

/**
 * Toggles a fullscreen element
 * @param ref the ref to the element you
 * want to have fullscreen
 * @param bodyClassname the bodylcassname that gets toggled on fullscreen
 */
export const useFullscreen = (ref: any, bodyClassname?: string): [boolean, () => void] => {
    if (!canUseDOM) {
        return [false, noop];
    }
    const emit = useEvents('fullscreen');
    const [isFullscreen, setIsFullscreen] = useState(false);
    const sf = screenfull as any;
    const toggleFullscreen = async () => {
        if (!ref || !ref.current) {
            return false;
        }

        sf.toggle(ref.current);
    };

    const onChange = () => {
        setIsFullscreen(sf.isFullscreen);
        if (sf.isFullscreen) {
            emit('enterFullScreen');
        } else {
            emit('exitFullScreen');
        }
    };

    // Did Mount
    useEffect(() => {
        if (!sf.isEnabled) {
            return;
        }
        sf.on('change', onChange);
        return () => {
            sf.off('change', onChange);
        };
    }, []);

    // Onchange isFullscreen
    // @todo: put into own hook
    useEffect(() => {
        const body = document.querySelector('body');
        if (body && bodyClassname) {
            if (isFullscreen) {
                body.classList.add(bodyClassname);
            } else {
                body.classList.remove(bodyClassname);
            }
        }

        return () => {
            if (body && bodyClassname) {
                body.classList.remove(bodyClassname);
            }
        };
    }, [isFullscreen]);

    return [isFullscreen, toggleFullscreen];
};
