import * as React from 'react';

const SvgV2CartPlus = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={12} height={12} viewBox="0 0 12 12" {...props}>
        <g fillRule="evenodd" strokeWidth={2}>
            <path d="M0 6h12M6 0v12" />
        </g>
    </svg>
);

export default SvgV2CartPlus;
