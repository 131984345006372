import * as React from 'react';

const SvgArrowBigLeft = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="1em" height="1em" viewBox="0 0 25 45" {...props}>
        <path
            d="M24.861.157c.191.2.184.517-.021.707L1.485 22.501l23.354 21.636a.493.493 0 0 1 .021.707.516.516 0 0 1-.722.02L0 22.501 24.139.137a.518.518 0 0 1 .722.02z"
        />
    </svg>
);

export default SvgArrowBigLeft;
