import * as React from 'react';

const SvgV2CartPlusSmall = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={10} height={10} viewBox="0 0 10 10" {...props}>
        <g fillRule="evenodd">
            <path d="M0 5h10M5 0v10" />
        </g>
    </svg>
);

export default SvgV2CartPlusSmall;
