import { addGaEvent } from 'common/primitives/analytics';
import Button, { ButtonGroup } from 'common/primitives/buttons';
import IconDirections from 'common/primitives/icons/components/V1Directions';
import IconEnvelope from 'common/primitives/icons/components/V1Envelope';
import IconSendList from 'common/primitives/icons/components/V1Sendlist';
import IconWorld from 'common/primitives/icons/components/V1World2';
import Translate from 'common/primitives/translations';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React from 'react';
import { FindVitraContentLocationExtended } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';

interface CommonLocationButtonsProps {
    className?: string;
    location: FindVitraContentLocationExtended;
    withIcons?: boolean;
    withWishlist?: boolean;
}

export const styles = {
    withIconStyle: css`
        padding-left: 0;
        > div {
            justify-content: flex-start;
        }
    `,
    icon: css`
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    `
};

const CommonLocationButtons: React.FunctionComponent<CommonLocationButtonsProps> = ({
    className,
    location,
    withIcons,
    withWishlist
}) => {
    // State
    const [state, dispatch]: any = useStateValue();

    const handleContactRetailer = (evt: React.MouseEvent) => {
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'POI Details',
            eventLabel: `${location.cn} | Contact`
        });
        dispatch({ type: 'SET_ACTIVE_LOCATION', location });
        dispatch({ type: 'UX_TOGGLE_OVERLAY', overlayName: 'contact' });
    };

    const handleContactRetailerWishlist = (evt: React.MouseEvent) => {
        dispatch({ type: 'SET_ACTIVE_LOCATION', location });
        dispatch({ type: 'UX_TOGGLE_OVERLAY', overlayName: 'wishlist' });
    };

    const handleOpenDirections = (loc: FindVitraContentLocationExtended) => (evt: React.MouseEvent) => {
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'POI Details',
            eventLabel: `${location.cn} | Directions`
        });
        if (canUseDOM) {
            window.open(
                `http://maps.google.com/maps?saddr=${state.position.coords.latitude},${state.position.coords.longitude}&daddr=${location.sa},${location.city}`
            );
        }
    };

    const handleOpenWebpage = (loc: FindVitraContentLocationExtended) => (evt: React.MouseEvent) => {
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'POI Details',
            eventLabel: `${location.cn} | Website Link`
        });
        if (loc.link && loc.link.href) {
            if (canUseDOM) {
                let url = loc.link.href;
                if (!/^(https?:)?\/\//i.test(url)) {
                    url = `http://${url}`;
                }
                window.open(url);
            }
        }
    };

    return (
        <ButtonGroup className={cx(className, 'buttons')}>
            {location.purchasable === true && location.email_retailer && withWishlist && (
                <Button
                    testId="button--sendWishlist"
                    type={withIcons ? 'transparent' : 'blackBorder'}
                    className={cx(withIcons && styles.withIconStyle)}
                    onClick={handleContactRetailerWishlist}
                >
                    {withIcons && <IconSendList className={styles.icon} />}
                    <div className="button__copy">
                        <Translate id="community_button_label_wishlist" />
                    </div>
                </Button>
            )}
            {location.email_retailer && (
                <Button
                    testId="button--contact"
                    type={withIcons ? 'transparent' : 'blackBorder'}
                    className={cx(withIcons && styles.withIconStyle)}
                    onClick={handleContactRetailer}
                >
                    {withIcons && <IconEnvelope className={styles.icon} />}
                    <div className="button__copy">
                        <Translate id="community_button_label_contact" />
                    </div>
                </Button>
            )}
            <Button
                testId="button--directions"
                type={withIcons ? 'transparent' : 'blackBorder'}
                className={cx(withIcons && styles.withIconStyle)}
                onClick={handleOpenDirections(location)}
            >
                {withIcons && <IconDirections className={styles.icon} />}
                <div className="button__copy">
                    <Translate id="community_button_label_directions" />
                </div>
            </Button>
            {location.link && location.link.href && (
                <Button
                    testId="button--web"
                    type={withIcons ? 'transparent' : 'blackBorder'}
                    className={cx(withIcons && styles.withIconStyle)}
                    onClick={handleOpenWebpage(location)}
                >
                    {withIcons && <IconWorld className={styles.icon} />}
                    <div className="button__copy">
                        {location.link &&
                            location.link.name &&
                            location.link.name.replace(/\/$/, '').replace(/(^\w+:|^)\/\//, '')}
                    </div>
                </Button>
            )}
        </ButtonGroup>
    );
};

export default CommonLocationButtons;
