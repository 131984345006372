import Button from 'common/primitives/buttons';
import Translate from 'common/primitives/translations';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { useCallback, useState } from 'react';
import { colors, visuallyHidden } from 'common/styles';

interface SkipToContentButtonProps {
    className?: string;
}

const styles = {
    wrapper: css`
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.white};

        &:not(:focus-within) {
            ${visuallyHidden}
        }
    `,

    button: css`
        width: auto;
        margin: 0;
    `
};

const SkipToContentButton: React.FunctionComponent<SkipToContentButtonProps> = ({ className }) => {
    const [linkTarget, setLinkTarget] = useState<string>('#');

    const onFocus = useCallback(() => {
        if (canUseDOM) {
            const firstElementAfterNav = (
                document.querySelector('[data-type="vitra--info-banner"]') ??
                document.querySelector('[data-type="vitra--main-navigation"]')
            )?.nextElementSibling;

            if (firstElementAfterNav) {
                if (!firstElementAfterNav.id) {
                    firstElementAfterNav.id = 'afternav';
                }

                setLinkTarget(`#${firstElementAfterNav.id}`);
            }
        }
    }, []);

    return (
        <div className={cx(styles.wrapper, className)}>
            <Button link={{ href: linkTarget }} onFocus={onFocus} className={styles.button} testId="skipToContent">
                <Translate id="navigation.skip_to_content_button" defaultMessage="Skip to content" />
            </Button>
        </div>
    );
};

export default SkipToContentButton;
