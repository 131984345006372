import * as React from 'react';

const SvgV2Alert = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
        <g fillRule="nonzero">
            <path d="M11 1C5.477 1 1 5.477 1 11s4.477 10 10 10 10-4.477 10-10S16.523 1 11 1zm0 1.5a8.5 8.5 0 110 17 8.5 8.5 0 010-17z" />
            <path d="M11.19 12.778a1.23 1.23 0 110 2.462 1.23 1.23 0 010-2.462zm.894-6.826v6.168h-1.836V5.952h1.836z" />
        </g>
    </svg>
);

export default SvgV2Alert;
