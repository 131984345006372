import * as React from 'react';

const SvgRotate = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        aria-hidden="true"
        data-prefix="fal"
        data-icon="sync"
        className="rotate_svg__svg-inline--fa rotate_svg__fa-sync rotate_svg__fa-w-16"
        viewBox="0 0 512 512"
        {...props}
        width="1em"
        height="1em"
    >
        <path
            fill="currentColor"
            d="M492 8h-10c-6.627 0-12 5.373-12 12v110.627C426.929 57.261 347.224 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h10.016c6.353 0 11.646-4.949 11.977-11.293C48.157 132.216 141.097 42 256 42c82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-.301 248h-10.015c-6.352 0-11.647 4.949-11.977 11.293C463.841 380.158 370.546 470 256 470c-82.608 0-154.672-46.952-190.299-116H180c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H20c-6.627 0-12 5.373-12 12v160c0 6.627 5.373 12 12 12h10c6.627 0 12-5.373 12-12V381.373C85.071 454.739 164.777 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z"
        />
    </svg>
);

export default SvgRotate;
