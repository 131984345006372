import copy from 'clipboard-copy';
import { isMobile } from 'common/hooks/useBreakpoints';
import Button from 'common/primitives/buttons';
import { TextInput } from 'common/primitives/forms';
import VitraModal from 'common/primitives/modal/vitraModal';
import Translate, { useTranslations } from 'common/primitives/translations';
import { media } from 'common/styles';
import { fontStyles } from 'common/styles/fonts';
import { css } from 'linaria';
import React from 'react';
import { useForm } from 'react-hook-form';

type ShareModal = {
    share: {
        title: string;
        url: string;
    };
    onClose: () => void;
};

const styles = {
    inputField: css`
        display: none;
        ${media.tablet} {
            display: block;
        }
    `
};

const VitraShareModal: React.FunctionComponent<ShareModal> = ({ share, onClose }) => {
    const { title, url } = share;

    const { register, setValue } = useForm({
        defaultValues: {
            url
        }
    });
    const copied = useTranslations('checkout_actions_copied_link');
    const handleOnClick = async () => {
        setValue('url', copied);
        copy(url);

        // ios native sharing
        if ((navigator as any).share && isMobile()) {
            await (navigator as any)
                .share({
                    title,
                    url
                })
                .catch(console.error);
        }

        setTimeout(() => {
            setValue('url', url);
            onClose();
        }, 1000);
    };

    return (
        <VitraModal type="large" closeOnClickOutside={true} onClose={onClose}>
            <div style={{ maxWidth: '500px' }}>
                <p className={fontStyles.p} style={{ textAlign: 'center' }}>
                    <Translate id="wishlist_share_description" />
                </p>
                <form>
                    <TextInput
                        name="url"
                        inputRef={register}
                        className={styles.inputField}
                        onFocus={(event: any) => {
                            event.currentTarget.select();
                        }}
                    />
                </form>
                <Button onClick={handleOnClick} testId="copyShareLink" type="blackBorder">
                    <Translate id="cart_actions_copy_link" />
                </Button>
            </div>
        </VitraModal>
    );
};
export default VitraShareModal;
