import * as React from 'react';

const SvgV1Phone = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={16.636} height={17} viewBox="0 0 16.636 17" {...props}>
        <path d="M12.763 16.67c-1.756 0-4.613-.766-8.261-4.411C-1.445 6.31.185 2.375.257 2.21l.028-.063L2.433 0l4.541 4.542-1.895 1.895c.158.398.674 1.449 2.166 2.959C8.75 10.884 9.8 11.4 10.199 11.558l1.895-1.895 4.542 4.54-2.142 2.143-.056.026c-.023.013-.62.298-1.675.298zM.91 2.561c-.158.476-1.073 3.993 4.112 9.177 3.468 3.469 6.124 4.197 7.741 4.197.712 0 1.163-.147 1.308-.203l1.528-1.529-3.505-3.503-1.677 1.678-.197-.042c-.055-.013-1.372-.323-3.495-2.421-2.101-2.126-2.412-3.443-2.424-3.498l-.043-.197 1.677-1.677-3.502-3.505L.91 2.561zM11.604 7.722l-.734-.017a1.863 1.863 0 00-.543-1.369 1.852 1.852 0 00-1.369-.543l-.018-.734a2.59 2.59 0 011.904.759c.51.507.779 1.184.76 1.904zM13.502 7.719l-.734-.011a3.724 3.724 0 00-1.101-2.712 3.732 3.732 0 00-2.712-1.101l-.011-.734a4.47 4.47 0 013.243 1.315 4.47 4.47 0 011.315 3.243z" />
        <path d="M15.581 7.718l-.734-.008a5.796 5.796 0 00-1.709-4.185 5.795 5.795 0 00-4.129-1.709h-.055l-.007-.734a6.533 6.533 0 014.709 1.925 6.512 6.512 0 011.925 4.711z" />
    </svg>
);

export default SvgV1Phone;
