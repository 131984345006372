import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

interface StatusBadgeProps extends React.ComponentPropsWithoutRef<'span'> {
    count?: number;
    label?: string;
    className?: string;
}

const styles = {
    badge: css`
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: ${colors.primary};
    font-size: 10px;
    color: ${colors.white}
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
`
};

const StatusBadge: React.FunctionComponent<StatusBadgeProps> = ({ count, label = '', className, ...otherProps }) =>
    count ? (
        // with role="status", screen readers will automatically read the label when it is updated
        <span role="status" {...otherProps} aria-label={label} className={cx(styles.badge, className)}>
            <span aria-hidden>{count}</span>
        </span>
    ) : null;

export default StatusBadge;
