import { useBoostrap } from 'common/hooks/useBoostrap';
import Button from 'common/primitives/buttons';
import { SelectInput } from 'common/primitives/forms';
import { useTranslations } from 'common/primitives/translations';
import { rh } from 'common/styles';
import { canUseDOM } from 'exenv';
import cookie from 'js-cookie';
import { css } from 'linaria';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Locale } from '../main-navigation-types';
import { worldFlags } from '../navigationEnum';
import DropDown from './dropDown';
import { getCurrentPageLanguage, setLanguageCookiesAndRedirect } from './navigationLocale';

interface NavigationContentCountryProps {
    /**
     * All locales
     */
    defaultLocales?: Locale[];
    /**
     * The default locale to the display if the config is not available
     */
    defaultPageLocale?: string;
    /**
     * On Submitting the form, gets called with the locale
     * eg. `de-de`
     */
    onSubmit?: (locale: string, iso: string) => void;

    /**
     * The vatid country of a b2b customer
     */
    vatIdCountry?: string;

    /**
     * whether the component is used in the mobile navigation
     */
    isMobile?: boolean;
}

const styles = {
    message: css`
        margin-bottom: ${rh(0.5)};
        line-height: 1.6;
    `,
    inputs: css`
        display: flex;
        flex-flow: column nowrap;
        gap: ${rh(0.25)};
    `,
    input: css`
        margin-bottom: 0;
    `,
    button: css`
        -webkit-font-smoothing: auto;
    `
};

const DropDownCountry: React.FunctionComponent<NavigationContentCountryProps> = ({
    defaultLocales = [],
    onSubmit = setLanguageCookiesAndRedirect,
    vatIdCountry,
    isMobile = false
}) => {
    const locales: Locale[] = useBoostrap('countries', defaultLocales);
    const pageLocale = getCurrentPageLanguage();
    const hasWorldflag = worldFlags.includes(pageLocale);
    const [languageOptions, setLanguageOptions] = useState<any>([]);
    const selectedCountry =
        // First check if we hava a country with that iso
        locales.find((loc: any) => {
            return loc.iso.toLowerCase() == pageLocale.split('-')[1];
        }) ||
        // If we dont have a country we search for the first language-country tuple
        locales.find((loc: any) => {
            return loc.languages.find((l: any) => l.code === pageLocale);
        });
    if (!selectedCountry) {
        return null;
    }

    const defaultValues = !hasWorldflag
        ? {
              country: selectedCountry.iso,
              language: pageLocale
          }
        : {};

    const { register, handleSubmit, setValue } = useForm({
        defaultValues
    });

    const countrySelectOptions = (vatIdCountry ? locales.filter((l) => l.iso === vatIdCountry) : locales).map((l) => ({
        name: l.name,
        value: l.iso
    }));
    const countrySelectOptionsSorted = sortBy(countrySelectOptions, 'name');

    useEffect(() => {
        const availableLocales = vatIdCountry ? locales.filter((l) => l.iso === vatIdCountry) : locales;

        const pageLocaleCountry =
            // First check if we hava a country with that iso
            availableLocales.find((loc: any) => {
                return loc.iso.toLowerCase() == pageLocale.split('-')[1];
            }) ||
            // If we dont have a country we search for the first language-country tuple
            availableLocales.find((loc: any) => {
                return loc.languages.find((l: any) => l.code === pageLocale);
            });
        if (pageLocaleCountry) {
            // weird race condition not selecting the country
            setTimeout(() => {
                setValue('country', pageLocaleCountry.iso);
                setValue('language', pageLocale);
            }, 20);
            setLanguageOptions(pageLocaleCountry.languages.map((l) => ({ name: l.name, value: l.code })));
        } else {
            setLanguageOptions([]);
        }
    }, [pageLocale, vatIdCountry]);

    useEffect(() => {
        // We check if have a world flag
        // if so we check the cookie, if this is empty we do not pre-select a country
        if (!canUseDOM || !hasWorldflag) {
            return;
        }
        const selectedCountryCookie = cookie.get('selected_country') as string;
        if (selectedCountryCookie) {
            // weird race condition not selecting the country
            setTimeout(() => {
                setValue('country', selectedCountryCookie);
                setValue('language', pageLocale);
            }, 20);
        }
    }, []);

    const onSelectCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const _country = event.currentTarget.value;
        const locale = locales.find((l) => l.iso === _country);
        if (locale && locale.languages.length >= 0) {
            setTimeout(() => {
                setValue('country', _country);
            }, 20);
            if (locale.languages.length === 1) {
                setLanguageOptions([]);
            } else {
                const lo = locale.languages.map((l) => ({ name: l.name, value: l.code }));
                setTimeout(() => {
                    setValue('language', lo[0].value);
                }, 20);
                setLanguageOptions(lo);
            }
        }
        return event;
    };

    const onSubmitForm = ({ country, language }: any) => {
        const sLocale = locales.find((c: any) => c.iso === country);
        if (sLocale) {
            if (sLocale.languages.length === 1) {
                onSubmit(sLocale.languages[0].code, sLocale.iso);
            } else {
                if (!language) {
                    language = sLocale.languages[0].code;
                }
                // we need to check if the language value actually exists
                const selectedLang = sLocale.languages.find((l) => l.code === language);
                if (selectedLang) {
                    onSubmit(selectedLang.code, sLocale.iso);
                }
            }
        }
    };

    const t = {
        title: useTranslations('dropdown.country.title', 'Location & Language'),
        message: useTranslations('dropdown.country.message', 'Choose your location and language:'),
        locationInputLabel: useTranslations('dropdown.country.location.input.label', 'Location'),
        languageInputLabel: useTranslations('dropdown.country.language.input.label', 'Language'),
        submitLabel: useTranslations('dropdown.country.submit.label', 'Select')
    };

    return (
        <DropDown title={t.title} isMobile={isMobile}>
            <div className={styles.message}>{t.message}</div>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className={styles.inputs}>
                    <SelectInput
                        className={styles.input}
                        disabled={vatIdCountry ? true : false}
                        name="country"
                        label={t.locationInputLabel}
                        options={countrySelectOptionsSorted}
                        defaultOption={useTranslations('dropdown_country_country_empty')}
                        onChange={onSelectCountry}
                        inputRef={register}
                    />
                    {languageOptions && languageOptions.length > 1 && (
                        <SelectInput
                            className={styles.input}
                            name="language"
                            label={t.languageInputLabel}
                            options={languageOptions}
                            defaultOption={useTranslations('dropdown_country_country_empty')}
                            inputRef={register}
                        />
                    )}
                </div>
                <Button className={styles.button} buttonType="submit" testId="navigation-select-country">
                    {t.submitLabel}
                </Button>
            </form>
        </DropDown>
    );
};

export const MobileCountrySettings: React.FunctionComponent = () => <DropDownCountry isMobile />;

export default DropDownCountry;
