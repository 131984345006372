import * as React from 'react';
import { css, cx } from 'linaria';

interface ArrowRotateProps {
    up: boolean;
    className?: string;
}

const styles = {
    arrow: css`
        display: inline-flex;
        svg {
            transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
        }
    `,
    up: css`
        svg {
            transform: rotate(180deg);
        }
    `
}

const ArrowRotate: React.FunctionComponent<ArrowRotateProps> = ({ up, className }) => {
    return (
        <div className={cx(
            'arrow__rotate',
            styles.arrow,
            up && styles.up,
            className
        )}>
             <svg width={12} height={8}>
                <path d="M11.22 1.563a.133.133 0 010 .188L5.753 7.219a.133.133 0 01-.188 0L.095 1.751a.133.133 0 010-.188L.661.997a.133.133 0 01.188 0l4.808 4.808L10.465.997a.134.134 0 01.19 0l.566.566z" />
            </svg>
        </div>
    );
};
export default ArrowRotate;
