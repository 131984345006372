// This component wraps VitraMaterial and shall only be used for colors
// * add to cart
// * add to wishlist

import { CartBffContext } from 'common/hooks/useCart-v2';
import { WishlistButton } from 'common/hooks/useWishlist';
import IconAddToCart from 'common/primitives/icons/components/V2Cart';
import { colors, media } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useContext } from 'react';
import { BlankButton } from '../buttons';
import VitraMaterial, { VitraMaterialProps } from './vitraMaterial';

const styles = {
    wrapper: css`
        position: relative;
        &:hover {
            .iconWrapper {
                display: flex;
            }
        }
        ${media.belowDesktop} {
            .iconWrapper {
                display: flex;
            }
        }
    `,
    wrapperHasOnClick: css`
        &:hover {
            cursor: pointer;
        }
    `,
    iconWrapper: css`
        display: none;
        position: absolute;
        z-index: 1;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: white;
        align-items: center;
        justify-content: center;
        &:hover svg {
            fill: ${colors.primary};
        }
    `,
    iconWrapperAddToWishlist: css`
        right: 24px;
        top: 24px;
    `,
    iconAddToWishlist: css`
        width: 21px;
        height: 21px;
    `,
    iconWrapperPadding: css`
        right: 34px !important;
    `,
    iconWrapperAddToCart: css`
        right: 24px;
        top: 64px;
    `,
    iconAddToCart: css`
        width: 21px;
        height: 21px;
    `
};

export interface VitraMaterialColorProps extends VitraMaterialProps {
    onClick?: () => void;
}

const VitraMaterialColor: React.FunctionComponent<VitraMaterialColorProps> = ({
    className,
    material,
    display = 'small',
    lazy = false,
    onClick
}) => {
    const cartBff = useContext(CartBffContext);
    const addToCart = (item: Material) => async (e: React.MouseEvent) => {
        e.preventDefault();
        await cartBff.addItem(item.id, 'material');
    };

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const showAddToCart = !!material.addToCart;
    return (
        <div
            className={cx(styles.wrapper, onClick && styles.wrapperHasOnClick)}
            key={material.id}
            onClick={handleOnClick}
        >
            <div
                className={cx(
                    'iconWrapper',
                    styles.iconWrapper,
                    styles.iconWrapperAddToWishlist,
                    display === 'big' && styles.iconWrapperPadding
                )}
            >
                <WishlistButton
                    sku={material.id}
                    className={styles.iconAddToWishlist}
                    showModal={true}
                    type={'material'}
                />
            </div>
            {showAddToCart && (
                <BlankButton
                    testId={`AddToCart-${material.id}`}
                    className={cx(
                        'iconWrapper',
                        styles.iconWrapper,
                        styles.iconWrapperAddToCart,
                        display === 'big' && styles.iconWrapperPadding
                    )}
                    onClick={addToCart(material)}
                >
                    <IconAddToCart className={styles.iconAddToCart} />
                </BlankButton>
            )}
            <VitraMaterial
                display={display}
                className={className}
                material={material}
                type={'color' as any}
                lazy={lazy}
            />
        </div>
    );
};
export default VitraMaterialColor;
