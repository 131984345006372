import type { BffCartResultItem } from 'common/graphql/sdk';
import { useConfiguratorModal } from 'common/hooks/useConfiguratorModal-v3';
import V2Info from 'common/primitives/icons/components/V2Info';
import VitraPicture from 'common/primitives/images/vitraPicture';
import { getLocalizedPrice, useTranslations } from 'common/primitives/translations';
import { colors, fontSizeAndLineHeight, linkColor, rh } from 'common/styles';
import { css } from 'linaria';
import React, { Fragment, SyntheticEvent } from 'react';

const styles = {
    image: css`
        max-width: 30%;
        margin-right: ${rh(0.5)};
    `,
    item: css`
        padding-bottom: ${rh(0.5)};
        padding-top: ${rh(0.5)};
        border-top: 1px solid ${colors.checkoutBorder};
        width: 100%;
        display: flex;
        ${fontSizeAndLineHeight('14px')}
    `,
    info: css`
        width: 100%;
    `,
    name: css`
        font-weight: 600;
    `,
    infoPriceQty: css`
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    `,
    qty: css`
        white-space: nowrap;
    `,
    price: css`
        margin-left: auto;
        white-space: nowrap;
    `,
    infoLink: css`
        margin-top: ${rh(0.25)};
        display: flex;
        a {
            ${linkColor(colors.textGrey, colors.primary)};
        }
    `,
    messageIcon: css`
        padding-right: 5px;
        padding-left: 0;
        position: relative;
        top: 5px;
        left: -4px;
        svg {
            fill: ${colors.textGrey};
        }
    `,
    messageText: css``,
    message: css`
        position: relative;
        display: flex;
        padding: 5px;
        color: ${colors.textGrey};
    `
};

interface CheckoutCartItems {
    /**
     * the cart items to display
     */
    lineItems: BffCartResultItem[];

    /**
     * Toggle to block the whole UI
     */
    toggleBlockUi?: () => void;
}

const VitraCheckoutCartItems: React.FunctionComponent<CheckoutCartItems> = ({ lineItems, toggleBlockUi }) => {
    const t = {
        quantity: useTranslations('checkout.sidebar.cart.quantity', 'Quantity'),
        custom_configuration: useTranslations('checkout.sidebar.cart.custom_configuration', 'Individually configured'),
        show_details: useTranslations('checkout.sidebar.cart.show_details', 'Details')
    };

    const { startConfiguration, configurationModal } = useConfiguratorModal(true);

    const showDetails = (item: BffCartResultItem) => async (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        await startConfiguration(item.sku);
    };

    return (
        <Fragment>
            {configurationModal}
            {lineItems.map((item) => {
                return (
                    <div className={styles.item}>
                        {item.image && (
                            <div className={styles.image}>
                                <VitraPicture image={item.image} />
                            </div>
                        )}
                        <div className={styles.info}>
                            <div className={styles.name}>{item.name}</div>
                            <div className={styles.infoPriceQty}>
                                <div className={styles.qty}>
                                    {t.quantity}: &nbsp;{item.quantity}
                                </div>
                                <div className={styles.price}>{getLocalizedPrice(item.totalPrice)}</div>
                            </div>
                            {item.showReturnNote && (
                                <div className={styles.message}>
                                    <div className={styles.messageIcon}>
                                        <V2Info />
                                    </div>
                                    <div className={styles.messageText}>{t.custom_configuration}</div>
                                </div>
                            )}
                            {item.isConfigurable && toggleBlockUi && (
                                <div className={styles.infoLink}>
                                    <a href="#" onClick={showDetails(item)}>
                                        {t.show_details}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </Fragment>
    );
};

export default React.memo(VitraCheckoutCartItems, (prevProps, nextProps) => {
    if (prevProps.lineItems.length === nextProps.lineItems.length) {
        return true;
    }
    return false; // props are not equal -> update the component
});
