import { canUseDOM } from 'exenv';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];

const detectUserActivity = (timeout = 3000): boolean => {
    const [state, setState] = useState<boolean>(false);
    if (!canUseDOM) {
        return state;
    }
    useEffect(() => {
        let inactivityTimeout: NodeJS.Timeout;

        const reportUserActivity = debounce(
            () => {
                setState(true);
                clearTimeout(inactivityTimeout);
                inactivityTimeout = setTimeout(() => setState(false), timeout);
            },
            100,
            { leading: true, trailing: false }
        );

        for (const event of events) {
            window.addEventListener(event, reportUserActivity);
        }

        return () => {
            if (canUseDOM) {
                for (const event of events) {
                    window.removeEventListener(event, reportUserActivity);
                }
                if (inactivityTimeout) {
                    clearTimeout(inactivityTimeout);
                }
            }
        };
    }, []);

    return state;
};

export default detectUserActivity;
