import V2Info from 'common/primitives/icons/components/V2Info';
import { colors, fontSizeAndLineHeight, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

const styles = {
    messages: css`
        width: 100%;
    `,
    message: css`
        position: relative;
        background: ${colors.silverDark};
        display: flex;
        padding: 20px;
        margin-bottom: ${rh(0.65)};
        ${fontSizeAndLineHeight('14px', 0.75)}
        ${media.tablet} {
            ${fontSizeAndLineHeight('17px', 1)}
        }
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `,
    messageTitle: css`
        font-weight: 600;
    `,
    messageIcon: css`
        padding-right: 15px;
        position: relative;
        top: 5px;
    `,
    messageText: css`
        margin-top: -4px;
        margin-bottom: -4px;
    `,

    messageError: css`
        color: ${colors.salmon};
        svg {
            fill: ${colors.salmon};
        }
    `,

    messageHint: css`
        svg {
            fill: ${colors.salmon};
        }
    `,
    messageSuccess: css`
        color: ${colors.findVitraColorGreen};
        svg {
            fill: ${colors.findVitraColorGreen};
        }
    `
};

type iCheckoutMessagestMessageType = 'error' | 'hint' | 'success';

export interface iCheckoutMessagestMessage {
    title?: string;
    description?: string;
    type: iCheckoutMessagestMessageType;
}

interface VitraCheckoutCommonMessagesProps {
    messages: iCheckoutMessagestMessage[];
}

const VitraCheckoutCommonMessages: React.FunctionComponent<VitraCheckoutCommonMessagesProps> = ({ messages }) => {
    return (
        <div className={styles.messages}>
            {messages.map((message) => {
                return (
                    <div
                        className={cx(
                            message.type === 'error' && styles.messageError,
                            message.type === 'hint' && styles.messageHint,
                            message.type === 'success' && styles.messageSuccess,
                            styles.message
                        )}
                    >
                        <div className={styles.messageIcon}>
                            <V2Info />
                        </div>
                        <div>
                            {message.title && <div className={styles.messageTitle}>{message.title}</div>}
                            {message.description && (
                                <div
                                    className={styles.messageText}
                                    dangerouslySetInnerHTML={{ __html: message.description }}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
export default VitraCheckoutCommonMessages;
