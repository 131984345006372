import { colors } from 'common/styles';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import * as React from 'react';

const styles = {
    wrapper: css`
        height: 100%;
        position: relative;
    `,
    spinner: css`
        font-size: 48px;
        display: inline-block;
        width: 1em;
        height: 1em;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    `,
    stroke: css`
        position: absolute;
        z-index: 1;
        left: 0.4629em;
        bottom: 0;
        width: 0.024em;
        height: 0.2777em;
        border-radius: 0.0555em;
        transform-origin: center -0.2222em;
        mix-blend-mode: difference;
    `,
    strokeBlack: css`
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        background-color: transparent;
        animation: spinner-fade-black 1s infinite linear;
        @keyframes spinner-fade-black {
            0% {
                background-color: ${colors.black};
            }
            100% {
                background-color: transparent;
            }
        }
    `,
    strokeBlackBackground: css`
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    `,
    strokeWhite: css`
        position: absolute;
        z-index: 1;
        height: 100%;
        height: 100%;
        width: 100%;
        background-color: transparent;
        animation: spinner-fade-white 1s infinite linear;
        @keyframes spinner-fade-white {
            0% {
                background-color: white;
            }
            100% {
                background-color: transparent;
            }
        }
    `,
    strokeWhiteBackground: css`
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    `
};

interface SpinnerProps {
    /**
     * An additional className fpr the component
     */
    className?: string;
    /**
     * How many strokes should be displayed
     */
    strokeCount?: number;
    /**
     * The color of the strokes
     *
     * Important: If the browser supports mix-blend-mode these properties are ignored
     * because we use a CSS pattern that assures that the spinner is always visible
     * on every background
     */
    type?: 'black' | 'white';

    /**
     * Force to disable mixblend
     */
    mixblendDisabled?: boolean;
}

const VitraSpinner: React.FunctionComponent<SpinnerProps> = (props) => {
    let mixBlendCSSSupport = false;
    if (canUseDOM && ((window as any).CSS || {}).supports) {
        mixBlendCSSSupport = (window as any).CSS.supports('mix-blend-mode: difference');
    }
    const strokeColor = props.type || 'black';
    const strokeCounts: number = props.strokeCount || 11;
    const durationSeconds = 1;
    const animationDelay: number = Math.round((durationSeconds / (strokeCounts - 1)) * 100) / 100;
    const animationRadius: number = Math.ceil(360 / strokeCounts);

    function renderStrokes() {
        const html = [];
        for (let i = 0; i < strokeCounts; i++) {
            const animationStyle = {
                animationDelay: `${i * animationDelay}s`
            };
            const transformStyle = {
                transform: `rotate(${i * animationRadius}deg)`
            };
            html.push(
                <div className={styles.stroke} style={transformStyle}>
                    <div
                        className={cx(
                            mixBlendCSSSupport && !props.mixblendDisabled && styles.strokeWhite,
                            strokeColor === 'black' &&
                                (!mixBlendCSSSupport || props.mixblendDisabled) &&
                                styles.strokeBlack,
                            strokeColor === 'white' &&
                                (!mixBlendCSSSupport || props.mixblendDisabled) &&
                                styles.strokeWhite
                        )}
                        style={animationStyle}
                    ></div>
                    <div
                        className={cx(
                            mixBlendCSSSupport && !props.mixblendDisabled && styles.strokeWhiteBackground,
                            strokeColor === 'black' &&
                                (!mixBlendCSSSupport || props.mixblendDisabled) &&
                                styles.strokeBlackBackground,
                            strokeColor === 'white' &&
                                (!mixBlendCSSSupport || props.mixblendDisabled) &&
                                styles.strokeWhiteBackground
                        )}
                    />
                </div>
            );
        }
        return html;
    }

    return (
        <div className={cx(props.className, styles.wrapper)}>
            <div className={styles.spinner}>{renderStrokes()}</div>
        </div>
    );
};

export default VitraSpinner;
