import * as React from 'react';

const SvgV2ContactBusiness = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
        <g transform="translate(-954.5 -2001.967)">
            <circle cx={25} cy={25} r={25} data-name="Ellipse 22846" transform="translate(954.5 2001.967)" />
            <path
                fill="#fff"
                d="M987.682 2033.512h1.818v1.818h-20v-1.818h1.818v-13.636a.909.909 0 01.909-.909h9.091a.909.909 0 01.909.909v13.636h3.636v-7.272h-1.818v-1.818h2.727a.909.909 0 01.909.909zm-14.546-12.727v12.727h7.273v-12.727zm1.818 5.455h3.636v1.818h-3.635zm0-3.636h3.636v1.818h-3.635z"
                data-name="Pfad 7429"
            />
        </g>
    </svg>
);

export default SvgV2ContactBusiness;
