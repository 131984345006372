import { canUseDOM } from 'exenv';
import { useEffect, useState } from 'react';

/**
 * MatchMedia Helper Hook
 * @param mediaString can be any css media query or an alias for one int th map
 */
export const useMatchMedia = (mediaString: string): boolean => {
    const [matches, setMatches] = useState(false);

    const alias = new Map([['touch', '(pointer: coarse)']]);

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        const checkfor = alias.get(mediaString) || mediaString;
        setMatches(window.matchMedia(checkfor).matches);
    }, []);

    return matches;
};
