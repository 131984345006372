import { timer } from 'common/utils/animation';
import { canUseDOM } from 'exenv';
import React from 'react';
import CommonMap from '../common/commonMap';
import { FindVitraContentLocationExtended, MapMarker } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';

interface CommonSearchMapProps {
    className?: string;
}

const CommonSearchMap: React.FunctionComponent<CommonSearchMapProps> = (props) => {
    const [state, dispatch]: any = useStateValue();
    const locations = state.locationsFiltered.chunked[state.pagination.page - 1] || [];

    /**
     * Animate the contents of the result list
     * IMPORTANT: This works only if the containerElement is the closest relatively positioned parent element.
     * REPEAT ME:
     * listitem => Direct child of the container
     * container => position: relative
     */
    const animateListContent = (id: string) => {
        const container = document.getElementById('resultList');
        const listItem = document.getElementById(`resultListLocationId--${id}`);
        if (listItem && container) {
            const start = container.scrollTop;
            const goal = listItem.offsetTop;
            const distance = goal - start;
            const ease = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
            timer((i: number) => {
                container.scrollTop = start + distance * ease(i);
            }, 500);
        }
    };

    /**
     * Markers
     */
    const markers = locations.map((l: FindVitraContentLocationExtended) => ({
        ...l.coords,
        isHighlighted: l._code === state.ux.highlightLocation,
        type: l.type,
        id: l._code
    }));

    const handleMapMarkerClick = (id: string) => {
        dispatch({
            type: 'UX_TOGGLE_HIGHLIGHT_LOCATION',
            id
        });
        if (canUseDOM) {
            animateListContent(id);
        }
    };

    const handleRportVisibleMarkers = (m: MapMarker[]) => {
        dispatch({
            type: 'UX_SET_VISIBLE_MARKERS',
            m
        });
    };

    return (
        <CommonMap
            className={props.className}
            markers={markers}
            onMapMarkerClick={handleMapMarkerClick}
            onReportVisibleMarkers={handleRportVisibleMarkers}
            showPosition={state.position.address}
            position={state.position.coords}
            useClustering={true}
        />
    );
};

export default CommonSearchMap;
