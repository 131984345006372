import * as React from 'react';

const SvgPinterest = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg  height="1em" viewBox="0 0 22.002 22" {...props}>
        <path
            fill="#333"
            d="M11 0C4.925 0 0 4.924 0 11c0 4.66 2.899 8.641 6.99 10.243-.095-.871-.183-2.207.04-3.156.2-.858 1.289-5.468 1.289-5.468s-.329-.657-.329-1.632c0-1.53.887-2.671 1.991-2.671.939 0 1.392.705 1.392 1.549 0 .943-.602 2.355-.913 3.663-.259 1.094.55 1.987 1.63 1.987 1.954 0 3.458-2.061 3.458-5.037 0-2.634-1.891-4.476-4.594-4.476-3.131 0-4.968 2.348-4.968 4.773 0 .945.364 1.958.818 2.51.09.108.104.205.076.316-.083.347-.269 1.094-.305 1.246-.048.202-.159.245-.368.149-1.374-.639-2.231-2.647-2.231-4.262 0-3.47 2.521-6.656 7.266-6.656 3.819 0 6.783 2.719 6.783 6.353 0 3.79-2.391 6.84-5.709 6.84-1.113 0-2.162-.576-2.522-1.261 0 0-.551 2.098-.683 2.616-.249.955-.92 2.151-1.368 2.883C8.772 21.826 9.867 22 11 22c6.076 0 11.002-4.926 11.002-11 0-6.076-4.925-11-11.002-11"
        />
    </svg>
);

export default SvgPinterest;
