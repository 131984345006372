import { useConfig } from 'common/hooks/useBoostrap';
import { findAll } from 'highlight-words-core';

/**
 * Fast Substring search
 * @param needle string
 * @param haystack string
 */
export const fuzzySearch = (needle: string, haystack: string): boolean => {
    const hlen = haystack.length;
    const nlen = needle.length;
    if (nlen > hlen) {
        return false;
    }
    if (nlen === hlen) {
        return needle === haystack;
    }
    outer: for (let i = 0, j = 0; i < nlen; i++) {
        const nch = needle.charCodeAt(i);
        while (j < hlen) {
            if (haystack.charCodeAt(j++) === nch) {
                continue outer;
            }
        }
        return false;
    }
    return true;
};

export const highlighText = (searchWords: string, textToHighlight: string): string => {
    return findAll({
        searchWords: `${searchWords}`.trim().split(' '),
        textToHighlight
    })
        .map((chunk: any) => {
            const { end, highlight, start } = chunk;
            const text = textToHighlight.substr(start, end - start);
            if (highlight) {
                return `<strong>${text}</strong>`;
            } else {
                return text;
            }
        })
        .join('');
};

export const getSearchPageUrl = (searchTerm?: string): string => {
    const searchPageUrl = useConfig('searchPageUrl', '/search');
    return searchTerm
        ? `${searchPageUrl}?${new URLSearchParams({
              s: searchTerm
          }).toString()}`
        : searchPageUrl;
};
