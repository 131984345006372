import { isMobile } from 'common/hooks/useBreakpoints';
import Translate from 'common/primitives/translations';
import { colors, media, rh } from 'common/styles';
import { fontSizeAndLineHeight, fontStyles } from 'common/styles/fonts';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { useRef } from 'react';
import { FindVitraContentLocationExtended } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';
import CommonSearchResultListLocation from './commonSearchResultListLocation';
import CommonSearchShareButton from './commonSearchShareButton';

const styles = {
    resultList: css`
        height: 100%;
        width: 100%;
        position: relative;
    `,
    resultListDesktop: css`
        background: ${colors.findVitraColorSilver};
        max-height: 670px;
        min-height: 670px;
        overflow-y: scroll;
    `,
    resultListTitle: css`
        flex: 0 1 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        line-height: 1;
        padding: 34px 0px 16px;
        ${media.tablet} {
            padding: 34px 16px ${rh(0.5)};
        }
    `,
    resultListTitleCopy: css`
        ${fontSizeAndLineHeight('22px', 1)}
        font-weight: 100;
    `,
    resultListTitleDistance: css`
        font-weight: 100;
        &:hover {
            cursor: pointer;
        }
        > div {
            text-decoration: underline;
        }
        display: none;
        ${media.tablet} {
            display: inline-flex;
            padding-left: 8px;
        }
    `,
    resultListEmpty: css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    `
};

interface CommonSearchResultListProps {
    type?: 'desktop' | 'mobile';
}

export const CommonSearchResultListEmpty: React.FunctionComponent<CommonSearchResultListProps> = ({
    type = 'desktop'
}) => (
    <div className={cx(styles.resultList, styles.resultListEmpty, type == 'desktop' && styles.resultListDesktop)}>
        <h2 className={fontStyles.h2}>
            <Translate id="community_search_results_empty_title" />
        </h2>
        <div>
            <Translate id="community_search_results_empty_help" />
        </div>
    </div>
);

export const CommonSearchResultList: React.FunctionComponent<CommonSearchResultListProps> = ({ type = 'desktop' }) => {
    const resultListRef = useRef(null);

    // State
    const [state, dispatch]: any = useStateValue();
    const locations = state.locationsFiltered.chunked[state.pagination.page - 1];
    const total = state.locationsFiltered.list.length;
    const distance = state.scope.live.distance;
    const unit = state.options.unit;
    const mobileStyle = isMobile();
    /**
     * Assures that the result__list which is opverflow:hidden always
     * shows the toggled element within it - It's the case if you toggle
     * an element at the very bottom
     */
    const assureChildIsVisibleInContainer = (itemEl: HTMLDivElement) => {
        if (!canUseDOM) {
            return;
        }
        window.setTimeout(() => {
            const containerEl = resultListRef.current;
            if (containerEl !== null) {
                const itemElBottom = itemEl.getBoundingClientRect().bottom;
                const containerElBottom = (containerEl as any).getBoundingClientRect().bottom;
                if (itemElBottom > containerElBottom) {
                    (containerEl as any).scrollTop += itemElBottom - containerElBottom;
                }
            }
        }, 100);
    };

    const handleDistanceClick = (evt: React.MouseEvent) => {
        evt.preventDefault();
        dispatch({ type: 'UX_TOGGLE_FILTER_FLYOUT', id: 'distance' });
    };

    return (
        <div
            id="resultList"
            ref={resultListRef}
            className={cx(styles.resultList, type == 'desktop' && styles.resultListDesktop)}
        >
            <div className={styles.resultListTitle}>
                <h2 className={styles.resultListTitleCopy} style={{ marginBottom: 0 }}>
                    <Translate id="community_search_results_title" data={{ count: String(total) }} />
                </h2>
                <div className={cx(styles.resultListTitleDistance)} onClick={handleDistanceClick}>
                    (
                    <Translate
                        html
                        id="community_search_results_title_distance"
                        data={{ distance: String(distance), unit }}
                    />
                    )
                </div>
                <CommonSearchShareButton />
            </div>
            {locations &&
                locations.map((loc: FindVitraContentLocationExtended) => (
                    <CommonSearchResultListLocation
                        key={loc._code}
                        location={loc}
                        type={type}
                        guaranteeVisibility={assureChildIsVisibleInContainer}
                    />
                ))}
        </div>
    );
};
