import * as React from 'react';

const SvgV2ContactCampus = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
        <path d="M26.39 35.46v17.96h28.22V35.46L40.5 24.7 26.39 35.46z" />
        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zm17.5 54.82a1.43 1.43 0 01-1.43 1.41H24.94a1.43 1.43 0 01-1.44-1.41v-20a1.42 1.42 0 01.55-1.11L39.61 21.8a1.47 1.47 0 011.78 0L57 33.67a1.42 1.42 0 01.55 1.11z" />
    </svg>
);

export default SvgV2ContactCampus;
