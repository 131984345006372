import { TranslateProvider } from 'common/primitives/translations';
import { widgetBaseStyle } from 'common/styles/fonts';
import { cx } from 'linaria';
import React, { Fragment } from 'react';

interface BaseWidget {
    id: string;
    ref?: any;
    type: string;
    translations?: any;
    children: any;
    className?: string;
}


const VitraBaseWidget: React.FunctionComponent<BaseWidget> = ({ id, ref, type, children, translations, className }) => {
    return (
        <div ref={ref} id={id} data-type={type} className={cx(widgetBaseStyle, className)}>
            <TranslateProvider translations={translations || {}}>
                <Fragment>
                    <a id={`a-${id}`} />
                    {children}
                </Fragment>
            </TranslateProvider>
        </div>
    );
};
export default VitraBaseWidget;
