import { getBffLanguageAndCountry } from 'common/graphql/client';
import { FeatureFlags, useFeature } from 'common/hooks/useFeature';
import { loadFromPriceData } from 'common/hooks/usePricing';
import VitraGrid from 'common/primitives/grid/vitraGrid';
import { colors, media, rh } from 'common/styles';
import { Teaser } from 'components/teaser/teaser';
import { VitraTeaser as VitraTeaserListing } from 'components/teaser/teaser-listing';
import { css, cx } from 'linaria';
import React, { useEffect, useState } from 'react';
import VitraColumns from '../columns/vitraColumns';
import VitraProduct from './vitraProduct';

export interface VitraProductListingProps {
    /**
     * A Classname for the warpper
     */
    className?: string;

    /**
     * The Product
     */
    products: Product[];

    /**
     * Shows the attibutes below the product
     */
    showAttributes?: boolean;

    /**
     * Shows the attibutes below the product
     */
    loadFromPrices?: boolean;

    /**
     * the button Bar
     */
    wishlistButtons?: 'all' | 'cart';

    /**
     * A teaser to show in the listing
     */
    teaser?: Teaser;

    /**
     * Render methodd of the listing.
     * columns: css flex columns, grid: css grid
     */
    appearance?: 'columns' | 'grid' | 'slider';
}

const styles = {
    // The grid teaser might be far higher on mobile than the other items so we dont want to be all grid items of equal height
    // On Tablet we assume we have enough space to make all grid items of equal height
    gridBordered: css`
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-rows: auto; // See comment above
        ${media.tabletAndUp} {
            grid-auto-rows: 1fr; // See comment above
            grid-template-columns: repeat(3, minmax(0, 1fr));
            border-left: 1px solid ${colors.lightgrey};
        }
        ${media.desktopAndUp} {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    `,
    gridItemBordered: css`
        padding: 0 ${rh(0.5)} ${rh(1)};
        overflow: hidden;
        ${media.tabletAndUp} {
            border-right: 1px solid ${colors.lightgrey};
            border-bottom: 1px solid ${colors.lightgrey};
            border-bottom: 1px solid ${colors.lightgrey};
            // Targets the first row
            &:nth-child(-n + 3) {
                border-top: 1px solid ${colors.lightgrey};
            }
        }
        ${media.desktopAndUp} {
            // Targets the first row
            &:nth-child(-n + 4) {
                border-top: 1px solid ${colors.lightgrey};
            }
        }
        a:not([role='button']) {
            color: ${colors.black} !important;
            picture {
                transition: transform 0.2s ease-in-out;
                transform: scale(1);
                transform-origin: bottom center;
            }
            &:hover picture {
                transform: scale(1.05);
            }
        }
    `,
    gridWithTeaser: css`
        &:nth-child(7) {
            padding: 0;
        }
        &:nth-child(7) {
            background-color: #d2d2d2 !important;
            grid-column: 1 / 3;
        }
        ${media.desktopAndUp} {
            &:nth-child(7) {
                background-color: #d2d2d2 !important;
                grid-column: 3 / 5;
            }
        }
    `,
    gridTeaser: css`
        position: relative;
        left: -1px;
        top: -1px;
        min-height: 0;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
    `,
    columnsCentered: css`
        justify-content: center;
    `
};

const VitraProductListing: React.FunctionComponent<VitraProductListingProps> = ({
    className,
    products,
    teaser,
    appearance = 'grid',
    loadFromPrices,
    showAttributes,
    wishlistButtons
}) => {
    // !!! We need to make a clone of "products" because we mutate it later @see array.shift()
    const p = [...products];

    const [productsWithPrices, setProductsWithPrices] = useState<Product[]>(p);
    const bffFromPrice = useFeature(FeatureFlags.prices_fromPrice_productListing);
    useEffect(() => {
        setProductsWithPrices(p);
        const { showPrices } = getBffLanguageAndCountry();
        if (showPrices && loadFromPrices && bffFromPrice && p.length > 0) {
            loadFromPriceData(p).then((p) => setProductsWithPrices(p));
        }
    }, [p]);

    // The main purspose of this function is to inject a teaser into the product listing
    const getRenderItems = (productsToRender: Product[]): any => {
        const renderItems: any = [];

        while (productsToRender.length > 0) {
            if (renderItems.length === 6 && teaser) {
                renderItems.push(
                    <VitraTeaserListing
                        className={styles.gridTeaser}
                        content={teaser.content}
                        options={teaser.options}
                    />
                );
            } else {
                const product = productsToRender.shift();
                renderItems.push(
                    <VitraProduct
                        showAttributes={showAttributes}
                        wishlistButtons={wishlistButtons}
                        product={product!}
                        lazy={true}
                    />
                );
            }
        }
        return renderItems;
    };

    switch (appearance) {
        case 'grid':
        case 'slider':
            return (
                <VitraGrid
                    className={cx(styles.gridBordered, className)}
                    columnClassName={cx(styles.gridItemBordered, teaser && styles.gridWithTeaser)}
                >
                    {getRenderItems(productsWithPrices)}
                </VitraGrid>
            );
        case 'columns':
        default:
            return (
                <VitraColumns
                    columns={[
                        { media: media.mobileX, columns: 2 },
                        { media: media.tablet, columns: 3 },
                        { media: media.desktop, columns: 4 }
                    ]}
                    className={cx(products.length < 4 && styles.columnsCentered, className)}
                >
                    {getRenderItems(productsWithPrices)}
                </VitraColumns>
            );
    }
};
export default VitraProductListing;
