// tslint:disable max-classes-per-file
import { EventEmitterCall, useEvents } from 'common/hooks/useEvents';
import ArrowLeft from 'common/primitives/icons/components/V1ArrowLeft';
import ArrowRight from 'common/primitives/icons/components/V1ArrowRight';
import VitraPagination from 'common/primitives/pagination/vitraPagination';
import { rh } from 'common/styles';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export interface VitraSwiperProps {
    mediaItemRef?: any;
    /**
     * The className of the main element
     */
    className?: string;
    /**
     * Optional Arrow Right
     */
    arrowRight?: JSX.Element;
    /**
     * Optional Arrow Right Class
     */
    arrowsClassName?: string;
    /**
     * Optional Arrow Left
     */
    arrowLeft?: JSX.Element;
    /**
     * Show Arrows
     */
    showArrows?: boolean;
    /**
     * Show Pagination
     */
    showPagination?: boolean;
    /**
     * If the Swiper should auto scroll
     * eg. `3000` for a 3sek interval
     */
    auto?: number;
    /**
     * The id for the event bus
     */
    eventId?: string;

    /**
     * The Embla options
     */
    options: EmblaOptionsType;

    /**
     * On Change to slide
     */
    onChange?: (index: number) => void;
    /**
     * When the icon settles
     */
    onSettle?: (index: number) => void;

    /**
     * Called when ths
     */
    onScroll?: () => void;
}

interface VitraSwiperSlideProps {
    className?: string;
    style?: any;
}

const styles = {
    wrapper: css`
        position: relative;
    `,
    slider: css`
        display: flex;
        width: 100%;
    `,
    slideSlide: css`
        position: relative;
    `,
    pagination: css`
        margin-top: -${rh(2)};
        position: absolute;
        z-index: 1;
    `,
    arrow: css`
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 10;
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: ${rh(1.5)};
            height: ${rh(1.5)};
        }
    `,
    arrowLeft: css`
        left: 0;
    `,
    arrowRight: css`
        right: 0;
    `,
    embla: css`
        overflow: hidden;
    `
};

let Swiper: React.FunctionComponent<VitraSwiperProps> = ({
    className,
    showArrows,
    arrowsClassName,
    arrowLeft,
    arrowRight,
    onChange,
    onSettle,
    onScroll,
    mediaItemRef,
    showPagination,
    auto,
    children,
    eventId,
    options
}) => {
    const { loop } = options;
    options.startIndex = options.startIndex === undefined ? 0 : options.startIndex;
    const [emblaRef, embla] = useEmblaCarousel({ skipSnaps: true, ...options });
    const [currentIndex, setCurrentIndex] = useState<number>(options.startIndex);
    // const [timerMs, setTimerMs] = useState<number>(auto || 0);

    const clearTimer = () => {
        // setTimerMs(0);
    };

    if (mediaItemRef) {
        useImperativeHandle(mediaItemRef, () => ({
            next: () => {
                onRight();
            },
            prev: () => {
                onLeft();
            },
            hasNext: (): boolean => {
                if (embla) {
                    return embla.canScrollNext();
                }
                return false;
            },
            hasPrev: (): boolean => {
                if (embla) {
                    return embla.canScrollPrev();
                }
                return false;
            }
        }));
    }

    useEffect(() => {
        if (onChange) {
            onChange(currentIndex);
        }
    }, [currentIndex]);

    /*
    Inter Component/Widget Communication via events
    */
    let emit: EventEmitterCall;
    if (eventId) {
        const onSetIndex = (ix: number) => {
            setCurrentIndex(ix);

            if (embla) {
                clearTimer();
                embla.scrollTo(ix);
            }
        };

        const scrollNext = () => {
            if (embla) {
                clearTimer();
                embla.scrollNext();
            }
        };

        const scrollPrev = () => {
            if (embla) {
                clearTimer();
                embla.scrollPrev();
            }
        };

        emit = useEvents(eventId, { onSetIndex, scrollNext, scrollPrev });
    }

    /*
    Auto swipe via useInterval

    To halt the Interval we can either pass 0 or null

    - We halt the swiper if the window is not in focus
    - We halt he swiper for some period after the user swiped by himself
    */
    if (auto) {
        // useInterval(
        //     () => {
        //         if (embla) {
        //             if (embla.canScrollNext()) {
        //                 embla.scrollNext();
        //             } else {
        //                 embla.scrollTo(0);
        //             }
        //         }
        //     },
        //     timerMs,
        //     true
        // );
    }

    const startTimer = () => {
        if (auto) {
            // setTimerMs(auto);
        }
    };

    const displayLeftArrow =
        showArrows &&
        children &&
        (((children as any).length > 1 && currentIndex > 0) || (loop && (children as any).length >= 2));

    const displayRightArrow =
        showArrows &&
        children &&
        (children as any).length > 1 &&
        (currentIndex !== (children as any).length - 1 || loop);

    const onLeft = (e: any = undefined) => {
        if (e) {
            e.preventDefault();
        }

        if (embla) {
            clearTimer();
            embla.scrollPrev();
        }
    };

    const scrollToIndex = (index: number) => {
        if (embla) {
            clearTimer();
            embla.scrollTo(index);
        }
    };

    const onRight = (e: any = undefined) => {
        if (e) {
            e.preventDefault();
        }
        if (embla) {
            clearTimer();
            embla.scrollNext();
        }
    };

    useEffect(() => {
        if (embla) {
            if (canUseDOM) {
                // this is nasty but needed to correctly initialize the slider within a modal
                setTimeout(() => {
                    embla.reInit(options);
                }, 300);

                embla.on('select', () => {
                    const ix = embla.selectedScrollSnap();
                    /**
                     * I commented out setCurrentIndex because it breaks the
                     * first animation e.g. if you click on the arrow
                     * on the first slide...
                     */
                    setCurrentIndex(ix);
                });
                if (onScroll) {
                    embla.on('scroll', () => {
                        onScroll();
                    });
                }
                if (auto) {
                    startTimer();
                    embla.on('pointerDown', () => {
                        clearTimer();
                    });
                }
                embla.on('settle', () => {
                    if (onSettle) {
                        onSettle(embla.selectedScrollSnap());
                    }
                    if (emit && !embla.canScrollNext()) {
                        emit('reachedEnd');
                    }
                });
            }
        }
        return () => {
            clearTimer();
        };
    }, [embla]);

    return (
        <div className={cx(styles.wrapper, className)}>
            {displayLeftArrow && (
                <a
                    href="#"
                    className={cx('swiper__arrow swiper__arrow--left', styles.arrow, styles.arrowLeft, arrowsClassName)}
                    onClick={onLeft}
                >
                    {arrowLeft ? arrowLeft : <ArrowLeft />}
                </a>
            )}
            <div className={cx('embla', styles.embla)} ref={emblaRef as any}>
                <div className={cx('embla__slider', styles.slider)}>{children}</div>
            </div>
            {showPagination && (
                <VitraPagination
                    className={styles.pagination}
                    onClick={scrollToIndex}
                    paginationTotal={(children as any).length}
                    paginationCurrent={currentIndex}
                />
            )}
            {displayRightArrow && (
                <a
                    href="#"
                    className={cx(
                        'swiper__arrow swiper__arrow--right',
                        styles.arrow,
                        styles.arrowRight,
                        arrowsClassName
                    )}
                    onClick={onRight}
                >
                    {arrowRight ? arrowRight : <ArrowRight />}
                </a>
            )}
        </div>
    );
};

const Slide: React.FunctionComponent<VitraSwiperSlideProps> = ({ children, className, style }) => {
    return (
        <div className={cx(className, 'embla__slide', styles.slideSlide)} style={style}>
            {children}
        </div>
    );
};

Swiper = forwardRef(Swiper as any);

export { Slide, Swiper };
