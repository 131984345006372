import { colors, linkColor, media, rh } from 'common/styles';
import { fuzzySearch, highlighText } from 'common/utils/search';
import { css, cx } from 'linaria';
import React, { useRef, useState } from 'react';
import Translate, { useTranslations } from '../translations';
import { ListingFilterCategory, ListingFilterValue } from './types';

import { useBreakpoint } from 'common/hooks/useBreakpoints';
import { useEvents } from 'common/hooks/useEvents';
import V2Check from 'common/primitives/icons/components/V2Check';
import partition from 'common/utils/partition';

interface VitraListingFilterList {
    category: ListingFilterCategory;
    activeFilters: string[];
    hasSearch?: boolean;
    onSelect: (key: string, value: string) => void;
    onClose: () => void;
    onReset: (id: string) => void;
}

interface FilterActions {
    categoryId: string;
    onSearch?: ((term: string) => void) | false;
    onClose: () => void;
    onReset: (id: string) => void;
}

const styles = {
    filterList: css``,
    filterListFixedHeight: css`
        position: relative;
        /* This is fixed to cut the rows */
        height: 370px;
        /* min-height: 200px;
        height: auto; */
        overflow-x: scroll;
        padding-bottom: 20px;
    `,
    filterListItems: css``,
    filterListItemsColumns: css`
        padding: ${rh(1)} 0 ${rh(0.25)} 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    `,
    filterListColumn: css`
        ${media.tablet} {
            width: 50%;
        }
        ${media.desktop} {
            width: 33%;
        }
        ${media.desktopX} {
            width: 25%;
        }
    `,
    filterListLink: css`
        padding: ${rh(0.125)} 32px ${rh(0.125)} 0;
        display: inline-flex;
        width: 100%;
        line-height: ${rh(0.75)};
        ${linkColor(colors.black, colors.textGrey)};
        font-weight: 100;
        text-decoration: none;
        strong {
            font-style: normal;
            font-weight: 600;
        }
    `,
    filterListLinkActive: css`
        ${linkColor(colors.black, colors.black)};
    `,
    filterListLinkDisabled: css`
        opacity: 0.5;
        pointer-events: none;
    `,
    filterListActionsHasSearch: css`
        border-top: 1px solid ${colors.lightgrey};
    `,
    filterListActions: css`
        display: flex;
        align-items: center;
        padding: ${rh(0.25)} 0 ${rh(0.5)};
        a {
            ${linkColor(colors.black, colors.textGrey)};
            margin-left: ${rh(0.5)};
        }
    `,
    checkbox: css`
        flex-shrink: 0;
        margin-top: 3px;
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid ${colors.lightgrey};
        margin-right: 16px;
        position: relative;
        top: -1px;
        svg {
            position: absolute;
            top: 0px;
            left: 2px;
        }
    `,
    filterListItemsRow: css`
        margin-right: ${rh(0.5)};
        width: 25%;
    `,
    filterListItemsRowHeader: css`
        font-weight: 100;
        font-size: 14px;
        color: ${colors.textGrey};
    `,
    filterListItemsRowItems: css``,
    filterListItemsRowLink: css`
        width: 100%;
        padding-left: 0;
    `,
    filterListSearch: css``,
    fiterListLinks: css`
        margin-left: auto;
        a {
            ${linkColor(colors.textGrey, colors.primary)};
            text-decoration: none;
            font-size: 14px;
            font-weight: 100;
        }
    `,
    filterListInput: css`
        color: ${colors.black};
        border: 1px solid ${colors.inputBorderColor};
        padding: ${rh(0.4)} 16px;
        font-size: 1rem;
        &:focus {
            border: 1px solid ${colors.inputBorderColor};
            outline: none;
        }
    `,
    filterListLinkHighlighted: css`
        font-weight: 500;
    `,
    searchNotFound: css``
};

const FilterListCheckbox = ({ isActive }: { isActive: boolean }): JSX.Element => (
    <div className={styles.checkbox}>{isActive && <V2Check />}</div>
);

const FilterListLink = ({ value, isActive, toggleItem, className, term, isHighlighted }: any) => (
    <a
        href={value.link ? value.link.href : '#'}
        onClick={toggleItem(value)}
        className={cx(
            className,
            styles.filterListLink,
            isActive && styles.filterListLinkActive,
            isHighlighted && styles.filterListLinkHighlighted,
            value.disabled && styles.filterListLinkDisabled
        )}
    >
        <FilterListCheckbox isActive={isActive} />
        <span dangerouslySetInnerHTML={{ __html: highlighText(term, value.name) }} />
    </a>
);

export const VitraListingFilterActions: React.FunctionComponent<FilterActions> = ({
    onClose,
    onSearch,
    onReset,
    categoryId
}) => {
    const closeFilter = (e: any) => {
        e.preventDefault();
        onClose();
    };
    const resetFilter = (e: any) => {
        e.preventDefault();
        onReset(categoryId);
    };

    // Clear the input from the outside via an event
    // used if the filter dropdown closes
    const filterSearchRef = useRef();
    const onCloseFilter = () => {
        if (onSearch) {
            onSearch('');
        }
        if (filterSearchRef && filterSearchRef.current) {
            (filterSearchRef.current as any).value = '';
        }
    };
    useEvents('listing', { onCloseFilter });

    return (
        <div className={cx(styles.filterListActions, !!onSearch && styles.filterListActionsHasSearch)}>
            {!!onSearch && (
                <div className={styles.filterListSearch}>
                    <input
                        className={styles.filterListInput}
                        onChange={(e) => onSearch(e.target.value)}
                        placeholder={useTranslations('listing.filter.search', 'Search')}
                        type="text"
                        name="seach"
                        ref={filterSearchRef as any}
                    />
                </div>
            )}
            <div className={styles.fiterListLinks}>
                <a onClick={resetFilter} href="#">
                    <Translate id="listing.filter.remove" defaultMessage="Remove filter" />
                </a>
                <a onClick={closeFilter} href="#">
                    <Translate id="listing.filter.close" defaultMessage="Close filter" />
                </a>
            </div>
        </div>
    );
};

export const VitraListingFilterList: React.FunctionComponent<VitraListingFilterList> = ({
    category,
    activeFilters,
    onClose,
    onReset,
    hasSearch,
    onSelect
}) => {
    const values = category.items || [];
    const [highlighted, setHighlighted] = useState<string[]>([]);
    const [term, setTerm] = useState<string>('');

    const toggleItem = (value: ListingFilterValue) => (e: any) => {
        e.preventDefault();
        if (!value.disabled) {
            onSelect(category.id, value.id);
        }
    };

    const onSearch = (t: string) => {
        setTerm(t);
        const itemIds = values
            .filter((value: ListingFilterCategory) => fuzzySearch(t.toLowerCase(), value.name.toLocaleLowerCase()))
            .map((value: ListingFilterCategory) => value.id.toLocaleLowerCase());

        setHighlighted(itemIds);
    };

    // We must partition the array to display the filers in columns with filters in alphabetical order
    const breakpoint = useBreakpoint('desktop');
    const numPartitions = {
        desktopX: 4,
        desktop: 3,
        tablet: 2
    };

    const partitions = partition(values, (numPartitions as any)[breakpoint] || 1);

    return (
        <div className={styles.filterList}>
            <div className={cx(styles.filterListItemsColumns, !!hasSearch && styles.filterListFixedHeight)}>
                {partitions.map((column: Array<ListingFilterValue>) => (
                    <div className={styles.filterListColumn}>
                        {column.map((value: ListingFilterValue) => {
                            const isActive = activeFilters.includes(value.id.toLocaleLowerCase());
                            const isHighlighted = highlighted.includes(value.id.toLocaleLowerCase());
                            if (term && !isHighlighted) {
                                return false;
                            }
                            return (
                                <FilterListLink toggleItem={toggleItem} term={term} isActive={isActive} value={value} />
                            );
                        })}
                    </div>
                ))}
                {values.length === 0 && term !== '' && (
                    <div className={styles.searchNotFound}>
                        <Translate
                            id="listing.filter.not_found"
                            defaultMessage="No results found for {term}"
                            data={{ term }}
                        />
                    </div>
                )}
            </div>

            <VitraListingFilterActions
                onSearch={hasSearch && onSearch}
                onClose={onClose}
                onReset={onReset}
                categoryId={category.id}
            />
        </div>
    );
};

export const VitraListingFilterListRows: React.FunctionComponent<VitraListingFilterList> = ({
    category,
    activeFilters,
    onClose,
    onReset,
    onSelect
}) => {
    const values = category.items || [];

    const toggleItem = (value: ListingFilterValue) => (e: any) => {
        e.preventDefault();
        if (!value.disabled) {
            onSelect(category.id, value.id);
        }
    };

    return (
        <div className={styles.filterList}>
            <div className={cx(styles.filterListItems, styles.filterListItemsColumns)}>
                {values.map((row: any) => {
                    const child = row.items || [];
                    return (
                        <div className={styles.filterListItemsRow}>
                            <div className={styles.filterListItemsRowHeader}>{row.name}</div>
                            <div className={styles.filterListItemsRowItems}>
                                {child.map((value: ListingFilterValue) => {
                                    const isActive = activeFilters.includes(value.id);
                                    return (
                                        <FilterListLink
                                            className={styles.filterListItemsRowLink}
                                            toggleItem={toggleItem}
                                            isActive={isActive}
                                            value={value}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <VitraListingFilterActions onSearch={false} onClose={onClose} onReset={onReset} categoryId={category.id} />
        </div>
    );
};
