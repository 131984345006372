import { colors, linkColor, rh } from 'common/styles';
import { css, cx } from 'linaria';
import {
    ListingActivefilter,
    ListingFilterCategory,
    ListingFilterExtractedValue,
    ListingFilterSelected
} from './types';

import flatten from 'lodash/flatten';
import React from 'react';
import VitraTag from '../tags/vitraTag';
import Translate from '../translations';

const styles = {
    selectedFilters: css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        gap: ${rh(0.5)};
        padding-top: ${rh(0.75)};
        padding-bottom: ${rh(0.75)};
    `,
    removeAllFiltersLink: css`
        font-weight: 500;
        ${linkColor(colors.textGrey, colors.primary)};
        padding: ${rh(0.25)} 0;
        font-size: 14px;
    `
};

export const extractValuesFromFilter = (
    categories: ListingFilterCategory[],
    filter: ListingActivefilter
): ListingFilterExtractedValue[] => {
    const category = categories.find((c) => c.id.toLocaleLowerCase() === filter.id.toLocaleLowerCase());
    if (!category) {
        return [];
    }
    const children = category.items || [];
    switch (category.type) {
        case 'list':
        case 'list-search':
            return filter.values.map((id) => {
                const item = children.find((v: any) => v.id.toLowerCase() === id);
                return {
                    id: item.id.toLocaleLowerCase(),
                    name: item.name,
                    categoryId: category.id
                };
            });
        case 'list-rows':
            // eslint-disable-next-line no-case-declarations
            const values = flatten(children.map((v: any) => v.items)) as any;
            return filter.values.map((id) => {
                const item = values.find((v: any) => v.id.toLowerCase() === id);
                return {
                    id: item.id.toLocaleLowerCase(),
                    name: item.name,
                    categoryId: category.id
                };
            });
        case 'custom':
            if (category.extract) {
                return category.extract(filter, category);
            }
            break;
    }

    return [];
};

const VitraListingFilterSelected: React.FunctionComponent<ListingFilterSelected> = ({
    activeFilters,
    removeFilter,
    categories,
    onClearAllFilters,
    className
}) => {
    // Remove a single Filter
    const remove = (id: string, value: string) => (e: any) => {
        e.preventDefault();
        removeFilter(id, value);
    };

    // Remove all Filters
    const removeAll = (e: any) => {
        e.preventDefault();
        onClearAllFilters();
    };

    return (
        <div className={cx(styles.selectedFilters, className)}>
            {activeFilters.map((filter) => {
                const items = extractValuesFromFilter(categories, filter);
                return items.map((item) => {
                    return <VitraTag label={item.name} onClick={remove(item.categoryId, item.id)} />;
                });
            })}
            {activeFilters.length > 0 && (
                <a href="#" className={styles.removeAllFiltersLink} onClick={removeAll}>
                    <Translate id="listing.filter.remove_all" defaultMessage="Delete selection" />
                </a>
            )}
        </div>
    );
};

export default VitraListingFilterSelected;
