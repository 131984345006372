import { BffCartResultShippingCostPrice, BffCartResultTotalPrice, BffProductPrice } from 'common/graphql/sdk';
import { useConfig } from 'common/hooks/useBoostrap';
import { reportError } from 'common/hooks/useError';
import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import template from 'lodash/template';
import React, { createContext, useContext } from 'react';

interface TranslateProviderProps {
    /**
     * The translations as dict
     */
    translations: Dict<string>;
}

interface TranslateProps {
    /**
     * The key of the Translations
     */
    id: string;

    /**
     * If the element has html
     * It get's wrapped in a div and set via innerHtml
     */
    html?: boolean;

    /**
     * Optional Classname if a html element is used
     */
    className?: string;

    /**
     * The default Message of the Translation
     * this gets used in the translate connector
     */
    defaultMessage?: string;

    /**
     * A dict with variables to replace
     * for the text: Aktuell befinden sich <strong>{count} Produkte</strong> in Ihrer persönlichen Wunschliste
     * eg. `{count: 3}`
     */
    data?: Dict<string>;
}

export const compileTemplate = (translation: string, vars: Dict<string>): string => {
    if (!vars) {
        return translation;
    }
    try {
        const compiled = template(translation, { interpolate: /{([\s\S]+?)}/g });
        return compiled(vars);
    } catch (err) {
        return translation;
    }
};

export const TranslateCtx = createContext({});

export const TranslateProvider: React.FunctionComponent<TranslateProviderProps> = ({ children, translations }) => {
    return <TranslateCtx.Provider value={translations}>{children}</TranslateCtx.Provider>;
};

export const useTranslations = (id: string, defaultMessage?: string, data?: Dict<string>): string => {
    try {
        const translations = useContext(TranslateCtx) || ({} as any);
        const str = get(translations, id) || defaultMessage || id;
        if (!data) {
            return str;
        }
        return compileTemplate(str, data);
    } catch (err) {
        if (canUseDOM && window.location.host.includes('vitra.com')) {
            reportError(new Error('Translate Context Error'), 'translate', { id, severity: 'critical' }, { id });
        }
    }
    if (!data) {
        return defaultMessage || id;
    }
    return compileTemplate(defaultMessage || id, data);
};

const Translate: React.FunctionComponent<TranslateProps> = ({ id, html, data, className, defaultMessage }) => {
    const translations = useContext(TranslateCtx) || ({} as any);
    let text = get(translations, id);

    // Interpolate the template string
    if (!isEmpty(data)) {
        text = compileTemplate(text, data as Dict<string>);
    }

    if (!text && data && defaultMessage) {
        text = compileTemplate(defaultMessage, data as Dict<string>);
    }

    if (!text && !data) {
        text = defaultMessage;
    }

    if (!text) {
        text = id;
    }

    if (!html && !className) {
        return text;
    }

    return <div className={className} data-testid={id} dangerouslySetInnerHTML={{ __html: text }} />;
};

export const getLocalizedPrice = (
    total?: BffCartResultTotalPrice | BffProductPrice | BffCartResultShippingCostPrice
): string => {
    if (!total) {
        return '';
    }
    if (total.raw === undefined || !total.currencyCode) {
        return total.formatted ?? '';
    }
    return (
        total.raw?.toLocaleString(useConfig('code', 'de-de'), {
            style: 'currency',
            currency: total.currencyCode,
            currencyDisplay: 'code'
        }) || ''
    );
};

export default Translate;
