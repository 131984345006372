import { colors, containerCentered, fontSizeAndLineHeight, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect } from 'react';

import useToggle from 'common/hooks/useToggle';
import { canUseDOM } from 'exenv';
import VitraLink from '../links/vitraLink';

const styles = {
    wrapper: css`
        z-index: 5000;
        transition: all 0.5s;
        position: fixed;
        top: -100px;
        width: 100%;
        background: ${colors.black};
        color: #fff;
        ${fontSizeAndLineHeight('14px', 0.75)}
        ${media.tablet} {
            ${fontSizeAndLineHeight('17px')}
        }
    `,
    wrapperOpen: css`
        top: 0;
    `,
    inner: css`
        padding: ${rh(0.5)};
        ${media.tablet} {
            display: flex;
        }
    `,
    message: css`
        strong {
            font-weight: 600;
        }
    `,
    action: css`
        ${linkColor(colors.white, colors.white)};
        margin-left: auto;
        text-align: center;
        width: 100%;
        display: block;
        ${media.tablet} {
            width: auto;
        }
    `
};

export interface VitraPopoverProps {
    /**
     * The wrapper className
     */
    className?: string;
    /**
     * If the item removes itself
     */
    autoRemove?: boolean;
    /**
     * The Message
     */
    message?: any;
    /**
     * Action Link
     */
    action?: Link;
    /**
     * The id is useful if the message doesnt change
     * So we can trigger the popver via a diffrent id
     */
    id?: string;
}

const VitraPopover: React.FunctionComponent<VitraPopoverProps> = ({ message, autoRemove, action, id }) => {
    const [isOpen, toggleOpen] = useToggle(false);
    const revert = (e: any) => {
        e.preventDefault();
        if (action && action.onClick) {
            toggleOpen(false);
            action.onClick();
        }
    };

    useEffect(() => {
        toggleOpen(!!message);
        if (canUseDOM && message && autoRemove) {
            setTimeout(() => {
                toggleOpen(false);
            }, 5000);
        }
    }, [message, id]);

    return (
        <div className={cx(isOpen && styles.wrapperOpen, styles.wrapper)}>
            <div className={containerCentered}>
                <div className={styles.inner}>
                    <div className={styles.message}>{message}</div>
                    {action && <VitraLink onClick={revert} className={styles.action} {...action} />}
                </div>
            </div>
        </div>
    );
};

export default VitraPopover;
