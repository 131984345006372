import * as React from 'react';

const SvgV2ContactProject = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
        <path d="M55 31.38H25v21h30zM34.6 45.77a4.77 4.77 0 01-.62-9.5v5.36h5.35a4.77 4.77 0 01-4.73 4.14zm15.58 0h-7.75v-2.35h7.75zm0-7.19h-7.75v-2.35h7.75z" />
        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zm17.37 53.6a1.17 1.17 0 01-1.17 1.17h-15v3.6h-2.38v-3.6h-15a1.17 1.17 0 01-1.18-1.17V29h34.73zm0-27.62H22.62v-2.35h34.75z" />
    </svg>
);

export default SvgV2ContactProject;
