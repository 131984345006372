import { BffCartAddress, BffCustomerAddress } from 'common/graphql/sdk';
import { useConfig } from 'common/hooks/useBoostrap';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';
import { useTranslations } from '../translations';
import { AddressOutputFormatDescription, defaultAddressFormat } from './vitraAddressConfig';
import { AddressState } from './vitraAddressFormTypes';

interface VitraAddressFormattedProps {
    addressFormat?: AddressOutputFormatDescription;
    addressStates?: AddressState[];
    addressValues: BffCustomerAddress | BffCartAddress;
    className?: string;
}

const styles = {
    address: css`
        div:first-child:not(:empty) {
            font-weight: 600;
        }
    `
};

/**
 * Formats adress values of the type CheckoutAddressValues | CheckoutAddress
 * to a given country specific format
 */
const VitraAddressFormatted: React.FunctionComponent<VitraAddressFormattedProps> = ({
    addressFormat = defaultAddressFormat,
    addressStates,
    addressValues,
    className
}) => {
    // Get the full countryname eg. de -> Deutschland
    const countriesData = JSON.parse(useConfig('countriesData', '{}'));
    const getFullCountryName = (countryShort: string) => {
        return countriesData[countryShort.toUpperCase()] ?? countryShort;
    };

    // Get the full regionname eg. AL-> Alabama
    const getFullRegionName = (regionShort: string, addressStates: AddressState[] | undefined): string => {
        if (!addressStates) {
            return regionShort;
        }
        return addressStates.find((state) => state.value === regionShort)?.name ?? regionShort;
    };

    const translations = {
        mr: useTranslations('address.fields.salutation.mr', 'Mr'),
        ms: useTranslations('address.fields.salutation.ms', 'Ms'),
        mrms: useTranslations('address.fields.salutation.mx', 'Mx')
    };

    // First filter out any rows which would render as completly emtpy rows
    const addressRowsToRender = addressFormat.filter((row) => row.some((r) => !!(addressValues as any)[r]));

    return (
        <div className={cx('address', styles.address, className)}>
            {addressRowsToRender.map((row) => (
                <div className="addressRow">
                    {row
                        .filter((entry) => (addressValues as any)[`${entry}`])
                        .map((entry) => {
                            const value = (addressValues as any)[`${entry}`];
                            if (entry === 'salutation') {
                                return (
                                    <Fragment>
                                        {translations[value.toLowerCase() as keyof typeof translations]}&nbsp;
                                    </Fragment>
                                );
                            }
                            if (entry === 'country') {
                                return (
                                    <span className={`rowItem rowItem--${entry}`}>
                                        {value}&nbsp;({getFullCountryName(value)})&nbsp;
                                    </span>
                                );
                            }
                            if (entry === 'region') {
                                return (
                                    <span className={`rowItem rowItem--${entry}`}>
                                        /&nbsp;{getFullRegionName(value, addressStates)}&nbsp;
                                    </span>
                                );
                            }
                            return <span className={`rowItem rowItem--${entry}`}>{value}&nbsp;</span>;
                        })}
                </div>
            ))}
        </div>
    );
};

export default VitraAddressFormatted;
