import { useBoostrap } from 'common/hooks/useBoostrap';
import { reportError } from 'common/hooks/useError';
import { get } from 'common/utils/fetch';
import { getSearchPageUrl } from 'common/utils/search';
import { getCurrentPageLanguage } from 'components/main-navigation-v2/common/navigationLocale';
import { SearchResult } from 'components/main-navigation-v2/main-navigation-types';
import intersection from 'lodash/intersection';
import take from 'lodash/take';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import wade from 'wade';

// needs to live outside of the function to be able to cache the search index
let searchIndex: any = null;
let searchData: any = null;
export const createSearchRequest = <T extends { name: string }>(
    externalSearchData?: T[]
): ((query: string) => Promise<T[]>) => {
    if (externalSearchData) {
        // this is only used in storybook
        searchData = externalSearchData;
    }
    return async (query: string) => {
        if (!searchIndex) {
            const locale = getCurrentPageLanguage();
            if (!searchData) {
                const searchConfig = useBoostrap('search', false);
                if (!searchConfig) {
                    console.warn('search not found in boostrap');
                    return [];
                }
                try {
                    const res: any = await get(searchConfig);
                    searchData = [...res.products, ...res.designer];
                    searchIndex = wade((searchData ?? []).map((d: any) => d.name));
                } catch (err) {
                    reportError(err, 'search', { query, locale });
                }
            }
        }

        // make results
        let results: any = [];
        const searchWords = `${query}`.trim().split(' ');

        if (searchIndex) {
            const result = searchIndex(query)
                .map((item: any) => (searchData ?? [])[item.index])
                .filter(Boolean);

            const searchWordsLower = searchWords.map((t) => `${t.toLowerCase()}`);

            results = result.sort((a: any, b: any) => {
                const aSorting = intersection(
                    a.name.split(' ').map((t: any) => `${t.toLowerCase()}`),
                    searchWordsLower
                ).length;
                const bSorting = intersection(
                    b.name.split(' ').map((t: any) => `${t.toLowerCase()}`),
                    searchWordsLower
                ).length;

                return aSorting > bSorting ? -1 : 1;
            });
        }

        return take(results, 7) as [];
    };
};

interface QuickSearchContextAttributes {
    /** the currently entered search term */
    searchTerm: string;
    /** setter function for next search term */
    updateSearchTerm: (newSearchTerm: string) => void;
    /** the results that the search Term has delivered */
    searchResults: SearchResult[];
    /** the URL of the search page with all results for the current search term */
    searchPageUrl: string;
}

const QuickSearchContext = createContext<QuickSearchContextAttributes>({
    searchTerm: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateSearchTerm: () => {},
    searchResults: [],
    searchPageUrl: ''
});

interface QuickSearchProviderProps {
    /** child elements that need quick search functionality */
    children: ReactNode;
    /** value to initialize the search term */
    initialSearchTerm?: string;
    /** function to perform the actual search */
    searchFn?: (searchString: string) => Promise<SearchResult[]>;
}

export const QuickSearchProvider: React.FC<QuickSearchProviderProps> = ({ children, initialSearchTerm, searchFn }) => {
    const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm ?? '');
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const getResults = async () => {
        try {
            const searchFunc: any = searchFn ? searchFn : createSearchRequest();
            setSearchResults(await searchFunc(searchTerm));
        } catch (err) {
            reportError(err, 'search');
            setSearchResults([]);
        }
    };
    useEffect(() => {
        if (searchTerm != '') {
            getResults();
        }
    }, [searchTerm]);

    return (
        <QuickSearchContext.Provider
            value={{
                searchTerm,
                updateSearchTerm: setSearchTerm,
                searchResults,
                searchPageUrl: getSearchPageUrl(searchTerm)
            }}
        >
            {children}
        </QuickSearchContext.Provider>
    );
};

export const useQuickSearch = (): QuickSearchContextAttributes => useContext(QuickSearchContext);
