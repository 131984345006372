import * as React from 'react';

const SvgV2Grid = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={14} height={15} viewBox="0 0 14 15" {...props}>
        <defs>
            <style>{'.V2-grid_svg__cls-3{fill:#333}'}</style>
            <clipPath id="V2-grid_svg__clip-path">
                <path d="M0 0h14v15H0z" />
            </clipPath>
        </defs>
        <g id="V2-grid_svg__icon_single_grid" clipPath="url(#V2-grid_svg__clip-path)" data-name="icon/single/grid">
            <g transform="translate(1 -60)">
                <path
                    id="V2-grid_svg__Rechteck_11766"
                    d="M0 0h5v5H0z"
                    className="V2-grid_svg__cls-3"
                    data-name="Rechteck 11766"
                    transform="translate(-1 60)"
                />
            </g>
            <g transform="translate(10 -60)">
                <path
                    id="V2-grid_svg__Rechteck_11766-2"
                    d="M0 0h5v5H0z"
                    className="V2-grid_svg__cls-3"
                    data-name="Rechteck 11766"
                    transform="translate(-1 60)"
                />
            </g>
            <g transform="translate(1 -51)">
                <path
                    id="V2-grid_svg__Rechteck_11766-3"
                    d="M0 0h5v5H0z"
                    className="V2-grid_svg__cls-3"
                    data-name="Rechteck 11766"
                    transform="translate(-1 60)"
                />
            </g>
            <g transform="translate(10 -51)">
                <path
                    id="V2-grid_svg__Rechteck_11766-4"
                    d="M0 0h5v5H0z"
                    className="V2-grid_svg__cls-3"
                    data-name="Rechteck 11766"
                    transform="translate(-1 60)"
                />
            </g>
        </g>
    </svg>
);

export default SvgV2Grid;
