import * as React from 'react';

const SvgV2CloseSmall = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
        <g fillRule="nonzero">
            <path d="M1.06 0l14.142 14.142-1.06 1.06L0 1.062z" />
            <path d="M15.204 1.414L1.062 15.556l-1.06-1.06L14.142.354z" />
        </g>
    </svg>
);

export default SvgV2CloseSmall;
