/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Sentry from '@sentry/browser';
import { canUseDOM } from 'exenv';

export const reportError = (error: any, label?: string, data?: any, context?: any): void => {
    if (!canUseDOM) {
        return;
    }

    if (process.env.NODE_ENV !== 'production') {
        console.error(error);
    } else {
        // Skip emersya errors
        if (label === 'emersya') {
            return;
        }
        Sentry.configureScope((scope) => {
            if (label && label !== '') {
                scope.setTag('label', label);
                scope.setTag('system', label);
            }
            if (data) {
                scope.setExtras(data);
                if (data.severity) {
                    scope.setTag('severity', data.severity);
                }
                if (data.system) {
                    scope.setTag('system', data.system);
                }
            }
            if (context) {
                scope.setContext(label!, context);
            }
            Sentry.captureException(error);
        });
    }
};
